import React, { useState, useEffect, memo } from 'react';

import './Outages.css'

import OutageRow from './OutageRow';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'
import outagesComponentToShowState, { outagesListTicketState, outagesListTickeClosedtState } from '../../atoms/outages_state.atom';


import { api_bd } from '../../services/Api'

var qtd_closed_events = ''
function TicketList(props) {
    const [eventList, setEventList] = useRecoilState(outagesListTicketState)
    const [eventListClosed, setEventListClosed] = useRecoilState(outagesListTickeClosedtState)
    const [qtdClosedEvents, setQtdClosedEvents] = useState('30')
    const profile_user = useRecoilValue(profileData)
    const componentToShow = useRecoilValue(outagesComponentToShowState)

    const buscarEventos = async () => {
        let tmpEv = await api_bd.get(`/outages/status/aberto`)
        if (tmpEv.data.length === 0) {
            // setLoadMessage('Nenhum evento encontrado para os filtros selecionados')
        } else {
            if (componentToShow === null) {
                setEventList(tmpEv.data)
            }
        }
    }

    const buscarEventosFechados = async (_qtd) => {
        let tmpEv = await api_bd.get(`/outages/status/fechado/${_qtd}`)
        if (tmpEv.data.length === 0) {
            // setLoadMessage('Nenhum evento encontrado para os filtros selecionados')
        } else {
            if (componentToShow === null) {
                setEventListClosed(tmpEv.data)
            }
        }
    }

    useEffect(() => {
        buscarEventos()
        // buscarEventosFechados()
        let interval = setInterval(() => {
            buscarEventos()
            buscarEventosFechados(qtd_closed_events)
        }, 30000)
        return () => {
            clearInterval(interval)
        }
    }, [])


    useEffect(()=>{
        qtd_closed_events = qtdClosedEvents
        buscarEventosFechados(qtd_closed_events)
    },[qtdClosedEvents])



    return (
        <div className='card' style={{ width: '100%', maxHeight: '81vh', display: 'flex', flexDirection: 'row', gap: '5%' }}>

            {/* Content */}

            <div style={{ display: 'flex', flexDirection: 'column', width: '53%', height: '100%' }}>
                <span style={{ fontSize: '1.8em', fontWeight: 'bold', paddingTop: 13, paddingBottom: 30 }}>Eventos em andamento</span>

                <div className="outage-rows-container">
                    {eventList.length > 0 && eventList.map(ev => (
                        <OutageRow ev={ev} key={`${ev._id}`} buscar_eventos={buscarEventos} />
                    ))}

                    {eventList.length === 0 &&
                        <div style={{ fontSize: '1.5em', display: 'flex', width: '100%', justifyContent: 'center' }}>Nenhum evento aberto</div>
                    }
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', width: '40%', height: '100%' }}>
                <div style={{ marginTop: 13, marginBottom: 30, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <span style={{ fontSize: '1.8em', fontWeight: 'bold' }}>Últimos Eventos Finalizados</span>
                    <span style={{ fontSize: '1.8em', fontWeight: 'bold' }}>Qtd:</span>
                    <select 
                        style={{ marginLeft: 10, fontSize: '1.5em', fontWeight: 'bold', color: '#324d8c' }}
                        onChange={(e) => {
                            setQtdClosedEvents(e.target.value)
                        }}
                        defaultValue="30" 
                    >
                        <option selected={qtdClosedEvents === '30' ? true : false} value="30">30</option>
                        <option selected={qtdClosedEvents === '50' ? true : false} value="50">50</option>
                        <option selected={qtdClosedEvents === '100' ? true : false} value="100">100</option>
                        <option selected={qtdClosedEvents === '200' ? true : false} value="200">200</option>
                        <option selected={qtdClosedEvents === 'todos' ? true : false} value="todos">Todos</option>
                    </select>
                </div>
                <div className="outage-rows-container">
                    {eventListClosed.length > 0 && eventListClosed.map(evc => (
                        <OutageRow ev={evc} key={`${evc._id}`} buscar_eventos={buscarEventosFechados} />
                    ))}

                    {eventListClosed.length === 0 &&
                        <div style={{
                            fontSize: '1.5em',
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            Nenhum evento finalizado
                        </div>
                    }
                </div>
            </div>
            {/* /Content */}

        </div>
    )
}

export default TicketList;