import React, {
    useState,
    useEffect
} from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Styles from '../Styles'

import { api_bd } from '../../services/Api'

//Imports para o SELECT
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Autocomplete from '@material-ui/lab/Autocomplete';

import municipios from '../../assets/municipios_brasil.json';

import { useSetRecoilState, useRecoilValue } from 'recoil'
import clientDetailsData from '../../atoms/client_details.atom'

function FormDataAccess(props) {
    const { data_client, width, callbackfn } = props
    const update_client_data = useSetRecoilState(clientDetailsData)

    const styles = Styles();

    const [data_access, set_data_access] = useState({ situacao: 'Ativo', tipo: 'Ativo de Rede' })
    const [submit_is_disabled, set_submit_is_disabled] = useState(false)

    const [type_access, set_type_access] = useState('Ativo de Rede')

    const lista_municipios = [...municipios]

    const [list_stations, set_list_stations] = useState([])

    const carregar_estacoes = async () => {
        const lista = await api_bd.get('/stations')
        const list = lista.data.filter(sta => sta.user === data_client._id).sort((a, b) => a.codigo_estacao.localeCompare(b.codigo_estacao))
        set_list_stations(list)
    }


    const handle_data_access = (key, value) => {
        let data = data_access
        data[key] = value
        set_data_access(data)
        if (key === 'tipo') {
            set_type_access(value)
        }
    }


    useEffect(() => {
        (async () => {
            await carregar_estacoes()
        })()
    }, [])


    return (
        <div className={styles.div_root_component} style={{ width }}>
            <Card className={styles.card}>
                <CardContent>
                    <CardHeader
                        title="Adicionar Acesso"
                        subheader=""
                    />
                    <form className={styles.form_cad_user} noValidate autoComplete="off">

                        <div style={{ width: "100%" }}>
                            <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                <InputLabel htmlFor="select-label">Tipo &nbsp;  *</InputLabel>
                                <Select
                                    defaultValue={'Ativo de Rede'}
                                    labelId="select-label"
                                    id="select-nivel"
                                    // value={type_filter_access}
                                    onChange={e => {
                                        handle_data_access("tipo", e.target.value)
                                    }}
                                >
                                    <MenuItem value="Ativo de Rede">Ativo de Rede</MenuItem>
                                    <MenuItem value="Conta">Conta</MenuItem>
                                    <MenuItem value="Ferramenta">Ferramenta</MenuItem>
                                    <MenuItem value="Servidor">Servidor</MenuItem>
                                    <MenuItem value="VPN">VPN</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div style={{ width: "100%" }}>
                            <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                <InputLabel htmlFor="select-label">Situação &nbsp;  *</InputLabel>
                                <Select
                                    defaultValue={'Ativo'}
                                    labelId="select-label"
                                    id="select-nivel"
                                    // value={type_filter_access}
                                    onChange={e => {
                                        handle_data_access("situacao", e.target.value)
                                    }}
                                >
                                    <MenuItem value="Ativo">Ativo</MenuItem>
                                    <MenuItem value="Inativo">Inativo</MenuItem>
                                </Select>
                            </FormControl>
                        </div>


                        {/* OCULTO */}
                        {/* { false && (type_access === 'VPN' || type_access === 'Ativo de Rede' || type_access === 'Conta' || type_access === 'Servidor' || type_access === 'Ferramenta') &&
                            <div style={{ width: "100%" }}>
                                <Autocomplete
                                    options={lista_municipios}
                                    getOptionLabel={(option) => `${option['municipio_uf']}`}
                                    id="localidade"
                                    autoComplete
                                    includeInputInList
                                    renderInput={(params) => <TextField {...params} label="Localidade   *" margin="normal" />}
                                    onChange={e => {
                                        handle_data_access("localidade", e.target.innerText ? e.target.innerText : "")
                                    }}
                                />
                            </div>
                        } */}


                        {(type_access === 'Ativo de Rede' || type_access === 'Servidor') &&
                            <div style={{ width: "100%" }}>
                                <Autocomplete
                                    options={list_stations}
                                    getOptionLabel={(option) => {
                                        //  PB-JPA-BESS-000 ESTACAO/João Pessoa-PB
                                        let text  = option.codigo_estacao ? `${option.codigo_estacao} `: '' // PB-JPA-BESS-000
                                            text += option.nome ? `${option.nome}/`: '' // ESTACAO/
                                            text += option.localidade?.municipio ? `${option.localidade?.municipio}-` : '' // João Pessoa-
                                            text += option.localidade?.sigla_estado // PB
                                        
                                        return text
                                        // return `${option.codigo_estacao} ${option.nome ? option.nome+'/': (option.codigo_estacao && !option.nome) ? '-': ''}${option.localidade?.municipio ? option.localidade?.municipio+'-' : ''}${option.localidade?.sigla_estado}`   
                                    }}
                                    id="estacao"
                                    autoComplete
                                    includeInputInList
                                    renderInput={(params) => <TextField {...params} label="Estação  *" margin="normal" />}
                                    onChange={async (e) => {
                                        const loc = e.target.innerText ? e.target.innerText.split(' ')[0] : ""
                                        const data = await list_stations.filter((el) => el.codigo_estacao === loc)
                                        if (data.length > 0) {
                                            handle_data_access("estacao", `${data[0].codigo_estacao}`)
                                        } else {
                                            handle_data_access("estacao", '')
                                        }
                                    }}
                                />


                                {/* <Autocomplete
                                    value={list_stations.length > 0 ? {
                                        codigo_estacao: data_access.estacao ? data_access.estacao : 'Selecione',
                                        nome: data_access.estacao ? list_stations.filter((el) => el.codigo_estacao === data_access.estacao)[0].nome : '',
                                        localidade: {
                                            municipio: data_access.estacao ? list_stations.filter((el) => el.codigo_estacao === data_access.estacao)[0].localidade.municipio : '',
                                            sigla_estado: data_access.estacao ? list_stations.filter((el) => el.codigo_estacao === data_access.estacao)[0].localidade.sigla_estado : '',
                                        }

                                    } : { codigo_estacao: '', nome: '', localidade: { municipio: '', sigla_estado: '' } }}
                                    options={list_stations}
                                    getOptionLabel={(option) => `${option.codigo_estacao} ${option.nome ? option.nome+'/': ''}${option.localidade?.municipio ? option.localidade?.municipio+'-' : ''}${option.localidade?.sigla_estado}`}
                                    id="estacao"
                                    autoComplete
                                    includeInputInList
                                    renderInput={(params) => <TextField {...params} label="Estação" margin="normal" />}
                                    onChange={async (e) => {
                                        const loc = e.target.innerText ? e.target.innerText.split(' ')[0] : ""
                                        const data = await list_stations.filter((el) => el.codigo_estacao === loc)
                                        if (data.length > 0) {
                                            handle_data_access("estacao", `${data[0].codigo_estacao}`)
                                        } else {
                                            handle_data_access("estacao", '')
                                        }
                                    }}
                                /> */}
                            </div>
                        }


                        {type_access === 'VPN' &&
                            <>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_data_access("descricao", e.target.value)
                                        }}
                                        id="descricao"
                                        label="Título"
                                        required={true}
                                    />
                                </div>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_data_access("referencia_estacao", e.target.value)
                                        }}
                                        id="referencia_estacao"
                                        label="Referência da Estação"
                                    />
                                </div>
                                <div style={{ width: "100%" }}>
                                    <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                        <InputLabel htmlFor="select-label">Tipo de VPN</InputLabel>
                                        <Select
                                            labelId="select-label"
                                            id="select-tipo-vpn"
                                            // value={type_filter_access}
                                            onChange={e => {
                                                handle_data_access("tipo_vpn", e.target.value)
                                            }}
                                        >
                                            <MenuItem value="PPTP">PPTP</MenuItem>
                                            <MenuItem value="L2TP">L2TP</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_data_access("chave_ipsec", e.target.value)
                                        }}
                                        id="chave_ipsec"
                                        label="Chave IPSec"
                                    />
                                </div>
                            </>
                        }

                        {(type_access === 'Ativo de Rede' || type_access === 'VPN' || type_access === 'Ferramenta' || type_access === 'Conta' || type_access === 'Servidor') &&
                            <div style={{ width: "100%" }}>
                                <TextField
                                    className={styles.form_input}
                                    onChange={e => {
                                        handle_data_access("ip_dominio_link", e.target.value)
                                    }}
                                    id="ip_dominio_link"
                                    label={(type_access === 'VPN' || type_access === 'Servidor' || type_access === 'Ativo de Rede') ? 'IP' : type_access === 'Conta' ? 'URL' : 'IP/Domínio'}
                                    required={true}
                                />
                            </div>
                        }



                        {(type_access === 'Ativo de Rede' || type_access === 'Servidor') &&
                            <div style={{ width: "100%" }}>
                                <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                    <InputLabel htmlFor="select-label">Tipo de Acesso</InputLabel>
                                    <Select
                                        labelId="select-label"
                                        id="select-tipo-acesso"
                                        // value={type_filter_access}
                                        onChange={e => {
                                            handle_data_access("tipo_acesso", e.target.value)
                                        }}
                                    >
                                        <MenuItem value="telnet">Telnet</MenuItem>
                                        <MenuItem value="ssh">SSH</MenuItem>
                                        <MenuItem value="winbox">winbox</MenuItem>
                                        <MenuItem value="web">Web</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        }


                        {type_access === 'Ativo de Rede' &&
                            <>
                                <div style={{ width: "100%" }}>
                                    <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                        <InputLabel htmlFor="select-label">Equipamento&nbsp;&nbsp;*</InputLabel>
                                        <Select
                                            labelId="select-label"
                                            id="select-equipamento"
                                            // value={type_filter_access}
                                            onChange={e => {
                                                handle_data_access("equipamento", e.target.value)
                                            }}
                                        >
                                            <MenuItem value="Roteador">Roteador</MenuItem>
                                            <MenuItem value="Retificador">Retificador</MenuItem>
                                            <MenuItem value="Radio">Rádio</MenuItem>
                                            <MenuItem value="Switch">Switch</MenuItem>
                                            <MenuItem value="OLT">OLT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div style={{ width: "100%" }}>
                                    <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                        <InputLabel htmlFor="select-label">Fabricante&nbsp;&nbsp;*</InputLabel>
                                        <Select
                                            labelId="select-label"
                                            id="select-Fabricante"
                                            // value={type_filter_access}
                                            onChange={e => {
                                                handle_data_access("fabricante", e.target.value)
                                            }}
                                        >
                                            <MenuItem value="Huawei">Huawei</MenuItem>
                                            <MenuItem value="Cisco">Cisco</MenuItem>
                                            <MenuItem value="Datacom">Datacom</MenuItem>
                                            <MenuItem value="Mikrotik">Mikrotik</MenuItem>
                                            <MenuItem value="Juniper">Juniper</MenuItem>
                                            <MenuItem value="Intelbras">Intelbras</MenuItem>
                                            <MenuItem value="Fiberhome">Fiberhome</MenuItem>
                                            <MenuItem value="Raisecom">Raisecom</MenuItem>
                                            <MenuItem value="A10">A10</MenuItem>
                                            <MenuItem value="Extreme">Extreme</MenuItem>
                                            <MenuItem value="Outro">Outro</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_data_access("modelo", e.target.value)
                                        }}
                                        id="modelo"
                                        label="Modelo"
                                    />
                                </div>
                            </>
                        }


                        {(type_access === 'Ferramenta' || type_access === 'Conta') &&
                            <div style={{ width: "100%" }}>
                                <TextField
                                    className={styles.form_input}
                                    onChange={e => {
                                        handle_data_access("descricao", e.target.value)
                                    }}
                                    id="descricao"
                                    label="Serviço"
                                    required={true}
                                />
                            </div>
                        }


                        {(type_access === 'Ativo de Rede' || type_access === 'Servidor') &&
                            <div style={{ width: "100%" }}>
                                <TextField
                                    className={styles.form_input}
                                    onChange={e => {
                                        handle_data_access("descricao", e.target.value)
                                    }}
                                    id="descricao"
                                    label={type_access === 'Ativo de Rede' ? 'Hostname' : 'Descrição'}
                                    required={true}
                                />
                            </div>
                        }


                        {(type_access === 'Ativo de Rede' || type_access === 'Ferramenta' || type_access === 'Servidor') &&
                            <div style={{ width: "100%" }}>
                                <TextField
                                    type="number"
                                    className={styles.form_input}
                                    onChange={e => {
                                        handle_data_access("porta", e.target.value)
                                    }}
                                    id="porta"
                                    label={type_access === 'Servidor' ? 'Porta SSH (Ex: 23)' : 'Porta (Ex: 8080)'}
                                />
                            </div>
                        }

                        {(type_access === 'Ativo de Rede' || type_access === 'VPN' || type_access === 'Ferramenta' || type_access === 'Conta' || type_access === 'Servidor') &&
                            <>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_data_access("usuario_intec", e.target.value)
                                        }}
                                        id="usuario_intec"
                                        label="Usuário Intec"
                                    />
                                </div>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_data_access("senha_intec", e.target.value)
                                        }}
                                        id="senha_intec"
                                        label="Senha Intec"
                                    />
                                </div>
                            </>
                        }

                        {(type_access === 'Servidor' || type_access === 'Ativo de Rede') &&
                            <div style={{ width: "100%" }}>
                                <TextField
                                    className={styles.form_input}
                                    onChange={e => {
                                        handle_data_access("senha_root", e.target.value)
                                    }}
                                    id="senha_root"
                                    label="Senha root"
                                />
                            </div>
                        }


                        {(type_access === 'Ativo de Rede' || type_access === 'VPN' || type_access === 'Ferramenta' || type_access === 'Conta' || type_access === 'Servidor') &&
                            <>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_data_access("usuario_cliente", e.target.value)
                                        }}
                                        id="usuario_cliente"
                                        label="Usuário cliente"
                                    />
                                </div>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            handle_data_access("senha_cliente", e.target.value)
                                        }}
                                        id="senha_cliente"
                                        label="Senha cliente"
                                    />
                                </div>
                            </>
                        }


                        {(type_access === 'Ativo de Rede' || type_access === 'VPN' || type_access === 'Conta' || type_access === 'Servidor' || type_access === 'Ferramenta') &&
                            <div style={{ width: "100%" }}>
                                <TextField
                                    className={styles.form_input}
                                    onChange={e => {
                                        handle_data_access("observacoes", e.target.value)
                                    }}
                                    id="Observações"
                                    label="Observações"
                                />
                            </div>
                        }


                        <div style={{ width: "100%" }}>
                            <Button
                                className={styles.btn_submit}
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={submit_is_disabled}
                                onClick={async () => {

                                    let filters;

                                    switch (data_access.tipo) {
                                        case 'Ativo de Rede':
                                            filters = {
                                                situacao: "Situação",
                                                // localidade: "Localidade",
                                                estacao: "Estação",
                                                ip_dominio_link: "IP",
                                                // tipo_acesso: "Tipo de Acesso",
                                                equipamento: 'Equipamento',
                                                fabricante: 'Fabricante',
                                                descricao: "Hostname"
                                            }
                                            break;

                                        case 'VPN':
                                            filters = {
                                                situacao: "Situação",
                                                // localidade: "Localidade",
                                                descricao: "Título",
                                                ip_dominio_link: "IP"
                                            }
                                            break;

                                        case 'Conta':
                                            filters = {
                                                situacao: "Situação",
                                                // localidade: "Localidade",
                                                ip_dominio_link: "URL",
                                                descricao: "Serviço"
                                            }
                                            break;

                                        case 'Servidor':
                                            filters = {
                                                situacao: "Situação",
                                                // localidade: "Localidade",
                                                // estacao: "Estação",
                                                ip_dominio_link: "IP",
                                                // tipo_acesso: "Tipo de Acesso",
                                                descricao: "Descrição"
                                            }
                                            break;

                                        case 'Ferramenta':
                                            filters = {
                                                situacao: "Situação",
                                                // localidade: "Localidade",
                                                ip_dominio_link: "IP/Domínio",
                                                descricao: "Serviço"
                                            }
                                            break;

                                        default:
                                            break;
                                    }

                                    for (let f in Object.values(filters)) {
                                        let filter = Object.keys(filters)[f]
                                        if (data_access[filter] === undefined || data_access[filter] === '') {
                                            return alert(`Campo obrigatório: ${filters[filter].toUpperCase()}`)
                                        }
                                    }

                                    try {
                                        const resp = await api_bd.put(`/customers/addaccess/${data_client._id}`, data_access)
                                        update_client_data(resp.data)
                                        callbackfn(resp.data)
                                        alert(`Dados inseridos com sucesso!\nDescrição: ${data_access.descricao}\nCliente: ${data_client.nome}`)
                                    } catch (error) {
                                        if (error.message.indexOf('code 400') > -1) {
                                            alert("Verifique os dados e tente novamente.")
                                        }
                                    }
                                }}
                            >
                                Salvar
                        </Button>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
}

export default FormDataAccess;

//Schema Acesso
//{"localidade":"Capim","situacao":"Ativo","tipo":"Ativo de Rede","equipamento":"roteador","fabricante":"intelbras","modelo":"NMS3000","descricao":"descTESTE","ip_dominio_link":"user.com","porta":"123","usuario_intec":"userintec","senha_intec":"senhaintec","senha_root":"senharoot","usuario_cliente":"userclient","senha_cliente":"senhaclient","observacoes":"observaaaaa"}



