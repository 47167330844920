import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { Trash2 } from 'lucide-react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'

import { api_bd, api_bd_nr } from '../../services/Api';

const { backend_files_address } = require('../../config.json')

const loadUsersLits = async (setData) => {
    api_bd.get('/users?state=true').then(resp => setData(resp.data))
}


function gerarCalendario(_escala, actualDate = new Date()) {

    let cellHeaderWidth = '14.28%'
    let cellHeaderHeight = '30px'
    let cellWidth = '14.28%'
    let cellHeight = '50px'
    let cellBorder = '0.5px solid #c3c3c3'

    function structureCellHeader(_value) {
        const style = `display: flex; justify-content: center; align-items: center; min-width: ${cellHeaderWidth}; height: ${cellHeaderHeight}; border: ${cellBorder}`
        return (`<div style="${style}">${_value}</div>`)
    }


    function structureCellContent(_value, _bgColor, _textColor) {
        const style = `display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: ${_bgColor}; color: ${_textColor}; min-width: ${cellWidth}; height: ${cellHeight}; border: ${cellBorder}`
        return (`<div style="${style}">${_value}</div>`)
    }



    let hoje = actualDate//new Date();
    let dia = hoje.getDate();
    let mes = hoje.getMonth();
    let ano = hoje.getFullYear();
    let nomemes = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    let inicio = new Date(ano, mes, 1);
    let letrasSemana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
    let a = 0;
    let bi = bissexto(ano) ? 29 : 28;
    let diasmes = [31, bi, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    let mesmostrado = mes;
    let mesanterior = mes - 1;
    let proximomes = mes + 1;

    function bissexto(year) {
        if (year % 4 == 0) // regra básica
            return true; // se o ano é bissexto
        /* else */ // neste caso o else não é necessario
        return false; // se o ano não é bissexto
    }

    function getInfo(_dia, _escala) {
        let bgColor = '#fff', colaborador = '', contato = '', textColor = ''
        for (let k in _escala) {
            let element = _escala[k]
            // console.log(element, _dia)
            if (_dia >= element.inicio && _dia <= element.fim) {
                colaborador = element.colaborador
                bgColor = element.color
                textColor = element.textColor
                contato = element.contato
            }
        }
        return { bgColor, colaborador, contato, textColor }
    }



    //Linha nome do mes e ano
    let t = `<div style="width: 100%; font-weight: bold; font-size: 1.3em"> Escala de sobreaviso: ${nomemes[mesmostrado]}, ${ano}</div>`;

    t += '<div style="display: flex; flex-direction: column; width: 100%;">'; //Table

    //LINHA HEADER
    t += '<div style="display: flex; flex-direction: row; width: 100%">'; //TR
    for (let i = 0; i < letrasSemana.length; i++) {
        t += structureCellHeader(`<b>${letrasSemana[i]}</b>`);
    }
    t += '</div>';


    t += '<div style="display: flex; flex-direction: row">'; //TR

    let increase;
    if (inicio.getDay() === 0) {
        increase = 0;
    } else {
        increase = 1;
    }
    for (let i = 1; a < diasmes[mes]; i++) {
        if (i < inicio.getDay() + increase) {
            t += structureCellContent(' '); //TD
        } else {
            a++;

            let { bgColor, colaborador, textColor } = getInfo(a, _escala)//get analist data

            if (a == dia) {
                //Dia atual
                let contentCell = `<span style="margin: 0px; font-weight: bold;">${String(a).padStart(2, '0')}</span>`
                contentCell += '<span style="margin: 0px; font-weight: bold; margin-top: 5px">' + colaborador.split(' ')[0] + '</span>'

                t += structureCellContent(contentCell, bgColor, textColor)

            } else {
                //Outra data
                let contentCell = `<span style="margin: 0px;">${String(a).padStart(2, '0')}</span>`
                contentCell += '<span style="margin: 0px; margin-top: 5px">' + colaborador.split(' ')[0] + '</span>'

                t += structureCellContent(contentCell, bgColor, textColor)
            }
        }
        if (i % 7 === 0) {
            t += '</div>'; // /TR
            t += '<div style="display: flex; flex-direction: row">'; // TR
        }
    }
    t += '</div>'; // /TR

    t += '</div>'; // /TABLE

    return t;
}


function removeFromList(arr, itemToRemove, scaleStartToRemove, setData) {
    let temp = [...arr]//arr.filter(el => (el.id !== itemToRemove && el.inicio !== scaleStartToRemove))

    let arrIndex = arr.findIndex(ell => {
        return ell.id === itemToRemove && ell.inicio === scaleStartToRemove
    })

    // console.log(arrIndex)

    temp.splice(arrIndex, 1)

    setData(temp)
}

const getScale = (month, year, setData) => {
    api_bd_nr.get(`/warningscale?month=${month}&year=${year}`)
        .then(resp => {
            // console.log(resp.data)
            setData(resp.data[0] ? resp.data[0].staffList : [])
        })
}

const scalationLine = (el, index, userProfile, scaleList, setScaleList) => {
    return (
        <div 
        key={`${el.id}_${index}`} 
        className='flex flex-row w-full items-center justify-between p-2 border border-white' 
        style={{ backgroundColor: userProfile.nivel.match(/Super|Técnico-Admin/i) ? el.color : '#3C5CA6', color: el.textColor }}>
            <div className='flex flex-row items-center'>
                <span className='m-0 w-[100px]'>{el.colaborador}</span>
                {el.contato &&
                    <a
                        rel="noreferrer"
                        target="_blank"
                        className='flex items-center ml-3'
                        style={{ color: el.textColor, textDecoration: 'none' }}
                        href={`https://wa.me/55${el.contato.replace(/\D|-/gi, '')}`}
                    >
                        <span className='m-0'>Contato: {el.contato}</span>
                        <img className='w-[30px]' src="https://i.pinimg.com/originals/79/dc/31/79dc31280371b8ffbe56ec656418e122.png" />
                    </a>
                }

            </div>

            {Object.keys(userProfile).length > 0 && userProfile.nivel.match(/Super|Técnico-Admin/i) &&
                <Trash2
                    size={20}
                    className='cursor-pointer mr-4'
                    onClick={() => {
                        removeFromList(scaleList, el.id, el.inicio, setScaleList)
                    }}
                />
            }

        </div>
    )
}

const getUniques = (arr, keyFilter) => {
    let data = []
    arr.forEach(element => {
        let temp = data.find(el => {
            return (element[keyFilter] === el[keyFilter])
        })

        if (!temp) {
            data.push(element)
        }
    });
    return (data)
}

function WarningScale() {
    const monthName = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];
    const [scaleList, setScaleList] = useState([])
    const [usersList, setUsersList] = useState([])

    // const [worker, setworker] = useState({})
    // const [scaleColor, setScaleColor] = useState('#3C5CA6')
    // const [scaleStart, setScaleStart] = useState(1)
    // const [scaleEnd, setScaleEnd] = useState(1)

    const [newScale, setNewScale] = useState({
        worker: {},
        function: 'Plantonista',
        scaleColor: '#3C5CA6',
        scaleStart: 1,
        scaleEnd: 1
    })

    const userProfile = useRecoilValue(profileData)

    const [actualDate, setActualDate] = useState(new Date())

    const [submitButtomDisabled, setSubmitButtomDisabled] = useState(false)

    useEffect(() => {
        loadUsersLits(setUsersList)
        getScale(monthName[actualDate.getMonth()], actualDate.getFullYear(), setScaleList)
    }, [])

    useEffect(() => {
        if (usersList.length > 0) {
            //console.log(usersList)
            setNewScale(old => {
                return ({
                    ...old,
                    worker: { id: usersList[0]._id, nome: usersList[0].nome, nivel: usersList[0].nivel, contato: usersList[0].contato },
                })
            })
        }
    }, [usersList])


    return (
        <div className='flex flex-col w-full height_less_header overflow-y-auto p-4'>

            {Object.keys(userProfile).length > 0 && userProfile.nivel.match(/cliente/i) &&
                <div className='flex flex-col w-full space-y-3 rounded border border-intec-100 p-5 text-xl'>
                    <h3 className='font-bold mb-3 text-2xl'>Plantão Intec Solutions</h3>
                    A fim de cumprir com excelência o atendimento ao cliente, designamos nossos colaboradors, à disposição mediante a escala como plantonistas. Segue datas e horários: 20h00 as 08h00 nos dias de semana e de 00h00 as 24:00 nos finais de semanas e feriados.
                </div>
            }

            {Object.keys(userProfile).length > 0 && userProfile.nivel.match(/Super|Técnico-Admin/i) &&
                <div className='flex flex-col w-full space-y-3 rounded border border-intec-100 p-5'>
                    <div className='flex flex-row items-center'>
                        <label className='w-[100px]'>Colaborador: </label>
                        <select
                            value={JSON.stringify(newScale.worker)}
                            className='border border-intec-100 rounded'
                            onChange={(e) => {
                                setNewScale(old => {
                                    return ({
                                        ...old,
                                        worker: JSON.parse(e.target.value)
                                    })
                                })
                            }}
                        >
                            {usersList.filter(el => el.nivel.match(/técnico|super/i)).map(user => (
                                <option key={`${user._id}`} value={JSON.stringify({ id: user._id, nome: user.nome, nivel: user.nivel, contato: user.contato })}>{user.nome}</option>
                            ))}
                        </select>
                    </div>

                    <div className='flex flex-row items-center'>
                        <label className='w-[100px]'>Função: </label>
                        <select
                            value={newScale.function}
                            className='border border-intec-100 rounded'
                            onChange={(e) => {
                                setNewScale(old => {
                                    return ({
                                        ...old,
                                        function: e.target.value
                                    })
                                })
                            }}
                        >
                            <option value="CEO">CEO</option>
                            <option value="COO">COO</option>
                            <option value="Plantonista">Plantonista</option>
                            <option value="Engenharia">Engenharia</option>
                            <option value="Gerente Engenharia">Gerente Engenharia</option>
                        </select>
                    </div>

                    <div className='flex flex-row items-center'>
                        <label className='w-[100px]' >Cor para exibição:</label>
                        <input type="color" id="head" name="head" value={newScale.scaleColor} className='border border-intec-100 rounded w-20'
                            onChange={(e) => {
                                // setScaleColor(e.target.value)
                                setNewScale(old => {
                                    return ({
                                        ...old,
                                        scaleColor: e.target.value
                                    })
                                })
                            }}
                        />
                    </div>

                    {newScale.function === 'Plantonista' &&
                        <>

                            <div className='flex flex-row items-center'>
                                <label className='w-[100px]'>Início no dia:</label>
                                <input type="number" min={1} max={31} value={newScale.scaleStart} className='border border-intec-100 rounded text-center w-20'
                                    onChange={(e) => {
                                        // setScaleStart(e.target.value)
                                        setNewScale(old => {
                                            return ({
                                                ...old,
                                                scaleStart: e.target.value
                                            })
                                        })
                                    }}
                                />
                            </div>
                            <div className='flex flex-row items-center'>
                                <label className='w-[100px]'>Fim no dia:</label>
                                <input type="number" min={newScale.scaleStart} max={31} value={newScale.scaleEnd} className='border border-intec-100 rounded text-center w-20'
                                    onChange={(e) => {
                                        // setScaleEnd(e.target.value)
                                        setNewScale(old => {
                                            return ({
                                                ...old,
                                                scaleEnd: e.target.value
                                            })
                                        })
                                    }}
                                />
                            </div>
                        </>
                    }

                    <div className='flex flex-row items-center mt-5'>
                        <button
                            className='rounded bg-intec-500 text-white p-2  hover:bg-intec-400'
                            onClick={() => {
                                let temp = [...scaleList]
                                temp.push({
                                    id: newScale.worker.id,
                                    colaborador: newScale.worker.nome,
                                    funcao: newScale.function,
                                    color: newScale.scaleColor,
                                    textColor: "#fff",
                                    contato: newScale.worker.contato,
                                    inicio: newScale.scaleStart,
                                    fim: newScale.scaleEnd
                                })
                                // setScale(temp)
                                setScaleList(temp)
                            }}
                        >Adicionar ao calendário</button>
                    </div>
                </div>
            }

            <div className='flex w-full flex-col justify-center mt-5 rounded border border-intec-100 p-5'>
                <div className='flex flex-col w-full items-center'>
                    <div style={{ width: '70%' }} dangerouslySetInnerHTML={{ __html: gerarCalendario(scaleList.filter(el => el.funcao === 'Plantonista'), actualDate) }}></div>

                    {Object.keys(userProfile).length > 0 && userProfile.nivel.match(/cliente/i) &&
                        <div className='flex flex-col w-[70%] mt-10 space-y-3 text-xl'>
                            O nosso meio de atendimento disponibilizado para os acionamentos emergenciais será o telefônico, onde o técnico deverá ser acionado após abertura de ticket no menu ao lado.<br />
                            O acionamento deverá obedecer a ordem de escalation, onde o primeiro responsável deverá ser o primeiro a ser acionado, caso não obtenha sucesso, deverá acionar o próximo responsável.
                        </div>
                    }


                    <div className='flex flex-col w-[70%]  mt-10'>
                        <div className='w-full font-bold  text-xl text-center'>
                            Escalation
                        </div>
                        <div className='flex flex-row w-full'>
                            <div className='flex justify-center items-center border border-white w-[180px] text-white bg-intec-500 font-bold'>Plantonistas</div>
                            <div className='text-left w-full'>
                                {Object.keys(userProfile).length > 0 && (userProfile.nivel.match(/cliente/i) || (userProfile.nivel === 'Técnico')) &&
                                    getUniques(scaleList.filter(el => el.funcao === 'Plantonista'), 'id').map((el, index) => (
                                        scalationLine(el, index, userProfile, scaleList, setScaleList)
                                    ))
                                }

                                {Object.keys(userProfile).length > 0 && userProfile.nivel.match(/super|técnico-admin/i) &&
                                    scaleList.filter(el => el.funcao === 'Plantonista').map((el, index) => (
                                        scalationLine(el, index, userProfile, scaleList, setScaleList)
                                    ))
                                }
                            </div>
                        </div>
                        <div className='flex flex-row w-full'>
                            <div className='flex justify-center items-center border border-white w-[180px] text-white bg-intec-500 font-bold'>Engenharia</div>
                            <div className='text-left w-full'>
                                {scaleList.filter(el => el.funcao === 'Engenharia').map((el, index) => (
                                    scalationLine(el, index, userProfile, scaleList, setScaleList)
                                ))}
                            </div>
                        </div>
                        <div className='flex flex-row w-full'>
                            <div className='flex justify-center items-center border border-white w-[180px] text-white bg-intec-500 font-bold'>Gerente Engenharia</div>
                            <div className='text-left w-full'>
                                {scaleList.filter(el => el.funcao === 'Gerente Engenharia').map((el, index) => (
                                    scalationLine(el, index, userProfile, scaleList, setScaleList)
                                ))}
                            </div>
                        </div>
                        <div className='flex flex-row w-full'>
                            <div className='flex justify-center items-center border border-white w-[180px] text-white bg-intec-500 font-bold'>COO</div>
                            <div className='text-left w-full'>
                                {scaleList.filter(el => el.funcao === 'COO').map((el, index) => (
                                    scalationLine(el, index, userProfile, scaleList, setScaleList)
                                ))}
                            </div>
                        </div>
                        <div className='flex flex-row w-full'>
                            <div className='flex justify-center items-center border border-white w-[180px] text-white bg-intec-500 font-bold'>CEO</div>
                            <div className='text-left w-full'>
                                {scaleList.filter(el => el.funcao === 'CEO').map((el, index) => (
                                    scalationLine(el, index, userProfile, scaleList, setScaleList)
                                ))}
                            </div>
                        </div>
                    </div>



                    {/* <div className='text-left mt-10  w-[70%]'>
                        {scaleList.map((el, index) => (
                            scalationLine(el, index, userProfile, scaleList, setScaleList)
                        ))}
                    </div> */}



                    {false &&
                        <div className='text-left mt-10  w-[70%]'>
                            {scaleList.map((el, index) => (

                                <div key={`${el.id}_${index}`} className='flex flex-row w-full items-center justify-between p-2' style={{ backgroundColor: el.color, color: el.textColor }}>
                                    <div className='flex flex-row items-center'>
                                        <span className='m-0 w-[100px]'>{el.colaborador}</span>
                                        {el.contato &&
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                className='flex items-center ml-3'
                                                style={{ color: el.textColor, textDecoration: 'none' }}
                                                href={`https://wa.me/55${el.contato.replace(/\D|-/gi, '')}`}
                                            >
                                                <span className='m-0'>Contato: {el.contato}</span>
                                                <img className='w-[30px]' src="https://i.pinimg.com/originals/79/dc/31/79dc31280371b8ffbe56ec656418e122.png" />
                                            </a>
                                        }

                                    </div>

                                    {Object.keys(userProfile).length > 0 && userProfile.nivel.match(/Super|Técnico-Admin/i) &&
                                        <Trash2
                                            size={20}
                                            className='cursor-pointer mr-4'
                                            onClick={() => {
                                                removeFromList(scaleList, el.id, el.inicio, setScaleList)
                                            }}
                                        />
                                    }

                                </div>

                            ))}
                        </div>
                    }


                    {Object.keys(userProfile).length > 0 && userProfile.nivel.match(/Super|Técnico-Admin/i) &&
                        <button
                            className={`rounded ${submitButtomDisabled ? 'bg-intec-100' : 'bg-intec-500'} mt-4 bg-intec-500 font-bold text-white p-2 hover:bg-intec-400`}
                            disabled={submitButtomDisabled}
                            onClick={() => {
                                if (submitButtomDisabled === false) {
                                    setSubmitButtomDisabled(true)

                                    const dados = {
                                        month: monthName[actualDate.getMonth()],
                                        year: actualDate.getFullYear(),
                                        staffList: scaleList
                                    }

                                    api_bd_nr.post('/warningscale', dados)
                                        .then(resp => {
                                            setNewScale({
                                                worker: { id: usersList[0]._id, nome: usersList[0].nome, nivel: usersList[0].nivel, contato: usersList[0].contato },
                                                function: 'Plantonista',
                                                scaleColor: '#3C5CA6',
                                                scaleStart: 1,
                                                scaleEnd: 1
                                            })
                                            setSubmitButtomDisabled(false)
                                            alert('Escala salva com sucesso!')
                                        })
                                        .catch(error => {
                                            alert('Ocorreu um erro, tente novamente mais tarde.')
                                            setSubmitButtomDisabled(false)
                                        })
                                }
                            }}
                        >
                            Salvar Escala de sobreaviso
                        </button>
                    }
                </div>
            </div>


        </div>
    );
}

export default WarningScale;