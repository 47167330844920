import React from 'react';
import { useDropzone } from 'react-dropzone';

function FileDropzone(props) {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));
        

    props.set_files(acceptedFiles)

    return (
        <div {...getRootProps()} style={{ width: '100%', minHeight: 50, backgroundColor: '#ececec', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', overflowY: 'auto' }}>
            <input {...getInputProps()} />
            {files.length === 0 &&
                <div style={{ widht: '100%', textAlign:'center' }}>
                    <p><b>ANEXOS</b></p>
                    <p><b>Arraste e solte os arquivos aqui, ou clique para selecionar</b></p>
                    <p style={{fontSize: '0.8em'}}><br />JPG, JPEG, GIF, PNG, SVG, MP3, AAC, MP4, MPEG, RAR, DOC, DOCX, XLS, XLSX, CSV, PPT, PPTX, PDF, TXT</p>
                    <p style={{fontSize: '0.8em'}}>MAX: 50MB</p>
                </div>
            }
            {files.length > 0 &&
                <div style={{ widht: '100%', marginTop: 10 }}>
                    <h4>Arquivos Selecionados: {files.length}</h4>
                    <ul>{files}</ul>
                </div>
            }
        </div>
    );
}

export default FileDropzone;