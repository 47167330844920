import React, { useState, useEffect } from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

function CustomBarChart(props) {

	const [dadosGraficos, setDadosGraficos] = useState({})
	const [userImages, setUserImages] = useState([])


	const msToTime = (ms) => {
		var hours = ms / (1000 * 60 * 60);
		var absoluteHours = Math.floor(hours);
		var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

		//Get remainder from hours and convert to minutes
		var minutes = (hours - absoluteHours) * 60;
		var absoluteMinutes = Math.floor(minutes);
		var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

		//Get remainder from minutes and convert to seconds
		var seconds = (minutes - absoluteMinutes) * 60;
		var absoluteSeconds = Math.floor(seconds);
		var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

		return h + ':' + m + ':' + s;
	}


	//my plugin
	// const barAvatar = {
	// 	id: 'barAvatar',
	// 	afterDraw(chart, args, option) {

	// 		const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart
	// 		ctx.save()

	// 		//console.log(chart, args, option)//
	// 		console.log(x)//
	// 		//console.log(chart.$context.chart._layers.length)

	// 		let imgDimensions = {
	// 			w: 30,
	// 			h: 30
	// 		}



	// 		for (let i = 0; i < chart.$context.chart._layers.length; i++) {
	// 			// let img1 = new Image()
	// 			// img1.src = usersImages[i]
	// 			console.log(usersImages[i])
	// 			// ctx.drawImage(null, x.getPixelForValue(chart.config.data.datasets[0].data[i]), y.getPixelForValue(i) - imgDimensions.h / 2, imgDimensions.w, imgDimensions.h)
	// 		}

	// 		// chart.config.data.datasets[0].data[0]
	// 		// ctx.drawImage(img1, x.getPixelForValue(0), y.getPixelForValue(0) - imgDimensions.h / 2, imgDimensions.w, imgDimensions.h)
	// 		// ctx.drawImage(img1, x.getPixelForValue(0), y.getPixelForValue(1) - imgDimensions.h / 2, imgDimensions.w, imgDimensions.h)
	// 	}
	// }

	// ChartJS.register(
	// 	CategoryScale,
	// 	LinearScale,
	// 	BarElement,
	// 	Title,
	// 	Tooltip,
	// 	Legend,
	// 	barAvatar
	// );

	// const optionsC = {
	// 	indexAxis: 'y',
	// 	elements: {
	// 		bar: {
	// 			borderWidth: 2,
	// 		},
	// 	},
	// 	responsive: true,
	// 	plugins: {
	// 		// barAvatar,
	// 		tooltip: {
	// 			callbacks: {
	// 				title: function (tooltipItem) {
	// 					return tooltipItem[0].label
	// 				},
	// 				label: function (tooltipItem) {
	// 					return ' ' + msToTime(tooltipItem.raw) + 'hs'
	// 				}
	// 			}
	// 		},
	// 		legend: {
	// 			display: false,
	// 			// position: 'top',
	// 		},
	// 		title: {
	// 			display: true,
	// 			text: 'Tempo de trabalho por analista',
	// 		}
	// 	},
	// 	scales: {
	// 		x: {
	// 			ticks: {
	// 				// Include a dollar sign in the ticks
	// 				callback: function (value, index, ticks) {
	// 					return msToTime(value)
	// 				}
	// 			}
	// 		},
	// 		y: {
	// 			ticks: {
	// 				beginAtZero: true
	// 			}
	// 		}
	// 	},
	// 	layout: {
	// 		padding: {
	// 			bottom: 60
	// 		}
	// 	}
	// };

	const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

	const data = {
		labels,
		datasets: [
			{
				label: 'Dataset 1',
				data: labels.map(() => Math.random() * (1000 - (0)) + (0)),
				borderColor: 'rgb(255, 99, 132)',
				backgroundColor: 'rgba(255, 99, 132, 0.5)',
			},
			{
				label: 'Dataset 2',
				data: labels.map(() => Math.random() * (1000 - (0)) + (0)),
				borderColor: 'rgb(53, 162, 235)',
				backgroundColor: 'rgba(53, 162, 235, 0.5)',
			},
		],
	};

	//***************************************************************************** */

	useEffect(() => {
		if (props.dados && (Object.keys(props.dados).length > 0)) {
			// console.log(props.dados)
			setUserImages(props.dados.images)
			setDadosGraficos(props.dados.chartjs)
		}

	}, [])

	return (
		dadosGraficos && (Object.keys(dadosGraficos).length > 0) &&
		<Bar
			options={{
				indexAxis: 'y',
				elements: {
					bar: {
						borderWidth: 2,
					},
				},
				responsive: true,
				plugins: {
					barAvatar: {
						id: 'barAvatar',
						afterDraw(chart, args, option) {

							const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart
							ctx.save()

							//console.log(chart, args, option)//
							// console.log(x)//
							//console.log(chart.$context.chart._layers.length)

							let imgDimensions = {
								w: 30,
								h: 30
							}



							for (let i = 0; i < chart.$context.chart._layers.length; i++) {
								// let img1 = new Image()
								// img1.src = usersImages[i]
								console.log(userImages[i])
								// ctx.drawImage(null, x.getPixelForValue(chart.config.data.datasets[0].data[i]), y.getPixelForValue(i) - imgDimensions.h / 2, imgDimensions.w, imgDimensions.h)
							}

							// chart.config.data.datasets[0].data[0]
							// ctx.drawImage(img1, x.getPixelForValue(0), y.getPixelForValue(0) - imgDimensions.h / 2, imgDimensions.w, imgDimensions.h)
							// ctx.drawImage(img1, x.getPixelForValue(0), y.getPixelForValue(1) - imgDimensions.h / 2, imgDimensions.w, imgDimensions.h)
						}
					},
					tooltip: {
						callbacks: {
							title: function (tooltipItem) {
								return tooltipItem[0].label
							},
							label: function (tooltipItem) {
								return ' ' + msToTime(tooltipItem.raw) + 'hs'
							}
						}
					},
					legend: {
						display: false,
						// position: 'top',
					},
					title: {
						display: true,
						text: 'Tempo de trabalho por analista',
					}
				},
				scales: {
					x: {
						ticks: {
							// Include a dollar sign in the ticks
							callback: function (value, index, ticks) {
								return msToTime(value)
							}
						}
					},
					y: {
						ticks: {
							beginAtZero: true
						}
					}
				},
				layout: {
					padding: {
						bottom: 60
					}
				}
			}}
			data={dadosGraficos}
		/>

	)
}

export default CustomBarChart;