import { atom, selector } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({
    key: '@sgiuser', // this key is using to store data in local storage
    storage: localStorage, // configurate which stroage will be used to store the data
})

const userState = atom({
    key: 'userState', // unique ID (with respect to other atoms/selectors)
    default: {}, // default value (aka initial value)
    effects_UNSTABLE: [persistAtom],
});


export const profileData = selector({
    key: 'profileData', // unique ID (with respect to other atoms/selectors)
    get: ({ get }) => {
        const userdata = get(userState);
        const ret = {
            id: userdata.sgi_data?._id || userdata.glpi_data?.glpiID || '',
            username: userdata.sgi_data?.user_glpi || userdata.glpi_data?.glpiname || '',
            nome: userdata.sgi_data?.nome || userdata.glpi_data?.glpifirstname || '',
            imagem: userdata.sgi_data?.image_profile?.filename || 'default-logo.png',
            nivel: userdata.sgi_data?.nivel || userdata.glpi_data?.glpiactiveprofile?.name,
            contato: userdata.sgi_data?.contato,
            ativo: userdata.sgi_data?.ativo,
            permissoes: userdata.sgi_data?.permissoes,
            access_customers: userdata.sgi_data?.access_customers || [],
            desk_permissions: userdata.sgi_data?.desk_permissions || {},
            conta_master: userdata.sgi_data?.conta_master || {
                masterUserName: "",
                masterUserId: ""
            }
        }
        return ret.nome === '' ? {} : ret
    },
});

export default userState