import React, { 
    useState, 
    // useEffect 
} from 'react';

// import { makeStyles } from '@material-ui/core/styles';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import FileUploader from '../FileUploader'

import Styles from '../Styles'

import { api_bd } from '../../services/Api'


import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


function FormDataClient(props) {
    const { width, set_data_new_client } = props
    const styles = Styles()

    const [data_client, set_data_client] = useState({situacao: 'Ativo'})
    const [submit_is_disabled, set_submit_is_disabled] = useState(false)

    const [logomarca, set_logomarca] = useState(null);

    // useEffect(() => {
    //     console.log('Dados cliente: ', data_client)
    // }, [data_client])


    // useEffect(() => {
    //     console.log('logomarca cliente: ', logomarca)
    // }, [logomarca])

    const handle_data_client = (key, value) => {
        let data = { ...data_client}
        data[key] = value
        set_data_client(data)
    }

    return (
        <div className={styles.div_root_component} style={{ width }}>
            <Card className={styles.card}>
                <CardContent>
                    <CardHeader
                        title="Adicionar Cliente"
                        subheader=""
                    />
                    <form className={styles.form_cad_user} noValidate autoComplete="off">
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("user_glpi", e.target.value)
                                }}
                                id="user_glpi"
                                label="Login"
                                required={true}
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("nome", e.target.value)
                                }}
                                id="nome"
                                label="Nome"
                                required={true}
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("cnpj_1", e.target.value)
                                }}
                                id="cnpj_1"
                                label="CNPJ 1"
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("cnpj_2", e.target.value)
                                }}
                                id="cnpj_2"
                                label="CNPJ 2"
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("cnpj_3", e.target.value)
                                }}
                                id="cnpj_3"
                                label="CNPJ 3"
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("end", e.target.value)
                                }}
                                id="endereco"
                                label="Endereço"
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("contato", e.target.value)
                                }}
                                id="contato"
                                label="Contato"
                                required={true}
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("fone_1", e.target.value)
                                }}
                                id="fone_1"
                                label="Fone 1"
                                required={true}
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("fone_2", e.target.value)
                                }}
                                id="fone_2"
                                label="Fone 2"
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("fone_3", e.target.value)
                                }}
                                id="fone_3"
                                label="Fone 3"
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("email_1", e.target.value)
                                }}
                                id="email_1"
                                label="Email 1"
                                required={true}
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("email_2", e.target.value)
                                }}
                                id="email_2"
                                label="Email 2"
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("user_default_cliente", e.target.value)
                                }}
                                autoComplete="current-password"
                                id="user_default_cliente"
                                label="Usuário default cliente"
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("senha_default_cliente", e.target.value)
                                }}
                                autoComplete="current-password"
                                id="senha_default_cliente"
                                label="Senha default cliente"
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("user_default_intec", e.target.value)
                                }}
                                autoComplete="current-password"
                                id="user_default_intec"
                                label="Usuário default Intec"
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("senha_default_intec", e.target.value)
                                }}
                                autoComplete="current-password"
                                id="senha_default_intec"
                                label="Senha default Intec"
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("link_whats", e.target.value)
                                }}
                                id="link_whats"
                                label="Link WhatsApp"
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <FormControl className={styles.form_input} style={{marginTop: 15}}>
                                <InputLabel htmlFor="select-label">Situação &nbsp;  *</InputLabel>
                                <Select
                                    defaultValue={'Ativo'}
                                    labelId="select-label"
                                    id="situacao"
                                    onChange={e => {
                                        handle_data_client("situacao", e.target.value)
                                    }}
                                    required={true}
                                >
                                    <MenuItem value="Ativo">Ativo</MenuItem>
                                    <MenuItem value="Inativo">Inativo</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("contrato", { horas: parseInt(e.target.value)})
                                }}
                                type='number'
                                id="contrato_horas"
                                label="Contrato (horas)"
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("link_gdrive", e.target.value)
                                }}
                                id="link_gdrive"
                                label="Link Google Drive"
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("esquema_rede", e.target.value)
                                }}
                                id="esquema_rede"
                                label="Esquema da Rede"
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("mapa_zabbix", e.target.value)
                                }}
                                id="mapa_zabbix"
                                label="Mapa do Zabbix"
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("weathermap", e.target.value)
                                }}
                                id="weathermap"
                                label="Weathermap"
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("grafana", e.target.value)
                                }}
                                id="grafana"
                                label="Grafana"
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("cliente_desde", e.target.value)
                                }}
                                id="cliente_desde"
                                label="Cliente Desde"
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <div style={{
                                color: "#888888",
                                marginLeft: 0,
                                marginTop: 15,
                                marginBottom: 5,
                                top: 10
                            }}>Logotipo</div>
                            <FileUploader set_file={set_logomarca} />
                        </div>
                        <div style={{ width: "100%" }}>
                            <Button
                                className={styles.btn_submit}
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={submit_is_disabled}
                                onClick={async () => {

                                    const filters = {
                                        user_glpi: "Usuário GLPI", 
                                        nome: "Nome", 
                                        contato: "Contato",
                                        fone_1: "Fone 1",
                                        email_1: "Email 1",
                                        situacao: "Situação",
                                    }
    
                                    for(let f in Object.values(filters)){
                                        // console.log(filter)
                                        // console.log(Object.keys(filters)[f])
                                        let filter = Object.keys(filters)[f]
                                        // console.log(data_client[filter])
                                        if(data_client[filter] === undefined || data_client[filter] === ''){
                                            return alert(`Campo obrigatório: ${filters[filter].toUpperCase()}`)
                                        }
                                    }

                                    if (logomarca !==  null) {
                                        const formData = new FormData();
                                        formData.append('file', logomarca)
                                        for (let key in data_client) {
                                            if(key !== 'contrato'){
                                                formData.append(key, data_client[key])
                                            }  
                                        }
                                        try {
                                            const resp = await api_bd.post('/customers', formData)
                                            alert(`Cliente cadastrado com sucesso!\nCLIENTE\nNome: ${data_client.nome}\nAgora você pode adicionar mais dados ao cadastro do cliente como IPs, Trânsitos e Acessos.`)
                                            set_data_new_client(resp.data)
                                            window.location.href = '/'
                                        } catch (error) {
                                            if (error.message.indexOf('code 400') > -1) {
                                                alert("Verifique os dados e tente novamente.")
                                            }
                                        }

                                    } else {
                                        try {
                                            const resp = await api_bd.post('/customers', data_client)
                                            alert(`Cliente cadastrado com sucesso!\nCLIENTE\nNome: ${data_client.nome}\nAgora você pode adicionar mais dados ao cadastro do cliente como IPs, Trânsitos e Acessos.`)
                                            set_data_new_client(resp.data)
                                        } catch (error) {
                                            if (error.message.indexOf('code 400') > -1) {
                                                alert("Verifique os dados e tente novamente.")
                                            }
                                        }
                                    }
                                }}
                            >
                                Salvar
                            </Button>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
}

export default FormDataClient;

//Schema Cliente
//{"nome":"fulano","cnpj_1":"123","cnpj_2":"321","cnpj_3":"123","end":"Avenida teste","contato":"contato teste","fone_1":"fone1","fone_2":"fone2","fone_3":"fone 3","email_1":"email1","email_2":"email2","password":"senha","link_whats":"wa.me","situacao":"inativo","cliente_desde":"10/01/2020"}