import React, { useState } from 'react';
import ShowContent from './ShowContent';

function Reports(props) {
    const [dataI, setDataI] = useState(new Date())
    const [dataF, setDataF] = useState(new Date())
    const [optionReport, setOptionReport] = useState('')

    return (
        <div className='p-4 w-full height_less_header overflow-y-auto'>
            <div className='flex flex-row my-3 space-x-3 w-full rounded border border-intec-200 p-5 bg-white justify-between'>
                <div>
                    <select onChange={(e)=>{
                        setOptionReport(e.target.value)
                    }}>
                        <option>Selecione o tipo do relatório</option>
                        <option value="analystreport">Relatório de analistas</option>
                        <option value="customerreport">Relatório de clientes</option>
                        <option value="customerreportcgr">Relatório de clientes CGR</option>
                    </select>
                </div>
                <div className='flex flex-row items-center'>
                    <div>Intervalo do relatório: </div>
                    <div>
                        <span className='mr-2'>Inicial:</span>
                        <input 
                        type="date" 
                        max={dataF.toLocaleDateString('en-ca')}
                        value={dataI.toLocaleDateString('en-ca')}
                        onChange={(e)=>{
                            setDataI(new Date(`${e.target.value} 00:00:00`))
                        }} 
                        />
                    </div>
                    <div>
                        <span className='mr-2'>Fim:</span>
                        <input 
                        type="date"
                        min={dataI.toLocaleDateString('en-ca')}
                        value={dataF.toLocaleDateString('en-ca')} 
                        onChange={(e)=>{
                            setDataF(new Date(`${e.target.value} 00:00:00`))
                        }} 
                        />
                    </div>
                </div>
            </div>
            <ShowContent content={optionReport} interval={{datei: dataI, datef: dataF}} />
        </div>
    );
}

export default Reports;