import React, { useState, useEffect, memo } from 'react';

import './Outages.css'

import OutageRow from './OutageRow';
import OutageReportTable from './OutageReportTable';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'
import outagesComponentToShowState, { outagesListTicketState, outagesListTickeClosedtState } from '../../atoms/outages_state.atom';

import { api_bd } from '../../services/Api'

function OutageReport(props) {
    const [eventList, setEventList] = useState([])
    const [messageNoData, setMessageNoData] = useState('Preencha os dados e clique em buscar')
    const profile_user = useRecoilValue(profileData)
    const componentToShow = useRecoilValue(outagesComponentToShowState)

    const [btnDisabled, setBtnDisabled] = useState(false)

    const [optsReport, setOptsReport] = useState({
        iniInterval: new Date().toISOString().split('T')[0],
        endInterval: new Date().toISOString().split('T')[0],
        optSearch: 'data',
        argSearch: '',
        argSearch2: ''
    })

    const buscarEventos = async (_data) => {
        setMessageNoData('Buscando dados...')
        let tmpEv = await api_bd.post(`/outages/report`, _data)
        if (tmpEv.data.length === 0) {
            setMessageNoData('Não foram encontrados eventos com os dados especificados')
            // setLoadMessage('Nenhum evento encontrado para os filtros selecionados')
            setBtnDisabled(false)
        } else {
            if (componentToShow === null) {
                setEventList(tmpEv.data)
                setBtnDisabled(false)
            }
        }
    }


    // useEffect(() => {
    //     //buscarEventos()
    //     let interval = setInterval(() => {
    //         buscarEventos()
    //     }, 30000)
    //     return () => {
    //         clearInterval(interval)
    //     }
    // }, [])



    return (
        <div className='card' style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '5%' }}>

            {/* Content */}

            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                <span style={{ fontSize: '1.8em', fontWeight: 'bold', paddingTop: 13, paddingBottom: 30 }}>RELATÓRIOS</span>


                <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '3%' }}>{/* barra de opçoes de visualização */}

                    {/* Datas de início e fim */}
                    <div style={{ marginBottom: 20, padding: 20, display: 'flex', flexDirection: 'column', gap: 10, minWidth: 280, width: '42%', borderRadius: 5, boxShadow: '#3c3c3c88 3px 3px 10px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: "100%" }}>
                            <span style={{ margin: 0, minWidth: 100, width: 100 }}>Intervalo início:</span>
                            <input
                                type='date'
                                disabled={optsReport.optSearch === 'protocolo' ? true :  false}
                                value={optsReport.iniInterval}
                                style={{ width: 130, minWidth: 130, }}
                                onChange={(e) => {
                                    setOptsReport(old => {
                                        return { ...old, iniInterval: e.target.value }
                                    })
                                }}
                            ></input>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: "100%" }}>
                            <span style={{ margin: 0, minWidth: 100, width: 100 }}>Intervalo fim:</span>
                            <input
                                type='date'
                                disabled={optsReport.optSearch === 'protocolo' ? true :  false}
                                value={optsReport.endInterval}
                                style={{ width: 130, minWidth: 130, }}
                                onChange={(e) => {
                                    setOptsReport(old => {
                                        return { ...old, endInterval: e.target.value }
                                    })
                                }}
                            ></input>
                        </div>
                    </div>


                    <div style={{ marginBottom: 20, padding: 20, display: 'flex', flexDirection: 'column', gap: 10, minWidth: 320, width: '55%', borderRadius: 5, boxShadow: '#3c3c3c88 3px 3px 10px' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', width: "100%" }}>
                            <span style={{ margin: 0, minWidth: 80, width: 80 }}>Buscar por:</span>
                            <select
                                style={{ width: 200, minWidth: 200, }}
                                value={optsReport.optSearch}
                                defaultValue="data"
                                onChange={(e) => {
                                    setOptsReport(old => {
                                        return { 
                                            ...old, 
                                            optSearch: e.target.value,
                                            argSearch: '',
                                            argSearch2: ''
                                        }
                                    })
                                }}
                            >
                                <option value="data">Apenas datas</option>
                                <option value="fornecedor">Datas e fornecedor</option>
                                <option value="trecho">Datas e trecho</option>
                                <option value="protocolo">Apenas Protocolo</option>
                            </select>

                            <input
                                type='submit'
                                disabled={btnDisabled}
                                value="Buscar"
                                style={{ marginLeft: 15, padding: 4, width: 80, border: 'none', backgroundColor: btnDisabled === false ? '#3c5ca6' : '#c3c3c3', color: '#fff', borderRadius: 5 }}
                                onClick={() => {
                                    setBtnDisabled(true)
                                    // console.log(optsReport)
                                    buscarEventos(optsReport)
                                }}
                            />

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {optsReport.optSearch !== 'data' &&
                                <>
                                    <input
                                        type='text'
                                        onChange={(e) => {
                                            setOptsReport(old => {
                                                return { ...old, argSearch: e.target.value }
                                            })
                                        }}
                                        style={{ marginLeft: 80, minWidth: 200, width: 200 }}
                                        placeholder={optsReport.optSearch === 'fornecedor' ? 'Insira o nome do forncedor' : optsReport.optSearch === 'protocolo' ? 'Insira o protocolo' : optsReport.optSearch === 'trecho' ? 'Insira o Trecho Ponto A' : ''}
                                        value={optsReport.argSearch}
                                    />

                                    {optsReport.optSearch === 'trecho' &&
                                        <input
                                            type='text'
                                            onChange={(e) => {
                                                setOptsReport(old => {
                                                    return { ...old, argSearch2: e.target.value }
                                                })
                                            }}
                                            style={{ marginLeft: 15, minWidth: 200, width: 200 }}
                                            placeholder="Insira o Trecho Ponto B"
                                            value={optsReport.argSearch2}
                                        />
                                    }
                                </>
                            }

                        </div>
                    </div>

                </div>{/* final barra de opçoes de visualização */}


                <div className="outage-report-rows-container">
                    {eventList.length > 0 && 
                        <OutageReportTable data={eventList} />
                        // <OutageRow ev={ev} key={`${ev._id}`} buscar_eventos={buscarEventos} />
                    }

                    {eventList.length === 0 &&
                        <div style={{ fontSize: '1.5em', display: 'flex', width: '100%', justifyContent: 'center', marginBottom: 20 }}>{messageNoData}</div>
                    }
                </div>
            </div>

            {/* /Content */}

        </div>
    )
}

export default OutageReport;