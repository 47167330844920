import React, { useState } from 'react';

import axios from 'axios'

const { api_backend_address_nr } = require('../../config.json')

const GeradorScriptProvisionamento = (props) => {
    const [data, setData] = useState({
        snmpprofile: '',//DATACOM
        srvprofile: '',//HUAWEI
        lineprofile: '',
        remoteprofile: '',//ZTE
        uservlan: '',
        gemport: '',
        shelf: '',
        slot: '',
        pon: '',
        text: '',
    })

    const [output, setOutput] = useState('')

    const handleData = (key, value) => {
        setData(old => {
            return ({ ...old, [key]: value })
        })
    }

    let url = ''

    switch (props.option) {
        case 'datacom_generator':
            url = `${api_backend_address_nr}gerarscriptdatacom?onlyresp=true`
            break;
        case 'huawei_generator':
            url = `${api_backend_address_nr}gerarscript2?onlyresp=true`
            break;
        case 'zte_generator':
            url = `${api_backend_address_nr}gerarscriptzte?onlyresp=true`
            break;

        default:
            break;
    }

    return (
        <div className='flex flex-row w-full justify-between'>
            <div className='flex flex-col max-w-[49%]'>
                <h2 className='mt-6'>Insira o texto sem cabeçalho com as seguintes colunas (mac, vlan, descrição)</h2>
                <p>OBS: Os espaços entre as colunas devem ter no mínimo 2 espaços</p>

                <div
                    style={{ display: 'flex', flexDirection: 'column' }}
                    className=' mt-2 space-y-2'
                >
                    {props.option && props.option === 'datacom_generator' &&

                        <input className='p-2 border border border-intec-200 rounded' type="text" placeholder="snmp profile"
                            onChange={(e) => {
                                handleData('snmpprofile', e.target.value)
                            }}
                        />
                    }

                    {props.option && props.option === 'huawei_generator' &&
                        <input className='p-2 border border border-intec-200 rounded' type="text" placeholder="srv profile"
                            onChange={(e) => {
                                handleData('srvprofile', e.target.value)
                            }}
                        />
                    }

                    {props.option && props.option === 'zte_generator' &&
                        <input className='p-2 border border border-intec-200 rounded' type="text" placeholder="remote profile"
                            onChange={(e) => {
                                handleData('remoteprofile', e.target.value)
                            }}
                        />
                    }

                    <input className='p-2 border border border-intec-200 rounded' type="text" placeholder="line profile"
                        onChange={(e) => {
                            handleData('lineprofile', e.target.value)
                        }}
                    />

                    <input className='p-2 border border border-intec-200 rounded' type="text" placeholder="user vlan"
                        onChange={(e) => {
                            handleData('uservlan', e.target.value)
                        }}
                    />

                    {props.option && props.option !== 'zte_generator' &&
                        <input className='p-2 border border border-intec-200 rounded' type="text" placeholder="gemport"
                            onChange={(e) => {
                                handleData('gemport', e.target.value)
                            }}
                        />
                    }

                    <div className='space-x-2'>
                        <input className='p-2 border border border-intec-200 rounded' type="text" placeholder="shelf"
                            onChange={(e) => {
                                handleData('shelf', e.target.value)
                            }}
                        />
                        <input className='p-2 border border border-intec-200 rounded' type="text" placeholder="slot"
                            onChange={(e) => {
                                handleData('slot', e.target.value)
                            }}
                        />
                        <input className='p-2 border border border-intec-200 rounded' type="text" placeholder="pon"
                            onChange={(e) => {
                                handleData('pon', e.target.value)
                            }}
                        />
                    </div>
                    <textarea className='border border border-intec-200 rounded' rows="10" cols="150"
                        placeholder="1234567891234567	100  descricao&#10;1234567891234567	100  descricao"
                        onChange={(e) => {
                            handleData('text', e.target.value)
                        }}
                    ></textarea>
                    <input className='p-2 border border border-intec-200 rounded' type="button" value="Gerar Script" style={{ height: 50 }}
                        onClick={() => {
                            axios.post(url, data).then(resp => {
                                console.log(resp.data)
                                setOutput(resp.data)
                            })
                        }}
                    />
                </div>
            </div>

            <div className='flex flex-col w-[49%] min-w-[49%] max-w-[49%] mt-6'>
                <span>Saída</span>
                <div className='border border border-intec-200 rounded' style={{ whiteSpace: 'pre-line' }}>
                    {output ? output : <>&nbsp;</>}
                </div>
            </div>
        </div>
    )
}

const ShowContent = (props) => {
    switch (props.option) {
        case 'datacom_generator':
        case 'huawei_generator':
        case 'zte_generator':
            return (<GeradorScriptProvisionamento option={props.option} />)
            break;

        default:
            return (<></>)
            break;
    }
}

function SgiTools(props) {
    const [selectedTool, setSelectedTool] = useState('')
    return (
        <div className='p-4 w-full height_less_header overflow-y-auto'>
            <div className='flex flex-row my-3 space-x-3 w-full rounded border border-intec-200 p-5 bg-white justify-start'>
                <span className='ml-0 font-bold'>Selecione uma ferramenta</span>
                <select
                    onChange={(e) => {
                        setSelectedTool(e.target.value)
                    }}
                >
                    <option value=''>Selecione</option>
                    <option value='datacom_generator'>Gerador Datacom (script de provisionamento)</option>
                    <option value='huawei_generator'>Gerador Huawei (script de provisionamento)</option>
                    <option value='zte_generator'>Gerador ZTE (script de provisionamento)</option>
                </select>
            </div>
            <div className='flex flex-row justify-between'>
                <ShowContent option={selectedTool} />
            </div>
        </div>
    );
}

export default SgiTools;