import React from 'react';

import styles from './UploadComponentStyle.module.css'

function UploadComponent(props) {
    return (
        <div className={styles.inputWrapper}>
            <input
                onChange={props.handleFileChange}
                type='file'
                multiple name="file"
                className={styles.fileInput}
                style={props.style || null}
            />
        </div>
    );
}

export default UploadComponent;