import React from 'react';

// import { Container } from './styles';

import { msToTime } from '../../util/fns';

function OutageReportTable({ data = [] }) {


    const timeString = (_ms) => {
        const timeObj = msToTime(_ms)

        return `${timeObj.horas}:${timeObj.minutos}:${timeObj.segundos}`
    }


    const RowTableHeader = (props) => {
        return (
            <tr>
                {props.data.map(el => (
                    <td align='center' width="150" style={{ padding: 3, border: '#c3c3c3 1px solid' }}><b>{el}</b></td>
                ))}
            </tr>
        )
    }

    const RowTable = (props) => {
        return (
            <tr>
                {props.data.map(el => (
                    <td style={{ padding: 3, width: 150, border: '#c3c3c3 1px solid', fontSize: 10 }}>{el}</td>
                ))}
            </tr>
        )
    }


    return (
        <>
            <table border={0} cellSpacing={0} style={{ marginBottom: 20 }} id="outage-report-table">
                <RowTableHeader data={['COD', 'EVENTO', 'PROTOCOLO', 'HORÁRIO DO INCIDENTE', 'FORNCECEDOR', 'TRECHO PONTO A', 'TRECHO PONTO B', 'SUB-TRECHO PONTO A', 'SUB-TRECHO PONTO B', 'FINALIZAÇÃO DO TICKET', 'TEMPO TOTAL']} />
                {data.length > 0 && data.map(el => (
                    <RowTable key={el._id} data={[
                        String(el.cod_id).padStart(5, '0'),
                        el.tipo_evento || <>&nbsp;&nbsp; - </>,
                        el.numero_protocolo || <>&nbsp;&nbsp; - </>,
                        el.horario_inicio !== '' ? `${new Date(el.horario_inicio).toLocaleDateString()} às ${new Date(el.horario_inicio).toLocaleTimeString()}` : <>&nbsp;&nbsp; - </>,
                        el.fornecedor || <>&nbsp;&nbsp; - </>,
                        el.trecho_ponto_a || <>&nbsp;&nbsp; - </>,
                        el.trecho_ponto_b || <>&nbsp;&nbsp; - </>,
                        el.sub_trecho_ponto_a || <>&nbsp;&nbsp; - </>,
                        el.sub_trecho_ponto_b || <>&nbsp;&nbsp; - </>,
                        el.horario_fim !== '' ? `${new Date(el.horario_fim).toLocaleDateString()} às ${new Date(el.horario_fim).toLocaleTimeString()}` : <>&nbsp;&nbsp; - </>,
                        timeString((new Date(el.horario_fim).getTime() - new Date(el.horario_inicio))) || <>&nbsp;&nbsp; - </>
                    ]} />
                ))}
            </table>
            <input
                type='button'
                value="Exportar"
                style={{ marginBottom: 20, padding: 4, border: 'none', backgroundColor: '#3c5ca6', color: '#fff', borderRadius: 5 }}
                onClick={() => {
                    var headertable = '<h3>Export Outages - ' + new Date().toLocaleDateString() + ' às ' + new Date().toLocaleTimeString() + '</h3>'
                    var a = document.createElement('a');
                    var data_type = 'data:application/vnd.ms-excel';
                    var table_div = document.getElementById('outage-report-table');
                    var table_html = headertable.replace(/ /g, '%20') + table_div.outerHTML.replace(/ /g, '%20');
                    a.href = data_type + ', ' + table_html;
                    a.download = 'outage-export.xls';
                    a.click();
                    // e.preventDefault();
                }}
            />
        </>
    );
}

export default OutageReportTable;

/*

Fornecedor: VELOO
Protocolo: 202305431719
Trecho Ponto A: MACEIO (MCO)
Trecho Ponto B: CARUARU (CRU)
Sub Trecho Ponto A: CARUARU (CRU)
Sub Trecho Ponto B: QUIPAPA (QPA)
Horário do incidente: 20/05/2023 às 11:29:00
Ticket aberto em: 02/05/2023 às 11:56:23
Última interação: 02/05/2023 às 13:47:11
Ticket Finalizado em: 02/05/2023 às 12:25:00


{
    "_id": "640a590d64a71503330049b0",
    "status": "aberto",
    "tipo_evento": "Link Com Problema",
    "horario_inicio": "2023-03-05T04:46",
    "horario_fim": "",
    "trecho_ponto_a": "SALVADOR (SDR-A01)",
    "trecho_ponto_b": "BARUERI (BRE-A02) ",
    "sub_trecho_ponto_a": "",
    "sub_trecho_ponto_b": "",
    "responsabilidade": "",
    "atendente": "",
    "numero_protocolo": "CSE0038140",
    "qual_o_problema": "ROMPIMENTO",
    "clientes_afetados": "",
    "fornecedor": "TELXIUS",
    "estacao": "",
    "responsavel_estacao": "",
    "equipamento": "",
    "userId": "62dabe20a09d8b003d7c6e01",
    "userName": "Benisson Santos",
    "userLevel": "Técnico",
    "customer_id": "",
    "customer_name": "",
    "date_rfo": "",
    "ev_time": "",
    "rfo_supervisor": "",
    "rfo_numero_protocolo": "",
    "causa": "",
    "solucao": "",
    "observacao": "",
    "data_insercao": "2023-03-09T22:09:17.990Z",
    "cod_id": 27,
    "interacoes": [
        {
            "tipo_interacao": "",
            "hora_contato": "",
            "quem_foi_contatado": "",
            "observacao": "",
            "foi_escalonado": "",
            "hora_escalonado": "",
            "pra_quem_foi_escalonado": "",
            "porque_nao_foi_escalonado": "",
            "previsao_para_solucao": "",
            "foi_solucionado": "",
            "qual_foi_solucao": "",
            "hora_solucao": "",
            "porque_nao_foi_solucionado": "",
            "feedback_do_suporte": "",
            "txt_interacao": "ROTA TELXIUS SUBMARINA, Sem data prevista para reparo.",
            "add_rfo": true,
            "userId": "62dabe20a09d8b003d7c6e01",
            "userName": "Benisson Santos",
            "userLevel": "Técnico",
            "_id": "640a592864a71503330049b1",
            "data": "2023-03-09T22:09:44.512Z"
        },
        {
            "tipo_interacao": "",
            "hora_contato": "",
            "quem_foi_contatado": "",
            "observacao": "",
            "foi_escalonado": "",
            "hora_escalonado": "",
            "pra_quem_foi_escalonado": "",
            "porque_nao_foi_escalonado": "",
            "previsao_para_solucao": "",
            "foi_solucionado": "",
            "qual_foi_solucao": "",
            "hora_solucao": "",
            "porque_nao_foi_solucionado": "",
            "feedback_do_suporte": "",
            "txt_interacao": "Segundo noticias do site Telesintese https://www.telesintese.com.br/telxius-vai-trazer-navio-de-manutencao-ao-brasil-para-reparar-cabo-submarino-na-costa-da-bahia/\n\nSegundo Sgrott, a falha em questão foi no cabo SAM-1. O componente liga Fortaleza a Santos (SP), passando por Rio de Janeiro e Salvador. O dano ocorreu em uma branch unit (derivação) localizada a 30 km da costa da capital baiana. O cabo reside a uma profundidade de 1.5 mil metros a 2 mil metros do fundo do mar.\n\nO reparo será feito por um navio de manutenção do consórcio APMA. Antes de chegar ao Brasil, a embarcação, que presta serviço nas águas dos oceanos Atlântico e Pacífico, deve repor os estoques de peças. A expectativa é de que o conserto, ou a eventual substituição do componente, seja feito em até 30 dias.",
            "add_rfo": true,
            "userId": "639bc7edaa3aa20031b43929",
            "userName": "Pedro Ivo",
            "userLevel": "Técnico",
            "_id": "6411a9db2f718d0105eafb2e",
            "data": "2023-03-15T11:19:55.698Z"
        },
        {
            "tipo_interacao": "",
            "hora_contato": "",
            "quem_foi_contatado": "",
            "observacao": "",
            "foi_escalonado": "",
            "hora_escalonado": "",
            "pra_quem_foi_escalonado": "",
            "porque_nao_foi_escalonado": "",
            "previsao_para_solucao": "",
            "foi_solucionado": "",
            "qual_foi_solucao": "",
            "hora_solucao": "",
            "porque_nao_foi_solucionado": "",
            "feedback_do_suporte": "",
            "txt_interacao": "Telxius informou que foi identificado falha no cabo submarino que liga Fortaleza a Santos (SP), passando por Rio de Janeiro e Salvador. O dano ocorreu em uma branch unit (derivação) localizada a 30 km da costa da capital baiana. O cabo reside a uma profundidade de 1.5 mil metros a 2 mil metros do fundo do mar. O reparo será feito por um navio de manutenção a expectativa é de que o conserto, ou a eventual substituição do componente, seja feito em até 30 dias..",
            "add_rfo": true,
            "userId": "62dabe20a09d8b003d7c6e01",
            "userName": "Benisson Santos",
            "userLevel": "Técnico",
            "_id": "6411cd9c2f718d0105eafb93",
            "data": "2023-03-15T13:52:28.837Z"
        },
        {
            "tipo_interacao": "",
            "hora_contato": "",
            "quem_foi_contatado": "",
            "observacao": "",
            "foi_escalonado": "",
            "hora_escalonado": "",
            "pra_quem_foi_escalonado": "",
            "porque_nao_foi_escalonado": "",
            "previsao_para_solucao": "",
            "foi_solucionado": "",
            "qual_foi_solucao": "",
            "hora_solucao": "",
            "porque_nao_foi_solucionado": "",
            "feedback_do_suporte": "",
            "txt_interacao": "Vai ser necessário a recuperação do cabo da costa de salvador, cerca de 50km ate o entroncamento com a continuação do cabo sentido sudeste a previsão final ate entao para recuperação é 10 de maio podendo ser estendido de acordo com a evolução das atividades",
            "add_rfo": false,
            "userId": "639bc7edaa3aa20031b43929",
            "userName": "Pedro Ivo",
            "userLevel": "Técnico",
            "_id": "6446accdf1b950002b51ed91",
            "data": "2023-04-24T16:22:37.510Z"
        }
    ],
    "tratativas": [],
    "createdAt": "2023-03-09T22:09:17.834Z",
    "updatedAt": "2023-04-24T16:22:37.509Z",
    "__v": 0,
    "evento_solucionado": {
        "status": true,
        "horario": "2023-03-29T19:18:33.154Z",
        "userId": "639bc7edaa3aa20031b43929",
        "userName": "Pedro Ivo",
        "userLevel": "Técnico"
    }
}
*/