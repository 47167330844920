import React, { useEffect } from 'react';
import { logout } from '../services/Api'
import { Redirect } from 'react-router-dom';

import { useSetRecoilState } from 'recoil'
import userState from '../atoms/user.atom'
import customerData from '../atoms/customers.atom'
import openTreatment from '../atoms/open_treatment'
import navigationState from '../atoms/navigate_state.atom'

import {logoff} from '../services/login'

function Logout({setToken}) {
    const setUser = useSetRecoilState(userState)
    const customer_Data = useSetRecoilState(customerData)
    const tratativa_aberta = useSetRecoilState(openTreatment)
    const navigation_state = useSetRecoilState(navigationState)


    useEffect(() => { 
        // logout()
        // setToken(undefined)
        setUser(logoff())
        customer_Data([])
        tratativa_aberta(null)
        navigation_state({})
    }, [])

    return (
        <Redirect to="/login" />
    )
}

export default Logout;