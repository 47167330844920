import { atom } from 'recoil'
// import { recoilPersist } from 'recoil-persist'

const outagesComponentToShowState = atom({
    key: 'outagesComponentState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
    // effects_UNSTABLE: [persistAtom],
});

export const outagesDetailTicketState = atom({
    key: 'outagesDetailState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
    // effects_UNSTABLE: [persistAtom],
});

export const outagesListTicketState = atom({
    key: 'outagesListState', // unique ID (with respect to other atoms/selectors)
    default: [], // default value (aka initial value)
    // effects_UNSTABLE: [persistAtom],
});

export const outagesListTickeClosedtState = atom({
    key: 'outagesListClosedState', // unique ID (with respect to other atoms/selectors)
    default: [], // default value (aka initial value)
    // effects_UNSTABLE: [persistAtom],
});


export default outagesComponentToShowState