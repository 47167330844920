import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({
    key: '@sgiTriagemTicket', // this key is using to store data in local storage
    storage: localStorage, // configurate which stroage will be used to store the data
})

const triagemTicket = atom({
    key: 'triagemTicket', // unique ID (with respect to other atoms/selectors)
    default: {
        made_contact: '',
        verified_priority: '',
        verified_title: '',
        verified_description: ''
    },
    // effects_UNSTABLE: [persistAtom],
});

export default triagemTicket