import axios from 'axios';
import { useEffect, useState } from 'react';
import CustomerBarChart from './CustomerBarChart';
import { FileSpreadsheet } from 'lucide-react';

const { backend_files_address } = require('../../config.json')

function CustomerReportCGR(props) {
	const [customerData, setCustomerData] = useState([])
	const [chartData, setChartData] = useState({})

	const formatToExport = (_data) => {
		let xlsx = [
			['Cliente:', _data.customer_name],
			['Tempo Total:', _data.tempoTotalF],
			[],
			['Ticket', 'Título', 'Tempo', 'Categoria', 'Analistas'],
		]

		let temp = Object.keys(_data.analistas).map(key => {
			return ({
				..._data.analistas[key]
			})
		})

		temp = temp.sort((a, b) => {
			if (a.analista < b.analista) {
				return -1;
			}
			if (a.analista > b.analista) {
				return 1;
			}
			return 0;
		})

		temp.forEach(el => {
			Object.keys(el.ticketsPerDate).length > 0 && Object.keys(el.ticketsPerDate).map((key) => {
				Object.keys(el.ticketsPerDate[key]).map((key2) => {
					xlsx.push([
						key2,
						el.ticketsPerDate[key][key2].titulo,
						el.ticketsPerDate[key][key2].tempoF,
						el.ticketsPerDate[key][key2].categoria.toUpperCase() || " - ",
						el.ticketsPerDate[key][key2].respTec.join(', ')
					])
				})
			})
		})

		// console.log('result', xlsx)
		exportToCsv(xlsx, `Report ${_data.customer_name}`)
	}

	// var Results = [array1, array2];
	const exportToCsv = function (_arrOfArr, title) {
		let CsvString = "";
		_arrOfArr.forEach(function (RowItem, RowIndex) {
			RowItem.forEach(function (ColItem, ColIndex) {
				CsvString += ColItem + ";";
			});
			CsvString += "\r\n";
		});
		CsvString = "data:application/csv," + encodeURIComponent(CsvString);
		let x = document.createElement("A");
		x.setAttribute("href", CsvString);
		x.setAttribute("download", `${title}.csv`);
		document.body.appendChild(x);
		x.click();
	};


	const ShowDetail = ({ analistas }) => {
		const [analystData, setAnalystData] = useState([])

		useEffect(() => {
			let temp = Object.keys(analistas).map(key => {
				return ({
					...analistas[key]
				})
			})

			temp = temp.sort((a, b) => {
				if (a.analista < b.analista) {
					return -1;
				}
				if (a.analista > b.analista) {
					return 1;
				}
				return 0;
			})

			setAnalystData(temp)
		}, [analistas])

		return (
			<>
				<div className='ml-8 mb-3'>
					{/* <details>
						<summary className='text-intec-500'>Detalhes</summary> */}
					{analystData.length > 0 && analystData.map(el => (
						Object.keys(el.ticketsPerDate).length > 0 && Object.keys(el.ticketsPerDate).map((key, index) => (
							<ul className='ml-8'>
								{Object.keys(el.ticketsPerDate[key]).map((key2, index2) => (
									<div key={`${key2}_${index2}`}>
										{/* <li>Data: {new Date(`${key} 00:00:00`).toLocaleDateString()}</li> */}
										<li className='font-bold'>Ticket: {key2}</li>
										<li>Título: {el.ticketsPerDate[key][key2].titulo}</li>
										<li>Tempo: {el.ticketsPerDate[key][key2].tempoF}</li>
										<li>Categoria: {el.ticketsPerDate[key][key2].categoria.toUpperCase() || " - "}</li>
										<li className='mb-3'>Analista(s): {el.ticketsPerDate[key][key2].respTec.join(', ')}</li>
									</div>
								))}
							</ul>
						))
					))}
					{/* </details> */}
				</div>
			</>
		)
	}

	const formatBarDataChart = (_data, _setData) => {
		// Bar Chart Data
		let barDataChart = {
			labels: [],
			datasets: [
				{
					label: 'Horas Contratadas',
					data: [],
					backgroundColor: '#00730b',
					borderWidth: 1
				},
				{
					label: 'Horas Trabalhadas',
					data: [],
					backgroundColor: '#324d8c',
					borderWidth: 1
				}
			]
		};

		_data.forEach(el => {
			// console.log(el.horasContrato)
			barDataChart.labels.push(el.customer_name)
			barDataChart.datasets[0].data.push(el.horasContrato)
			barDataChart.datasets[1].data.push(el.tempoTotal)
		})

		if (barDataChart.labels.length > 10) {
			barDataChart.labels = barDataChart.labels.slice(0, 10)
		}

		if (barDataChart.datasets[1].data.length > 10) {
			barDataChart.datasets[0].data = barDataChart.datasets[0].data.slice(0, 10)
			barDataChart.datasets[1].data = barDataChart.datasets[1].data.slice(0, 10)
		}

		_setData(barDataChart)
	}

	useEffect(() => {
		axios.get(`https://apisgi.intecsolutions.com.br/nrapi/customerreport/${props.interval.datei.toLocaleDateString('en-ca')}/${props.interval.datef.toLocaleDateString('en-ca')}/?setor=cgr`)
			.then(resp => {
				// console.log(resp.data)
				if (resp.data && Object.keys(resp.data).length > 0) {
					let temp = Object.keys(resp.data).map(key => {
						return ({
							...resp.data[key]
						})
					})

					temp = temp.sort((a, b) => {
						if (a.tempoTotal > b.tempoTotal) {
							return -1;
						}
						if (a.tempoTotal < b.tempoTotal) {
							return 1;
						}
						return 0;
					})

					// console.log(temp)

					formatBarDataChart(temp, setChartData)

					setCustomerData(temp)
				}
			})
	}, [props.interval])


	// useEffect(() => {
	// 	const data = {
	// 		labels: ['um', 'dois', 'tres', 'quatro', 'cinco', 'seis', 'sete'],
	// 		datasets: [{
	// 			label: 'My First Dataset',
	// 			data: [65, 59, 80, 81, 56, 55, 40],
	// 			// backgroundColor: [
	// 			// 	'rgba(255, 99, 132, 0.2)',
	// 			// 	'rgba(255, 159, 64, 0.2)',
	// 			// 	'rgba(255, 205, 86, 0.2)',
	// 			// 	'rgba(75, 192, 192, 0.2)',
	// 			// 	'rgba(54, 162, 235, 0.2)',
	// 			// 	'rgba(153, 102, 255, 0.2)',
	// 			// 	'rgba(201, 203, 207, 0.2)'
	// 			// ],
	// 			// borderColor: [
	// 			// 	'rgb(255, 99, 132)',
	// 			// 	'rgb(255, 159, 64)',
	// 			// 	'rgb(255, 205, 86)',
	// 			// 	'rgb(75, 192, 192)',
	// 			// 	'rgb(54, 162, 235)',
	// 			// 	'rgb(153, 102, 255)',
	// 			// 	'rgb(201, 203, 207)'
	// 			// ],
	// 			borderWidth: 1
	// 		}]
	// 	};
	// 	setChartData(data)
	// }, [])

	return (
		<div className='w-full h-full'>
			{customerData.length > 0 && Object.keys(chartData).length > 0 &&
				<CustomerBarChart data={chartData} />
			}
			{customerData.length > 0 && customerData.map(el => (
				<div key={`${el.id}`} className='flex flex-row my-3 space-x-3 w-full rounded border border-intec-200 p-5 bg-white'>
					<div>
						{/* <img className='rounded-full w-[70px] h-[70px] object-cover' src={`https://apisgi.intecsolutions.com.br/api/customers/getimg/${encodeURI(el.customer_name)}`} /> */}

						<object className='rounded-full w-[70px] h-[70px] object-cover' data={`https://apisgi.intecsolutions.com.br/api/customers/getimg/${encodeURI(el.customer_name)}`} type="image/png">
							<img className='rounded-full w-[70px] h-[70px] object-cover' src={`${backend_files_address}/user_placeholder.jpg`} />
						</object>

					</div>
					<div>
						<p className='font-bold flex flex-row items-center'>
							<span style={{ margin: 0 }}>{el.customer_name} </span>
							<span
								className='flex flex-row  items-center cursor-pointer'
								onClick={() => { formatToExport(el) }}
							>Exportar: <FileSpreadsheet size={20} className='ml-2' /></span>
						</p>
						<p className='text-intec-500 text-md'>Tempo Total: {el.tempoTotalF}</p>


						<details>
							<summary className='text-intec-500'>Detalhes</summary>
							<ShowDetail analistas={el.analistas} />
						</details>
					</div>
				</div>
			))}
		</div>
	);
}

export default CustomerReportCGR;