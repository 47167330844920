// import { Blocks } from 'lucide-react'

import { useEffect, useState } from "react";

import { useRecoilValue } from "recoil";
import { profileData } from "../../atoms/user.atom";
import { api_bd } from "../../services/Api";

const { backend_files_address } = require('../../config.json')

function TicketClassification({ ticketData, setTicketState, closeModal }) {
    const loggedUser = useRecoilValue(profileData)

    const listOfTypes = [
        {
            tipo_solicitacao: 'IMPLANTAÇÕES',
            categoria: 'Implantação'
        },
        {
            tipo_solicitacao: 'SUPORTES',
            categoria: 'Suporte'
        },
        {
            tipo_solicitacao: 'ADEQUAÇÕES',
            categoria: 'Adequação'
        },
        {
            tipo_solicitacao: 'MIGRAÇÕES',
            categoria: 'Migração'
        },
    ]

    const icons = {
        arrowRight: (size = 24) => <svg xmlns="http://www.w3.org/2000/svg" width={`${size}`} height={`${size}`} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M6 9h6V5l7 7-7 7v-4H6V9z" /></svg>,
        sector: (size = 24) => <svg xmlns="http://www.w3.org/2000/svg" width={`${size}`} height={`${size}`} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><rect width="7" height="7" x="14" y="3" rx="1" /><path d="M10 21V8a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H3" /></svg>,
        category: (size = 24) => <svg xmlns="http://www.w3.org/2000/svg" width={`${size}`} height={`${size}`} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M15 3v18" /><rect width="18" height="18" x="3" y="3" rx="2" /><path d="M21 9H3" /><path d="M21 15H3" /></svg>,
        user: (size = 24) => <svg xmlns="http://www.w3.org/2000/svg" width={`${size}`} height={`${size}`} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" /><circle cx="9" cy="7" r="4" /><path d="M22 21v-2a4 4 0 0 0-3-3.87" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /></svg>
    }

    const [actualStep, setActualStep] = useState(0)
    const [data, setData] = useState({
        setor: 'noc',
        tipo_solicitacao: 'SUPORTES',
        categoria: 'Suporte'
    })
    const [respTec, setRespTec] = useState({})
    const [listUsers, setListUsers] = useState([])

    const handleData = (key, data) => {
        setData(old => {
            return ({ ...old, [key]: data })
        })
    }

    const getListUsers = (setData) => {
        api_bd.get('/users')
            .then(resp => {
                const alphabetically = resp.data.filter(user => user.ativo === true).sort((a, b) => a.nome.localeCompare(b.nome))

                let usersList = [...alphabetically]

                for (let ukey in usersList) {
                    usersList[ukey]['image_profile'] = usersList[ukey].image_profile
                }

                setData(usersList)
            })
            .catch(e => { console.error('list users error: ', e) })
    }

    const Step0 = () => {
        return (
            <div className="flex flex-col">
                <p className="pl-1">Selecione o setor responsável</p>
                <select
                    className="mt-3 p-3 border border-zinc rounded"
                    onChange={(e) => {
                        handleData('setor', e.target.value)
                    }}
                    value={data.setor}
                >
                    <option value='noc'>NOC</option>
                    <option value='cgr'>CGR</option>
                </select>

                <button
                    className="mt-20 bg-intec-500 hover:bg-intec-600 text-white font-bold rounded p-3 w-[80px] self-end"
                    onClick={() => {
                        setActualStep(old => {
                            if (old + 1 <= 3) {
                                return old + 1
                            } else {
                                return old
                            }
                        })
                    }}
                >
                    Próximo
                </button>
            </div>
        )
    }

    const Step1 = () => {
        return (
            <div className="flex flex-col">
                <p className="pl-1">Selecione a categoria</p>
                <select
                    className="mt-3 p-3 border border-zinc rounded"
                    onChange={(e) => {
                        handleData('tipo_solicitacao', e.target.value.split('|')[0])
                        handleData('categoria', e.target.value.split('|')[1])
                    }}
                >
                    {listOfTypes.map(el => (
                        <option key={`${el.tipo_solicitacao}`} selected={el.tipo_solicitacao === data.tipo_solicitacao} value={`${el.tipo_solicitacao}|${el.categoria}`}>{el.tipo_solicitacao}</option>
                    ))}
                </select>

                <div className="flex flex-row mt-20 w-full justify-between">
                    <button
                        className="bg-intec-500 hover:bg-intec-600 text-white font-bold rounded p-3 w-[80px]"
                        onClick={() => {
                            setActualStep(old => {
                                if (old - 1 >= 0) {
                                    return old - 1
                                } else {
                                    return old
                                }
                            })
                        }}
                    >
                        Anterior
                    </button>

                    <button
                        className="bg-intec-500 hover:bg-intec-600 text-white font-bold rounded p-3 w-[80px]"
                        onClick={async () => {
                            if (loggedUser.nivel === "Técnico") {
                                await addRespTec(ticketData._id, loggedUser)
                                await api_bd.put(`/tickets/${ticketData._id}`, {
                                    ...data
                                }).then(resp => {
                                    setTicketState({ page_content: 'ticket_detail', data: resp.data[0] })
                                    closeModal()
                                }).catch(e => {
                                    alert('Ocorreu um erro tente novamente mais tarde')
                                })
                            } else {
                                setActualStep(old => {
                                    if (old + 1 <= 3) {
                                        return old + 1
                                    } else {
                                        return old
                                    }
                                })
                            }
                        }}
                    >
                        {loggedUser.nivel === "Técnico" ? 'Salvar' : 'Próximo'}
                    </button>
                </div>

            </div>
        )
    }

    const Step2 = () => {
        return (
            <div className="flex flex-col">
                <p className="pl-4">{Object.keys(respTec).length > 0 ? <>Técnico Selecionado: <b>{respTec.nome}</b></> : <>Selecione um técnico</>}</p>
                <div
                    style={{ width: '100%', maxHeight: 150, overflowY: 'auto' }}
                    className="bg-zinc-100 rounded p-3"
                >

                    {listUsersFree(listUsers, ticketData.resposaveis_tecnicos).length === 0
                        ?
                        (
                            <>Todos os técnicos já foram adicionados</>
                        )
                        :
                        (
                            listUsersFree(listUsers, ticketData.resposaveis_tecnicos).map(user => (
                                <div
                                    // className='toast-list-users-card'
                                    className='mt-3 p-3 flex flex-row justify-between items-center cursor-pointer border border-zinc rounded'
                                    onClick={(e) => {
                                        setRespTec(user)
                                    }}
                                >
                                    <div>
                                        {user.nome.toUpperCase()}
                                    </div>
                                    <div className="w-20 h-20">
                                        <img
                                            className="rounded-full w-full h-full object-cover"
                                            src={`${(user.image_profile.filename !== '') ? backend_files_address + '/' + user.image_profile.filename : `${backend_files_address}/user_placeholder.jpg`} `}
                                        />
                                    </div>
                                </div>
                            ))
                        )
                    }
                </div>

                <div className="flex flex-row mt-20 w-full justify-between">
                    <button
                        className="bg-intec-500 hover:bg-intec-600 text-white font-bold rounded p-3 w-[80px]"
                        onClick={() => {
                            setActualStep(old => {
                                if (old - 1 >= 0) {
                                    return old - 1
                                } else {
                                    return old
                                }
                            })
                        }}
                    >
                        Anterior
                    </button>

                    <button
                        className={`${Object.keys(respTec).length > 0 ? 'bg-intec-500 hover:bg-intec-600' : 'bg-zinc'} text-white font-bold rounded p-3 w-[80px]`}
                        onClick={async () => {
                            if (Object.keys(respTec).length > 0) {
                                setActualStep(old => {
                                    if (old + 1 <= 3) {
                                        return old + 1
                                    } else {
                                        return old
                                    }
                                })

                                await addRespTec(ticketData._id, respTec)
                                await api_bd.put(`/tickets/${ticketData._id}`, {
                                    ...data
                                }).then(resp => {
                                    setTicketState({ page_content: 'ticket_detail', data: resp.data[0] })
                                    closeModal()
                                }).catch(e => {
                                    alert('Ocorreu um erro tente novamente mais tarde')
                                })
                                // console.log(`/tickets/${ticketData._id}`,{
                                //     ...data  
                                // })
                            }
                        }}
                    >
                        Salvar
                    </button>
                </div>
            </div>
        )
    }

    const listUsersFree = (arrayUsers, arrayTicketRespUsers) => {
        let list = []

        for (const k in arrayUsers) {
            const user = arrayUsers[k]
            if (user.nivel.match(/super|técnico/i)) {
                if ((arrayTicketRespUsers.find(el => (el.id === user._id)) ? false : true)) {
                    list.push(user)
                }
            }
        }

        return list
    }

    const addRespTec = (_id_ticket, _user) => {
        return new Promise((resolve, reject) => {
            api_bd.put(`/tickets/getforme/${_id_ticket}`, _user)
                .then(resp => resolve(resp.data))
                .catch(e => reject(e))
        })
        // set_navigation_state({ page_content: 'ticket_detail', data: resp.data[0] })
        // set_situacao_chamado(resp.data[0].situacao)
    }

    // console.log(loggedUser)

    const ShowContent = ({ actualStep }) => {
        switch (actualStep) {
            case 0:
                return <Step0 />
                break;
            case 1:
                return <Step1 />
                break;
            case 2:
                return <Step2 />
                break;

            default:
                return <></>
                break;
        }
    }

    useEffect(() => {
        getListUsers(setListUsers)
    }, [])

    return (

        <div className="w-2/4 bg-white rounded flex flex-col p-5" style={{ position: "relative" }} >
            <div className="flex flex-row justify-center items-center space-x-5 mb-20">
                <div className="flex flex-col items-center">
                    <div className={`${actualStep === 0 ? 'animation_filter_active' : ''} border-2 ${actualStep === 0 ? 'border-zinc text-zinc p-3' : 'border-green-500 text-green-500 p-3'} rounded-full w-20 h-20 flex justify-center items-center`}>
                        {icons.sector(24)}
                    </div>
                    <div className="mt-3">
                        Definição de Setor
                    </div>
                </div>

                <div className={`${actualStep === 0 ? 'border-zinc text-zinc' : 'border-green-500 text-green-500'} -mt-5`}>
                    {icons.arrowRight(32)}
                </div>

                <div className="flex flex-col items-center">
                    <div className={`${actualStep === 1 ? 'animation_filter_active' : ''} border-2 ${actualStep < 2 ? 'border-zinc text-zinc p-3' : 'border-green-500 text-green-500 p-3'} p-3 rounded-full w-20 h-20 flex justify-center items-center`}>
                        {icons.category(24)}
                    </div>
                    <div className="mt-3">
                        Definição de Categoria
                    </div>
                </div>

                {(loggedUser.nivel.match(/super|Técnico-Admin/i)) &&
                    <>
                        <div className={`${actualStep < 2 ? 'border-zinc text-zinc' : 'border-green-500 text-green-500'} -mt-5`}>
                            {icons.arrowRight(32)}
                        </div>


                        <div className="flex flex-col items-center">
                            <div className={`${actualStep >= 2 ? 'animation_filter_active' : ''} border-2 ${(actualStep < 3 && Object.keys(respTec).length === 0) ? 'border-zinc text-zinc p-3' : 'border-green-500 text-green-500 p-3'} p-3 rounded-full w-20 h-20 flex justify-center items-center`}>
                                {icons.user(24)}
                            </div>
                            <div className="mt-3">
                                Definição de Técnico
                            </div>
                        </div>
                    </>
                }
            </div>

            <ShowContent actualStep={actualStep} />

        </div >
    );
}

export default TicketClassification;