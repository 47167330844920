import React, { useState, useEffect, memo } from 'react';

import './MonitoramentoNoturno.css';

import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import EventDetail from './EventDetail';
import FormRompimento from './FormRompimento';
import FormEstacaoIndisponivel from './FormEstacaoIndisponivel';
import FormLinkComProblema from './FormLinkComProblema';
import FormClienteComProblema from './FormClienteComProblema';

import { api_bd } from '../../services/Api'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'
import customerData from '../../atoms/customers.atom'
import nightMonitoringComponentToShowState, { nightMonitoringListTicketState } from '../../atoms/night_monitoring _state.atom';

import Modal from '../Modal';
import NewTicket from './NewTicket';
import NewInteraction from './NewInteraction';
import TicketList from './TicketList';
import CloseTicket from './CloseTicket';

import { msToTime } from '../../util/fns';

const { backend_files_address } = require('../../config.json')

function MonitoramentoNoturno(props) {
	// const [eventList, setEventList] = useState([])
	const [showFormNewEvent, setShowFormNewEvent] = useState(false)

	const [componentToShow, setComponentToShow] = useRecoilState(nightMonitoringComponentToShowState)

	const profile_user = useRecoilValue(profileData)

	const FloatAddButtom = ({ showForm }) => {
		return (
			<div
				className='default_color'
				style={{ width: 50, height: 50, display: 'flex', borderRadius: 25, justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: 20, right: 30, cursor: 'pointer' }}
				onClick={(e) => {
					showForm(true)
				}}
			>
				<span className="material-icons left_icon default_color" style={{ margin: 0, color: '#fff', fontSize: '2.3em' }}>add</span>
			</div>
		)
	}

	const ContentComponent = () => {
		switch (componentToShow.component) {
			case 'newInteraction':
				return (
					<NewInteraction ev={componentToShow.data} callbackfn={async () => {
						setComponentToShow(null)
					}} />
				)
				break;
			case 'eventDetail':
				return (
					<EventDetail ev={componentToShow.data} callbackfn={async () => {
						setComponentToShow(null)
					}} />
				)
				break;

			case 'closeTicket':
				return (
					<CloseTicket ev={componentToShow.data} callbackfn={async () => {
						setComponentToShow(null)
					}} />
				)
				break;

			default:
				break;
		}
	}

	return (
		<div className='container'>
			<div className='card' style={{ width: '100%' }}>
				<span style={{ fontSize: '1.8em', fontWeight: 'bold' }}>MONITORAMENTO NOTURNO</span>
			</div>

			{/* Content */}
			<TicketList />

			<FloatAddButtom showForm={setShowFormNewEvent} />

			{showFormNewEvent &&
				<Modal callbackfn={() => {
					setShowFormNewEvent(false)
				}}>
					<div className='card' style={{ padding: 30, width: '80%', maxWidth: '80%', height: '85vh', backgroundColor: '#fff', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
						<NewTicket callbackfn={() => {
							setShowFormNewEvent(false)
						}} />
					</div>
				</Modal>
			}


			{/* {(evDetail !== null) &&
				<Modal callbackfn={() => {
					setEvDetail(null)
				}}>
					<EventDetail ev={evDetail} />
				</Modal>
			} */}


			{componentToShow !== null &&
				<Modal callbackfn={() => {
					setComponentToShow(null)
				}}>
					<div className='card' style={{ padding: 30, width: '80%', maxWidth: '80%', height: '85vh', backgroundColor: '#fff', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
						<ContentComponent />
					</div>
				</Modal>
			}
		</div>
	);
}

export default MonitoramentoNoturno;