import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';

import Styles, { primary_color } from '../Styles';

import { api_bd } from '../../services/Api';
// import { Container } from './styles';


import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import navigationState from '../../atoms/navigate_state.atom';

function PlanejamentoEtapas(props) {
    const styles = Styles();

    const [navigation_state, set_navigation_state] = useRecoilState(navigationState)

    const [inputEtapa, setInputEtapa] = useState('')
    const [selectEtapa, setSelectEtapa] = useState('Planejamento')


    const [etapas, seEtapas] = useState(navigation_state.data.flowNodes.steps || [])

    const [addLevantmento, setAddLevantmento] = useState(navigation_state.data.flowNodes.steps.filter(step => step.type === 'Levantamento de Insumos').length > 0 ? true : false)
    const [addAgendamento, setAddAgendamento] = useState(navigation_state.data.flowNodes.steps.filter(step => step.type === 'Agendamento de Janela').length > 0 ? true : false)


    useEffect(() => {
        if ((navigation_state.data?.flowNodes?.validateSteps?.state === 'aproved') && (selectEtapa !== 'Execução')) {
            setSelectEtapa('Execução')
        }
    }, [])

    return (
        // ocultar div e mostrar div da proxima etapa (execução)
        <div style={{ width: '100%', backgroundColor: '#fff', borderRadius: 5, padding: 50 }}>

            {(navigation_state.data?.flowNodes?.validateSteps?.state === 'aproved' || navigation_state.data?.flowNodes?.validateSteps?.state === 'aproved') &&
                <div style={{ fontSize: '1.5em', fontWeight: 'bolder', marginBottom: 30 }}>ETAPAS APROVADAS<hr /></div>
            }



            {navigation_state.data?.flowNodes?.validateSteps?.state === 'aproved' &&
                <>
                    <div style={{ backgroundColor: 'lightgreen', borderRadius: 10, padding: 10, marginBottom: 30 }}>
                        <div style={{ fontWeight: 'bolder', marginBottom: 15 }}>Etapas de Planejamento span</div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                            {navigation_state.data?.flowNodes?.steps.filter(step => step.type !== 'Execução').map((step, index) => (
                                <div key={`${step.title}${index}`}>{step.title}</div>
                            ))}
                        </div>
                        <div style={{ fontWeight: 'bolder', marginBottom: 15 }}>
                            Aprovado por: {navigation_state.data?.flowNodes?.validateSteps.user.name} em {new Date(navigation_state.data?.flowNodes?.validateSteps.validationDate).toLocaleDateString()} às {new Date(navigation_state.data?.flowNodes?.validateSteps.validationDate).toLocaleTimeString()}
                        </div>
                    </div>
                </>
            }



            {navigation_state.data?.flowNodes?.validateExecutionSteps?.state === 'aproved' &&
                <>
                    <div style={{ backgroundColor: 'lightgreen', borderRadius: 10, padding: 10, marginBottom: 30 }}>
                        <div style={{ fontWeight: 'bolder', marginBottom: 15 }}>Etapas de Execução</div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                            {navigation_state.data?.flowNodes?.steps.filter(step => step.type === 'Execução').map((step, index) => (
                                <div key={`${step.title}${index}`}>{step.title}</div>
                            ))}
                        </div>
                        <div style={{ fontWeight: 'bolder', marginBottom: 15 }}>
                            Aprovado por: {navigation_state.data?.flowNodes?.validateExecutionSteps.user.name} em {new Date(navigation_state.data?.flowNodes?.validateExecutionSteps.validationDate).toLocaleDateString()} às {new Date(navigation_state.data?.flowNodes?.validateExecutionSteps.validationDate).toLocaleTimeString()}
                        </div>
                    </div>
                    <hr style={{ marginBottom: 30 }} />
                </>
            }


            <div style={{ fontSize: '1.5em', fontWeight: 'bolder', marginBottom: 30 }}>ETAPAS DA IMPLANTAÇÃO<hr /></div>

            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                <input
                    type="checkbox"
                    style={{ marginRight: 5 }}
                    checked={addLevantmento}
                    onChange={(e) => {
                        const option = !addLevantmento
                        let verify
                        if (option) {
                            verify = etapas.filter(step => step.type === 'Levantamento de Insumos')
                            if (verify.length === 0) {
                                let old = [...etapas]
                                old.push({
                                    date: Date.now(),
                                    title: 'Levantameto',
                                    state: 'pending',
                                    type: 'Levantamento de Insumos'
                                })
                                seEtapas(old)
                                setAddLevantmento(true)
                            }
                        } else {
                            verify = etapas.filter(step => step.type !== 'Levantamento de Insumos')
                            seEtapas(verify)
                            setAddLevantmento(false)
                        }
                    }}
                />Adicionar etapa de levantamento de insumos.
            </div>

            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
                <input
                    type="checkbox"
                    style={{ marginRight: 5 }}
                    checked={addAgendamento}
                    onChange={(e) => {
                        const option = !addAgendamento
                        let verify
                        if (option) {
                            verify = etapas.filter(step => step.type === 'Agendamento de Janela')
                            if (verify.length === 0) {
                                let old = [...etapas]
                                old.push({
                                    date: Date.now(),
                                    title: 'Agendamento de Janela',
                                    state: 'pending',
                                    type: 'Agendamento de Janela'
                                })
                                seEtapas(old)
                                setAddAgendamento(true)
                            }
                        } else {
                            verify = etapas.filter(step => step.type !== 'Agendamento de Janela')
                            seEtapas(verify)
                            setAddAgendamento(false)
                        }
                    }}
                />Adicionar etapa de  agendamento de janela.
            </div>

            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <input
                    style={{ fontSize: '1.1em', marginRight: 5, padding: 5, width: '60%' }}
                    type="text"
                    value={inputEtapa}
                    placeholder="Titulo da etapa"
                    onChange={(e) => {
                        setInputEtapa(e.target.value)
                    }}
                />

                <select
                    style={{ fontSize: '1.1em', marginLeft: 5, padding: 5, width: '40%' }}
                    disabled={true}
                    type="text"
                    value={selectEtapa}
                    placeholder="Titulo da etapa"
                    onChange={(e) => {
                        setSelectEtapa(e.target.value)
                    }}
                >
                    {navigation_state.data?.flowNodes?.validateSteps?.state !== 'aproved' &&
                        <>
                            <option value='Planejamento'>Planejamento</option>
                            {/* <option value='Levantamento de Insumos'>Levantamento de Insumos</option> */}
                            {/* <option value='Agendamento de Janela'>Agendamento de Janela</option> */}
                        </>
                    }


                    {navigation_state.data?.flowNodes?.validateSteps?.state === 'aproved' &&
                        <option value='Execução'>Execução</option>
                    }
                </select>
            </div>

            <Button
                className={styles.btn_submit}
                variant="contained"
                size="small"
                style={{ width: 200, height: 40, backgroundColor: primary_color, color: '#fff', marginRight: 5 }}
                onClick={async () => {
                    if (inputEtapa === '') { return }
                    for (let key in etapas) {
                        if (etapas[key].title === inputEtapa) {
                            return window.alert('Já existe uma etapa com este título, tente novamente.')
                        }
                    }

                    seEtapas(old => {
                        return ([
                            ...old,
                            {
                                date: Date.now(),
                                title: inputEtapa,
                                state: 'pending',
                                type: navigation_state.data?.flowNodes?.validateSteps?.state === 'aproved' ? 'Execução' : selectEtapa
                            }
                        ])
                    })

                    setInputEtapa('')
                }}>
                Adicionar etapa
            </Button>


            <div style={{ marginBottom: 20, marginTop: 50 }}>
                <div style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Lista de Etapas<hr /></div>
                {navigation_state.data.flowNodes.validateSteps.state === 'rejected' &&
                    <>
                        <div style={{ color: 'red' }}>Planejamento devolvido por {navigation_state.data.flowNodes.validateSteps.user.name}.<br /><b>MOTIVO: {navigation_state.data.flowNodes.validateSteps.notes}</b></div>
                    </>
                }
            </div>

            <div style={{ marginBottom: 20 }}>
                {etapas.map(e => (
                    <div style={{ display: 'flex', alignItems: 'center', margin: 10 }}>
                        <span
                            onClick={() => {
                                seEtapas(old => {
                                    return (
                                        old.filter(ol => ol.title !== e.title)
                                    )
                                })
                            }}
                            style={{ cursor: 'pointer', marginLeft: -15, marginRight: 5, fontSize: '1.5em' }}
                            className="material-icons">delete</span> - {e.title} ({e.type})
                    </div>
                ))}
            </div>

            <hr />

            <Button
                disabled={etapas.length > 0 ? false : true}
                className={styles.btn_submit}
                variant="contained"
                size="small"
                style={{ width: 200, height: 40, backgroundColor: etapas.length > 0 ? primary_color : 'gray', color: '#fff', marginRight: 5 }}
                onClick={async () => {
                    // window.alert(props.idTicket)
                    if (etapas.length === 0) { return window.alert('Nenhuma etapa definida!') }


                    let dataToSend
                    if (navigation_state.data?.flowNodes?.validateSteps?.state === 'aproved') {
                        dataToSend = {
                            ...navigation_state.data.flowNodes,
                            validateExecutionSteps: {
                                state: 'pending',
                                notes: '',
                                user: {
                                    id: '',
                                    name: '',
                                }
                            },
                            actualState: 3,
                            steps: [...etapas]
                        }
                    } else {
                        dataToSend = {
                            ...navigation_state.data.flowNodes,
                            validateSteps: {
                                state: 'pending',
                                notes: '',
                                user: {
                                    id: '',
                                    name: '',
                                }
                            },
                            actualState: 3,
                            steps: [...etapas]
                        }
                    }


                    let response = await api_bd.put(`/tickets/${props.idTicket}`, {
                        situacao: {
                            titulo: "Aguardando aprovação",
                            descricao: ""
                        },
                        flowNodes: { ...dataToSend }
                    })

                    set_navigation_state({
                        page_content: 'ticket_detail',
                        data: { ...response.data[0] }
                    })

                    props.set_situacao_chamado({
                        titulo: "Aguardando aprovação",
                        descricao: ""
                    })

                    props.callbackfn()
                }}>
                Salvar lista de etapas
            </Button>

        </div >
    )
}

export default PlanejamentoEtapas;