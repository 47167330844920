import React, { useState, useEffect } from 'react';

import Modal from '../Modal'

import Button from '@material-ui/core/Button';

import { Card, CardContent } from '@material-ui/core';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import navigationState from '../../atoms/navigate_state.atom';

import Styles, { primary_color } from '../Styles';

import { api_bd } from '../../services/Api';

function CheckBoxesInsumos(props) {
    const styles = Styles();

    const navigation_state = useRecoilValue(navigationState)

    const set_navigation_state = useSetRecoilState(navigationState)

    const [inputInsumo, setInputInsumo] = useState('')

    const [respCheckInsumo, setRespCheckInsumo] = useState(navigation_state.data.flowNodes?.steps[props.navigate_step_index]?.list.person_authorization || '')

    const [insumos, setInsumos] = useState(navigation_state.data.flowNodes?.steps[props.navigate_step_index]?.list?.items || [])

    const countCheck = insumos.filter(item => item.state === true).length
    const percentOK = (countCheck / insumos.length) * 100

    return (
        <div style={{ width: '100%', backgroundColor: '#fff', borderRadius: 5 }}>

            <div style={{ fontSize: '1.5em', fontWeight: 'bolder', marginBottom: 30 }}>LEVANTAMENTO DE INSUMOS<hr /></div>

            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <input
                    style={{ fontSize: '1.1em', marginRight: 5, padding: 5, width: '60%' }}
                    type="text"
                    value={inputInsumo}
                    placeholder="Descrição insumo"
                    onChange={(e) => {
                        setInputInsumo(e.target.value)
                    }}
                />
            </div>

            <Button
                className={styles.btn_submit}
                variant="contained"
                size="small"
                style={{ width: 200, height: 40, backgroundColor: primary_color, color: '#fff', marginRight: 5 }}
                onClick={async () => {
                    if (inputInsumo === '') { return }
                    for (let key in insumos) {
                        if (insumos[key].desc.toLowerCase() === inputInsumo.toLowerCase()) {
                            return window.alert('Já existe um insumo com esta descrição, tente novamente.')
                        }
                    }

                    setInsumos(old => {
                        return ([
                            ...old,
                            {
                                date: Date.now(),
                                desc: inputInsumo,
                                state: false
                            }
                        ])
                    })

                    setInputInsumo('')
                }}>
                Adicionar insumo
            </Button>


            <div style={{ marginBottom: 20, marginTop: 50 }}>
                <div style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Lista de insumos para realização da atividade {percentOK > 0 ? '- ' + percentOK + '% do(s) insumo(s) checado(s)' : ''}<hr /></div>
            </div>

            <div style={{ marginBottom: 20 }}>
                {insumos.map(e => (
                    // <div style={{ display: 'flex', alignItems: 'center', margin: 10, padding: 5, borderRadius: 5, border: '#c3c3c355 solid 1px', boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)' }}>
                    <div style={{ display: 'flex', alignItems: 'center', margin: 10, padding: 5 }}>
                        <div>
                            <span
                                onClick={() => {
                                    if (window.confirm(`Deseja realmente excluir o item "${e.desc}" da lista?`)) {
                                        setInsumos(old => {
                                            return (old.filter(ol => ol.desc !== e.desc))
                                        })
                                    }
                                }}
                                style={{ cursor: 'pointer', marginLeft: 10, marginRight: 5, fontSize: '1.5em' }} className="material-icons">
                                delete
                            </span>
                        </div>

                        <div style={{ marginLeft: 10 }}>
                            <input type="checkbox" id="scales" name="scales" checked={e.state}
                                onChange={() => {
                                    setInsumos(insumos.map(insumo => {
                                        if (insumo.desc === e.desc) {
                                            // console.log(insumo)
                                            return { ...insumo, state: !e.state }
                                        } else {
                                            return insumo
                                        }
                                    }))
                                }}
                            />
                        </div>

                        <div style={{ marginLeft: 10 }}>
                            {e.desc}{e.state === true ? ' (OK)' : ''}
                        </div>

                    </div>
                ))}
            </div>



            {percentOK > 0 &&
                <>
                    <div style={{ marginBottom: 20, marginTop: 50 }}>
                        <div style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Responsável pela checagem dos insumos<hr /></div>
                    </div>
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                        <input
                            style={{ fontSize: '1.1em', marginRight: 5, padding: 5, width: '60%' }}
                            type="text"
                            value={respCheckInsumo}
                            placeholder="Pessoa responsável pela checagem dos insumos (lado do cliente)"
                            onChange={(e) => {
                                setRespCheckInsumo(e.target.value)
                            }}
                        />
                    </div>
                </>
            }

            <hr />

            <Button
                disabled={insumos.length > 0 ? false : true}
                className={styles.btn_submit}
                variant="contained"
                size="small"
                style={{ width: 300, height: 40, backgroundColor: insumos.length > 0 ? primary_color : 'gray', color: '#fff', marginRight: 5 }}
                onClick={async () => {
                    // window.alert(props.idTicket)
                    if (insumos.length === 0) { return window.alert('Nenhuma etapa definida!') }

                    if (percentOK > 0 && respCheckInsumo === '') { return window.alert('Informe o nome do responsável pela checagem dos insunmos.') }

                    let response = await api_bd.put(`/tickets/${navigation_state.data._id}`, {
                        flowNodes: {
                            ...navigation_state.data.flowNodes,
                            steps: navigation_state.data.flowNodes.steps.map((step, index) => {
                                if (index === props.navigate_step_index) {
                                    return {
                                        ...step,
                                        list: {
                                            items: insumos,
                                            person_authorization: (percentOK === 0 && respCheckInsumo !== '') ? '' : respCheckInsumo,
                                            date_authorization: respCheckInsumo !== '' ? Date.now() : ''
                                        }
                                    }
                                } else {
                                    return step
                                }
                            })
                        }
                    })

                    set_navigation_state({
                        page_content: 'ticket_detail',
                        data: { ...response.data[0] }
                    })

                    props.callbackfn()
                }}>
                Salvar lista de insumos
            </Button>
            <hr />
        </div >
    )
}

export default CheckBoxesInsumos;