import React, { useState, useEffect } from 'react';

import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Badge from '@material-ui/core/Badge';

import { Link } from 'react-router-dom';
import * as IoIcons from 'react-icons/io';
import Styles, { Fab, LineMenuHome } from '../Styles'

import {
    api_bd,
    api_glpi
} from '../../services/Api'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'
import customerData from '../../atoms/customers.atom'
import clientDetails from '../../atoms/client_details.atom'
import userTickets from '../../atoms/tickets.atom'
import navigationState from '../../atoms/navigate_state.atom'

import '../Navbar/Navbar.css';

import LogoColor from '../../assets/logo_big_intec.png'

const { backend_files_address } = require('../../config.json')

function TicketsSideBar(props) {
    const styles = Styles();

    const [sgi_user, set_sgi_user] = useRecoilState(userState)
    const profile_data = useRecoilValue(profileData)
    const set_client_details = useSetRecoilState(clientDetails)

    const [data_home, set_data_home] = useRecoilState(customerData)

    // const [glpi_user_name, set_glpi_user_name] = useState('')

    // const [glpi_user, set_glpi_user] = useState('')

    const [glpi_level, set_glpi_level] = useState('')

    // const [user_sgi, set_user_sgi] = useState(null)

    const [show_modal, set_show_modal] = useState(false)

    const [show_loading, set_show_loading] = useState(true)

    const [show_modal_tickets, set_show_modal_tickets] = useState(false)

    const [device_height, set_device_height] = useState(null)

    const [device_width, set_device_width] = useState(null)

    const [tickets, set_tickets] = useRecoilState(userTickets)

    const set_navigation_state = useSetRecoilState(navigationState)

    useEffect(() => {
        set_client_details({})

        const dw = document.documentElement.clientWidth
        const dh = document.documentElement.clientHeight
        set_device_width(dw)
        set_device_height(dh)
    }, [])


    useEffect(() => {
        // console.log('Page HOME', profile_data, sgi_user)
        // console.log('ProfileData', profile_data)
        // console.log('UserData', sgi_user)
        set_glpi_level(profile_data.nivel)
    }, [sgi_user])


    // useEffect(() => {
    // 	(async () => {
    // 		// const data = await getUserData()
    // 		// set_user_sgi(data.user_sgi)
    // 		// set_glpi_user(data.glpiname)
    // 		// set_glpi_user_name(data.glpifirstname)


    // 	})()
    // }, [glpi_level])


    useEffect(() => {
        (async () => {
            set_show_loading(true)
            if (glpi_level.match(/Cliente/)) {
                const dados = await api_bd.get(`/customers/glpiuser/${profile_data.username}`)
                set_show_loading(false)
                if (dados.data.length > 0) {
                    set_data_home(dados.data)

                    // props.history.push({
                    // 	pathname: 'clientdetails',
                    // 	state: { data_client: dados.data[0] }
                    // });
                }
            } else if (glpi_level.match(/Técnico/i) || glpi_level === 'Super') {
                const dados = await api_bd.get(`/customers`)
                const ordered_data = dados.data ? await dados.data.sort((a, b) => a.nome.localeCompare(b.nome)) : []
                // set_data_home(dados.data)
                set_show_loading(false)
                set_data_home(ordered_data)
            }

            const open_tickets = await api_glpi.get(`/search/Ticket/?is_deleted=0&criteria[0][field]=12&criteria[0][searchtype]=equals&criteria[0][value]=notold&criteria[1][link]=AND&criteria[1][field]=5&criteria[1][searchtype]=equals&criteria[1][value]=${sgi_user.glpi_data.glpiID}`)
            set_tickets(open_tickets.data)
        })()
    }, [glpi_level])

    // useEffect(() => {
    // 	console.log(tickets)
    // }, [tickets])


    return (
        <div className="flex flexRow" style={{ width: "100%", minHeight: device_height - 50, maxHeight: device_height - 50, alignItems: "flex-start" }}>
            <>
                {data_home.length == 0 &&
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        minHeight: device_height - 50,
                        maxHeight: device_height - 50
                    }}
                    >
                        <>
                            {show_loading === true &&
                                <div className="flex" style={{ justifyContent: 'center', alignItems: 'center', width: '80%', maxWidth: '80%', height: '20vh', maxHeight: '20vh' }}>
                                    <div className="loader" style={{ width: 50, height: 50, marginRight: 10 }}></div>
                                    <h2>Carregando dados, por favor aguarde...</h2>
                                </div>
                            }

                            {show_loading === false &&
                                <Card className={styles.card}>
                                    <CardContent className="flex flexCol center-v center-h" style={{ height: "100%" }} >
                                        <h2>Nenhuna Informação Cadastrada</h2>
                                    </CardContent>
                                </Card>
                            }

                        </>
                    </div>
                }

                {data_home.length > 0 &&
                    <>
                        <div style={{ backgroundColor: "#3c5ca6", minWidth: 210, width: 210, minHeight: device_height - 50, maxHeight: device_height - 50, color: "white" }}>
                            <div style={{ margin: 35, overflow: "hidden", width: 140, height: 140, backgroundColor: '#fff', borderRadius: '50%' }} className="flex center-h">
                                <img
                                    style={{ marginLeft: 0, marginTop: 0, width: 140, height: 140, objectFit: "cover" }}
                                    src={glpi_level.match(/Cliente/) ? `${backend_files_address}/${data_home[0].logomarca.filename}` : (profile_data && profile_data.imagem != '') ? `${backend_files_address}/${profile_data.imagem}` : LogoColor} />
                            </div>
                            <div style={{ width: "100%", marginTop: 20 }} className="flex flexCol center-h">
                                <div style={{ width: "100%", justifyContent: 'center' }} className="flex">
                                    <div>
                                        <span>{(profile_data && profile_data.nome != '') ? profile_data.nome : ''}</span>
                                        {false &&//Object.keys(tickets).length > 0 && show_modal_tickets == false &&
                                            <Link
                                            onClick={()=>{
                                                set_navigation_state({ page_content: ''  })
                                            }}
                                            to={{
                                                pathname: "/tickets",
                                                state: { page_content: 'tickets' }
                                            }}>

                                                <Badge
                                                    // badgeContent={tickets.totalcount} 
                                                    color="error"
                                                    style={{ cursor: 'pointer', zIndex: 1, marginBottom: 5 }}
                                                // onClick={()=>{
                                                // 	set_show_modal_tickets(true)
                                                // }}
                                                >
                                                    <span className="material-icons" style={{ fontSize: '1.4em' }}>
                                                        assignment
													</span>
                                                </Badge>
                                            </Link>
                                        }
                                    </div>
                                </div>
                                {/* <div style={{width: "100%"}} className="flex center-h">Whats</div> */}
                            </div>
                            {glpi_level.match(/Cliente/) &&
                                <div style={{ marginTop: 30 }}>
                                    <ul style={{ listStyleType: 'none' }}>
                                        <LineMenuHome>
                                            <Link
                                                onClick={() => set_client_details(data_home[0])}
                                                to={{
                                                    pathname: "/clientdetails",
                                                    state: { data_client: data_home[0] }
                                                }}>
                                                {/* {item.icon} */}
                                                <span>ACESSOS</span>
                                            </Link>
                                        </LineMenuHome>
                                    </ul>
                                </div>
                            }


                            <div style={{ marginTop: 30 }}>
                                <ul style={{ listStyleType: 'none' }}>
                                    {profile_data && (glpi_level === 'Super' || glpi_level.match(/Técnico/i)) &&
										<>
                                            <LineMenuHome style={{ cursor: 'pointer' }} 
                                            onClick={() => {
                                                set_navigation_state({page_content: 'formtickets'})
                                            }}>
                                                {/* {item.icon} */}
                                                <span>NOVO CHAMADO</span>
                                            </LineMenuHome>

                                            <LineMenuHome style={{ cursor: 'pointer' }} 
                                            onClick={() => { 
                                                set_navigation_state({page_content: 'listtickets'})
                                            }}>
                                                {/* {item.icon} */}
                                                <span>CHAMADOS</span>
                                            </LineMenuHome>
                                        </>
									}

									{(glpi_level === 'Super') &&
                                        <>
                                        </>
                                        //<LineMenuHome style={{ cursor: 'pointer' }} onClick={() => { set_show_modal(true) }}>
                                        //    <Link to={{
                                        //        pathname: "/caduser",
                                        //        state: { data_client: data_home[0] }
                                        //</ul>    }}>
                                        //        <span>Usuários</span>
                                        //   </Link>
                                        //</LineMenuHome>
                                    }

                                    {(glpi_level === 'Super' || glpi_level.match(/Técnico/i)) &&
                                        <></>
                                        // <>
                                        // 	<LineMenuHome>
                                        // 		<Link to={{
                                        // 			pathname: "/stations",
                                        // 			state: { data_client: data_home[0] }
                                        // 		}}>
                                        // 			<span>ESTAÇÕES</span>
                                        // 		</Link>
                                        // 	</LineMenuHome>
                                        // 	<LineMenuHome>
                                        // 		<Link to={{
                                        // 			pathname: "/suport",
                                        // 			state: { data_client: data_home[0] }
                                        // 		}}>
                                        // 			<span>PLANTONISTAS</span>
                                        // 		</Link>
                                        // 	</LineMenuHome>
                                        // </>
                                    }

                                </ul>
                            </div>
                        </div>
                    </>
                }
            </>

            {false &&
                <Link to='/cadclient'>
                    <Fab alt="Adicionar Cliente" className="flex center-h center-v">
                        <span className="material-icons" style={{ fontSize: 33, color: "white", marginLeft: -3 }}>person_add</span>
                    </Fab>
                </Link>
            }
        </div >
    );
}

export default TicketsSideBar;