import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const modalDataState = atom({
    key: 'modalDataState',
    default: {
        show: false,
        component: <></>
    },
});