import React, {
    useState,
    useEffect
} from 'react';

// import { makeStyles } from '@material-ui/core/styles';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Styles from '../Styles'

import { api_bd } from '../../services/Api'

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


function FormDataStation(props) {
    const { width, set_new_data } = props
    const styles = Styles()

    const [data_form, set_data_form] = useState({})
    const [error_message, set_error_message] = useState('')
    const [tecnico, set_tecnico] = useState({})

    const [submit_is_disabled, set_submit_is_disabled] = useState(true)

    const [codigo_estacao, set_codigo_estacao] = useState('')


    useEffect(() => {
        // Object.keys(data_form).length > 0 && console.log(data_form)
        let mensagem_de_erro = ''
        if (Object.keys(data_form).length > 0) {
            const filters = {
                dia: 'dia'
            }

            for (let f in Object.values(filters)) {
                let filter = Object.keys(filters)[f]
                if (data_form[filter] === undefined || data_form[filter] === '') {
                    mensagem_de_erro += `Campo obrigatório: ${filters[filter].toUpperCase()}\n\n`
                }
            }
            set_error_message(mensagem_de_erro)
        }

    }, [data_form])


    const handle_data_form = (key, value) => {
        let data = { ...data_form }
        data[key] = value
        set_data_form(data)
    }

    const handle_tecnico = (value) => {
        if(value !==  'Selecione'){
            let data = value.split("|")
    
            const tec = {nome: data[0], contato: data[1]}
    
            set_tecnico(tec)
        }
    }


    useEffect(() => {
        const d = new Date();
        handle_data_form('mes', d.getMonth())
        handle_data_form('ano', d.getFullYear())
    }, [])

    return (
        <div className={styles.div_root_component} style={{ width }}>
            <Card className={styles.card}>
                <CardContent>
                    <CardHeader
                        title={props.edit ? "Editar Estação" : "Adicionar Estação"}
                        subheader=""
                    />
                    <form className={styles.form_cad_user} noValidate autoComplete="off">
                        <div style={{ width: "100%" }}>
                            <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                <InputLabel htmlFor="select-label">Mês &nbsp;  *</InputLabel>
                                <Select
                                    defaultValue={data_form.mes || 0}
                                    labelId="select-label"
                                    id="select-nivel"
                                    // value={type_filter_access}
                                    onChange={e => {
                                        handle_data_form("mes", e.target.value)
                                    }}
                                >
                                    <MenuItem value="0">Janeiro</MenuItem>
                                    <MenuItem value="1">Fevereiro</MenuItem>
                                    <MenuItem value="2">Março</MenuItem>
                                    <MenuItem value="3">Abril</MenuItem>
                                    <MenuItem value="4">Maio</MenuItem>
                                    <MenuItem value="5">Junho</MenuItem>
                                    <MenuItem value="6">Julho</MenuItem>
                                    <MenuItem value="7">Agosto</MenuItem>
                                    <MenuItem value="8">Setembro</MenuItem>
                                    <MenuItem value="9">Outubro</MenuItem>
                                    <MenuItem value="10">Novembro</MenuItem>
                                    <MenuItem value="11">Dezembro</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    if(e.target.value > 0 && e.target.value < 32){
                                        handle_data_form("dia", e.target.value)
                                    }else{
                                        return false
                                    }
                                    
                                }}
                                id="dia"
                                type="Number"
                                label="dia"
                                required={true}
                                value={data_form?.dia}

                            />
                        </div>


                        <div style={{ width: "100%" }}>
                            <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                <InputLabel htmlFor="select-label">Mês &nbsp;  *</InputLabel>
                                <Select
                                    defaultValue={data_form.contato || "Selecione"}
                                    labelId="select-label"
                                    id="select-nivel"
                                    // value={type_filter_access}
                                    onChange={e => {
                                        handle_tecnico(e.target.value)
                                    }}
                                >
                                    <MenuItem value="Selecione">Selecione</MenuItem>
                                    <MenuItem value="Darlan|(82) 98115-1013">Darlan</MenuItem>
                                    <MenuItem value="Jenilson|(83) 98120-4998">Jenilson</MenuItem>
                                    <MenuItem value="Josemar|(83) 98156-4245">Josemar</MenuItem>
                                    <MenuItem value="Rafael|(82) 98203-8703">Rafael</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div style={{ width: "100%" }}>
                            <Button
                                className={styles.btn_submit}
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={submit_is_disabled}
                                onClick={async () => {

                                    try {
                                        const resp = await api_bd.post('/suport_calendar', { ...data_form, nome: tecnico.nome, contato: tecnico.contato })
                                        alert(`Plantonista cadastrado com sucesso!\nNome: ${data_form.nome}\nCódigo: ${codigo_estacao}`)
                                        set_new_data(resp.data)
                                    } catch (error) {
                                        if (error.message.indexOf('code 400') > -1) {
                                            alert("Verifique os dados e tente novamente.")
                                        }
                                    }

                                }}
                            >
                                Salvar
                            </Button>
                            
                            {error_message !==  '' &&
                                <p style={{ color: 'red', fontSize: '0.8em' }}>{error_message}</p>
                            }
                        </div>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
}

export default FormDataStation;

//Schema Cliente
//{"nome":"fulano","cnpj_1":"123","cnpj_2":"321","cnpj_3":"123","end":"Avenida teste","contato":"contato teste","fone_1":"fone1","fone_2":"fone2","fone_3":"fone 3","email_1":"email1","email_2":"email2","password":"senha","link_whats":"wa.me","situacao":"inativo","cliente_desde":"10/01/2020"

