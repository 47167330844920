import React, { useEffect, useState } from 'react';

import Modal from '../Modal';
// import styles from './styles.module.css'
// import LineData from './LineData';
import DataUser from './DataUser';

import { groupArrObjByKey } from '../../util/fns';

import { api_bd } from '../../services/Api'

function EditTotalTimeTicket(props) {

    const [showModal, setShowModal] = useState(false)
    const [dataComponent, setDataComponent] = useState([])
    const [showData, setShowData] = useState(false)

    const [btnSaveDisabled, setBtnSaveDisabled] = useState(false)

    const handleData = (_id, _key, _valor) => {
        const valorTz = new Date(_valor)
        const oldValue = dataComponent.filter(el => el._id === _id)[0]
        let tempData = [...dataComponent]
        const newData = tempData.map(item => {
            if (item._id === _id) {
                let updatedItem = { ...item, [_key]: valorTz.toISOString() }
                return updatedItem
            } else {
                return item
            }
        })
        setDataComponent(newData)
    }


    const saveDataToServer = () => {
        // console.log(`/tickets/${props.idTicket}`, {tratativas: dataComponent})
        api_bd.put(`/tickets/${props.idTicket}`, { tratativas: dataComponent }).then(resp => {
            props.set_navigation_state({ page_content: 'ticket_detail', data: resp.data[0] })
            alert('Dados atualizados com sucesso.')
            setBtnSaveDisabled(false)
            setShowModal(false)
        }).catch(e => {
            setBtnSaveDisabled(false)
            alert('Ocorreu um erro, tente novamente mais tarde.')
        })
    }


    const ShowUsers = ({ _data }) => {
        const [listUsers, setListUsers] = useState({})

        useEffect(() => {
            if (Object.keys(_data.length > 0))
                setListUsers(groupArrObjByKey(_data, 'nome'))
        }, [_data])

        return (
            <div style={{ position: 'relative' }}>
                {Object.keys(listUsers).length > 0 && Object.keys(listUsers).map((user, idmap) => (
                    <DataUser
                        showData={showData}
                        setShowData={setShowData}
                        handleData={handleData}
                        user={user}
                        data={listUsers[user]}
                        key={`${idmap}`}
                    />
                ))}
            </div>
        )
    }


    useEffect(() => {
        if (showModal === true) {
            // console.log(groupArrObjByKey(props.data, 'nome'))
            setDataComponent(props.data)
        }
    }, [showModal])



    return (

        <>
            {showModal &&
                <Modal callbackfn={() => {
                    setShowModal(false)
                }}>
                    <div
                        style={{
                            width: '80vw',
                            height: '80vh',
                            backgroundColor: '#fff',
                            borderRadius: 10,
                            position: 'relative',
                            padding: 20,
                            zIndex: 9999
                        }}
                    >
                        {showModal && dataComponent && dataComponent.length > 0 &&
                            <div
                                style={{
                                    maxHeight: '100%',
                                    overflowY: 'auto',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginBottom: 20,
                                        padding: '0px 10px 10px 0px',
                                        borderBottom: '1px solid #c3c3c3'
                                    }}
                                >
                                    <span
                                        style={{
                                            margin: 0,
                                            fontSize: '1.5em',
                                            fontWeight: 'bold'
                                        }}
                                    >Editar tempo de trabalho dos analistas.</span>


                                    <span
                                        style={{
                                            margin: 0,
                                            fontSize: '1.2em',
                                            fontWeight: 'bold',
                                            color: '#fff',
                                            backgroundColor: btnSaveDisabled ? '#c3c3c3' :'#3c68ce',
                                            padding: 10,
                                            borderRadius: 5,
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => {
                                            if(btnSaveDisabled === false){
                                                setBtnSaveDisabled(true)
                                                saveDataToServer()
                                            }
                                        }}
                                    >
                                        SALVAR
                                    </span>

                                </div>

                                <ShowUsers _data={dataComponent} />

                            </div>
                        }
                    </div>
                </Modal>
            }

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    cursor: 'pointer'
                }}
                onClick={() => {
                    setShowModal(true)
                }}
            >
                <div
                    style={{
                        backgroundColor: 'rgb(60, 92, 166)',
                        // marginTop: -1,
                        marginLeft: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '50%',
                        width: 15,
                        height: 15
                    }}
                >
                    <span
                        style={{ margin: 0, padding: 0, fontSize: '1em', cursor: 'pointer', color: '#fff' }}
                        className="material-icons"
                    >edit</span>
                </div>
                <span style={{ margin: 0, marginLeft: 5, padding: 0 }}>Editar</span>
            </div>
        </>
    );
}

export default EditTotalTimeTicket;