import React, { useState, useEffect } from 'react';
import './App.css';

import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './pages/Login';
import Logout from './pages/Logout';
import CadClient from './pages/CadClient';
import ClientDetails from './pages/ClientDetails';
import NetworkMap from './pages/NetworkMap';
import Suport_Calendar from './pages/Suport_Calendar';
import PageTickets from './pages/PageTickets';
import RoutePrivate from './components/RoutePrivate';

import RelatorioImplantacao from './components/FlowNodesComponent/Relatorio';


import ProjectsPage from './pages/Projects';


import Home_N from './pages/NewHome';

import PesquisaSatisfacao from './components/PesquisaSatisfacao';

import Stations from './pages/Stations';

import { useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from './atoms/user.atom'
import { realtimeState } from './atoms/realtimeState.atom';

import Dashboards from './components/Dashboards';
import Diagram from './components/Diagram';

import { socket } from './util/socket';

// import { getLoginToken, setLoginToken } from './services/Api'

var socket_is_connected = false

function App(props) {
	// const user = useRecoilValue(userState) || {}
	const profile_data = useRecoilValue(profileData) || {}
	const [isConnected, setIsConnected] = useState(socket.connected);
	const setRealtimeData = useSetRecoilState(realtimeState)

	// const [token, setToken] = useState(undefined)

	// useEffect(() => {
	// 	// (async () => {
	// 	// 	const session_token = await getLoginToken()		
	// 	// 	setToken(session_token)
	// 	// })()
	// 	console.log('profileData')
	//     console.log(profile_data)
	// }, [])


	// if(Object.keys(profile_data).length === 0){
	// 	if(window.location.pathname !=='/'){
	// 		window.location.href='/'
	// 	}
	// 	return <Login />
	// }

	// if (!token) {
	// 	if(window.location.pathname != '/'){
	// 		window.location.href='/'
	// 	}

	// 	// console.log(token)
	// 	return <Login setToken={setToken} />
	// }

	const falar = (_msg) => {
		const msg = new SpeechSynthesisUtterance()
		msg.text = _msg
		try {
			window.speechSynthesis.speak(msg)
		} catch (error) {
			console.log(error)
		}
	}

	function onSpeak(value) {
		if (socket_is_connected === true && (profile_data && profile_data?.username === value.user)) {
			falar(value.tts)
		}
	}

	function teamnotes_update(value) {
		// console.log({
		// 	topic: 'teamnotes_update',
		// 	data: value
		// })
		setRealtimeData(old => {
			return ({
				topic: 'teamnotes_update',
				data: value
			})
		})
	}

	function homeposts_update(value) {
		// console.log({
		// 	topic: 'homeposts_update',
		// 	data: value
		// })
		setRealtimeData(old => {
			return ({
				topic: 'homeposts_update',
				data: value
			})
		})
	}

	function handleMessages(topic, data) {
		// console.log({
		// 	topic,
		// 	data
		// })
		switch (topic) {
			case 'teamnotes_update':
			case 'homeposts_update':
				setRealtimeData(old => {
					return ({
						topic,
						data
					})
				})
				break;
			case 'speak':
				onSpeak(data)
				break;

			default:
				break;
		}
	}

	function onConnect() {
		setIsConnected(true);
		socket_is_connected = true
		console.log('realtime connected')
	}

	function onDisconnect() {
		setIsConnected(false);
		socket_is_connected = false
		console.log('realtime disconnected')
	}


	useEffect(() => {

		socket.on('connect', onConnect);
		socket.on('disconnect', onDisconnect);
		// socket.on('speak', onSpeak);
		// socket.on('teamnotes_update', teamnotes_update);

		// socket.onAny((topic, data) => {
		// 	handleMessages(topic, data);
		// });

		socket.onAny(handleMessages);

		return () => {
			socket.off('connect', onConnect);
			socket.off('disconnect', onDisconnect);
			// socket.off('speak', onSpeak);
			// socket.off('teamnotes_update', teamnotes_update);
			// socket.off('homeposts_update', homeposts_update);
			socket.offAny(handleMessages);
		};
	}, []);

	// useEffect(() => {
	// 	console.log(isConnected, fooEvents)
	// }, [fooEvents, isConnected])


	return (
		<Router>
			{Object.keys(profile_data).length > 0 ? <Navbar /> : <></>}
			<Switch>
				<Route path='/login' exact component={Login} />
				<Route path='/avaliacao/:idt' exact component={PesquisaSatisfacao} />
				<RoutePrivate path='/' exact component={Home_N} />
				<RoutePrivate path='/ch/:idticket' exact component={Home_N} />
				<RoutePrivate path='/cadclient' component={CadClient} />
				<RoutePrivate path='/clientdetails' component={ClientDetails} />
				<RoutePrivate path='/stations' component={Stations} />
				<RoutePrivate path='/tickets' component={PageTickets} />
				<RoutePrivate path='/suport' component={Suport_Calendar} />
				<RoutePrivate path='/networkmap' component={NetworkMap} />
				<RoutePrivate path='/diagram' component={Diagram} />
				<RoutePrivate path='/projects' component={ProjectsPage} />
				<RoutePrivate path='/logout' component={Logout} />
				<RoutePrivate path='/relatorio-i' component={RelatorioImplantacao} />
				<RoutePrivate path='/dashboard' component={Dashboards} />
				<RoutePrivate path='*' component={Home_N} />
			</Switch>
		</Router>
	);
}

export default App;



