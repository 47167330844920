import React from 'react';

import { 
    // useRecoilState, 
    useRecoilValue, 
    // useSetRecoilState 
} from 'recoil'
import { profileData } from '../atoms/user.atom';

function NivelDeAcesso(props) {
    const user_profile = useRecoilValue(profileData)
    return (props.visivel_para.indexOf(user_profile.nivel) > -1 ? props.children : <></>);
}

export default NivelDeAcesso;