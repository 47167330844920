import React from 'react';

const Toast = (props) => {
    const style_modal = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        flex: 1,
        padding: 20,
        borderRadius: 3,
        backgroundColor: '#fff',
        right: 10,
        bottom: 10,
        position: 'absolute',
        float: 'left',
        minHeight: 100, 
        minWidth: 400,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        overflowY: "auto",
        boxShadow: '0px 5px 10px #00000088',
        zIndex: 100
    }

    return (
        <div style={{...style_modal, ...props._style}} >
            {props.children}
        </div>
    )
}

export default Toast;