import React, { useEffect, useState } from 'react';

import navigationState from '../../atoms/navigate_state.atom';

import { useRecoilValue } from 'recoil'

import '../../App.css'
import Autocomplete from '@material-ui/lab/Autocomplete';

function RelatorioImplantacao() {
    const navigation_state = useRecoilValue(navigationState)

    const [tempoExecucao, setTempoExecucao] = useState(0)

    const responsaveis = navigation_state.data.resposaveis_tecnicos.map(resp => (resp.nome)).join(', ')

    const msToTime = (ms) => {
        let x = ms / 1000
        let secs = x % 60
        x /= 60
        let mins = x % 60
        x /= 60
        let hrs = x % 24
        x /= 24
        let days = x

        // console.log(days)

        // return Math.floor(days)-1 + 'd:'  + hrs<10 ? '0'+hrs : hrs + ':' + mins + ':' + secs
        return (`${days >= 1 ? Math.floor(days) + ' dia(s) ' : ''}${hrs < 10 ? '0' + Math.floor(hrs) : Math.floor(hrs)}:${mins < 10 ? '0' + Math.floor(mins) : Math.floor(mins)}:${secs < 10 ? '0' + Math.floor(secs) : Math.floor(secs)}`)
    }

    const calcMs = (_time_inicio, _time_fim) => {
        const date1 = Date.parse(_time_inicio)
        const date2 = Date.parse(_time_fim)
        const diffTime = date2 - date1
        return (diffTime)
    }


    const EtapasImplantacao = () => {
        const html = navigation_state.data.flowNodes.steps.map(step => {
            return (<div style={{ marginBottom: 10 }}>
                <p><b>Título: </b>{step.title}</p>
                <p><b>Tipo: </b>{step.type}</p>
                <p><b>Data e hora de planejamento: </b>{new Date(step.date).toLocaleDateString()} - {new Date(step.date).toLocaleTimeString()}</p>
                <p><b>Data e hora de Execução:  </b>{new Date(step.date_end).toLocaleDateString()} - {new Date(step.date_end).toLocaleTimeString()}</p>
                {step.type.toUpperCase() === 'LEVANTAMENTO DE INSUMOS' &&
                    <>
                        <p><b>Lista de insumos necessários para realização da atividade</b></p>
                        <div style={{ marginLeft: 15 }}>
                            {step.list.items.map(item => (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5 }}>
                                    <div>
                                        <span style={{ marginLeft: 0, color: item.state === false ? 'red' : '' }} className="material-icons">{item.state === true ? 'task_alt' : 'radio_button_unchecked'}</span>
                                    </div>
                                    <div>
                                        {item.desc}
                                    </div>
                                </div>
                            ))}
                            <p style={{ marginTop: 5 }}><b>Checagem dos insumos realizada por: </b>{step.list.person_authorization}, em {new Date(step.list.date_authorization).toLocaleDateString()} às {new Date(step.list.date_authorization).toLocaleTimeString()}</p>
                        </div>
                    </>
                }
            </div>)
        })


        return (
            <>{html}</>
        )
    }

    useEffect(() => {
        if (navigation_state?.data) {

            let t_execucao = 0
            for (let key in navigation_state.data.tratativas) {
                if (navigation_state.data.tratativas[key].fim !== null) {
                    const tempo_decorrido = calcMs(navigation_state.data.tratativas[key].inicio, navigation_state.data.tratativas[key].fim)
                    t_execucao += tempo_decorrido
                }

            }

            setTempoExecucao(t_execucao)

            window.print()
            setTimeout(() => { window.close() }, 1000)
        }
    }, [])

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', width: '100%', backgroundColor: '#fff', padding: 30 }}>
            {navigation_state?.data &&
                <>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                        <img style={{ width: 150 }} src="https://sgi.intecsolutions.com.br:4000/uploads/logo_intec.png" />
                    </div>


                    <div style={{ marginTop: 70, marginBottom: 40, fontSize: '1.4em', fontWeight: 'bolder' }}>
                        RELATÓRIO DE IMPLANTAÇÃO
                    </div>

                    <div>
                        <p style={{ fontSize: '1.1em', fontWeight: 'bolder', marginBottom: 10 }}>Informações da Ordem de Serviço<hr /></p>
                        <p><b>Número da O.S.: </b>{navigation_state.data.cod_id}</p>
                        <p><b>Data de abertura: </b>{new Date(navigation_state.data.createdAt).toLocaleDateString()}</p>
                        <p><b>Hora de abertura: </b>{new Date(navigation_state.data.createdAt).toLocaleTimeString()}</p>
                        <p><b>Cliente: </b>{navigation_state.data.customer_name}</p>
                        <p><b>Analistas envolvidos: </b>{responsaveis}</p>
                        <p><b>Tempo de execução: </b>{msToTime(tempoExecucao)}</p>
                    </div>

                    <div style={{ marginTop: 30 }}>
                        <p style={{ fontSize: '1.1em', fontWeight: 'bolder', marginBottom: 10 }}>Triagem do chamado<hr /></p>
                        <p><b>Data: </b>{new Date(navigation_state.data.flowNodes.screening.date).toLocaleDateString()}</p>
                        <p><b>Foi realizado contato com o cliente?: </b>{navigation_state.data.flowNodes.screening.made_contact !== 'não' ? 'SIM, ' : ''}{navigation_state.data.flowNodes.screening.made_contact.toUpperCase()}</p>
                        <p><b>A prioridade do ticket procede?: </b>
                            {navigation_state.data.flowNodes.screening.verified_priority?.newvalue &&
                                navigation_state.data.flowNodes.screening.verified_priority?.newvalue != '' &&
                                navigation_state.data.flowNodes.screening.verified_priority?.newvalue === 'sim'
                                ?
                                navigation_state.data.flowNodes.screening.verified_priority?.newvalue.toUpperCase()
                                :
                                <>
                                    NÃO,
                                    alterado de:  <b>{navigation_state.data.flowNodes.screening.verified_priority?.oldvalue.toUpperCase()}</b> para:  <b>{navigation_state.data.flowNodes.screening.verified_priority?.newvalue.toUpperCase()}</b>
                                </>
                            }
                        </p>

                        <p><b>O Titulo do ticket estava coeso?: </b>
                            {navigation_state.data.flowNodes.screening.verified_title?.newvalue &&
                                navigation_state.data.flowNodes.screening.verified_title?.newvalue != '' &&
                                navigation_state.data.flowNodes.screening.verified_title?.newvalue === 'sim'
                                ?
                                navigation_state.data.flowNodes.screening.verified_title?.newvalue.toUpperCase()
                                :
                                <>
                                    NÃO,
                                    alterado de:  <b>{navigation_state.data.flowNodes.screening.verified_title?.oldvalue.toUpperCase()}</b> para:  <b>{navigation_state.data.flowNodes.screening.verified_title?.newvalue.toUpperCase()}</b>
                                </>
                            }
                        </p>

                        <p><b>A descrição está completa ou precisando de algo mais?: </b>
                            {navigation_state.data.flowNodes.screening.verified_description?.newvalue &&
                                navigation_state.data.flowNodes.screening.verified_description?.newvalue != '' &&
                                navigation_state.data.flowNodes.screening.verified_description?.newvalue === 'sim'
                                ?
                                navigation_state.data.flowNodes.screening.verified_description?.newvalue.toUpperCase()
                                :
                                <>
                                    NÃO,
                                    alterado de:  <b>{navigation_state.data.flowNodes.screening.verified_description?.oldvalue.toUpperCase()}</b> para:  <b>{navigation_state.data.flowNodes.screening.verified_description?.newvalue.toUpperCase()}</b>
                                </>
                            }
                        </p>

                        <p style={{ fontSize: '1.1em', fontWeight: 'bolder', marginBottom: 10, marginTop: 30 }}>Etapas da Implantação<hr /></p>
                        <EtapasImplantacao />

                        <p style={{ fontSize: '1.1em', fontWeight: 'bolder', marginBottom: 10, marginTop: 30 }}>Aprovação do planejamento pelo gestor<hr /></p>
                        <p><b>Data: </b>{new Date(navigation_state.data.flowNodes.validateSteps.validationDate).toLocaleDateString()}</p>
                        <p><b>Gestor: </b>{navigation_state.data.flowNodes.validateSteps.user.name}</p>

                        <p style={{ fontSize: '1.1em', fontWeight: 'bolder', marginBottom: 10, marginTop: 30 }}>Auditoria pelo das etapas pelo gestor<hr /></p>
                        <p><b>Data: </b>{new Date(navigation_state.data.flowNodes.validateAudit.auditdDate).toLocaleDateString()}</p>
                        <p><b>Gestor: </b>{navigation_state.data.flowNodes.validateAudit.user.name}</p>
                    </div>
                </>
            }
        </div >
    );
}

export default RelatorioImplantacao;