import React, {
	useState,
	useEffect
} from 'react';

// import { makeStyles } from '@material-ui/core/styles';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Styles from '../components/Styles'

import { api_bd } from '../services/Api'

import Modal from '../components/Modal'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import navigationState from '../atoms/navigate_state.atom'

import TicketsSideBar from '../components/SideBars/TicketsSideBar'
import FormDataTickets from '../components/Forms/FormDataTickets'
import ListTickets from '../components/Tables/Tickets'
import TicketDetail from '../components/TicketDetail'

function PageTickets(props) {
	const styles = Styles();

	const [device_height, set_device_height] = useState(null)
	const [device_width, set_device_width] = useState(null)

	const [navigation_state, set_navigation_state] = useRecoilState(navigationState)

	useEffect(() => {
		set_navigation_state( {page_content: 'listtickets'} )
		// if (Object.keys(data_client).length === 0) {
		//     props.history.push("/")
		// }

		const dw = document.documentElement.clientWidth
		const dh = document.documentElement.clientHeight
		set_device_width(dw)
		set_device_height(dh)
	}, [])

	return (
		<div className="flex flexRow" style={{ width: "90%", minHeight: device_height - 50, maxHeight: device_height - 50, alignItems: "flex-start" }}>
			<TicketsSideBar />
			<div style={{
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'wrap',
				flex: 1,
				left: 0,
				top: 0,
				minWidth: device_width - 210,
				maxWidth: '90%',
				height: device_height - 50,
				maxHeight: device_height - 50,
				justifyContent: 'center',
				alignItems: 'flex-start',
				overflowY: "auto"
			}}>
				{navigation_state?.page_content &&
					(() => {
						switch (navigation_state.page_content) {
							case 'formtickets':
								return <FormDataTickets />
								break;
							case 'ticket_detail':
								return <TicketDetail />
								break;
							case 'listtickets':
								return <ListTickets />
								break
							default:
								return <ListTickets />
								break;
						}
					})()
				}
			</div>
		</div>
	);
}

export default PageTickets;

//Schema IP PUBLICO
//{"descricao":"descricao","ip":"192.168.10.1","bloco":"192.160.10.0/24"}