import React, { useEffect, useState, useRef } from 'react';

import './MonitoramentoNoturno.css'

import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { api_bd } from '../../services/Api'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'
import customerData from '../../atoms/customers.atom'
import { nightMonitoringListTicketState } from '../../atoms/night_monitoring _state.atom';

function NewTicket(props) {
    const refComp1 = useRef()
    const refComp2 = useRef()

    const profile_user = useRecoilValue(profileData)
    const clientList = useRecoilValue(customerData)
    const [listaClientes, setListaClientes] = useState([])
    const [eventFormIcon, setEventFormIcon] = useState('checklist')
    const [actualComp, setActualComp] = useState(1)
    const [dataTicket, setDataTicket] = useState({
        tipo_evento: "Selecione o tipo do evento",
        horario: '',
        ponto_a: '',
        ponto_b: '',
        foi_solucionado: '',
        hora_solucao: '',
        porque_nao_foi_solucionado: '',
        estacao: '',
        qual_o_problema: '',
        feedback_do_suporte: '',
        previsao_para_solucao: '',
        numero_do_ticket_com_o_supoerte: '',
        foi_escalonado: '',
        hora_escalonado: '',
        pra_quem_foi_escalonado: '',
        porque_nao_foi_escalonado: '',
        userId: '',
        userName: '',
        userLevel: '',
        data_insercao: { type: Date, default: '' },
        customer_id: '',
        customer_name: 'Selecione o cliente'
    })

    const setEventList = useSetRecoilState(nightMonitoringListTicketState)
    const buscarEventos = async () => {
        let tmpEv = await api_bd.get(`/night_monitoring/status/aberto/${profile_user.id}`)
        setEventList(tmpEv.data)
    }

    const handle_data = (key, value) => {
        let data = { ...dataTicket }
        data[key] = value
        setDataTicket(data)
    }

    const handle_select_client_data = (value) => {
        let data = { ...dataTicket }
        const { id, name } = JSON.parse(value)
        data.customer_id = id
        data.customer_name = name
        setDataTicket(data)
    }

    useEffect(() => {
        // console.log('ActualComp', actualComp)
        switch (actualComp) {
            case 1:
                refComp2.current.classList.remove('show_in')
                refComp2.current.classList.add('hidden_out')

                refComp1.current.classList.add('show_in')
                refComp1.current.classList.remove('hidden_out')
                break;
            case 2:
                refComp1.current.classList.remove('show_in')
                refComp1.current.classList.add('hidden_out')

                refComp2.current.classList.add('show_in')
                refComp2.current.classList.remove('hidden_out')
                break;
            // case 3:
            //     refComp2.current.classList.remove('show_in')
            //     refComp2.current.classList.add('hidden_out')

            //     refComp3.current.classList.add('show_in')
            //     refComp3.current.classList.remove('hidden_out')
            //     break;

            default:
                break;
        }
    }, [actualComp])


    useEffect(() => {
        switch (dataTicket.tipo_evento) {
            case 'Selecione o tipo do evento':
                setEventFormIcon('checklist')
                break;
            case 'Rompimento':
                setEventFormIcon('link_off')
                break;
            case 'Estação Indisponível':
                setEventFormIcon('wifi_tethering_error')
                break;
            case 'Link Com Problema':
                setEventFormIcon('mobiledata_off')
                break;
            case 'Cliente com Problema':
                setEventFormIcon('group')
                break;

            default:
                break;
        }
    }, [dataTicket.tipo_evento])


    useEffect(() => {
        setListaClientes(clientList.map(el => (<MenuItem value={`{"id": "${el._id}", "name": "${el.nome}"}`} style={{ color: '#000' }}>{el.nome}</MenuItem>)))
    }, [])


    const Rompimento = () => {
        const [cliente, setCliente] = useState('')
        const [horario, setHorario] = useState('')
        const [ponto_a, setPonto_a] = useState('')
        const [ponto_b, setPonto_b] = useState('')
        return (
            <div style={{ width: '100%' }}>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel htmlFor="select-customer">CLIENTE</InputLabel>
                        <Select
                            autoFocus={true}
                            defaultValue={"Selecione o cliente"}
                            value={cliente}
                            labelId="select-customer"
                            id="situacao"
                            onChange={e => {
                                setCliente(e.target.value)
                                // handle_select_client_data(e.target.value)
                            }}
                            required={true}
                        >
                            {listaClientes}
                        </Select>
                    </FormControl>
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="time"
                        style={{ width: '100%' }}
                        id="horario"
                        label="Qual horário do incidente?"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setHorario(e.target.value)
                            // handle_data("horario", e.target.value)
                        }}
                        value={horario}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="ponto_a"
                        label="Qual Ponto A?"
                        onChange={e => {
                            // handle_data("ponto_a", e.target.value)
                            setPonto_a(e.target.value)
                        }}
                        value={ponto_a}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="ponto_b"
                        label="Qual Ponto B?"
                        onChange={e => {
                            // handle_data("ponto_b", e.target.value)
                            setPonto_b(e.target.value)
                        }}
                        value={ponto_b}
                    />
                </div>
                <div
                    className='buttom fixToCenter'
                    style={{ marginTop: 20, padding: 10, width: 100 }}
                    onClick={async () => {
                        const filters = [horario, ponto_a, ponto_b, cliente]
                        for (let filtro in filters) {
                            // console.log(filters[filtro])
                            if (filters[filtro] === '') {
                                return (alert('Todos os campos devem ser preenchidos'))
                            }
                        }

                        const { id, name } = JSON.parse(cliente)

                        let data = new Date().getTime()
                        let tempdata = {
                            ...dataTicket,
                            horario,
                            ponto_a,
                            ponto_b,
                            customer_id: id,
                            customer_name: name,
                            userId: profile_user.id,
                            userName: profile_user.nome,
                            userLevel: profile_user.nivel,
                            data_insercao: data
                        }
                        await api_bd.post('/night_monitoring', tempdata)
                        await buscarEventos()
                        // console.log(resp.data)
                        props.callbackfn()

                    }}
                >
                    Salvar
                </div>
            </div>
        )
    }

    const EstacaoIndisponivel = () => {
        const [cliente, setCliente] = useState('')
        const [horario, setHorario] = useState('')
        const [estacao, setEstacao] = useState('')
        const [ponto_b, setPonto_b] = useState('')
        return (
            <div style={{ width: '100%' }}>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel htmlFor="select-customer">CLIENTE</InputLabel>
                        <Select
                            autoFocus={true}
                            defaultValue={"Selecione o cliente"}
                            value={cliente}
                            labelId="select-customer"
                            id="situacao"
                            onChange={e => {
                                setCliente(e.target.value)
                                // handle_select_client_data(e.target.value)
                            }}
                            required={true}
                        >
                            {listaClientes}
                        </Select>
                    </FormControl>
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="time"
                        style={{ width: '100%' }}
                        id="horario"
                        label="Qual horário do incidente?"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setHorario(e.target.value)
                            // handle_data("horario", e.target.value)
                        }}
                        value={horario}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="estacao"
                        label="Qual Estação?"
                        onChange={e => {
                            // handle_data("estacao", e.target.value)
                            setEstacao(e.target.value)
                        }}
                        value={estacao}
                    />
                </div>

                <div
                    className='buttom fixToCenter'
                    style={{ marginTop: 20, padding: 10, width: 100 }}
                    onClick={async () => {
                        const filters = [horario, estacao, cliente]
                        for (let filtro in filters) {
                            // console.log(filters[filtro])
                            if (filters[filtro] === '') {
                                return (alert('Todos os campos devem ser preenchidos'))
                            }
                        }

                        const { id, name } = JSON.parse(cliente)

                        let data = new Date().getTime()
                        let tempdata = {
                            ...dataTicket,
                            horario,
                            estacao,

                            customer_id: id,
                            customer_name: name,
                            userId: profile_user.id,
                            userName: profile_user.nome,
                            userLevel: profile_user.nivel,
                            data_insercao: data
                        }
                        await api_bd.post('/night_monitoring', tempdata)
                        await buscarEventos()
                        // console.log(resp.data)
                        props.callbackfn()
                    }}
                >
                    Salvar
                </div>
            </div>
        )
    }

    const LinkComProblema = () => {
        const [cliente, setCliente] = useState('')
        const [horario, setHorario] = useState('')
        const [qual_o_problema, setQual_o_problema] = useState('BAIXA PERFORMANCE')
        const [numero_do_ticket_com_o_supoerte, setNumero_do_ticket_com_o_supoerte] = useState('')
        const [fornecedor, setFornecedor] = useState('')
        return (
            <div style={{ width: '100%' }}>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel htmlFor="select-customer">CLIENTE</InputLabel>
                        <Select
                            autoFocus={true}
                            defaultValue={"Selecione o cliente"}
                            value={cliente}
                            labelId="select-customer"
                            id="situacao"
                            onChange={e => {
                                setCliente(e.target.value)
                                // handle_select_client_data(e.target.value)
                            }}
                            required={true}
                        >
                            {listaClientes}
                        </Select>
                    </FormControl>
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="time"
                        style={{ width: '100%' }}
                        id="horario"
                        label="Qual horário do incidente?"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setHorario(e.target.value)
                            // handle_data("horario", e.target.value)
                        }}
                        value={horario}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <FormControl style={{ width: '100%', flexDirection: 'column' }}>
                        <InputLabel htmlFor="select-qual_o_problema">Qual o Problema? </InputLabel>
                        <Select
                            defaultValue={qual_o_problema}
                            labelId="select-qual_o_problema"
                            id="qual_o_problema"
                            onChange={e => {
                                setQual_o_problema (e.target.value)
                            }}
                            required={true}
                        >
                            <MenuItem value="BAIXA PERFORMANCE">BAIXA PERFORMANCE</MenuItem>
                            <MenuItem value="INDISPONIBILIDADE">INDISPONIBILIDADE</MenuItem>
                        </Select>
                    </FormControl>
                </div>


                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="numero_do_ticket_com_o_supoerte"
                        label="Qual Número do Ticket Aberto com o Suporte?"
                        onChange={e => {
                            setNumero_do_ticket_com_o_supoerte(e.target.value)
                        }}
                        value={numero_do_ticket_com_o_supoerte}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="fornecedor"
                        label="Qual o fornecedor do link?"
                        onChange={e => {
                            setFornecedor(e.target.value)
                        }}
                        value={fornecedor}
                    />
                </div>

                <div
                    className='buttom fixToCenter'
                    style={{ marginTop: 20, padding: 10, width: 100 }}
                    onClick={async () => {
                        const filters = [horario, cliente, qual_o_problema, numero_do_ticket_com_o_supoerte]
                        for (let filtro in filters) {
                            // console.log(filters[filtro])
                            if (filters[filtro] === '') {
                                return (alert('Todos os campos devem ser preenchidos'))
                            }
                        }

                        const { id, name } = JSON.parse(cliente)

                        let data = new Date().getTime()
                        let tempdata = {
                            ...dataTicket,
                            horario,
                            qual_o_problema,
                            numero_do_ticket_com_o_supoerte,
                            fornecedor,

                            customer_id: id,
                            customer_name: name,
                            userId: profile_user.id,
                            userName: profile_user.nome,
                            userLevel: profile_user.nivel,
                            data_insercao: data
                        }
                        await api_bd.post('/night_monitoring', tempdata)
                        await buscarEventos()
                        // console.log(resp.data)
                        props.callbackfn()
                    }}
                >
                    Salvar
                </div>
            </div>
        )
    }

    const ClienteComProblema = () => {
        const [cliente, setCliente] = useState('')
        const [horario, setHorario] = useState('')
        const [qual_o_problema, setQual_o_problema] = useState('BAIXA PERFORMANCE')
        const [numero_do_ticket_com_o_supoerte, setNumero_do_ticket_com_o_supoerte] = useState('')
        return (
            <div style={{ width: '100%' }}>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel htmlFor="select-customer">CLIENTE</InputLabel>
                        <Select
                            autoFocus={true}
                            defaultValue={"Selecione o cliente"}
                            value={cliente}
                            labelId="select-customer"
                            id="situacao"
                            onChange={e => {
                                setCliente(e.target.value)
                                // handle_select_client_data(e.target.value)
                            }}
                            required={true}
                        >
                            {listaClientes}
                        </Select>
                    </FormControl>
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="time"
                        style={{ width: '100%' }}
                        id="horario"
                        label="Qual horário do incidente?"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setHorario(e.target.value)
                            // handle_data("horario", e.target.value)
                        }}
                        value={horario}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <FormControl style={{ width: '100%', flexDirection: 'column' }}>
                        <InputLabel htmlFor="select-qual_o_problema">Qual o Problema? </InputLabel>
                        <Select
                            defaultValue={qual_o_problema}
                            labelId="select-qual_o_problema"
                            id="qual_o_problema"
                            onChange={e => {
                                setQual_o_problema (e.target.value)
                            }}
                            required={true}
                        >
                            <MenuItem value="BAIXA PERFORMANCE">BAIXA PERFORMANCE</MenuItem>
                            <MenuItem value="INDISPONIBILIDADE">INDISPONIBILIDADE</MenuItem>
                        </Select>
                    </FormControl>
                </div>


                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="numero_do_ticket_com_o_supoerte"
                        label="Qual Número do Ticket Aberto com o Suporte?"
                        onChange={e => {
                            setNumero_do_ticket_com_o_supoerte(e.target.value)
                        }}
                        value={numero_do_ticket_com_o_supoerte}
                    />
                </div>

                <div
                    className='buttom fixToCenter'
                    style={{ marginTop: 20, padding: 10, width: 100 }}
                    onClick={async () => {
                        const filters = [horario, cliente, qual_o_problema, numero_do_ticket_com_o_supoerte]
                        for (let filtro in filters) {
                            // console.log(filters[filtro])
                            if (filters[filtro] === '') {
                                return (alert('Todos os campos devem ser preenchidos'))
                            }
                        }

                        // console.log(cliente)
                        const { id, name } = JSON.parse(cliente)

                        let data = new Date().getTime()
                        let tempdata = {
                            ...dataTicket,
                            horario,
                            qual_o_problema,
                            numero_do_ticket_com_o_supoerte,

                            customer_id: id,
                            customer_name: name,
                            userId: profile_user.id,
                            userName: profile_user.nome,
                            userLevel: profile_user.nivel,
                            data_insercao: data
                        }
                        await api_bd.post('/night_monitoring', tempdata)
                        await buscarEventos()
                        // console.log(resp.data)
                        props.callbackfn()
                    }}
                >
                    Salvar
                </div>
            </div>
        )
    }


    const TicketOption = ({ option }) => {
        // console.log('Data Ticket TIPO EVENTO: ',dataTicket.tipo_evento)
        switch (option) {
            case 'Rompimento':
                return <Rompimento />
                break;
            case 'Estação Indisponível':
                return <EstacaoIndisponivel />
                break;
            case 'Link Com Problema':
                return <LinkComProblema />
                break;
            case 'Cliente com Problema':
                return <ClienteComProblema />
                break;

            default:
                return <div />
                break;
        }
    }

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%', padding: 30, overflow: 'hidden' }}>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', height: 60, width: '100%', marginBottom: 40 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 60, height: 60, maxWidth: 60, maxHeight: 60 }}>
                    <span className="material-icons left_icon" style={{ margin: 0, fontSize: '4em' }}>{eventFormIcon}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginLeft: 20 }}>
                    <span style={{ margin: 0, fontSize: '2.5em' }}>{dataTicket.tipo_evento}</span>
                </div>
            </div>
            <div ref={refComp1}
                className='hidden_out'
                style={{
                    position: 'absolute',
                    width: '90%'
                }}
            >
                <FormControl style={{ width: '100%' }}>
                    <InputLabel htmlFor="select-event-type">TIPO DE EVENTO</InputLabel>
                    <Select
                        defaultValue="Selecione o tipo do evento"
                        labelId="select-event-type"
                        id="situacao"
                        style={{ width: '100%' }}
                        onChange={e => {
                            handle_data('tipo_evento', e.target.value)
                            // handle_data_client("situacao", e.target.value)
                        }}
                        required={true}
                    >
                        <MenuItem value="Selecione o tipo do evento">Selecione o tipo do evento</MenuItem>
                        <MenuItem value="Rompimento">Rompimento</MenuItem>
                        <MenuItem value="Estação Indisponível">Estação Indisponível</MenuItem>
                        <MenuItem value="Link Com Problema">Link Com Problema</MenuItem>
                        <MenuItem value="Cliente com Problema">Cliente com Problema</MenuItem>
                    </Select>
                </FormControl>

                <div
                    className='buttom fixToCenter'
                    style={{ marginTop: 20, padding: 10, width: 100 }}
                    onClick={() => {
                        // console.log(dataTicket)
                        if (dataTicket.tipo_evento === "Selecione o tipo do evento") {
                            return (alert('Selecione um tipo de evento para continuar'))
                        } else {
                            setActualComp(old => {
                                return old === 2 ? 1 : old + 1
                            })
                        }
                    }}
                >
                    Avançar
                </div>

            </div>

            <div ref={refComp2}
                className='hidden_out'
                style={{
                    position: 'absolute',
                    width: '90%'
                }}
            >
                <TicketOption option={dataTicket.tipo_evento} />
            </div>
        </div>
    );
}

export default NewTicket;