import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { modalDataState } from "../../atoms/appState";

function Modal() {
	const [modalData, setModalData] = useRecoilState(modalDataState)

	useEffect(() => {

		document.onkeydown = function (evt) {
			evt = evt || window.event;
			var isEscape = false;
			if ("key" in evt) {
				isEscape = (evt.key === "Escape" || evt.key === "Esc");
			} else {
				isEscape = (evt.keyCode === 27);
			}

			if (isEscape) {
				setModalData({ show: false, component: <></> })
			}
		}

	}, [])

	return (
		<>
			{modalData.show && modalData.show === true ? (
				<>
					<div
						id="backdrop"
						className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
						onClick={(e) => {
							if (e.target.id && e.target.id === 'backdrop') {
								setModalData({ show: false, component: <></> })
							}
						}}
					>
						<div className="relative min-w-[80%] min-w-[80%] md:min-w-[300px] md:max-w-[50%] my-6">
							{/*content*/}
							{/* <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none overflow-x-auto"> */}
							<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none overflow-x-auto p-6 min-w-[300px] max-w-[87vw] lg:min-w-[600px] lg:max-w-[70vw]">
								{/*body*/}
								{modalData.component && modalData.component}
								{/* <div className="min-w-[300px] max-w-[87vw] lg:min-w-[600px] lg:max-w-[70vw] relative p-6 max-h-[90vh] overflow-y-auto">
									{modalData.component && modalData.component}
								</div> */}
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}
		</>
	);
}

export default Modal;
