import React, { useState, useEffect } from 'react';

import styles from './styles.module.css'

import { api_bd } from '../../services/Api'

import UploadComponent from './UploadComponent';

const configs = require('../../config.json')

function NewPost(props) {
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [dataPost, setDataPost] = useState({
        text: '',
        anexos: null
    })


    const handleData = (key, value) => {
        let temp = { ...dataPost }
        temp[key] = value
        setDataPost(temp)
    }


    const handleFileChange = (e) => {
        setDataPost(old => {
            return ({ ...old, anexos: e.target.files })
        })
        // setFileList(e.target.files);
    };


    return (
        <div style={{ width: 'clamp(500px, 80%, 1000px )', minHeight: 400, borderRadius: 10, backgroundColor: '#fff', padding: 30, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* <input
                style={{
                    width: '100%',
                    padding: 10,
                    border: '1px solid #c3c3c399',
                    borderRadius: 5
                }}
                onChange={(e) => {
                    setTitle(e.target.value)
                }}
                value={title}
                type='text'
                placeholder='Titulo da postagem'
            /> */}

            <textarea
                style={{
                    width: '100%',
                    padding: 10,
                    height: 300,
                    border: '1px solid #c3c3c399',
                    borderRadius: 5
                }}
                onChange={(e) => {
                    handleData('text', e.target.value)
                }}
                placeholder='Texto da postagem'
            >{dataPost.text}</textarea>

            <div style={{ width: '100%', marginTop: 10 }}>
                <UploadComponent
                    handleFileChange={handleFileChange}
                />
            </div>

            {dataPost.anexos !== null &&
                <div style={{ width: '100%', marginTop: 10 }}>
                    <ul style={{ marginTop: 10, marginLeft: 20 }}>
                        <li><b>Anexos:</b></li>
                        <ul>
                            {Array.from(dataPost.anexos).map((file, i) => (
                                <li key={i}>
                                    {file.name} - {file.type}
                                </li>
                            ))}
                        </ul>
                    </ul>
                </div>
            }

            <input
                type='buttom'
                value={btnDisabled ? 'Aguarde...' : 'Salvar'}
                disabled={btnDisabled}
                style={{
                    marginTop: 20,
                    padding: 15,
                    backgroundColor: btnDisabled ? '#c3c3c3' : '#3c68ce',
                    borderRadius: 5,
                    textAlign: 'center',
                    color: '#fff',
                    fontWeight: 'bold',
                    border: 'none',
                    width: '100%',
                    cursor: 'pointer'
                }}
                onClick={async () => {
                    if (dataPost.text === '') {
                        alert('Texto da Mensagem é obrigatório.')
                    } else if(btnDisabled === false){
                        setBtnDisabled(true)

                        // const dados = {
                        //     title: title,
                        //     text: message,
                        //     user: {
                        //         nome: props.user_profile.nome,
                        //         username: props.user_profile.username,
                        //         nivel: props.user_profile.nivel
                        //     },
                        //     date: new Date()
                        // }
                        
                        const data = new FormData();
                        
                        for (let k in dataPost) {
                            if (k === 'anexos') {
                                if (dataPost.anexos) {
                                    Array.from(dataPost.anexos).forEach((file, i) => {
                                        data.append(`file`, file, file.name);
                                    });
                                }
                            } else {
                                data.append(`${k}`, dataPost[k]);
                            }
                        }

                        data.append('userName', props.user_profile.nome)
                        data.append('userLogin', props.user_profile.username)
                        data.append('userLevel', props.user_profile.nivel)
                        data.append('date', new Date())


                        api_bd.post('/homeposts', data).then(resp => {
                            setBtnDisabled(false)
                            props.setShowTextInput(false)
                        }).catch(e => {
                            // console.log(JSON.stringify(e.response))
                            setBtnDisabled(false)
                            alert('Ocorreu um erro ao enviar os dados, tente novamente mais tarde.')
                        })
                    }
                }}
            />
        </div>
    )
}

export default NewPost;