import React, { useState, useEffect } from 'react';
import { XCircle } from 'lucide-react'

const Modal2 = (props) => {
    const { callbackfn } = props

    const [device_height, set_device_height] = useState(null)
    const [device_width, set_device_width] = useState(null)

    const handleClose = () => {
        props.callbackfn();
    };

    useEffect(() => {

        document.onkeydown = function (evt) {
            evt = evt || window.event;
            var isEscape = false;
            if ("key" in evt) {
                isEscape = (evt.key === "Escape" || evt.key === "Esc");
            } else {
                isEscape = (evt.keyCode === 27);
            }

            if (isEscape) {
                handleClose()
            }
        }

    }, [])


    return (
        <div
            style={{
                backgroundColor: '#0006',
                minHeight: '100vh',
                minWidth: '100vw',
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                left: 0,
                top: 0,
                zIndex: 100
            }}
        >
            <div
                style={
                    props.styleContent || {
                        display: 'flex',
                        position: 'relative',
                        width: props._width ? props._width : '100%',
                        justifyContent: 'center',//...props._style
                    }
                }
            >
                <div
                    style={{
                        position: 'absolute',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: props.children && props.children.props.style.width,
                        padding: 10
                    }}
                >
                    <XCircle
                        size={30}
                        onClick={handleClose}
                        style={{
                            color: 'inherit',
                            cursor: 'pointer'
                        }}
                    />
                </div>
                {props.children}
            </div>

        </div>
    )
}

export default Modal2;