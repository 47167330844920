import React, { useState, useEffect, useRef } from 'react';

import styles from './styles.module.css'

import { api_bd } from '../../services/Api'

import { useRecoilValue } from 'recoil';

import { realtimeState } from '../../atoms/realtimeState.atom';

import UploadComponent from './UploadComponent';

import ShowAnexo from './ShowAnexo';

const configs = require('../../config.json')

// import { Container } from './styles';


function ViewPost(props) {

    const [commentData, setCommentData] = useState({
        text: '',
        anexos: null
    })

    const [showTextInput, setShowTextInput] = useState(props.showCommentInput)

    const [postData, sePostData] = useState({})

    const [btnSendCommentDisabled, setBtnSendCommentDisabled] = useState(false)

    const realtimeData = useRecoilValue(realtimeState)

    const listCommentsRef = useRef(null)

    const handleCommentData = (key, value) => {
        let temp = { ...commentData }
        temp[key] = value
        setCommentData(temp)
    }


    const handleFileChange = (e) => {
        setCommentData(old => {
            return ({ ...old, anexos: e.target.files })
        })
        // setFileList(e.target.files);
    };

    const scrollToBottom = (id) => {
        const element = document.getElementById(id)
        if (element) {
            // const height = element.scrollHeight
            // setTimeout(()=>{
            //     element.scrollTop = height
            // },1000)
            element.scrollTo({
                top: element.scrollHeight,
                behavior: 'smooth'
            });
        }
    }

    const setPostRead = (_idNode, _idUser) => {
        api_bd.put(`/homeposts/note/setread/${_idNode}/${_idUser}`)
    }

    const loadPost = (_idNode) => {
        api_bd.get(`/homeposts/note/${_idNode}`).then(resp => {
            if (resp.data.read) {
                // console.log(props.expanded && props.expanded === true)
                if (resp.data.read.indexOf(props.user_profile.id) === -1) {
                    setPostRead(resp.data._id, props.user_profile.id)
                }
            }

            if (postData.comments) {
                if (resp.data.comments && resp.data.comments.length !== postData.comments.length) {
                    sePostData(resp.data)
                    scrollToBottom("commentlist")
                }
            } else {
                sePostData(resp.data)
                scrollToBottom("commentlist")
            }

        })
    }

    useEffect(() => {
        if (Object.keys(realtimeData).length > 0) {
            if (realtimeData.topic === 'homeposts_update' && realtimeData.data.action && realtimeData.data.action.match(/new_comment|comment_deleted/i)) {
                // console.log("View Posts: ", realtimeData)
                if (realtimeData.data.postid === props.idNote) {
                    loadPost(props.idNote)
                }
            }
        }
    }, [realtimeData])

    useEffect(() => {
        if (props.expanded === false) {
            setShowTextInput(false)
            props.setShowCommentInput(false)
        }
    }, [props.expanded])

    useEffect(() => {
        loadPost(props.idNote)

        return () => { }
    }, [])

    return (
        <div>
            {Object.keys(postData).length > 0 &&
                <>
                    <div
                        id="commentlist"
                        style={{
                            marginTop: props.expanded ? 20 : 0,
                            overflowY: 'auto',
                            maxHeight: props.expanded ? props.maxHeightList - 400 : 0,
                            transition: 'max-height 1s'
                        }}
                    >
                        {postData.comments.map(comment => (
                            <div key={`${comment.date}`} className={`${styles.notificationsContentModalComments}`} >

                                <div style={{ height: 100 }}>
                                    <img className={styles.notificationCommentAvatar} src={`${configs.api_backend_address}users/getuserimg/${comment.user.username}`} />
                                </div>
                                <div className={styles.notificationCommentContent}>
                                    <p style={{ whiteSpace: 'pre-wrap' }}>{comment.text}</p>
                                    {comment.anexos && comment.anexos.length > 0 &&
                                        <div style={{ width: '100%' }}>
                                            {comment.anexos.map((anexo) => (
                                                <div key={`${anexo._id}`}>
                                                    <ShowAnexo anexo={anexo} />
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    <p className={styles.notificationUserNameInMsg}><b>Usuário:</b> {comment.user.nome} - {new Date(comment.date).toLocaleDateString()} às {new Date(comment.date).toLocaleTimeString()}</p>
                                </div>

                                {comment.user.username === props.user_profile.username &&
                                    <span
                                        style={{
                                            float: 'left',
                                            cursor: 'pointer'
                                        }}
                                        className={`material-icons`}
                                        onClick={() => {
                                            api_bd.delete(`/homeposts/dellcomment/${postData._id}/${comment._id}`, {})
                                                .then(resp => {
                                                    loadPost(props.idNote)
                                                })
                                                .catch(e => {
                                                    alert('Ocorreuum erro ao tentar excluir, tente novamente mais tarde.')
                                                })
                                        }}
                                    >
                                        delete
                                    </span>
                                }
                            </div>

                        ))}
                        {/* <div
                            onClick={() => {
                                setShowTextInput(true)
                            }}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                // justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer'
                            }}
                        >
                            <span style={{ margin: 0, marginLeft: 20 }} className={`material-icons`}>chat_bubble</span>
                            <span style={{ margin: 0, marginLeft: 5 }}>Digite um comentário!</span>
                        </div> */}
                    </div>


                    <div
                        style={{
                            overflowY: 'auto',
                            maxHeight: props.showCommentInput ? 200 : 0,
                            marginTop: props.showCommentInput ? 20 : 0,
                            transition: 'max-height 0.3s, margin-top 0.6s'
                        }}
                        className={styles.notificationsTextbox}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            {/* Comment text input */}
                            <textarea
                                placeholder='Digite um comentário.'
                                className={styles.notificationsTextarea}
                                onInput={(e) => {
                                    handleCommentData('text', e.target.value)
                                }}
                                id="textcomment"
                                value={commentData.text}
                            ></textarea>

                            <div
                                style={{
                                    backgroundColor: btnSendCommentDisabled ? '#c3c3c3' : '#3c68ce'
                                }}
                                className={styles.notificationsBtnSendComment}
                                onClick={() => {
                                    if (commentData.text === '') {
                                        return alert("O campo comentário deve ser preenchido.")
                                    } else if (btnSendCommentDisabled === false) {
                                        setBtnSendCommentDisabled(true)

                                        // const comment = {
                                        //     text: textComment,
                                        //     user: {
                                        //         nome: props.user_profile.nome,
                                        //         username: props.user_profile.username,
                                        //         nivel: props.user_profile.nivel
                                        //     },
                                        //     date: new Date()
                                        // }

                                        const data = new FormData();
                                        for (let k in commentData) {
                                            if (k === 'anexos') {
                                                if (commentData.anexos) {
                                                    Array.from(commentData.anexos).forEach((file, i) => {
                                                        data.append(`file`, file, file.name);
                                                    });
                                                }
                                            } else {
                                                data.append(`${k}`, commentData[k]);
                                            }
                                        }

                                        data.append('userName', props.user_profile.nome)
                                        data.append('userLogin', props.user_profile.username)
                                        data.append('userLevel', props.user_profile.nivel)
                                        data.append('date', new Date())

                                        //addcomment
                                        api_bd.put(`/homeposts/addcomment/${postData._id}`, data).then(resp => {
                                            props.setExpanded(true)
                                            props.setShowCommentInput(false)
                                            setCommentData({
                                                text: '',
                                                anexos: null
                                            })
                                            loadPost(props.idNote)
                                            setBtnSendCommentDisabled(false)
                                        }).catch(e => {
                                            alert('Ocorreu um erro ao enviar os dados, tente novamente mais tarde.')
                                            setBtnSendCommentDisabled(false)
                                        })
                                    }
                                }}
                            >
                                <span className={`material-icons`}>{btnSendCommentDisabled ? 'hourglass_empty' : 'keyboard_arrow_right'}</span>
                            </div>
                        </div>


                        {/* attachements input */}
                        <div style={{ width: '100%', marginTop: 10, marginLeft: 50 }}>
                            <UploadComponent
                                handleFileChange={handleFileChange}
                            />
                        </div>

                        {commentData.anexos !== null &&
                            <div style={{ width: '100%', marginTop: 10, maxHeight: 50, overflowY: 'auto' }}>
                                <ul style={{ marginTop: 10, marginLeft: 20 }}>
                                    <li><b>Anexos: {String(Array.from(commentData.anexos).length).padStart(2, '0')}</b></li>
                                    <ul>
                                        {Array.from(commentData.anexos).map((file, i) => (
                                            <li key={i}>
                                                {file.name} - {file.type}
                                            </li>
                                        ))}
                                    </ul>
                                </ul>
                            </div>
                        }
                    </div>
                </>
            }
        </div>
    );
}

export default ViewPost;