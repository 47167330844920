import React, {useState, useEffect} from 'react';

import styles from './styles.module.css'

import { api_bd } from '../../services/Api'

const configs = require('../../config.json')

function NewPost(props) {
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    return (
        <div style={{ width: 'clamp(500px, 80%, 1000px )', minHeight: 400, borderRadius: 10, backgroundColor: '#fff', padding: 30, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <input
                style={{
                    width: '100%',
                    padding: 10,
                    border: '1px solid #c3c3c399',
                    borderRadius: 5
                }}
                onChange={(e) => {
                    setTitle(e.target.value)
                }}
                value={title}
                type='text'
                placeholder='Titulo da postagem'
            />

            <textarea
                style={{
                    width: '100%',
                    padding: 10,
                    height: 300,
                    marginTop: 20,
                    border: '1px solid #c3c3c399',
                    borderRadius: 5
                }}
                onChange={(e) => {
                    setMessage(e.target.value)
                }}
                placeholder='Texto da postagem'
            >{message}</textarea>
            <input
                type='buttom'
                value={btnDisabled ? 'Aguarde...' : 'Salvar'}
                disabled={btnDisabled}
                style={{
                    marginTop: 20,
                    padding: 15,
                    backgroundColor: btnDisabled ? '#c3c3c3' : '#3c68ce',
                    borderRadius: 5,
                    textAlign: 'center',
                    color: '#fff',
                    fontWeight: 'bold',
                    border: 'none',
                    width: '100%',
                    cursor: 'pointer'
                }}
                onClick={async () => {
                    if (title === '' || message === '') {
                        alert('Títutlo e Mensagem são obrigatórios.')
                    } else {
                        setBtnDisabled(true)

                        const dados = {
                            title: title,
                            text: message,
                            user: {
                                nome: props.user_profile.nome,
                                username: props.user_profile.username,
                                nivel: props.user_profile.nivel
                            },
                            date: new Date()
                        }

                        api_bd.post('/outages/teamnotes', dados).then(resp => {
                            setBtnDisabled(false)
                            props.setShowTextInput(false)
                        }).catch(e => {
                            setBtnDisabled(false)
                            alert('Ocorreu um erro ao enviar os dados, tente novamente mais tarde.')
                        })
                    }
                }}
            />
        </div>
    )
}

export default NewPost;