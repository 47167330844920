import { atom } from 'recoil'
// import { recoilPersist } from 'recoil-persist'

const nightMonitoringComponentToShowState = atom({
    key: 'nightMonitoringComponentState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
    // effects_UNSTABLE: [persistAtom],
});

export const nightMonitoringDetailTicketState = atom({
    key: 'nightMonitoringDetailState', // unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
    // effects_UNSTABLE: [persistAtom],
});

export const nightMonitoringListTicketState = atom({
    key: 'nightMonitoringListState', // unique ID (with respect to other atoms/selectors)
    default: [], // default value (aka initial value)
    // effects_UNSTABLE: [persistAtom],
});

export default nightMonitoringComponentToShowState