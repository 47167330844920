import React, { useState } from 'react';

import DashBoard1 from '../page_contents/DashBoard1';
import DashBoard2 from '../page_contents/DashBoard2';

function Dashboards() {

    const [option, setOption] = useState('dash1')

    const ShowContent = ({ option }) => {
        switch (option) {
            case 'dash2':
                return <DashBoard1 />
                break;
            case 'dash1':
                return <DashBoard2 />
                break;

            default:
                break;
        }
    }
    return (
        <div div className='div_content'>
            <div className='card flex flex-row justify-around'>
                <div
                    className='p-3 m-1 rounded w-[200px] bg-intec-500 text-white text-center text-xl'
                    onClick={() => {
                        setOption('dash1')
                    }}
                >DashBoard 1</div>

                <div
                    className='p-3 m-1 rounded w-[200px] bg-intec-500 text-white text-center text-xl'
                    onClick={() => {
                        setOption('dash2')
                    }}
                >DashBoard 2</div>
            </div>


            <ShowContent option={option} />

        </div>
    );
}

export default Dashboards;