import { atom, selector } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({
    key: '@sgiuser', // this key is using to store data in local storage
    storage: localStorage, // configurate which stroage will be used to store the data
})

export const realtimeState = atom({
    key: 'realtimeState', // unique ID (with respect to other atoms/selectors)
    default: {}, // default value (aka initial value)
});