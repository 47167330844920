
import { makeStyles } from '@material-ui/core/styles';

import styled from 'styled-components';


export const primary_color = '#3c5ca6'
export const highlight = '#324d8c'

const Styles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    div_content: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column'",
        alignItems: "flex_start",
        justifyContent: "center"
    },

    div_root_component: {
        margin: 10
    },

    form_cad_user: {
        display: "flex",
        flexDirecion: "row",
        flexWrap: "wrap",
        width: "100%",
        zIndex: 99999
    },
    form_input: {
        width: "100%",
        zIndex:1
    },
    form_select: {
        width: "200px"
    },
    btn_submit: {
        marginTop: '20px',
        marginLeft: '0px',
        paddingRight: "25px",
        marginBottom: "20px"
    },
    btn_opt: {
        marginTop: '20px',
        marginLeft: '0px',
        paddingRight: "25px",
        marginBottom: "20px"
    },
    card: {
        margin: 10,
        minWidth: 150,
        minHeight: 75,
        color: primary_color
    },
    imgCard: {
        width: 150
    },
    font_09:{
        fontSize: '0.9em'
    }
}));

export const colors_access_types = {
    "Ativo de Rede": '#0049b5',
    "Conta": '#007730',
    "Ferramenta": '#d84200',
    "Servidor": '#a5902f',
    "VPN": '#ac0e07'
}

export const Fab = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 60px;
height: 60px;
border-radius: 30px;
background-color: ${primary_color};
color: white;
position: fixed;
z-index: 9999;
bottom: 50px;
right: 50px;
cursor: pointer;
&:first-child {
    font-size: 35px;
}
&:hover {
    background-color: ${highlight}
}
`;


export const MiniFab = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 30px;
min-width: 30px;
max-width: 30px;
height: 30px;
min-height: 30px;
max-height: 30px;
border-radius: 15px;
background-color: red;
color: white;
z-index: 9999;
cursor: pointer;
&:first-child {
    font-size: 35px;
}
&:hover {
    background-color: ${highlight}
}
`;

export const Btn = styled.div`
display: flex;
padding: 5px;
justify-content: center;
align-items: center;
border-radius: 5px;
background-color: #345091;
color: white;
&:hover {
    background-color: ${highlight}
}
`;

export const LineMenuHome = styled.li`
margin: 15px;
padding: 5px;
justify-content: center;

border-bottom: 1px solid #fff;
color: white;
`;


export const Table = styled.div`
display: flex;
padding: 5px;
justify-content: center;
align-items: center;
border-radius: 5px;
background-color: #345091;
color: white;
&:hover {
    background-color: ${highlight}
}
`;

export const TableHead = styled.div`
display: flex;
background-color: ${primary_color};
color: #fff;
`;

export const TableRow = styled.div`
display: flex;
flex-direction: row;
`;

export const TableCell = styled.div`
display: flex;
color: #000;
border: 2px solid #fff;
`;


export default Styles