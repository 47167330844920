
import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'

// import Chart from "react-google-charts";

import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Bar } from 'react-chartjs-2';

import { api_bd } from '../../services/Api';
// import { styles } from '@material-ui/pickers/views/Calendar/Calendar';

// import axios from 'axios' // remover

import './DashBoard2.css';

import CardsContadores from '../CardsContadores';
import ContadoresCGR from '../TicketsCGR/ContadoresCGR';

// import ChartColaborador from '../ChartColaborador'; //graficos de colaboradores

// import ChartTempoPorCliente from '../ChartTempoPorCliente.js';

const { backend_files_address } = require('../../config.json')


function DashBoard2() {

    Chart.register(ChartDataLabels);

    const [dados, setDados] = useState({})
    const [dadosGraficos, setDadosGraficos] = useState({})
    const [periodo, setPeriodo] = useState({})
    const [periodo_ano, setPeriodo_ano] = useState(null)
    const [periodo_mes, setPeriodo_mes] = useState(null)
    const profile_user = useRecoilValue(profileData)
    const [tipoVisualizacao, setTipoVisualizacao] = useState('clientes')
    const [runningNow, setRunningNow] = useState([])
    const [listFilterRunningNow, setListFilterRunningNow] = useState([])

    const [usersImages, setUsersImages] = useState([])

    const maintainAspectRatio = false
    const fontSizeYaxis = 20
    const fontSizeXaxis = 15
    const fontSizeToolTip = 16
    const chartHeight = 380
    const cardWidth = '49%'


    const shuffle = function (array) {
        var currentIndex = array.length;
        var temporaryValue, randomIndex;

        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    };

    const generateColor = function () {
        var hex = ['a', 'b', 'c', 'd', 'e', 'f', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        var color = '#';
        for (var i = 0; i < 6; i++) {
            shuffle(hex);
            color += hex[0];
        }
        return color;

    };

    const msToTime = (ms) => {
        var hours = ms / (1000 * 60 * 60);
        var absoluteHours = Math.floor(hours);
        var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

        //Get remainder from hours and convert to minutes
        var minutes = (hours - absoluteHours) * 60;
        var absoluteMinutes = Math.floor(minutes);
        var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

        //Get remainder from minutes and convert to seconds
        var seconds = (minutes - absoluteMinutes) * 60;
        var absoluteSeconds = Math.floor(seconds);
        var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

        return h + ':' + m + ':' + s;
    }

    const options = {
        plugins: {
            datalabels: {
                display: true,
                align: 'top',
                backgroundColor: '#ccc',
                borderRadius: 3,
                font: {
                    size: 18,
                }
            },
        }
    }

    const load_data = async () => {
        //setDadosGraficos({})

        // console.log('OPAAAA', periodo)

        const response = await api_bd.post(`/relatorios/resumo/todos`, periodo)// await axios.get('http://localhost:9500')//

        // console.log(response.data)

        if (Object.keys(response.data.clientes).length > 0) {
            setDados(response.data)
        } else {
            setDadosGraficos({ error: "Sem dados" })
        }

        // const clientes = response.data.clientes
    }



    const getRunningNow = async (_arrFilters) => {
        if (_arrFilters.length > 0) {
            // console.log('call running now', _arrFilters.length)

            const response = await api_bd.get(`/relatorios/runningnow`)
            let resp = []

            for (let k in response.data.tickets) {
                let ticket = response.data.tickets[k]
                let tratativa_ativa = ticket.tratativas.filter(el => el.fim === null)
                if (tratativa_ativa) {
                    // console.log(tratativa_ativa)
                    for (let j in tratativa_ativa) {
                        let tratativa = tratativa_ativa[j]
                        resp.push({
                            cod_id: ticket.cod_id,
                            nome: tratativa.nome,
                            id: tratativa.id_usuario
                        })
                    }

                }
            }


            // let dados = response.data.usersList.map(user => {
            let dados = _arrFilters.map(user => {
                if (resp.find(el => el.id === user.id)) {
                    return {
                        id: user._id,
                        nome: user.nome,
                        bgcolor: 'rgb(70, 149, 54)',//'#3c5ca6'
                        active: true,
                        avatar_uri: user.avatar_uri
                    }
                } else {
                    return {
                        id: user._id,
                        nome: user.nome,
                        bgcolor: '#c3c3c3',
                        active: false,
                        avatar_uri: user.avatar_uri
                    }
                }
            })

            setRunningNow(old => {
                return dados
            })
        }
    }



    function sortChartDataAnalistas(a, b) {
        if (a.tempo < b.tempo) {
            return -1;
        }
        if (a.tempo > b.tempo) {
            return 1;
        }
        return 0;
    }

    function sortChartDataAnalistasByTickets(a, b) {
        if (a.tempo < b.tempo) {
            return -1;
        }
        if (a.tempo > b.tempo) {
            return 1;
        }
        return 0;
    }

    function sortChartDataClientesByTempoDESC(a, b) {
        if (a.tempoTotal_ms > b.tempoTotal_ms) {
            return -1;
        }
        if (a.tempoTotal_ms < b.tempoTotal_ms) {
            return 1;
        }
        return 0;

    }

    function sortChartDataClientesByTempoASC(a, b) {
        if (a.tempoTotal_ms < b.tempoTotal_ms) {
            return -1;
        }
        if (a.tempoTotal_ms > b.tempoTotal_ms) {
            return 1;
        }
        return 0;
    }


    function sortChartDataClientesByQtdTicketsASC(a, b) {
        if (a.chamados.length < b.chamados.length) {
            return -1;
        }
        if (a.chamados.length > b.chamados.length) {
            return 1;
        }
        return 0;
    }

    function sortChartDataClientesByQtdTicketsDESC(a, b) {
        if (a.chamados.length > b.chamados.length) {
            return -1;
        }
        if (a.chamados.length < b.chamados.length) {
            return 1;
        }
        return 0;
    }


    useEffect(() => {
        if (dados.clientes) {

            let listFilterAnalistForShowInRunningNow = []

            let clientes = dados.clientes
            let tmpAnalistas = []
            let tmpAnalistas2 = []
            let tmpAnalistas3 = []
            let tmpAnalistas4 = []
            let tmpAnalistas5 = []
            let tmpAnalistas6 = []


            let tmpClientes = []
            let tmpClientes_chamados = []


            let tmpDadosGraficos = {
                chamadosPorCliente: {
                    labels: [],
                    datasets: [
                        {
                            label: ' Qtd ',
                            data: [],
                            borderColor: '#3366cc88',
                            backgroundColor: '#3366cc'
                        }
                    ],
                },

                tempoPorCliente: {
                    labels: [],
                    datasets: [
                        {
                            label: ' Tempo ',
                            data: [],
                            borderColor: '#3366cc88',
                            backgroundColor: '#3366cc'
                        }
                    ],
                },

                ticketsPorAnalista: {
                    labels: [],
                    datasets: [
                        {
                            label: ' Total ',
                            data: [],
                            borderColor: '#3366cc',
                            backgroundColor: '#3366cc'
                        },
                        {
                            label: ' Pendente ',
                            data: [],
                            borderColor: '#ffaa00',
                            backgroundColor: '#ffaa00'
                        }
                    ],
                },


                ticketsFechadosPorAnalista: {
                    labels: [],
                    datasets: [
                        {
                            label: ' Total ',
                            data: [],
                            borderColor: '#3366cc',
                            backgroundColor: '#3366cc'
                        }
                    ],
                },

                ticketsPendPorAnalista: {
                    labels: [],
                    datasets: [
                        {
                            label: ' Qtd ',
                            data: [],
                            borderColor: '#3366cc88',
                            backgroundColor: '#3366cc'
                        }
                    ],
                },

                mediaPesquisaSatisfacao: {
                    labels: [],
                    datasets: [
                        {
                            label: ' Média Geral ',
                            data: [],
                            borderColor: '#3366cc88',
                            backgroundColor: '#3366cc'
                        }
                    ],
                },

                totalHorasPorAnalista: {
                    labels: [],
                    datasets: [
                        {
                            label: ' Tempo ',
                            data: [],
                            borderColor: '#3366cc88',
                            backgroundColor: '#3366cc'
                        }
                    ],
                },

                analistaEficiencia_htk: {
                    labels: [],
                    datasets: [
                        {
                            label: ' Tempo por ticket ',
                            data: [],
                            borderColor: '#3366cc88',
                            backgroundColor: '#3366cc'
                        }
                    ],
                },
            }

            for (let key in dados.analistas) {
                listFilterAnalistForShowInRunningNow.push({
                    nome: dados.analistas[key].nome,
                    avatar_uri: dados.analistas[key].avatar_uri,
                    id: dados.analistas[key].id
                })

                tmpAnalistas.push({ nome: key, ...dados.analistas[key] })
                tmpAnalistas2.push({ nome: key, ...dados.analistas[key] })
                tmpAnalistas3.push({ nome: key, ...dados.analistas[key] })
                tmpAnalistas4.push({ nome: key, ...dados.analistas[key] })
                tmpAnalistas5.push({ nome: key, ...dados.analistas[key] })
                tmpAnalistas6.push({ nome: key, ...dados.analistas[key] })
            }


            for (let key in clientes) {
                tmpClientes.push({ nome: key, ...clientes[key] })
                tmpClientes_chamados.push({ nome: key, ...clientes[key] })
            }

            tmpClientes = tmpClientes.sort(sortChartDataClientesByTempoDESC)
            tmpClientes = tmpClientes.slice(0, 10)

            tmpClientes_chamados = tmpClientes_chamados.sort(sortChartDataClientesByQtdTicketsDESC)
            tmpClientes_chamados = tmpClientes_chamados.slice(0, 10)


            //Tickets fechados por analista
            let analistasTicketsFechados = tmpAnalistas.sort((a, b) => {
                if (a.tickets_finalizados.length > b.tickets_finalizados.length) {
                    return -1;
                }
                if (a.tickets_finalizados.length < b.tickets_finalizados.length) {
                    return 1;
                }
                return 0;
            })
            for (let key in analistasTicketsFechados) {
                tmpDadosGraficos.ticketsFechadosPorAnalista.labels.push(analistasTicketsFechados[key].nome)
                tmpDadosGraficos.ticketsFechadosPorAnalista.datasets[0].data.push(analistasTicketsFechados[key].tickets_finalizados.length)
            }


            //Ordem Descrescente
            let analistasTickets = tmpAnalistas.sort((a, b) => {
                if (a.tickets_abertos.length > b.tickets_abertos.length) {
                    return -1;
                }
                if (a.tickets_abertos.length < b.tickets_abertos.length) {
                    return 1;
                }
                return 0;
            })

            //Ordem Descrescente
            let analistasPend = tmpAnalistas2.sort((a, b) => {
                if (a.tickets_pendentes.length > b.tickets_pendentes.length) {
                    return -1;
                }
                if (a.tickets_pendentes.length < b.tickets_pendentes.length) {
                    return 1;
                }
                return 0;
            })

            //Ordem Descrescente
            let analistasMedia = tmpAnalistas3.sort((a, b) => {
                if (a.ranking.media_geral > b.ranking.media_geral) {
                    return -1;
                }
                if (a.ranking.media_geral < b.ranking.media_geral) {
                    return 1;
                }
                return 0;
            })

            //Ordem Descrescente
            let analistasHoras = tmpAnalistas4.sort((a, b) => {
                if (a.tempoTotal > b.tempoTotal) {
                    return -1;
                }
                if (a.tempoTotal < b.tempoTotal) {
                    return 1;
                }
                return 0;
            })

            //Ordem Descrescente
            let analistasEficiencia = tmpAnalistas5.sort((a, b) => {
                if (a.eficiencia_htk < b.eficiencia_htk) {
                    return -1;
                }
                if (a.eficiencia_htk > b.eficiencia_htk) {
                    return 1;
                }
                return 0;
            })


            for (let key in tmpClientes) {
                tmpDadosGraficos.tempoPorCliente.labels.push(tmpClientes[key].nome)
                tmpDadosGraficos.tempoPorCliente.datasets[0].data.push(tmpClientes[key].tempoTotal_ms)
            }

            for (let key in tmpClientes_chamados) {
                tmpDadosGraficos.chamadosPorCliente.labels.push(tmpClientes_chamados[key].nome)
                tmpDadosGraficos.chamadosPorCliente.datasets[0].data.push(tmpClientes_chamados[key].chamados.length)
            }



            for (let key in analistasTickets) {
                tmpDadosGraficos.ticketsPorAnalista.labels.push(analistasTickets[key].nome)
                tmpDadosGraficos.ticketsPorAnalista.datasets[0].data.push(analistasTickets[key].tickets_abertos.length)

                tmpDadosGraficos.ticketsPorAnalista.datasets[1].data.push(analistasTickets[key].tickets_pendentes.length)
            }


            // for (let key in analistasPend) {
            //     tmpDadosGraficos.ticketsPendPorAnalista.labels.push(analistasPend[key].nome)
            //     tmpDadosGraficos.ticketsPendPorAnalista.datasets[0].data.push(analistasPend[key].tickets_pendentes.length)
            // }


            for (let key in analistasMedia) {
                tmpDadosGraficos.mediaPesquisaSatisfacao.labels.push(analistasMedia[key].nome)
                tmpDadosGraficos.mediaPesquisaSatisfacao.datasets[0].data.push(analistasMedia[key].ranking.media_geral)
            }


            for (let key in analistasHoras) {
                tmpDadosGraficos.totalHorasPorAnalista.labels.push(analistasHoras[key].nome)
                tmpDadosGraficos.totalHorasPorAnalista.datasets[0].data.push(analistasHoras[key].tempoTotal)
            }

            for (let key in analistasEficiencia) {
                tmpDadosGraficos.analistaEficiencia_htk.labels.push(analistasEficiencia[key].nome)
                tmpDadosGraficos.analistaEficiencia_htk.datasets[0].data.push(analistasEficiencia[key].eficiencia_htk)
            }

            // setUsersImages(tmpAnalistasChartJS.images)
            // tmpDadosGraficos['charjs'] = {
            //     labels: tmpAnalistasChartJS.labels,
            //     datasets: [
            //         {
            //             label: 'Analistas',
            //             data: tmpAnalistasChartJS.values,
            //             borderColor: '#3366cc88',
            //             backgroundColor: '#3366cc',
            //         }
            //     ]
            // }


            //filtro para exibição do running now
            setListFilterRunningNow(listFilterAnalistForShowInRunningNow)

            setDadosGraficos(tmpDadosGraficos)

            // console.log(tmpDadosGraficos)
        }


    }, [dados])


    useEffect(() => {
        // if (Object.keys(periodo).length > 0) {
        if ((periodo_mes !== null) && (periodo_ano !== null)) {
            setPeriodo({ mes: periodo_mes, ano: periodo_ano })
        }
        // (async () => {
        //     setDadosGraficos({})

        //     // console.log('OPAAAA')

        //     const response = await api_bd.post(`/relatorios/state/todos`, { mes: periodo_mes, ano: periodo_ano })// await axios.get('http://localhost:9500')//
        //     //console.log(response.data)

        //     if (Object.keys(response.data.clientes).length > 0) {
        //         setDados(response.data)
        //     } else {
        //         setDadosGraficos({ error: "Sem dados" })
        //     }

        //     // const clientes = response.data.clientes


        // })()

        //alert(JSON.stringify(periodo))
        // }
    }, [periodo_mes, periodo_ano])

    useEffect(() => {
        if (Object.keys(periodo).length > 0) {
            // (async () => {
            //     setDadosGraficos({})

            //     // console.log('OPAAAA')

            //     const response = await api_bd.post(`/relatorios/resumo/todos`, periodo)// await axios.get('http://localhost:9500')//
            //     //console.log(response.data)

            //     if (Object.keys(response.data.clientes).length > 0) {
            //         setDados(response.data)
            //     } else {
            //         setDadosGraficos({ error: "Sem dados" })
            //     }

            //     // const clientes = response.data.clientes


            // })()
            load_data()
        }

    }, [periodo])



    useEffect(() => {
        let interval2 = null

        if (interval2) {
            try {
                clearInterval(interval2)
            } catch (error) {

            }
        }

        if (listFilterRunningNow.length > 0) {
            getRunningNow(listFilterRunningNow)
            interval2 = setInterval(async () => {
                getRunningNow(listFilterRunningNow)
            }, 30000);
        }

        return () => {
            try {
                clearInterval(interval2)
            } catch (error) {

            }
        };
    }, [listFilterRunningNow])


    useEffect(() => {
        const date = new Date(), y = date.getFullYear(), m = date.getMonth();
        const initDate = new Date(y, m, 1);
        const endDate = new Date(y, m + 1, 0);

        setPeriodo_ano(y)
        setPeriodo_mes(m)

        setPeriodo(old => {
            return ({
                mes: m,
                ano: y
            })
        })

        // console.log(document.querySelector('#chart_analistas').getElementsByTagName('text'))

        // if ((profile_user.nivel) && (profile_user.nivel === 'Técnico')) {
        //     setTipoVisualizacao('colaboradores')
        // }

        const interval = setInterval(async () => {
            load_data()
        }, 300000);

        return () => {
            clearInterval(interval);
        };

    }, [])

    return (
        <div div className='div_content'>
            <div className='card'>
                <div className='form_group'>
                    <div className='form_group_label'>Selecione o Mês:</div>
                    <div className='form_group_content'>
                        <select
                            value={periodo_mes}
                            onChange={(e) => {
                                //console.log({ ...periodo, mes: parseInt(e.target.value) })
                                setPeriodo_mes(parseInt(e.target.value))
                            }}
                        >
                            <option value="0">Janeiro</option>
                            <option value="1">Fevereiro</option>
                            <option value="2">Março</option>
                            <option value="3">Abril</option>
                            <option value="4">Maio</option>
                            <option value="5">Junho</option>
                            <option value="6">Julho</option>
                            <option value="7">Agosto</option>
                            <option value="8">Setembro</option>
                            <option value="9">Outubro</option>
                            <option value="10">Novembro</option>
                            <option value="11">Dezembro</option>
                        </select>
                    </div>
                </div>

                <div className='form_group' style={{ marginLeft: 20 }}>
                    <div className='form_group_label'>Selecione o Ano:</div>
                    <div className='form_group_content'>
                        <input
                            type='number'
                            min="2021"
                            value={periodo_ano}
                            onChange={(e) => {
                                // console.log({ ...periodo, ano: parseInt(e.target.value) })
                                setPeriodo_ano(parseInt(e.target.value))
                            }}
                        />
                    </div>
                </div>
            </div>

            {Object.keys(dadosGraficos).length === 0 &&
                <div style={{ display: 'flex', width: '100%', minHeight: '60vh', justifyContent: 'center', alignItems: 'center', fontSize: '2em', fontWeight: 'bolder' }}>
                    Carregando Dados....
                </div>
            }

            {(Object.keys(dadosGraficos).length === 1) && dadosGraficos.error &&
                <div style={{ display: 'flex', width: '100%', minHeight: '60vh', justifyContent: 'center', alignItems: 'center', fontSize: '2em', fontWeight: 'bolder' }}>
                    Não Foram encontradas informações para o período selecionado!
                </div>
            }

            {Object.keys(dadosGraficos).length > 1 &&
                <div style={{ width: '98%', maxWidth: '98%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

                    <div className='card' style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <span>Contadores NOC (Dados atuais fora do intervalo de tempo)</span>
                        <div className='flex flex-row justify-center w-full'>
                            <CardsContadores />
                        </div>
                    </div>

                    <div className='card' style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <span>Contadores CGR (Dados atuais fora do intervalo de tempo)</span>
                        <div className='flex flex-row justify-center w-full'>
                            <ContadoresCGR />
                        </div>
                    </div>

                    <div className='card w-full' style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <span>Analistas em atuação (Dados atuais fora do intervalo de tempo)</span>
                        <div className='w-full flex flex-row flex-wrap justify-start'>
                            {runningNow.length > 0 && runningNow.sort((a, b) => b.active - a.active).map(user => (
                                <div
                                    className={`${user.active ? 'bg-intec-500 border border-intec-500' : 'border border-zinc'} w-[180px] m-2 p-3 flex flex-row justify-between items-center cursor-pointer rounded`}
                                >
                                    <div className={`${user.active ? 'text-white font-bold' : 'text-zinc'}`}>
                                        {user.nome.toUpperCase()}
                                    </div>
                                    <div className="w-20 h-20">
                                        <img
                                            className={`rounded-full w-full h-full object-cover ${user.active === false ? 'grayscale' : ''}`}
                                            src={`${(user.avatar_uri !== '') ? backend_files_address + '/' + user.avatar_uri : `${backend_files_address}/user_placeholder.jpg`} `}
                                        />
                                    </div>
                                </div>

                                // <div
                                //     className='flex p-4 m-2 text-xl w-[180px] text-white font-bold rounded'
                                //     style={{ backgroundColor: user.bgcolor }}
                                // >
                                //     {user.nome}
                                // </div>
                            ))}
                        </div>
                    </div>

                    <div style={{ width: '98%', maxWidth: '98%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div className='card' style={{ width: cardWidth, maxWidth: cardWidth, display: 'flex', flexDirection: 'column' }}>
                            <span style={{ marginTop: 20, marginBottom: 10, fontSize: '1.3em', fontWeight: 'bold' }}>Tickets por analista</span>
                            <div style={{ display: 'flex', width: '100%', height: chartHeight }}>
                                <Bar options={{
                                    indexAxis: 'y',
                                    elements: {
                                        bar: {
                                            borderWidth: 2,
                                        },
                                    },
                                    responsive: true,
                                    // maintainAspectRatio: false,
                                    maintainAspectRatio,
                                    plugins: {
                                        // legend: {
                                        // 	position: 'right',
                                        // },
                                        datalabels: {
                                            display: true,
                                            color: '#fff',
                                            formatter: (valor) => valor > 0 ? valor : '',//Math.round,
                                            anchor: "end",
                                            offset: -30,
                                            align: "end",
                                            font: { size: 15, weight: 'bold' }
                                        },
                                        tooltip: {
                                            titleFont: {
                                                size: fontSizeToolTip
                                            },
                                            bodyFont: {
                                                size: fontSizeToolTip
                                            }
                                        },
                                        legend: {
                                            display: false,
                                            // position: 'top',
                                        },
                                        title: {
                                            display: false,
                                            text: 'Tickets por analista',
                                        },
                                    },
                                    scales: {
                                        y: {
                                            ticks: {
                                                font: {
                                                    size: fontSizeYaxis,
                                                }
                                            }
                                        },
                                        x: {
                                            ticks: {
                                                font: {
                                                    size: fontSizeXaxis
                                                }
                                            }
                                        }
                                    },

                                }}
                                    data={dadosGraficos.ticketsPorAnalista}
                                />
                            </div>
                        </div>


                        <div className='card' style={{ width: cardWidth, maxWidth: cardWidth, display: 'flex', flexDirection: 'column' }}>
                            <span style={{ marginTop: 20, marginBottom: 10, fontSize: '1.3em', fontWeight: 'bold' }}>Eficiência do Analista (tempo/ticket)</span>
                            <div style={{ display: 'flex', width: '100%', height: chartHeight }}>
                                <Bar options={{
                                    indexAxis: 'y',
                                    elements: {
                                        bar: {
                                            borderWidth: 2,
                                        },
                                    },
                                    responsive: true,
                                    // maintainAspectRatio: false,
                                    maintainAspectRatio,
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            color: '#fff',
                                            formatter: (valor) => valor > 0 ? msToTime(valor) : '',//Math.round,
                                            anchor: "end",
                                            // offset: -30,
                                            align: "start",
                                            font: { size: 15, weight: 'bold' }
                                        },
                                        tooltip: {
                                            enabled: true,
                                            callbacks: {
                                                title: function (tooltipItem) {
                                                    return tooltipItem[0].label
                                                },
                                                label: function (tooltipItem) {
                                                    return ' ' + msToTime(tooltipItem.raw) + 'hs por ticket'
                                                }

                                            },
                                            titleFont: {
                                                size: fontSizeToolTip
                                            },
                                            bodyFont: {
                                                size: fontSizeToolTip
                                            }
                                        },
                                        legend: {
                                            display: false,
                                            // position: 'top',
                                        },
                                        title: {
                                            display: false,
                                            text: 'Eficiencia do analista tempo por ticket',
                                        }
                                    },
                                    scales: {
                                        x: {
                                            ticks: {
                                                // Include a dollar sign in the ticks
                                                callback: function (value, index, ticks) {
                                                    return msToTime(value)
                                                },
                                                font: {
                                                    size: fontSizeXaxis
                                                }
                                            }
                                        },
                                        y: {
                                            ticks: {
                                                beginAtZero: true,
                                                font: {
                                                    size: fontSizeYaxis
                                                }
                                            }
                                        }
                                    },

                                }}
                                    data={dadosGraficos.analistaEficiencia_htk}
                                />
                            </div>
                        </div>

                    </div> {/* Final da ROW */}


                    <div style={{ width: '98%', maxWidth: '98%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div className='card' style={{ width: cardWidth, maxWidth: cardWidth, display: 'flex', flexDirection: 'column' }}>
                            <span style={{ marginTop: 20, marginBottom: 10, fontSize: '1.3em', fontWeight: 'bold' }}>TOP 10 Clientes (Tickets)</span>
                            <div style={{ display: 'flex', width: '100%', height: chartHeight }}>
                                <Bar options={{
                                    indexAxis: 'y',
                                    elements: {
                                        bar: {
                                            borderWidth: 2,
                                        },
                                    },
                                    responsive: true,
                                    // maintainAspectRatio: false,
                                    maintainAspectRatio,
                                    plugins: {
                                        // legend: {
                                        // 	position: 'right',
                                        // },
                                        datalabels: {
                                            display: true,
                                            color: '#fff',
                                            formatter: (valor) => valor > 0 ? valor : '',//Math.round,
                                            anchor: "end",
                                            offset: -30,
                                            align: "end",
                                            font: { size: 15, weight: 'bold' }
                                        },
                                        tooltip: {
                                            titleFont: {
                                                size: fontSizeToolTip
                                            },
                                            bodyFont: {
                                                size: fontSizeToolTip
                                            }
                                        },
                                        legend: {
                                            display: false,
                                            // position: 'top',
                                        },
                                        title: {
                                            display: false,
                                            text: 'Tickets por Cliente',
                                        },
                                    },
                                    scales: {
                                        y: {
                                            ticks: {
                                                font: {
                                                    size: fontSizeYaxis,
                                                }
                                            }
                                        },
                                        x: {
                                            ticks: {
                                                font: {
                                                    size: fontSizeXaxis
                                                }
                                            }
                                        }
                                    },

                                }}
                                    data={dadosGraficos.chamadosPorCliente}
                                />
                            </div>
                        </div>

                        <div className='card' style={{ width: cardWidth, maxWidth: cardWidth, display: 'flex', flexDirection: 'column' }}>
                            <span style={{ marginTop: 20, marginBottom: 10, fontSize: '1.3em', fontWeight: 'bold' }}>TOP 10 Clientes (horas)</span>
                            <div style={{ display: 'flex', width: '100%', width: '100%', height: chartHeight }}>
                                <Bar options={{
                                    indexAxis: 'y',
                                    elements: {
                                        bar: {
                                            borderWidth: 2,
                                        },
                                    },
                                    responsive: true,
                                    // maintainAspectRatio: false,
                                    maintainAspectRatio,
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            color: '#fff',
                                            formatter: (valor) => valor > 0 ? msToTime(valor) : '',//Math.round,
                                            anchor: "end",
                                            offset: -80,
                                            align: "end",
                                            font: { size: 15, weight: 'bold' }
                                        },
                                        tooltip: {
                                            callbacks: {
                                                title: function (tooltipItem) {
                                                    return tooltipItem[0].label
                                                },
                                                label: function (tooltipItem) {
                                                    return ' ' + msToTime(tooltipItem.raw) + 'hs'
                                                }
                                            },
                                            titleFont: {
                                                size: fontSizeToolTip
                                            },
                                            bodyFont: {
                                                size: fontSizeToolTip
                                            }
                                        },
                                        legend: {
                                            display: false,
                                            // position: 'top',
                                        },
                                        title: {
                                            display: false,
                                            text: 'Tempo por Cliente',
                                        }
                                    },
                                    scales: {
                                        x: {
                                            ticks: {
                                                // Include a dollar sign in the ticks
                                                callback: function (value, index, ticks) {
                                                    return msToTime(value)
                                                },
                                                font: {
                                                    size: fontSizeXaxis
                                                }
                                            }
                                        },
                                        y: {
                                            ticks: {
                                                beginAtZero: true,
                                                font: {
                                                    size: fontSizeYaxis
                                                }
                                            }
                                        }
                                    },

                                }}
                                    data={dadosGraficos.tempoPorCliente}
                                />
                            </div>
                        </div>

                    </div> {/* Final da ROW */}


                    <div style={{ width: '98%', maxWidth: '98%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div className='card' style={{ width: cardWidth, maxWidth: cardWidth, display: 'flex', flexDirection: 'column' }}>
                            <span style={{ marginTop: 20, marginBottom: 10, fontSize: '1.3em', fontWeight: 'bold' }}>Média pela pesquisa de satisfação</span>
                            <div style={{ display: 'flex', width: '100%', height: chartHeight }}>
                                <Bar options={{
                                    indexAxis: 'y',
                                    elements: {
                                        bar: {
                                            borderWidth: 2,
                                        },
                                    },
                                    responsive: true,
                                    // maintainAspectRatio: false,
                                    maintainAspectRatio,
                                    plugins: {
                                        // legend: {
                                        // 	position: 'right',
                                        // },
                                        datalabels: {
                                            display: true,
                                            color: '#fff',
                                            formatter: (valor) => valor > 0 ? valor.toFixed(2) : '',//Math.round,
                                            anchor: "end",
                                            offset: -50,
                                            align: "end",
                                            font: { size: 15, weight: 'bold' }
                                        },
                                        tooltip: {
                                            titleFont: {
                                                size: fontSizeToolTip
                                            },
                                            bodyFont: {
                                                size: fontSizeToolTip
                                            }
                                        },
                                        legend: {
                                            display: false,
                                            // position: 'top',
                                        },
                                        title: {
                                            display: false,
                                            text: 'Média pela pesquisa de satisfação',
                                        },
                                    },
                                    scales: {
                                        y: {
                                            ticks: {
                                                font: {
                                                    size: fontSizeYaxis,
                                                }
                                            }
                                        },
                                        x: {
                                            ticks: {
                                                font: {
                                                    size: fontSizeXaxis
                                                }
                                            }
                                        }
                                    },

                                }}
                                    data={dadosGraficos.mediaPesquisaSatisfacao}
                                />
                            </div>
                        </div>

                        <div className='card' style={{ width: cardWidth, maxWidth: cardWidth, display: 'flex', flexDirection: 'column' }}>
                            <span style={{ marginTop: 20, marginBottom: 10, fontSize: '1.3em', fontWeight: 'bold' }}>Horas por Analista</span>
                            <div style={{ display: 'flex', width: '100%', height: chartHeight }}>
                                <Bar options={{
                                    indexAxis: 'y',
                                    elements: {
                                        bar: {
                                            borderWidth: 2,
                                        },
                                    },
                                    responsive: true,
                                    // maintainAspectRatio: false,
                                    maintainAspectRatio,
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            color: '#fff',
                                            formatter: (valor) => valor > 0 ? msToTime(valor) : '',//Math.round,
                                            anchor: "end",
                                            offset: -80,
                                            align: "end",
                                            font: { size: 15, weight: 'bold' }
                                        },
                                        tooltip: {
                                            callbacks: {
                                                title: function (tooltipItem) {
                                                    return tooltipItem[0].label
                                                },
                                                label: function (tooltipItem) {
                                                    return ' ' + msToTime(tooltipItem.raw) + 'hs'
                                                }
                                            },
                                            titleFont: {
                                                size: fontSizeToolTip
                                            },
                                            bodyFont: {
                                                size: fontSizeToolTip
                                            }
                                        },
                                        legend: {
                                            display: false,
                                            // position: 'top',
                                        },
                                        title: {
                                            display: false,
                                            text: 'Tempo por Analista',
                                        }
                                    },
                                    scales: {
                                        x: {
                                            ticks: {
                                                // Include a dollar sign in the ticks
                                                callback: function (value, index, ticks) {
                                                    return msToTime(value)
                                                },
                                                font: {
                                                    size: fontSizeXaxis
                                                }
                                            }
                                        },
                                        y: {
                                            ticks: {
                                                beginAtZero: true,
                                                font: {
                                                    size: fontSizeYaxis
                                                }
                                            }
                                        }
                                    },

                                }}
                                    data={dadosGraficos.totalHorasPorAnalista}
                                />
                            </div>
                        </div>
                    </div> {/* Final da ROW */}

                    <div style={{ width: '98%', maxWidth: '98%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                        <div className='card' style={{ width: cardWidth, maxWidth: cardWidth, display: 'flex', flexDirection: 'column' }}>
                            <span style={{ marginTop: 20, marginBottom: 10, fontSize: '1.3em', fontWeight: 'bold' }}>Tickets finalizados por analista</span>
                            <div style={{ display: 'flex', width: '100%', height: chartHeight }}>
                                <Bar options={{
                                    indexAxis: 'y',
                                    elements: {
                                        bar: {
                                            borderWidth: 2,
                                        },
                                    },
                                    responsive: true,
                                    // maintainAspectRatio: false,
                                    maintainAspectRatio,
                                    plugins: {
                                        // legend: {
                                        // 	position: 'right',
                                        // },
                                        datalabels: {
                                            display: true,
                                            color: '#fff',
                                            formatter: (valor) => valor > 0 ? valor : '',//Math.round,
                                            anchor: "end",
                                            offset: -30,
                                            align: "end",
                                            font: { size: 15, weight: 'bold' }
                                        },
                                        tooltip: {
                                            titleFont: {
                                                size: fontSizeToolTip
                                            },
                                            bodyFont: {
                                                size: fontSizeToolTip
                                            }
                                        },
                                        legend: {
                                            display: false,
                                            // position: 'top',
                                        },
                                        title: {
                                            display: false,
                                            text: 'Tickets Fechados por Cliente',
                                        },
                                    },
                                    scales: {
                                        y: {
                                            ticks: {
                                                font: {
                                                    size: fontSizeYaxis,
                                                }
                                            }
                                        },
                                        x: {
                                            ticks: {
                                                font: {
                                                    size: fontSizeXaxis
                                                }
                                            }
                                        }
                                    },

                                }}
                                    data={dadosGraficos.ticketsFechadosPorAnalista}
                                />
                            </div>
                        </div>

                    </div> {/* Final da ROW */}



                </div>
            }
        </div >
    );
}

export default DashBoard2;