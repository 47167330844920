import React, { useState, useEffect } from 'react';

import Styles, { primary_color } from '../components/Styles'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../atoms/user.atom'
import navigationState from '../atoms/navigate_state.atom'

import LeftSideBar from '../components/SideBars/LeftSideBar'
import { Card, CardContent } from '@material-ui/core';


function ProjectsPage(props) {
    const styles = Styles();

    const profile_user = useRecoilValue(profileData)

    const [content_columns, set_content_columns] = useState([
        {
            id: '010101',
            titulo: 'Teste Fila',
            situacao: 'fila',
            descricao: 'Projeto de teste',
            interacoes: [
                {
                    id_usuario: 'agasfasdrawew3qe2ew123',
                    nome: 'Fulano',
                    interacao: 'teste de interação'
                }
            ]
        },
        {
            id: '020202',
            titulo: 'Teste Fila',
            situacao: 'fila',
            descricao: 'Projeto de teste',
            interacoes: [
                {
                    id_usuario: '65e4r6a3f1dwser6fa16f',
                    nome: 'Fulano',
                    interacao: 'teste de interação'
                }
            ]
        },
        {
            id: '0303003',
            titulo: 'Teste Em Execução',
            situacao: 'em_execucao',
            descricao: 'Projeto de teste',
            interacoes: [
                {
                    id_usuario: '68we7f6e5f41ewa6fwef',
                    nome: 'Fulano',
                    interacao: 'teste de interação'
                }
            ]
        },
        {
            id: '040404',
            titulo: 'Teste Concluído',
            situacao: 'concluido',
            descricao: 'Projeto de teste',
            interacoes: [
                {
                    id_usuario: 'wea68f74aw6f416e1acv',
                    nome: 'Fulano',
                    interacao: 'teste de interação'
                }
            ]
        }
    ])

    const styleCardsColumns = {
        minHeight: '100%',
        display: 'flex',
        flex: 1,
        margin: 10
    }

    const styleCardsColumnsContent = {
        width: '100%'
    }

    const styleTitleColumn = {
        padding: 5,
        width: '100%',
        textAlign: 'center',
        color: primary_color,
        fontSize: '1.3em',
        fontWeight: 'bold',
        borderBottom: `1px solid ${primary_color}66`,
        textShadow: '1px 1px 2px #0002'
    }

    const tipos_situacao = {
        fila: 'Fila',
        em_execucao: 'em_execucao',
        concluido: 'Concluído' 
    }

    const handle_update_column = (item, acao)=>{
        const tmpdata = content_columns.map(el => {
            if(el.id === item.id){
                if(el.situacao === 'fila'){
                    el.situacao = 'em_execucao'
                }else if(el.situacao === 'em_execucao'){
                    if(acao === 'prev'){
                        el.situacao = 'fila'
                    }else if(acao === 'next'){
                        el.situacao = 'concluido'
                    }
                }else if(el.situacao === 'concluido'){
                    el.situacao = 'em_execucao'
                }
            }
            return el
        })

        set_content_columns(tmpdata)
    }


    const ItemColumns = (props) => {
        return (
            props.dados.filter(item => item.situacao === props.filter).map(item => (
                <Card style={{ margin: 10, border: '1px solid #0001' }}>
                    <CardContent>
                        <div style={{ padding: 5, color: '#000d', position: 'relative' }}>
                            <div style={{position: 'absolute', right: 5, color: primary_color}}>
                                {item.situacao !== 'fila' &&
                                    <span 
                                    style={{marginLeft: 0, transform: 'scaleX(-1)'}} 
                                    onClick={()=>{
                                        handle_update_column(item, 'prev')
                                    }}
                                    className="material-icons">next_plan</span>
                                }

                                {item.situacao !== 'concluido' && 
                                    <span 
                                    style={{marginLeft: 0}} 
                                    onClick={()=>{
                                        handle_update_column(item, 'next')
                                    }}
                                    className="material-icons">next_plan</span>
                                }
                            </div>
                            
                            <div><b>ID:</b> {item.id}</div>
                            <div><b>Titulo:</b> {item.titulo}</div>
                            <div><b>Descrição:</b> {item.descricao}</div>
                            <div style={{ marginTop: 10 }}><b>Interações</b></div>
                            
                            {item.interacoes.map(interac => (
                                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#0001', margin: 10, borderRadius: 3, padding: 5 }}>
                                    {interac.interacao}
                                    <div style={{ width: '100%', fontSize: '0.8em', textAlign: 'right', color: '#0009' }}>{interac.nome}</div>
                                </div>
                            ))}
                            <div style={{ width: '100%', textAlign: 'right', color: primary_color, cursor: 'pointer' }}>
                                <span style={{ marginLeft: 0 }} className="material-icons">
                                    add_comment
                                </span>
                            </div>
                        </div>
                    </CardContent>
                </Card>

            ))
        )
    }

    return (
        <div
            className="flex flexRow height_less_header"
            style={{
                width: "100%",
                alignItems: "flex-start"
            }}>
            <LeftSideBar />
            <div
                className="height_less_header"
                style={{
                    padding: 10,
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    flex: 1,
                    left: 0,
                    top: 0,
                    maxWidth: '100%',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    overflowY: "auto",
                    backgroundColor: "#0001"
                }}>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1
                }}>
                    <Card style={styleCardsColumns} >
                        <CardContent style={styleCardsColumnsContent}>
                            <div style={styleTitleColumn}>FILA</div>
                            <ItemColumns dados={content_columns} filter="fila" />
                        </CardContent>
                    </Card>

                    <Card style={styleCardsColumns} >
                        <CardContent style={styleCardsColumnsContent}>
                            <div style={styleTitleColumn}>EM EXECUÇÃO</div>
                            <ItemColumns dados={content_columns} filter="em_execucao" />
                        </CardContent>
                    </Card>

                    <Card style={styleCardsColumns} >
                        <CardContent style={styleCardsColumnsContent}>
                            <div style={styleTitleColumn}>CONCLUÍDO</div>
                            <ItemColumns dados={content_columns} filter="concluido" />
                        </CardContent>
                    </Card>
                </div>

            </div>
        </div >

    );
}

export default ProjectsPage;


