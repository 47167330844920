import React, { useEffect, useState, useRef } from 'react';

import './Outages.css'

import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { api_bd } from '../../services/Api'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'
import customerData from '../../atoms/customers.atom'
import { outagesListTicketState } from '../../atoms/outages_state.atom';



/*
        interacoes: [
            {
                tipo_interacao: { type: String, default: "" },

                acionou_plantonista: { type: String, default: "" },
                hora_acionamento: { type: String, default: "" },
                quem_foi_acionado: { type: String, default: "" },

                foi_escalonado: { type: String, default: "" },
                hora_escalonado: { type: String, default: "" },
                pra_quem_foi_escalonado: { type: String, default: "" },
                porque_nao_foi_escalonado: { type: String, default: "" },
                previsao_para_solucao: { type: String, default: "" },

                foi_solucionado: { type: String, default: "" },
                qual_foi_solucao: { type: String, default: "" },
                hora_solucao: { type: String, default: "" },
                porque_nao_foi_solucionado: { type: String, default: "" },
                feedback_do_suporte: { type: String, default: "" },

                userId: { type: String, default: "" },
                userName: { type: String, default: "" },
                userLevel: { type: String, default: "" },
                data: { type: Date, default: () => Date.now() }
            }
        ]
*/

function NewInteraction(props) {
    const refComp1 = useRef()
    const refComp2 = useRef()
    const [actualComp, setActualComp] = useState(1)

    const profile_user = useRecoilValue(profileData)
    const clientList = useRecoilValue(customerData)

    const [buttonActive, setButtonActive] = useState(true)

    const setEventList = useSetRecoilState(outagesListTicketState)
    const buscarEventos = async () => {
        let tmpEv = await api_bd.get(`/outages/status/aberto`)
        setEventList(tmpEv.data)
    }

    const [dataInteraction, setDataInteraction] = useState({
        tipo_interacao: "Selecione o tipo da interação"
    })

    const handle_data = (key, value) => {
        let data = { ...dataInteraction }
        data[key] = value
        setDataInteraction(data)
    }

    useEffect(() => {
        // console.log('ActualComp', actualComp)
        switch (actualComp) {
            case 1:
                refComp2.current.classList.remove('show_in')
                refComp2.current.classList.add('hidden_out')

                refComp1.current.classList.add('show_in')
                refComp1.current.classList.remove('hidden_out')
                break;

            case 2:
                refComp1.current.classList.remove('show_in')
                refComp1.current.classList.add('hidden_out')

                refComp2.current.classList.add('show_in')
                refComp2.current.classList.remove('hidden_out')
                break;

            default:
                break;
        }
    }, [actualComp])


    //Formulário OK
    const FormContato = () => {
        const [dataContato, setDataContato] = useState({
            acionou_plantonista: '',
            hora_acionamento: '',
            quem_foi_acionado: '',
            hora_contato: '',
            quem_foi_contatado: ''
        })

        return (
            <>
                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="quem_foi_contatado"
                        label="Quem foi contatado?"
                        onChange={e => {
                            // handle_data("quem_foi_acionado", e.target.value)
                            setDataContato(old => {
                                return ({ ...old, quem_foi_contatado: e.target.value })
                            })
                        }}
                        value={dataContato.quem_foi_contatado}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="time"
                        style={{ width: '100%' }}
                        id="hora_contato"
                        label="Hora do contato?"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            // handle_data("hora_acionamento", e.target.value)
                            setDataContato(old => {
                                return ({ ...old, hora_contato: e.target.value })
                            })
                        }}
                        value={dataContato.hora_contato}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="text"
                        multiline
                        style={{ width: '100%' }}
                        id="obs_interaction"
                        label="Observações"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            // handle_data("hora_acionamento", e.target.value)
                            setDataContato(old => {
                                return ({ ...old, observacao: e.target.value })
                            })
                        }}
                        value={dataContato.observacao}
                    />
                </div>


                <div
                    className='buttom fixToCenter'
                    style={{ marginTop: 20, padding: 10, width: 100 }}
                    onClick={async () => {
                        if (dataContato.acionou_plantonista === 'NÃO') {
                            let filters = ['acionou_plantonista', 'hora_acionamento', 'quem_foi_acionado']
                            for (let filtro in filters) {
                                // console.log(filters[filtro])
                                if (dataContato[filters[filtro]] === '') {
                                    return (alert('Todos os campos devem ser preenchidos'))
                                }
                            }
                            // console.log({ ...dataInteraction, ...dataContato })
                            // props.callbackfn()
                        } else if (dataContato.acionou_plantonista === 'SIM') {
                            let filters = ['acionou_plantonista', 'hora_acionamento']
                            for (let filtro in filters) {
                                // console.log(filters[filtro])
                                if (dataContato[filters[filtro]] === '') {
                                    return (alert('Todos os campos devem ser preenchidos'))
                                }
                            }
                            // console.log(props.ev._id, { ...dataInteraction, ...dataContato })
                        }
                        // console.log(props.ev._id, { ...dataInteraction, ...dataContato })
                        if (buttonActive) {
                            setButtonActive(false)
                            await api_bd.put(`/outages/addinteract/${props.ev._id}`, {
                                ...dataInteraction,
                                ...dataContato,
                                userId: profile_user.id,
                                userName: profile_user.nome,
                                userLevel: profile_user.nivel
                            })
                            await buscarEventos()
                            props.callbackfn()
                        }
                    }}
                >
                    Salvar
                </div>
            </>
        )
    }


    //Formulário OK
    const FormEscalonamento = () => {
        const [dataEscalonamento, setDataEscalonamento] = useState({
            foi_escalonado: "",
            hora_escalonado: "",
            pra_quem_foi_escalonado: "",
            porque_nao_foi_escalonado: "",
            previsao_para_solucao: ""
        })

        return (
            <>
                <FormControl style={{ width: '100%' }}>
                    <InputLabel htmlFor="select-event-type">Foi Escalonado Para Alguém?</InputLabel>
                    <Select
                        defaultValue="Foi Escalonado Para Alguém?"
                        labelId="select-interaction-foi_escalonado"
                        id="foi_escalonado"
                        style={{ width: '100%' }}
                        onChange={e => {
                            // handle_data('foi_escalonado', e.target.value)
                            if (e.target.value !== dataEscalonamento.foi_escalonado) {
                                if (e.target.value === 'SIM') {
                                    let tempData = { ...dataEscalonamento }
                                    if (tempData.hasOwnProperty('porque_nao_foi_escalonado')) {
                                        delete tempData.porque_nao_foi_escalonado
                                        setDataEscalonamento(old => {
                                            return ({ ...old, foi_escalonado: e.target.value })
                                        })
                                    } else {
                                        setDataEscalonamento(old => {
                                            return ({ ...old, foi_escalonado: e.target.value })
                                        })
                                    }
                                } else if (e.target.value === 'NÃO') {
                                    let tempData = { ...dataEscalonamento }
                                    if (tempData.hasOwnProperty('pra_quem_foi_escalonado')) {
                                        delete tempData.pra_quem_foi_escalonado
                                        setDataEscalonamento(old => {
                                            return ({ ...old, foi_escalonado: e.target.value })
                                        })
                                    } else {
                                        setDataEscalonamento(old => {
                                            return ({ ...old, foi_escalonado: e.target.value })
                                        })
                                    }
                                }
                            }
                        }}
                        value={dataInteraction.acionou_plantonista}
                        required={true}
                    >
                        <MenuItem value="Foi Escalonado Para Alguém?">Foi Escalonado Para Alguém?</MenuItem>
                        <MenuItem value="SIM">SIM</MenuItem>
                        <MenuItem value="NÃO">NÃO</MenuItem>
                    </Select>
                </FormControl>

                {dataEscalonamento.foi_escalonado !== '' &&
                    <>
                        {dataEscalonamento.foi_escalonado === 'SIM' &&
                            <>
                                <div style={{ width: '100%', marginTop: 10 }}>
                                    <TextField
                                        type="time"
                                        style={{ width: '100%' }}
                                        id="hora_escalonado"
                                        label="Que horas foi escalonado?"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={e => {
                                            // handle_data("hora_escalonado", e.target.value)
                                            setDataEscalonamento(old => {
                                                return ({ ...old, hora_escalonado: e.target.value })
                                            })
                                        }}
                                        value={dataEscalonamento.hora_escalonado}
                                    />
                                </div>

                                <div style={{ width: '100%', marginTop: 10 }}>
                                    <TextField
                                        style={{ width: '100%' }}
                                        id="pra_quem_foi_escalonado"
                                        label="Para quem foi escalonado?"
                                        onChange={e => {
                                            // handle_data("pra_quem_foi_escalonado", e.target.value)
                                            setDataEscalonamento(old => {
                                                return ({ ...old, pra_quem_foi_escalonado: e.target.value })
                                            })
                                        }}
                                        value={dataEscalonamento.pra_quem_foi_escalonado}
                                    />
                                </div>
                            </>
                        }

                        {dataEscalonamento.foi_escalonado === 'NÃO' &&
                            <>
                                <div style={{ width: '100%', marginTop: 10 }}>
                                    <TextField
                                        style={{ width: '100%' }}
                                        id="porque_nao_foi_escalonado"
                                        label="Por que não foi escalonado?"
                                        onChange={e => {
                                            // handle_data("porque_nao_foi_escalonado", e.target.value)
                                            setDataEscalonamento(old => {
                                                return ({ ...old, porque_nao_foi_escalonado: e.target.value })
                                            })
                                        }}
                                        value={dataEscalonamento.porque_nao_foi_escalonado}
                                    />
                                </div>
                                {/* <div style={{ width: '100%', marginTop: 10 }}>
                                    <TextField
                                        type="time"
                                        style={{ width: '100%' }}
                                        id="previsao_para_solucao"
                                        label="Previsão para solução"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={e => {
                                            // handle_data("previsao_para_solucao", e.target.value)
                                            setDataEscalonamento(old => {
                                                return ({ ...old, previsao_para_solucao: e.target.value })
                                            })
                                        }}
                                        value={dataEscalonamento.previsao_para_solucao}
                                    />
                                </div> */}
                            </>
                        }

                        <div
                            className='buttom fixToCenter'
                            style={{ marginTop: 20, padding: 10, width: 100 }}
                            onClick={async () => {
                                if (dataEscalonamento.foi_escalonado === 'NÃO') {
                                    let filters = ['foi_escalonado', 'porque_nao_foi_escalonado']
                                    for (let filtro in filters) {
                                        // console.log(filters[filtro])
                                        if (dataEscalonamento[filters[filtro]] === '') {
                                            return (alert('Todos os campos devem ser preenchidos'))
                                        }
                                    }
                                    // console.log({ ...dataInteraction, ...dataEscalonamento })

                                } else if (dataEscalonamento.foi_escalonado === 'SIM') {
                                    let filters = ['foi_escalonado', 'hora_escalonado', 'pra_quem_foi_escalonado']
                                    for (let filtro in filters) {
                                        // console.log(filters[filtro])
                                        if (dataEscalonamento[filters[filtro]] === '') {
                                            return (alert('Todos os campos devem ser preenchidos'))
                                        }
                                    }
                                    // console.log(props.ev._id, { ...dataInteraction, ...dataEscalonamento })

                                }
                                if (buttonActive) {
                                    setButtonActive(false)
                                    await api_bd.put(`/outages/addinteract/${props.ev._id}`, {
                                        ...dataInteraction,
                                        ...dataEscalonamento,
                                        userId: profile_user.id,
                                        userName: profile_user.nome,
                                        userLevel: profile_user.nivel
                                    })
                                    await buscarEventos()
                                    props.callbackfn()
                                }
                            }}
                        >
                            Salvar
                        </div>
                    </>
                }
            </>
        )
    }



    const TicketOption = ({ option }) => {
        // console.log('Data Ticket TIPO EVENTO: ',dataInteraction.tipo_evento)
        switch (option) {
            case 'Contato':
                return <FormContato />
                break;
            case 'Escalonamento':
                return <FormEscalonamento />
                break;

            default:
                return <div />
                break;
        }
    }

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%', padding: 30, overflow: 'hidden' }}>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', height: 60, width: '100%', marginBottom: 40 }}>
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 60, height: 60, maxWidth: 60, maxHeight: 60 }}>
                    <div style={{ position: 'relative', display: 'flex', flexDirection: 'row' }}>
                        <span className="material-icons left_icon" style={{ position: 'absolute', marginLeft: -7, marginTop: -17, fontSize: '3.5em' }}>add</span>
                        <span className="material-icons left_icon" style={{ fontSize: '4em', marginLeft: 13, marginRight: 0, marginTop: 3 }}>edit_square</span>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginLeft: 20 }}>
                    <span style={{ margin: 0, fontSize: '2.5em' }}>Adicionar Nova Interação</span>
                </div>
            </div>
            <div ref={refComp1}
                className='hidden_out'
                style={{
                    position: 'absolute',
                    width: '90%'
                }}
            >
                <FormControl style={{ width: '100%' }}>
                    <InputLabel htmlFor="select-event-type">TIPO DE EVENTO</InputLabel>
                    <Select
                        defaultValue="Selecione o tipo da interação"
                        labelId="select-interaction-type"
                        id="situacao"
                        style={{ width: '100%' }}
                        onChange={e => {
                            handle_data('tipo_interacao', e.target.value)
                        }}
                        required={true}
                    >
                        <MenuItem value="Selecione o tipo da interação">Selecione o tipo da interação</MenuItem>
                        <MenuItem value="Contato">Contato</MenuItem>
                        <MenuItem value="Escalonamento">Escalonamento</MenuItem>
                    </Select>
                </FormControl>

                <div
                    className='buttom fixToCenter'
                    style={{ marginTop: 20, padding: 10, width: 100 }}
                    onClick={() => {
                        // console.log(dataInteraction)
                        if (dataInteraction.tipo_interacao === "Selecione o tipo da interação") {
                            return (alert('Selecione um tipo de interação para continuar'))
                        } else {
                            setActualComp(old => {
                                return old === 2 ? 1 : old + 1
                            })
                        }
                    }}
                >
                    Avançar
                </div>

            </div>


            <div ref={refComp2}
                className='hidden_out'
                style={{
                    position: 'absolute',
                    width: '90%'
                }}
            >
                <TicketOption option={dataInteraction.tipo_interacao} />
            </div>
        </div>
    );
}

export default NewInteraction;