import React, {
    useState,
    useEffect
} from 'react';

import * as IoIcons from 'react-icons/io';

import { Card } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import TextField from '@material-ui/core/TextField';

import Styles, { Fab } from '../components/Styles'
import { api_bd, getUserData } from '../services/Api'
import FormDataSuportCalendar from '../components/Forms/FormDataSuport_Calendar'


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

function Suport_Calendar(props) {
    const styles = Styles()

    const [data_new, set_data_new] = useState(null)

    const [device_height, set_device_height] = useState(null)
    const [device_width, set_device_width] = useState(null)

    const [show_modal, set_show_modal] = useState(false)
    const [content_modal, set_content_modal] = useState(null)

    const [list_plantonista, set_list_plantonista] = useState([])

    const [filtered_list, set_filtered_list] = useState([])

    const [user_data, set_user_data] = useState(undefined)

    const style_modal = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        flex: 1,
        backgroundColor: '#00000055',
        left: 0,
        top: 0,
        position: 'absolute',
        float: 'left',
        minWidth: '100%',
        maxWidth: '100%',
        height: `${device_height}px`,
        maxHeight: `${device_height}px`,
        justifyContent: 'center',
        alignItems: 'flex-start',
        overflowY: "auto"

    }


    const carregar_estacoes = async () => {
        const d = new Date();
        const lista = await api_bd.get(`/suport_calendar/${d.getMonth()}`)
        set_list_plantonista(lista.data)
    }


    useEffect(() => {
        // (async () => {

        // })()

        const dw = document.documentElement.clientWidth
        const dh = document.documentElement.clientHeight
        set_device_width(dw)
        set_device_height(dh)

    }, [])

    useEffect(() => {
        // if (data_new != null) {
        //     props.history.push({
        //         pathname: 'clientdetails', 
        //         state: { data_client: data_new }
        //     });
        // }
        // (async ()=>{
        //     
        // })()

        set_show_modal(false)

    }, [data_new])

    const handleClose = () => {
        set_show_modal(false);
    };

    const Modal = () => {
        return (
            <div style={style_modal} >
                <div style={{ width: '100%' }}>
                    <div style={{
                        width: '100%',
                        // backgroundColor:'yellow', 
                        maxHeight: 60
                    }}>
                        <IoIcons.IoIosCloseCircleOutline
                            size={60}
                            onClick={handleClose}
                            style={{
                                color: 'white',
                                marginLeft: '75%',
                                paddingTop: 15,
                                cursor: 'pointer'
                            }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            // backgroundColor:'yellow',
                            justifyContent: 'center'
                        }}
                    >
                        {
                            content_modal != null &&
                            (() => {
                                switch (content_modal) {
                                    case 'add_suport_day':
                                        return <FormDataSuportCalendar width={device_width < device_height ? "80%" : "50%"} set_new_data={set_data_new} />
                                        break;
                                    default:
                                        return <></>
                                        break;
                                }
                            })()
                        }
                    </div>
                </div>
            </div>)
    }

    return (
        <div className="flex flexRow" style={{ width: "100%", minHeight: device_height - 50, maxHeight: device_height - 50 }}>

            {show_modal &&
                <Modal />
            }


            <div
                className="flex flexCol"
                style={{
                    padding: 30,
                    width: "100%",
                    overflowY: "auto"
                }}
            >
                <div
                    className="flex flexCol"
                    style={{
                        padding: 30,
                        width: "100%",
                        overflowY: "auto"
                    }}
                >
                </div>
                {/* <iframe
                    src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FFortaleza&amp;src=ZmVsaXBlQG1peHBiLm5ldA&amp;src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&amp;src=bWl4cGIubmV0X2NsYXNzcm9vbTJjMTgzNDg2QGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&amp;src=ZWc5NnNmcGg5N2Q4bmNrMzh1amM2dWczcGNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=cHQtYnIuYnJhemlsaWFuI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&amp;src=cHQuYnJhemlsaWFuI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%23F09300&amp;color=%23a2845e&amp;color=%230B8043&amp;color=%239E69AF&amp;color=%23D50000&amp;color=%237986CB&amp;mode=AGENDA&amp;showNav=0&amp;showTabs=0&amp;showCalendars=0&amp;showTitle=0"
                    style={{ border: 0, width: 800, height: 600, frameborder: 0, scrolling: "no" }}></iframe> */}

                {/* <a href="tel:5583994035950">Ligar Para Felipe</a> */}
                {show_modal == false &&
                    <List component="nav" aria-label="main mailbox folders">
                        {filtered_list.map((el, index) => (
                            <ListItem button>
                                <>
                                    <ListItemIcon key={`${el.codigo_estacao}${index}`}>
                                        <span className="material-icons">business</span>
                                    </ListItemIcon>
                                    <ListItemText primary={el.codigo_estacao} secondary={el.nome} />
                                </>
                            </ListItem>
                        ))}
                    </List>
                }
            </div>

            {user_data && (user_data.glpiactiveprofile.name == 'Técnico' || user_data.glpiactiveprofile.name == 'Super-Admin') &&
                <Fab
                    alt="Cadastrar Plantão"
                    className="flex center-h center-v"
                    onClick={() => {
                        set_content_modal('add_suport_day')
                        set_show_modal(true)
                    }}
                >
                    <span style={{ fontSize: 25, color: "white", marginLeft: -3, marginRight: 2 }}>+</span>
                    <span className="material-icons" style={{ fontSize: 33, color: "white", marginLeft: -3 }}>business</span>
                </Fab>
            }
        </div >
    );
}

export default Suport_Calendar


