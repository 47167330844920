import React, { useEffect } from 'react';


import Button from '@material-ui/core/Button';

import Styles, { primary_color } from '../Styles';

import { api_bd } from '../../services/Api';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import navigationState from '../../atoms/navigate_state.atom';


function AprovacaoGestor(props) {
    const styles = Styles();

    const [navigation_state, set_navigation_state] = useRecoilState(navigationState)

    useEffect(() => {
        //console.log(props.loggeduser)
    }, [])

    return (
        <div style={{ width: '100%', backgroundColor: '#fff', borderRadius: 5, padding: 50 }}>
            <div style={{ fontSize: '1.3em', fontWeight: 'bold', marginBottom: 30 }}>Lista de Etapas</div>
            {navigation_state.data.flowNodes.steps.map((step, index) =>
                <div key={`${index}${step.step}`} style={{ marginTop: 10, marginBottom: 10 }}>
                    <b>{index + 1} - {step.title.toUpperCase()}</b>
                    (Tipo: {step.type.toUpperCase()}, criado em {new Date(step.date).toLocaleDateString()})
                    {(step.type !== 'Execução') && (navigation_state.data?.flowNodes?.validateSteps?.state === 'aproved') && <b> APROVADO</b>}
                    {(step.type !== 'Execução') && (navigation_state.data?.flowNodes?.validateSteps?.state === 'rejected') && <b> REPROVADO</b>}
                    {(step.type !== 'Execução') && (!navigation_state.data?.flowNodes?.validateSteps?.state.match(/aproved|rejected/)) && <b> PENDENTE</b>}

                    {(step.type === 'Execução') && (navigation_state.data?.flowNodes?.validateExecutionSteps?.state === 'aproved') && <b> APROVADO</b>}
                    {(step.type === 'Execução') && (navigation_state.data?.flowNodes?.validateExecutionSteps?.state === 'rejected') && <b> REPROVADO</b>}
                    {(step.type === 'Execução') && (!navigation_state.data?.flowNodes?.validateExecutionSteps?.state.match(/aproved|rejected/)) && <b> PENDENTE</b>}
                </div>
            )}

            <hr style={{ marginTop: 15, marginBottom: 15 }} />

            {navigation_state.data?.flowNodes.steps && navigation_state.data?.flowNodes.steps.length > 0 &&
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 30 }}>
                    <Button
                        className={styles.btn_submit}
                        variant="contained"
                        size="small"
                        style={{ width: 200, height: 40, backgroundColor: primary_color, color: '#fff', marginRight: 5 }}
                        onClick={async () => {

                            let dataToSend
                            if (navigation_state.data?.flowNodes?.validateSteps?.state === 'aproved') {
                                dataToSend = {
                                    ...navigation_state.data.flowNodes,
                                    actualState: 4,
                                    validateExecutionSteps: {
                                        state: 'aproved',
                                        user: {
                                            id: props.loggeduser.id,
                                            name: props.loggeduser.nome,
                                        },
                                        validationDate: Date.now(),
                                    },
                                }
                            } else {
                                dataToSend = {
                                    ...navigation_state.data.flowNodes,
                                    actualState: 2,
                                    validateSteps: {
                                        state: 'aproved',
                                        user: {
                                            id: props.loggeduser.id,
                                            name: props.loggeduser.nome,
                                        },
                                        validationDate: Date.now(),
                                    },
                                }
                            }


                            let response = await api_bd.put(`/tickets/${props.idTicket}`, {
                                situacao: { titulo: 'em_tratativa', descricao: '' },
                                flowNodes: { ...dataToSend }
                            })
                            set_navigation_state({
                                page_content: 'ticket_detail',
                                data: { ...response.data[0] }
                            })

                            props.set_situacao_chamado(response.data[0].situacao)

                            props.setShowModal(false)

                        }}>
                        Aprovar {navigation_state.data?.flowNodes?.validateSteps?.state !== 'aproved' ? 'PLANEJAMENTO' : 'EXECUÇÃO'}
                    </Button>

                    <Button
                        className={styles.btn_submit}
                        variant="contained"
                        size="small"
                        style={{ width: 200, height: 40, backgroundColor: 'red', color: '#fff', marginRight: 5 }}
                        onClick={async () => {

                            let motivoDevolucao = window.prompt('Motivo da devolução')
                            if (motivoDevolucao === '') {
                                return window.alert('O motivo da devolução não pode ser vazio.')
                            }


                            let dataToSend
                            if (navigation_state.data?.flowNodes?.validateSteps?.state === 'aproved') {
                                dataToSend = {
                                    ...navigation_state.data.flowNodes,
                                    actualState: 2,
                                    validateExecutionSteps: {
                                        state: 'rejected',
                                        notes: motivoDevolucao,
                                        user: {
                                            id: props.loggeduser.id,
                                            name: props.loggeduser.nome,
                                        },
                                        validationDate: Date.now(),
                                    }
                                }
                            } else {
                                dataToSend = {
                                    ...navigation_state.data.flowNodes,
                                    actualState: 2,
                                    validateSteps: {
                                        state: 'rejected',
                                        notes: motivoDevolucao,
                                        user: {
                                            id: props.loggeduser.id,
                                            name: props.loggeduser.nome,
                                        },
                                        validationDate: Date.now(),
                                    }
                                }
                            }


                            let response = await api_bd.put(`/tickets/${props.idTicket}`, {
                                flowNodes: { ...dataToSend }
                            })
                            set_navigation_state({
                                page_content: 'ticket_detail',
                                data: { ...response.data[0] }
                            })

                            props.setShowModal(false)

                        }}>
                        Reprovar {navigation_state.data?.flowNodes?.validateSteps?.state !== 'aproved' ? 'PLANEJAMENTO' : 'EXECUÇÃO'}
                    </Button>
                </div>
            }
        </div >
    )
}

export default AprovacaoGestor;