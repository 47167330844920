import React from 'react';

import styles from './styles.module.css'
import { useState } from 'react';

function LineData ({ dados, handleData }){

    const [lineData, setLineData] = useState(dados)
    
    const getTimeZone = (date) => {
        const temp = new Date(new Date(date).getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split('.')[0]
        return temp
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 20,
                border: '1px solid #c3c3c3',
                borderRadius: '5px',
                padding: 10,
            }}
        >
            <div className={styles.lineTimes}>
                <div>
                    Inicio:
                </div>
                <div>
                    <input
                        value={getTimeZone(lineData.inicio)} type="datetime-local"
                        onChange={(e)=>{
                            setLineData(old => {
                                return({...old, inicio: e.target.value})
                            })
                        }}
                        onBlur={(e) => {
                            // handleData(dados._id, 'inicio', e.target.value)
                            handleData(lineData._id, 'inicio', e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.lineTimes}>
                <div>
                    Fim:
                </div>
                <div>
                    <input
                        value={getTimeZone(lineData.fim)} type="datetime-local"
                        onChange={(e)=>{
                            setLineData(old => {
                                return({...old, fim: e.target.value})
                            })
                        }}
                        onBlur={(e) => {
                            // handleData(dados._id, 'fim', e.target.value)
                            handleData(lineData._id, 'fim', e.target.value)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default LineData;