import React from 'react';

const { backend_ticket_files } = require('../../config.json')

function ShowAnexo(props) {

    const testFile = (anexo) => {
        switch (true) {
            case /\.jpeg|\.jpg|\.png|\.gif|\.pjpeg|\.svg/i.test(anexo.filename):
                return (
                    <img
                        style={{ width: 150, height: 150, objectFit: 'contain', cursor: 'pointer' }}
                        src={`${backend_ticket_files}/${anexo.filename}`}
                        onClick={() => {
                            const imghtml = `<img style="width: 100%; height: 100%; object-fit: contain" src="${backend_ticket_files}/${anexo.filename}"/>`
                            const nWindow = window.open('', '', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,left=100,top=100,width=420,height=420')
                            nWindow.document.write(imghtml)

                        }}
                    />
                )
                break

            case /\.pdf/i.test(anexo.filename):
                return (
                    <div
                        style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                        onClick={() => {
                            const imghtml = `
                            <style>
                                *{
                                    padding: 0px;
                                    margin: 0px;
                                }
                            </style>
                            <iframe style="width: 100%;height: 100%" src="${backend_ticket_files}/${anexo.filename}"</iframe>`
                            const nWindow = window.open('', '', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,left=100,top=100,width=720,height=820')
                            nWindow.document.write(imghtml)

                        }}
                    >
                        <span style={{ fontSize: '2.5em' }} className="material-icons">picture_as_pdf</span> {anexo.filename.split('-')[1]}
                    </div>
                )
                break

            case /\.mp4|\.ogg|\.3gp|\.mpeg/i.test(anexo.filename):
                return (
                    <video width="320" height="240" controls>
                        <source src={`${backend_ticket_files}/${anexo.filename}`} />
                    </video>
                )
                break

            case /\.aac|\.m4a|\.mp3/i.test(anexo.filename):
                return (
                    <audio controls style={{ width: 320 }}>
                        <source src={`${backend_ticket_files}/${anexo.filename}`} />
                    </audio>
                )
                break

            case /\.xls|\.xlsx|\.csv|\.doc|\.docx|\.txt/i.test(anexo.filename):
                return (
                    <div style={{ display: 'flex', flexDirection: 'row' }}
                        onClick={() => {
                            const imghtml = `
                            <style>
                                *{
                                    padding: 0px;
                                    margin: 0px;
                                }
                            </style>
                            <iframe style="width: 100%;height: 100%" src="${backend_ticket_files}/${anexo.filename}"</iframe>`
                            const nWindow = window.open('', '', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,left=100,top=100,width=720,height=820')
                            nWindow.document.write(imghtml)

                        }}
                    ><span style={{ fontSize: '2.5em' }} className="material-icons">description</span> {anexo.filename.split('-')[1]}</div>
                )
                break

            default:
                return (<></>)
                break;
        }
    }



    return (
        <div>
            {testFile(props.anexo)}
        </div>

    );
}

export default ShowAnexo;