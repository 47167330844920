
import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'

import Chart from "react-google-charts";

import { api_bd } from '../../services/Api';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';

import axios from 'axios' // remover

import './DashBoard1.css';

import CustomBarChart from '../CustomBarChart';

function DashBoard1() {

    const [dados, setDados] = useState({})
    const [dadosGraficos, setDadosGraficos] = useState({})
    const [periodo, setPeriodo] = useState({})
    const profile_user = useRecoilValue(profileData)

    const [usersImages, setUsersImages] = useState([])


    const shuffle = function (array) {
        var currentIndex = array.length;
        var temporaryValue, randomIndex;

        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    };

    const generateColor = function () {
        var hex = ['a', 'b', 'c', 'd', 'e', 'f', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        var color = '#';
        for (var i = 0; i < 6; i++) {
            shuffle(hex);
            color += hex[0];
        }
        return color;

    };

    const msToTime = (ms) => {
        var hours = ms / (1000 * 60 * 60);
        var absoluteHours = Math.floor(hours);
        var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

        //Get remainder from hours and convert to minutes
        var minutes = (hours - absoluteHours) * 60;
        var absoluteMinutes = Math.floor(minutes);
        var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

        //Get remainder from minutes and convert to seconds
        var seconds = (minutes - absoluteMinutes) * 60;
        var absoluteSeconds = Math.floor(seconds);
        var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

        return h + ':' + m + ':' + s;
    }

    const options = {
        plugins: {
            datalabels: {
                display: true,
                align: 'bottom',
                backgroundColor: '#ccc',
                borderRadius: 3,
                font: {
                    size: 18,
                }
            },
        }
    }



    useEffect(() => {
        const date = new Date(), y = date.getFullYear(), m = date.getMonth();
        const initDate = new Date(y, m, 1);
        const endDate = new Date(y, m + 1, 0);

        setPeriodo(old => {
            return ({
                mes: m,
                ano: y
            })
        })


        // console.log(document.querySelector('#chart_analistas').getElementsByTagName('text'))

    }, [])



    function sortChartDataAnalistas(a, b) {
        if (a.tempo < b.tempo) {
            return -1;
        }
        if (a.tempo > b.tempo) {
            return 1;
        }
        return 0;
    }

    function sortChartDataClientes(a, b) {
        if (a.tempoTotal_ms < b.tempoTotal_ms) {
            return -1;
        }
        if (a.tempoTotal_ms > b.tempoTotal_ms) {
            return 1;
        }
        return 0;
    }


    useEffect(() => {
        if (dados.clientes) {
            let clientes = dados.clientes
            let analistas = dados.analistas

            let tmpClientes = []
            let tmpAnalistas = []

            let tmpAnalistasChartJS = {
                labels: [],
                values: [],
                images: []
            }

            let tmpDadosGraficos = {
                ticksTempoTotalPorCliente: [],
                tempoTotalPorCliente: [['Cliente', 'Tempo']],
                chamadosAbertoNoMesPorCliente: [],
                chamadosAntigosPorCliente: [],
                analistas: [['Analista', 'Tempo']],
                ticksAnalistas: []
            }

            for (let key in clientes) {
                tmpClientes.push({ nome: key, ...clientes[key] })
            }
            tmpClientes = tmpClientes.sort(sortChartDataClientes)
            for (let key in tmpClientes) {
                // let nome = key
                // tmpDadosGraficos.tempoTotalPorCliente.push([nome, { v: clientes[key].tempoTotal_ms, f: `${msToTime(clientes[key].tempoTotal_ms)}hs` }])
                // tmpDadosGraficos.ticksTempoTotalPorCliente.push({
                //     v: clientes[key].tempoTotal_ms,
                //     f: msToTime(clientes[key].tempoTotal_ms) + 'hs'
                // });


                // tmpDadosGraficos.chamadosAbertoNoMesPorCliente.push([nome, { v: clientes[key].abertosNoMes, f: `${clientes[key].abertosNoMes}` }])
                // tmpDadosGraficos.chamadosAntigosPorCliente.push([nome, { v: clientes[key].antigosTratadosNoMes, f: `${clientes[key].antigosTratadosNoMes}` }])

                tmpDadosGraficos.tempoTotalPorCliente.push([tmpClientes[key].nome, { v: tmpClientes[key].tempoTotal_ms, f: `${msToTime(tmpClientes[key].tempoTotal_ms)}hs` }])
                tmpDadosGraficos.ticksTempoTotalPorCliente.push({
                    v: tmpClientes[key].tempoTotal_ms,
                    f: msToTime(tmpClientes[key].tempoTotal_ms) + 'hs'
                });


                tmpDadosGraficos.chamadosAbertoNoMesPorCliente.push([tmpClientes[key].nome, { v: tmpClientes[key].abertosNoMes, f: `${tmpClientes[key].abertosNoMes}` }])
                tmpDadosGraficos.chamadosAntigosPorCliente.push([tmpClientes[key].nome, { v: tmpClientes[key].antigosTratadosNoMes, f: `${tmpClientes[key].antigosTratadosNoMes}` }])
            }



            for (let key in analistas) {
                let nome = key
                tmpAnalistas.push({
                    nome,
                    image: `https://sgi.intecsolutions.com.br:4000/files/${analistas[key].avatar_uri}`,
                    tempo: analistas[key].tempoTotal,
                    tempof: `${analistas[key].tempoTotalF}hs`
                })
            }
            tmpAnalistas = tmpAnalistas.sort(sortChartDataAnalistas)
            for (let key in tmpAnalistas) {
                //console.log(tmpAnalistas[key])
                let { nome, tempo, tempof, image } = tmpAnalistas[key]
                tmpDadosGraficos.analistas.push([nome, { v: tempo, f: `${tempof}` }])

                tmpDadosGraficos.ticksAnalistas.push({
                    v: tempo,
                    f: tempof + 'hs'
                });

                // if(tmpAnalistasChartJS[nome]){
                //     tmpAnalistasChartJS[nome].datasets.push(tempo)
                // }else{
                //     tmpAnalistasChartJS[nome]=
                // }


                // let cor = generateColor()

                tmpAnalistasChartJS.labels.push(nome)
                tmpAnalistasChartJS.values.push(tempo)
                tmpAnalistasChartJS.images.push(new Image().src = image)
                // tmpAnalistasChartJS.images.push(
                // tmpAnalistasChartJS.teste.push({
                //     label: nome,
                //     data: [tempo],
                //     borderColor: '#3366cc88',
                //     backgroundColor: '#3366cc',
                // })
            }

            setUsersImages(tmpAnalistasChartJS.images)
            tmpDadosGraficos['charjs'] = {
                labels: tmpAnalistasChartJS.labels,
                datasets: [
                    {
                        label: 'Analistas',
                        data: tmpAnalistasChartJS.values,
                        borderColor: '#3366cc88',
                        backgroundColor: '#3366cc',
                    }
                ]
            }

            // tmpDadosGraficos['charjs'] = {
            //     //labels: tmpAnalistasChartJS.labels,
            //     datasets: tmpAnalistasChartJS.teste
            // }

            // console.log(tmpDadosGraficos['charjs'])

            setDadosGraficos(tmpDadosGraficos)
        }

    }, [dados])


    useEffect(() => {
        if (Object.keys(periodo).length > 0) {
            (async () => {
                // console.log('OPAAAA')

                const response = await api_bd.post(`/relatorios/state/todos`, periodo)// await axios.get('http://localhost:9500')//
                // console.log(response.data)

                if (Object.keys(response.data.clientes).length > 0) {
                    setDados(response.data)
                } else {
                    setDadosGraficos({ error: "Sem dados" })
                }

                // const clientes = response.data.clientes


            })()

            //alert(JSON.stringify(periodo))
        }
    }, [periodo])



    return (
        <div div className='div_content'>
            <div className='card'>
                <div className='form_group'>
                    <div className='form_group_label'>Selecione o Mês:</div>
                    <div className='form_group_content'>
                        <select
                            value={periodo.mes}
                            onChange={(e) => {
                                //console.log({ ...periodo, mes: parseInt(e.target.value) })
                                setPeriodo({
                                    ...periodo,
                                    mes: parseInt(e.target.value)
                                })
                            }}
                        >
                            <option value="0">Janeiro</option>
                            <option value="1">Fevereiro</option>
                            <option value="2">Março</option>
                            <option value="3">Abril</option>
                            <option value="4">Maio</option>
                            <option value="5">Junho</option>
                            <option value="6">Julho</option>
                            <option value="7">Agosto</option>
                            <option value="8">Setembro</option>
                            <option value="9">Outubro</option>
                            <option value="10">Novembro</option>
                            <option value="11">Dezembro</option>
                        </select>
                    </div>
                </div>

                <div className='form_group' style={{ marginLeft: 20 }}>
                    <div className='form_group_label'>Selecione o Ano:</div>
                    <div className='form_group_content'>
                        <input
                            type='number'
                            min="2021"
                            value={periodo.ano}
                            onChange={(e) => {
                                // console.log({ ...periodo, ano: parseInt(e.target.value) })
                                setPeriodo({
                                    ...periodo,
                                    ano: parseInt(e.target.value)
                                })
                            }}
                        />
                    </div>
                </div>
            </div>

            {Object.keys(dadosGraficos).length === 0 &&
                <div style={{ display: 'flex', width: '100%', minHeight: '60vh', justifyContent: 'center', alignItems: 'center', fontSize: '2em', fontWeight: 'bolder' }}>
                    Carregando Dados....
                </div>
            }

            {(Object.keys(dadosGraficos).length === 1) && dadosGraficos.error &&
                <div style={{ display: 'flex', width: '100%', minHeight: '60vh', justifyContent: 'center', alignItems: 'center', fontSize: '2em', fontWeight: 'bolder' }}>
                    Não Foram encontradas informações para o período selecionado!
                </div>
            }

            {Object.keys(dadosGraficos).length > 1 &&
                <>
                    <div className='card'>
                        <div className='pizza_chart'>
                            <div style={{ width: '50%', maxHidth: '50%', order: 1 }}>
                                <Chart
                                    // width={'500px'}
                                    height={'300px'}
                                    chartType="PieChart"
                                    loader={<div>Loading Chart</div>}
                                    data={dadosGraficos.chamadosAbertoNoMesPorCliente}
                                    options={{
                                        title: 'Qtd de chamados abertos no mês',
                                    }}
                                    rootProps={{ 'data-testid': '1' }}
                                />
                            </div>

                            <div style={{ width: '50%', maxHidth: '50%', order: 2 }}>
                                <Chart
                                    // width={'500px'}
                                    height={'300px'}
                                    chartType="PieChart"
                                    loader={<div>Loading Chart</div>}
                                    data={dadosGraficos.chamadosAntigosPorCliente}
                                    options={{
                                        title: 'Qtd de chamados Antigos tratados no mês',
                                    }}
                                    rootProps={{ 'data-testid': '1' }}
                                />
                            </div>
                        </div>
                    </div>



                    <div className='card'>
                        <div style={{ width: '99%', maxWidth: '99%', order: 3 }}>
                            <Chart
                                // width={400}
                                height={500}
                                chartType="ColumnChart"
                                loader={<div>Loading Chart</div>}
                                data={dadosGraficos.tempoTotalPorCliente}
                                options={{
                                    title: 'Tempo Total por cliente',
                                    chartArea: { width: '80%' },
                                    hAxis: {
                                        title: 'Cliente',

                                    },
                                    vAxis: {
                                        title: 'Tempo',
                                        minValue: 0,
                                        ticks: dadosGraficos.ticksTempoTotalPorCliente,
                                        format: 'none'
                                    },
                                }}

                            />
                        </div>
                    </div>


                    {/* 
                    <div className='card'>
                        <div style={{ width: '99%', maxWidth: '99%', order: 3 }} id="chart_analistas">
                            <Chart
                                // width={400}
                                height={500}
                                chartType="ColumnChart"
                                loader={<div>Loading Chart</div>}
                                data={dadosGraficos.analistas}
                                options={{
                                    title: 'Tempo Total por Analista',
                                    chartArea: { width: '80%' },
                                    hAxis: {
                                        title: 'Analista',
                                        direction: '1'
                                    },
                                    vAxis: {
                                        title: 'Tempo',
                                        minValue: 0,
                                        ticks: dadosGraficos.ticksAnalistas,
                                        format: 'none'
                                    },
                                }}

                            />
                        </div>
                    </div> */}

                    <div className='card'>
                        {usersImages.length > 0 &&
                            <CustomBarChart dados={{ images: usersImages, chartjs: dadosGraficos.charjs }} />
                        }
                    </div>
                </>
            }
        </div>
    );
}

export default DashBoard1;