import React, {
    useState,
    useEffect
} from 'react';

import FormDataClient from '../components/Forms/FormDataClient'

import Styles from '../components/Styles'


function CadClient(props) {
    const styles = Styles()

    const [data_new_client, set_data_new_client] = useState(null)
    const [device_height, set_device_height] = useState(null)

    useEffect(()=>{
        const dh = document.documentElement.clientHeight
        set_device_height(dh)
    },[])

    useEffect(() => {
        if (data_new_client != null) {
            props.history.push({
                pathname: 'clientdetails', 
                state: { data_client: data_new_client }
            });
        }
    }, [data_new_client])

    return (
        <div className={`${styles.div_content} height_less_header`} style={{overflowY: 'auto'}}>
            {data_new_client == null &&
                <FormDataClient width="47%" set_data_new_client={set_data_new_client} />
            }
        </div>
    );
}

export default CadClient;