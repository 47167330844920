import React, {
    useState,
    useEffect
} from 'react';


import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import NewLogo from '../../assets/logo_sgi_intec.png'
import Logo_mini from '../../assets/logo_mini_intec.png'
import Bg_logo from '../../assets/intec_bg_login.jpg'

import Toast from '../Toast';

import { api_bd } from '../../services/Api'

import { containerRoot } from './styles.module.css'

const Styles = makeStyles((theme) => ({
    div_root: {
        // paddingTop: '10px',
        // paddingLeft: "5%",
        // paddingRight: "5%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        maxHeight: "100vh",
        backgroundImage: `url(${Bg_logo})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        overflowY: 'auto',
        marginBottom: 30
    },

    btn_submit: {
        marginTop: '20px',
        marginLeft: '0px',
        paddingRight: "25px",
        marginBottom: "20px",
        fontSize: 18
    },

    card: {
        width: '60%',
        minHeight: '80vh',
        borderRadius: 10,
        paddingTop: 5,
        minWidth: 275,
        color: "#3c5ca6",
        marginBottom: 30,
    },

    div_content: {
        display: 'flex',
        flexDirection: 'column',
        padding: 20
    },

    form_group_styled: {
        width: '100%'
    },

    form_group_label: {
        fontSize: 18
    },

    form_group_content: {
        border: 'none',
        backgroundColor: '#fff'
    },

    form_input: {
        padding: 10,
        border: 'none',
        minWidth: '50%'
    },
    btn_number: {
        marginTop: 10,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 15,
        paddingRight: 15,
        marginBottom: 20,
        cursor: 'pointer',
        fontWeight: 'bold',
        fontSize: 18,
        color: "#3c5ca6",
        borderColor: "#c3c3c388",
        borderRadius: 5
    }
}));

function PesquisaSatisfacao(props) {
    const styles = Styles();

    const [dados, setDados] = useState({
        _id: '',
        titulo: 'Carregando....',
        cod_id: 'Carregando....'
    })

    const [showToast1, setShowToast1] = useState(false)
    const [showToast2, setShowToast2] = useState(false)

    const [messageToast, setMessageToast] = useState('OBIGADO POR SUA CONTRIBUIÇÃO')

    const [pesquisa, setPesquisa] = useState({
        transparencia_do_analista: false,
        solucao_implantada: false,
        eficiencia_analista: false,
        aspecto_geral: false,
        preenchido: false
    })

    let callExecuted = false

    /**
     * pesquisa_satisfacao: {
            transparencia_do_analista: { type: Number, default: 0 },
            solucao_implantada: { type: Number, default: 0 },
            eficiencia_analista: { type: Number, default: 0 },
            aspecto_geral: { type: Number, default: 0 },
            preenchido: { type: Boolean, default: false }
        },
     */


    useEffect(() => {
        if (callExecuted === false) {
            (async () => {
                try {
                    const idTicket = window.location.pathname.split('avaliacao/')[1]
                    if (idTicket) {
                        const dados = await api_bd.post('/tickets/datapesquisasatisfacao', { idt: idTicket })
                        // console.log(window.location.pathname.split('pesquisasatifacao/')[1])
                        // console.log(dados.data)
                        setDados({ ...dados.data })
                    }
                } catch (error) {
                    if (error.message.match(/status code 409/)) {
                        alert('Esta pesquisa ja foi realizada!\nObrigado!!!')
                        window.location.href = '/'
                    }
                    // console.log('ERRRROOOR', JSON.stringify(error))
                }
            })()
            callExecuted = true
        }
    }, [])

    return (
        <div
            className='flex justify-center min-h-screen max-h-screen overflow-y-auto p-4 md:p-8'
            style={{
                backgroundImage: `url(${Bg_logo})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}
        >
            {showToast1 &&
                <Toast>
                    <div style={{ width: '100%', fontSize: 18, fontWeight: 'bold', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        {messageToast}
                    </div>
                </Toast>
            }

            {showToast2 &&
                <Toast />
            }

            <div
                className='flex flex-col p-0 w-[90%] md:w-3/4 lg:w-2/4'
            >
                <div className='bg-intec-500 w-full text-center rounded-t'>
                    <img src={Logo_mini} style={{ margin: 10, maxHeight: 40 }} />
                </div>

                <div className='bg-white p-10'>
                    <div className='flex flex-col'>
                        <div className={styles.form_group_styled} style={{ fontSize: '2em', fontWeight: 'bold' }}>
                            PESQUISA DE SATISFAÇÃO
                        </div>

                        <div className={styles.form_group_styled} style={{ marginTop: 20, fontSize: 18 }}>
                            Ticket ID: <b style={{ fontSize: 18 }}>{dados.cod_id}</b><br />
                            Título: <b style={{ fontSize: 18 }}>{dados.titulo}</b><br />
                        </div>

                        <div className={styles.form_group_styled} style={{ marginTop: 60 }}>
                            <div className={styles.form_group_label}>Como você classifica a transparência do analista que lhe atendeu ?</div>
                            <div className={styles.form_group_content} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <button className={styles.btn_number + ' hover:bg-intec-100'}
                                    style={{ backgroundColor: pesquisa?.transparencia_do_analista === 1 ? '#16f516' : '' }}
                                    onClick={() => {
                                        setPesquisa(old => {
                                            return { ...old, transparencia_do_analista: 1 }
                                        })
                                    }}
                                >1</button>
                                <button className={styles.btn_number + ' hover:bg-intec-100'}
                                    style={{ backgroundColor: pesquisa?.transparencia_do_analista === 2 ? '#16f516' : '' }}
                                    onClick={() => {
                                        setPesquisa(old => {
                                            return { ...old, transparencia_do_analista: 2 }
                                        })
                                    }}
                                >2</button>
                                <button className={styles.btn_number + ' hover:bg-intec-100'}
                                    style={{ backgroundColor: pesquisa?.transparencia_do_analista === 3 ? '#16f516' : '' }}
                                    onClick={() => {
                                        setPesquisa(old => {
                                            return { ...old, transparencia_do_analista: 3 }
                                        })
                                    }}
                                >3</button>
                                <button className={styles.btn_number + ' hover:bg-intec-100'}
                                    style={{ backgroundColor: pesquisa?.transparencia_do_analista === 4 ? '#16f516' : '' }}
                                    onClick={() => {
                                        setPesquisa(old => {
                                            return { ...old, transparencia_do_analista: 4 }
                                        })
                                    }}
                                >4</button>
                                <button className={styles.btn_number + ' hover:bg-intec-100'}
                                    style={{ backgroundColor: pesquisa?.transparencia_do_analista === 5 ? '#16f516' : '' }}
                                    onClick={() => {
                                        setPesquisa(old => {
                                            return { ...old, transparencia_do_analista: 5 }
                                        })
                                    }}
                                >5</button>
                            </div>
                            <hr />
                        </div>

                        <div className={styles.form_group_styled} style={{ marginTop: 30 }}>
                            <div className={styles.form_group_label}>De acordo com a classificação, qual a nota você daria para a solução implantada ?</div>
                            <div className={styles.form_group_content} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <button className={styles.btn_number + ' hover:bg-intec-100'}
                                    style={{ backgroundColor: pesquisa?.solucao_implantada === 1 ? '#16f516' : '' }}
                                    onClick={() => {
                                        setPesquisa(old => {
                                            return { ...old, solucao_implantada: 1 }
                                        })
                                    }}
                                >1</button>
                                <button className={styles.btn_number + ' hover:bg-intec-100'}
                                    style={{ backgroundColor: pesquisa?.solucao_implantada === 2 ? '#16f516' : '' }}
                                    onClick={() => {
                                        setPesquisa(old => {
                                            return { ...old, solucao_implantada: 2 }
                                        })
                                    }}
                                >2</button>
                                <button className={styles.btn_number + ' hover:bg-intec-100'}
                                    style={{ backgroundColor: pesquisa?.solucao_implantada === 3 ? '#16f516' : '' }}
                                    onClick={() => {
                                        setPesquisa(old => {
                                            return { ...old, solucao_implantada: 3 }
                                        })
                                    }}
                                >3</button>
                                <button className={styles.btn_number + ' hover:bg-intec-100'}
                                    style={{ backgroundColor: pesquisa?.solucao_implantada === 4 ? '#16f516' : '' }}
                                    onClick={() => {
                                        setPesquisa(old => {
                                            return { ...old, solucao_implantada: 4 }
                                        })
                                    }}
                                >4</button>
                                <button className={styles.btn_number + ' hover:bg-intec-100'}
                                    style={{ backgroundColor: pesquisa?.solucao_implantada === 5 ? '#16f516' : '' }}
                                    onClick={() => {
                                        setPesquisa(old => {
                                            return { ...old, solucao_implantada: 5 }
                                        })
                                    }}
                                >5</button>
                            </div>
                            <hr />
                        </div>

                        <div className={styles.form_group_styled} style={{ marginTop: 30 }}>
                            <div className={styles.form_group_label}>Como você classifica a eficiência do analista neste ticket ?</div>
                            <div className={styles.form_group_content} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <button className={styles.btn_number + ' hover:bg-intec-100'}
                                    style={{ backgroundColor: pesquisa?.eficiencia_analista === 1 ? '#16f516' : '' }}
                                    onClick={() => {
                                        setPesquisa(old => {
                                            return { ...old, eficiencia_analista: 1 }
                                        })
                                    }}
                                >1</button>
                                <button className={styles.btn_number + ' hover:bg-intec-100'}
                                    style={{ backgroundColor: pesquisa?.eficiencia_analista === 2 ? '#16f516' : '' }}
                                    onClick={() => {
                                        setPesquisa(old => {
                                            return { ...old, eficiencia_analista: 2 }
                                        })
                                    }}
                                >2</button>
                                <button className={styles.btn_number + ' hover:bg-intec-100'}
                                    style={{ backgroundColor: pesquisa?.eficiencia_analista === 3 ? '#16f516' : '' }}
                                    onClick={() => {
                                        setPesquisa(old => {
                                            return { ...old, eficiencia_analista: 3 }
                                        })
                                    }}
                                >3</button>
                                <button className={styles.btn_number + ' hover:bg-intec-100'}
                                    style={{ backgroundColor: pesquisa?.eficiencia_analista === 4 ? '#16f516' : '' }}
                                    onClick={() => {
                                        setPesquisa(old => {
                                            return { ...old, eficiencia_analista: 4 }
                                        })
                                    }}
                                >4</button>
                                <button className={styles.btn_number + ' hover:bg-intec-100'}
                                    style={{ backgroundColor: pesquisa?.eficiencia_analista === 5 ? '#16f516' : '' }}
                                    onClick={() => {
                                        setPesquisa(old => {
                                            return { ...old, eficiencia_analista: 5 }
                                        })
                                    }}
                                >5</button>
                            </div>
                            <hr />
                        </div>

                        <div className={styles.form_group_styled} style={{ marginTop: 30 }}>
                            <div className={styles.form_group_label}>Em um aspecto geral, qual nota você daria para o atendimento do analista ?</div>
                            <div className={styles.form_group_content} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <button className={styles.btn_number + ' hover:bg-intec-100'}
                                    style={{ backgroundColor: pesquisa?.aspecto_geral === 1 ? '#16f516' : '' }}
                                    onClick={() => {
                                        setPesquisa(old => {
                                            return { ...old, aspecto_geral: 1 }
                                        })
                                    }}
                                >1</button>
                                <button className={styles.btn_number + ' hover:bg-intec-100'}
                                    style={{ backgroundColor: pesquisa?.aspecto_geral === 2 ? '#16f516' : '' }}
                                    onClick={() => {
                                        setPesquisa(old => {
                                            return { ...old, aspecto_geral: 2 }
                                        })
                                    }}
                                >2</button>
                                <button className={styles.btn_number + ' hover:bg-intec-100'}
                                    style={{ backgroundColor: pesquisa?.aspecto_geral === 3 ? '#16f516' : '' }}
                                    onClick={() => {
                                        setPesquisa(old => {
                                            return { ...old, aspecto_geral: 3 }
                                        })
                                    }}
                                >3</button>
                                <button className={styles.btn_number + ' hover:bg-intec-100'}
                                    style={{ backgroundColor: pesquisa?.aspecto_geral === 4 ? '#16f516' : '' }}
                                    onClick={() => {
                                        setPesquisa(old => {
                                            return { ...old, aspecto_geral: 4 }
                                        })
                                    }}
                                >4</button>
                                <button className={styles.btn_number + ' hover:bg-intec-100'}
                                    style={{ backgroundColor: pesquisa?.aspecto_geral === 5 ? '#16f516' : '' }}
                                    onClick={() => {
                                        setPesquisa(old => {
                                            return { ...old, aspecto_geral: 5 }
                                        })
                                    }}
                                >5</button>
                            </div>
                            <hr />
                        </div>


                        <div className={styles.form_group_styled} style={{ marginTop: 30, textAlign: 'center' }}>
                            <Button
                                className={styles.btn_submit}
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={async e => {

                                    for (let k in pesquisa) {
                                        if (k !== 'preenchido') {
                                            if ((pesquisa[k] === false) || (pesquisa[k] === '')) {
                                                alert('Por favor preencha todos os campos.')
                                                return
                                            }
                                        }
                                    }

                                    const infoPesquisa = { ...pesquisa, preenchido: true }

                                    // console.log(infoPesquisa)
                                    const resp = await api_bd.put(`/tickets/savepesquisasatisfacao/${dados._id}-intecsolutions`, infoPesquisa)
                                    if (resp?.data?.message) {
                                        setMessageToast(resp?.data?.message)
                                        setShowToast1(true)
                                        setTimeout(() => {
                                            window.location.href = '/'
                                        }, 5000)
                                    }
                                }}
                            >
                                Salvar
                            </Button>
                        </div>
                    </div>
                </div>
                <div className='mt-40'>&nbsp;</div>
            </div>
        </div >
    );
}

export default PesquisaSatisfacao;