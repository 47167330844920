import axios from 'axios';
import { useEffect, useState } from 'react';

function AnalystReport(props) {
	const [analystData, setAnalystData] = useState([])

	const formatToExport = (_data) => {
		//Lines
		let xlsx = [
			['Cliente:', _data.customer_name], //line 1
			['Tempo Total:', _data.tempoTotalF], //line 2
			[],//line 3
			['Ticket', 'Título', 'Tempo', 'Analistas'], //line 4
		]

		let temp = Object.keys(_data.analistas).map(key => {
			return ({
				..._data.analistas[key]
			})
		})

		temp = temp.sort((a, b) => {
			if (a.analista < b.analista) {
				return -1;
			}
			if (a.analista > b.analista) {
				return 1;
			}
			return 0;
		})

		temp.forEach(el => {
			Object.keys(el.ticketsPerDate).length > 0 && Object.keys(el.ticketsPerDate).map((key) => {
				Object.keys(el.ticketsPerDate[key]).map((key2) => {
					xlsx.push([
						key2,
						el.ticketsPerDate[key][key2].titulo,
						el.ticketsPerDate[key][key2].tempoF,
						el.ticketsPerDate[key][key2].respTec.join(', ')
					])
				})
			})
		})

		// console.log('result', xlsx)
		exportToCsv(xlsx, `Report ${_data.customer_name}`)
	}

	// var Results = [array1, array2];
	const exportToCsv = function (_arrOfArr, title) {
		let CsvString = "";
		_arrOfArr.forEach(function (RowItem, RowIndex) {
			RowItem.forEach(function (ColItem, ColIndex) {
				CsvString += ColItem + ",";
			});
			CsvString += "\r\n";
		});
		CsvString = "data:application/csv," + encodeURIComponent(CsvString);
		let x = document.createElement("A");
		x.setAttribute("href", CsvString);
		x.setAttribute("download", `${title}.csv`);
		document.body.appendChild(x);
		x.click();
	};

	useEffect(() => {
		// if (analystData.length === 0) {
			// const date = new Date()
			// let firstDay = String(new Date(date.getFullYear(), date.getMonth(), 1).getDate()).padStart(2, '0')
			// let lastDay = String(new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()).padStart(2, '0')
			// const dateString = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-`

			// axios.get(`https://apisgi.intecsolutions.com.br/nrapi/analystreport/${dateString}${firstDay}/${dateString}${lastDay}`)
			axios.get(`https://apisgi.intecsolutions.com.br/nrapi/analystreport/${props.interval.datei.toLocaleDateString('en-ca')}/${props.interval.datef.toLocaleDateString('en-ca')}`)
				.then(resp => {
					// console.log(resp.data)
					if (resp.data && Object.keys(resp.data).length > 0) {
						let temp = Object.keys(resp.data).map(key => {
							return ({
								id: key,
								...resp.data[key]
							})
						})

						temp = temp.sort((a, b) => {
							if (a.analista < b.analista) {
								return -1;
							}
							if (a.analista > b.analista) {
								return 1;
							}
							return 0;
						})

						setAnalystData(temp)
					}
				})
		// }
	}, [props.interval])

	return (
		<div className='w-full h-full'>
			{analystData.length > 0 && analystData.map(el => (
				<div key={`${el.id}`} className='flex flex-row my-3 space-x-3 w-full rounded border border-intec-200 p-5 bg-white'>
					<div>
						<img className='rounded-full w-[70px] h-[70px] object-cover' src={`https://apisgi.intecsolutions.com.br/api/users/getuserimg2/${el.id}`} />
					</div>
					<div>
						<p className='font-bold'>{el.analista}</p>
						<p className='text-intec-500 text-md'>Tempo Total: {el.tempoTotalF}</p>

						<details>
							<summary className='text-intec-500'>Detalhes</summary>
							{Object.keys(el.ticketsPerDate).length > 0 && Object.keys(el.ticketsPerDate).map((key, index) => (
								<ul key={`${key}_${index}`} className='ml-8 text-intec-500'>
									<li className='mb-3'>{new Date(`${key} 00:00:00`).toLocaleDateString()}<br />
										<ul className='ml-8'>
											{Object.keys(el.ticketsPerDate[key]).map((key2, index2) => (
												<div key={`${key2}_${index2}`}>
													<li>Ticket: {key2} ({el.ticketsPerDate[key][key2].cliente})</li>
													<li>Título: {el.ticketsPerDate[key][key2].titulo}</li>
													<li>Tempo 1º play: {el.ticketsPerDate[key][key2].tef}</li> {/* TEMPO para o 1º play no ticket */}
													<li className='mb-3'>Tempo: {el.ticketsPerDate[key][key2].tempoF}</li>
												</div>
											))}
										</ul>
									</li>
								</ul>
							))}
						</details>
					</div>
				</div>
			))}
		</div>
	);
}

export default AnalystReport;