import React, { useState } from 'react';

import styles from './styles.module.css'

import ViewPost from './ViewPost';

import ShowAnexo from './ShowAnexo';

import { api_bd } from '../../services/Api';

const configs = require('../../config.json')

function RowPosts(props) {
    const notify = props.notify

    const [expanded, setExpanded] = useState(false)
    const [showCommentInput, setShowCommentInput] = useState(false)
    const maxHeightList = 800

    const add3Dots = (string, limit) => {
        var dots = "...";
        if (string.length > limit) {
            // you can also use substr instead of substring
            string = string.substring(0, limit) + dots;
        }

        return string;
    }

    return (
        <div
            className={`${styles['notificationsRow']}`}
            style={{
                backgroundColor: (notify.read.indexOf(props.user_profile.id) > -1) ? '#fff' : '#3c68ce',
                color: (notify.read.indexOf(props.user_profile.id) > -1) ? '#3c68ce' : '#fff',
                // maxHeight: expanded ? maxHeightList : 95,
                paddingBottom: 10
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    width: '100%',
                    flexDirection: 'row',
                    gap: 10,
                    borderBottom: (expanded || showCommentInput) ? '1px solid #c3c3c3' : 'none',
                    paddingBottom: (expanded || showCommentInput) ? 20 : 0
                }}
            >
                <div>
                    <img className={styles['notificationsRowAvatar']} style={{ borderRadius: '50%', objectFit: 'cover' }} src={`${configs.api_backend_address}users/getuserimg/${notify.user.username}`} />
                </div>
                <div
                    style={{
                        color: (notify.read.indexOf(props.user_profile.id) > -1) ? '#3c68ce' : '#fff',
                        flexGrow: 1,
                        borderLeft: '3px solid #3c68ce',
                        paddingLeft: 10
                    }}
                >
                    <p><b>{notify.user.nome}</b> - {new Date(notify.date).toLocaleDateString()} às {new Date(notify.date).toLocaleTimeString()}</p>
                    {notify.comments.length > 0
                        ? <p style={{ marginTop: 3 }}><b>{String(notify.comments.length).padStart(2, '0')} Comentário{notify.comments.length > 1 ? 's' : ''}</b></p>
                        : <p style={{ marginTop: 3 }}><b>Nenhum Comentário</b></p>}
                    <div style={{ width: '100%' }}>
                        <div>
                            <p style={{ marginTop: 10, whiteSpace: 'pre-wrap' }}>{notify.text}</p>
                        </div>
                    </div>
                    {notify.anexos && notify.anexos.length > 0 &&
                        <div style={{ width: '100%' }}>
                            {notify.anexos.map((anexo) => (
                                <div>
                                    <ShowAnexo anexo={anexo} />
                                </div>
                            ))}
                        </div>
                    }

                    <div
                        onClick={() => {
                            setExpanded(!expanded)
                        }}
                        style={{
                            display: 'flex',
                            // borderRadius: 10,
                            flexDirection: 'row',
                            // justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 10,
                            height: 30,
                            minWidth: 120,
                            maxWidth: 120,
                            // border: '1px solid #0001',
                            // boxShadow: '2px 2px 10px #0006',
                            cursor: 'pointer',
                        }}
                    >
                        <span style={{ margin: 0 }}>Ver mais</span>
                        <span style={{ margin: 0, marginRight: -10 }} className={`material-icons`}>{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</span>
                    </div>
                </div>

                {/* BTN DELETE */}
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 20 }}>
                    {((notify.user.username === props.user_profile.username) || (props.user_profile.nivel.match(/super/i))) &&
                        <span
                            style={{
                                float: 'right',
                                cursor: 'pointer',
                                fontSize: '2.2em',
                                marginRight: 10
                            }}
                            className={`material-icons`}
                            onClick={() => {
                                // delete('/teamnotes/dellcomment/:idnote/:idcomment'
                                let confirmation = window.confirm('Deseja realmente excluir esta postagem?')
                                if (confirmation) {
                                    api_bd.delete(`/homeposts/dellnote/${notify._id}`, {})
                                        .then(resp => {
                                            //props.setShowModal(false)
                                        })
                                        .catch(e => {
                                            alert('Ocorreu um erro ao tentar excluir, tente novamente mais tarde.')
                                        })
                                }
                            }}
                        >
                            delete
                        </span>
                    }
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                    cursor: 'pointer'
                }}
                onClick={()=>{
                    setShowCommentInput(true)
                }}
            >
                <span className={`material-icons`} style={{margin: 0}}>keyboard_return</span><span style={{margin: 0, marginTop: 4, marginLeft: 5}}>Responder</span>
            </div>

            {/* COMMENTS */}
            <ViewPost 
            idNote={notify._id} 
            user_profile={props.user_profile} 
            maxHeightList={maxHeightList} 
            expanded={expanded} 
            showCommentInput={showCommentInput} 
            setShowCommentInput={setShowCommentInput}
            setExpanded={setExpanded}
            />

        </div>
    );
}

export default RowPosts;