import React, { useState, useEffect, useRef } from 'react';

import './Outages.css';

import { api_bd } from '../../services/Api'

import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'
import customerData from '../../atoms/customers.atom'
import { outagesListTicketState, outagesListTickeClosedtState } from '../../atoms/outages_state.atom';

function CloseTicket(props) {
    const refComp1 = useRef()
    const refComp2 = useRef()
    const [actualComp, setActualComp] = useState(1)
    const [buttonEnabled, setButtonEnabled] = useState(false)

    const profile_user = useRecoilValue(profileData)
    const clientList = useRecoilValue(customerData)

    const setEventList = useSetRecoilState(outagesListTicketState)
    const setEventListClosed = useSetRecoilState(outagesListTickeClosedtState)

    const buscarEventos = async () => {
        let tmpEv = await api_bd.get(`/outages/status/aberto`)
        setEventList(tmpEv.data)
    }


    const buscarEventosFechados = async () => {
        let tmpEv = await api_bd.get(`/outages/status/fechado`)
        setEventListClosed(tmpEv.data)
    }

    // const [dataSolucaoTicket, setDataSolucaoTicket] = useState({
    //     foi_solucionado: "",
    //     hora_solucao: "",
    //     qual_foi_solucao: "",
    //     porque_nao_foi_solucionado: "",
    //     feedback_do_suporte: "",
    //     escalonar_diurno: false
    // })

    const date = new Date()

    const [dataSolucaoTicket, setDataSolucaoTicket] = useState({
        horario_fim: `${date.getFullYear()}-${String(date.getMonth()).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`,
        causa: "",
        solucao: "",
        observacao: "",
    })


    // useEffect(() => {
    //     console.log(props.ev)
    // }, [])



    const style_p = { marginTop: 5, marginBottom: 5 }
    const style_span = { marginLeft: 0, marginTop: 5, marginBottom: 5, width: '50%', fontSize: '1.3em' }
    const style_b = { fontSize: '1em' }

    const style_intera_span = { marginLeft: 0, marginTop: 5, marginBottom: 5, width: '50%', fontSize: '1em' }
    const style_intera_b = { fontSize: '1em' }

    useEffect(() => {
        (dataSolucaoTicket.foi_solucionado !== 'Selecione uma opção' && ((dataSolucaoTicket.hora_solucao !== '' && dataSolucaoTicket.qual_foi_solucao !== '') || dataSolucaoTicket.porque_nao_foi_solucionado !== '')) ? setButtonEnabled(true) : setButtonEnabled(false)
    }, [dataSolucaoTicket])

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%', padding: 30, overflow: 'hidden' }}>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', height: 60, width: '100%', marginBottom: 40 }}>
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 60, height: 60, maxWidth: 60, maxHeight: 60 }}>
                    <div style={{ position: 'relative', display: 'flex', flexDirection: 'row' }}>
                        <span className="material-icons left_icon" style={{ position: 'absolute', marginLeft: -7, marginTop: -17, fontSize: '3.5em' }}>done</span>
                        <span className="material-icons left_icon" style={{ fontSize: '4em', marginLeft: 13, marginRight: 0, marginTop: 3 }}>edit_square</span>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginLeft: 20 }}>
                    <span style={{ margin: 0, fontSize: '2.5em' }}>Finalizar Ticket</span>
                </div>
            </div>
            <div ref={refComp1}
                className='show_in'
                style={{
                    position: 'absolute',
                    width: '90%'
                }}
            >   
                <>
                    <div style={{ width: '100%', marginTop: 10 }}>
                        <TextField
                            type="datetime-local"
                            style={{ width: '100%' }}
                            id="hora_solucao"
                            label="Que horas foi solucionado?"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={e => {
                                setDataSolucaoTicket(old => {
                                    return ({ ...old, horario_fim: e.target.value })
                                })
                            }}
                            value={dataSolucaoTicket.hora_solucao}
                        />
                    </div>

                    <div style={{ width: '100%', marginTop: 10 }}>
                        <TextField
                            style={{ width: '100%' }}
                            id="causa"
                            label="Qual foi a causa?"
                            onChange={e => {
                                setDataSolucaoTicket(old => {
                                    return ({ ...old, causa: e.target.value })
                                })
                            }}
                            value={dataSolucaoTicket.hora_solucao}
                        />
                    </div>

                    <div style={{ width: '100%', marginTop: 10 }}>
                        <TextField
                            style={{ width: '100%' }}
                            id="solucao"
                            label="Qual foi a solução?"
                            onChange={e => {
                                setDataSolucaoTicket(old => {
                                    return ({ ...old, solucao: e.target.value })
                                })
                            }}
                            value={dataSolucaoTicket.hora_solucao}
                        />
                    </div>

                    <div style={{ width: '100%', marginTop: 10 }}>
                        <TextField
                            style={{ width: '100%' }}
                            id="solucao"
                            label="Observação"
                            onChange={e => {
                                setDataSolucaoTicket(old => {
                                    return ({ ...old, observacao: e.target.value })
                                })
                            }}
                            value={dataSolucaoTicket.hora_solucao}
                        />
                    </div>
                </>

                <div
                    className='buttom fixToCenter'
                    style={{ marginTop: 20, padding: 10, width: 100, backgroundColor: buttonEnabled ? '#3c5ca6' : '#c3c3c3' }}
                    onClick={async () => {
                        if (buttonEnabled) {
                            // console.log('ID: ', props.ev._id, 'Dados:', dataSolucaoTicket)
                            setButtonEnabled(false)
                            await api_bd.put(`/outages/${props.ev._id}`, {
                                ...dataSolucaoTicket,
                                status: 'fechado'
                            })
                            await api_bd.put(`/outages/addinteract/${props.ev._id}`, {
                                tipo_interacao: 'Solução',
                                userId: profile_user.id,
                                userName: profile_user.nome,
                                userLevel: profile_user.nivel
                            })
                            await buscarEventos()
                            await buscarEventosFechados()
                            props.callbackfn()
                        }
                    }}
                >
                    Salvar
                </div>

            </div>


        </div >
    );
}

export default CloseTicket;