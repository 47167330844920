import React, { useState, useEffect, memo } from 'react';

import './Outages.css'

import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import EventDetail from './OutageDetail';
import FormRompimento from './FormRompimento';
import FormEstacaoIndisponivel from './FormEstacaoIndisponivel';
import FormLinkComProblema from './FormLinkComProblema';
import FormClienteComProblema from './FormClienteComProblema';


import { api_bd } from '../../services/Api'

import { useRecoilState, useRecoilValue } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'
import customerData from '../../atoms/customers.atom'
import outagesComponentToShowState from '../../atoms/outages_state.atom'

import Modal from '../Modal';
// import NewTicket from './NewTicket';
import NewInteraction from './NewInteraction';
// import TicketList from './TicketList';

import { msToTime } from '../../util/fns';

// const { backend_files_address } = require('../../config.json')

function OutageRow({ ev, buscar_eventos }) {
    const [componentToShow, setComponentToShow] = useRecoilState(outagesComponentToShowState)
    const [evDetail, setEvDetail] = useState(null)
    const profile_user = useRecoilValue(profileData)

    const buscarEventos = buscar_eventos

    const startTreatment = async (_args) => {
        const { id, user } = _args
        const resp = await api_bd.put(`/outages/starttreatment/${id}`, {
            id_usuario: user.id,
            nivel: user.nivel,
            nome: user.nome,
            inicio: Date.now()
        })
        buscarEventos()
        // console.log(resp.status, resp.data)
    }

    const stopTreatment = async (_args) => {
        const { id, user } = _args
        const resp = await api_bd.put(`/outages/stoptreatment/${id}`, {
            id_usuario: user.id,
            nivel: user.nivel,
            nome: user.nome,
            fim: Date.now()
        })
        buscarEventos()
        // console.log(resp.status, resp.data)
    }

    const verifyIsRunning = (ev) => {
        return ev.tratativas.find(tr => (
            (tr.id_usuario === profile_user.id) && (tr.inicio !== null && tr.fim === null)
        )) ? true : false
    }

    const isRunning = verifyIsRunning(ev)

    const et = ev.tratativas.filter(tr => tr.fim !== null).reduce(function (accumulator, curValue) {
        return accumulator + curValue.elapsedTime
    }, 0)

    let elapsedTime = 0
    if (isRunning === false) {
        elapsedTime = msToTime(et)
    } else {
        let ini = ev.tratativas.filter(tr => tr.fim === null)[0].inicio
        let timeIni = new Date(ini).getTime()
        let timeEnd = new Date().getTime()
        elapsedTime = msToTime((et + (timeEnd - timeIni)))
    }

    const minutesToAlert = 60
    let blinkClass = ''

    if (ev.interacoes.length > 0) {
        blinkClass = (ev.status === 'aberto' && (((new Date().getTime() - new Date(ev.interacoes[ev.interacoes.length - 1].data).getTime() > (minutesToAlert * 60 * 1000)))) ? ' blink' : '')
    } else {
        blinkClass = (ev.status === 'aberto' && (((new Date().getTime() - new Date(ev.createdAt).getTime() > (minutesToAlert * 60 * 1000)))) ? ' blink' : '')
    }


    return (
        <div
            className={ev.evento_solucionado?.status === true ? 'card' : `card ${blinkClass}`}
            style={{
                width: '99%',
                marginLeft: 10,
                marginRight: 10,
                paddingTop: 10,
                paddingBottom: 15,
                marginRight: 3,
                marginLeft: 3,
                minHeight: 'auto',
                backgroundColor: ev.evento_solucionado?.status === true ? '#3c5ca6' : '#fff',
                color: ev.evento_solucionado?.status === true ? '#fff' : '#3c5ca6'
            }}>
            {/* LINE BUTTONS */}
            <div style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                borderBottom: `1px solid  ${ev.evento_solucionado?.status === true ? '#fff' : '#3c5ca6'}`,
                marginBottom: 10
            }}>

                <div style={{ display: 'flex', flexWrap: 'wrap', lineBreak: 'auto', flexDirection: 'row', width: ev.status === 'aberto' ? '70%' : '85%', alignItems: 'center', gap: 10, alignSelf: 'center', fontSize: '1.3em' }}>
                    Nº: {(ev.cod_id >= 0 && ev.cod_id < 10) ? '000' : (ev.cod_id >= 10 && ev.cod_id < 100) ? '00' : (ev.cod_id >= 100 && ev.cod_id < 1000) ? '0' : ''}{ev.cod_id} - {ev.tipo_evento} {ev.tipo_evento === 'Link Com Problema' ? '(' + ev.qual_o_problema + ')' : ''}
                </div>

                {/* Action Buttons */}
                <div style={{ display: 'flex', flexDirection: 'row', width: ev.status === 'aberto' ? '30%' : '15%', alignItems: 'center', gap: 12, justifyContent: 'flex-end' }}>
                    {ev.status === 'fechado' &&
                        <div style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setComponentToShow({
                                    component: 'rfo',
                                    data: {
                                        ...ev,
                                        profile_user
                                    }
                                })
                            }}
                        >
                            <span className="material-icons left_icon" style={{ margin: 0, fontSize: '2.2em' }}>visibility</span>
                        </div>
                    }


                    {ev.status !== 'fechado' &&
                        <div style={{ cursor: 'pointer', marginBottom: -2 }}
                            onClick={() => {
                                if (ev.evento_solucionado?.status === true) {
                                    const option = window.confirm('Deseja reverter o status do evento solucionado para aguardando solução?\n\nAo clicar em "OK" você reverte o status do evento.')
                                    if (option === true) {
                                        let dados = {
                                            evento_solucionado: {
                                                status: false,
                                                horario: '',
                                                userId: '',
                                                userName: '',
                                                userLevel: '',
                                            }
                                        }
                                        // console.log(dados)
                                        api_bd.put(`/outages/${ev._id}`, dados).then(resp => {
                                            buscarEventos()
                                        }).catch(error => {
                                            // console.log('update error', error)
                                        })
                                    }
                                } else {
                                    const option = window.confirm('O evento foi solucionado?\n\nAo clicar em "OK" você define o evento como concluído mas o ticket continuará aguardando a finalização')
                                    if (option === true) {
                                        let dados = {
                                            evento_solucionado: {
                                                status: true,
                                                horario: new Date(),
                                                userId: profile_user.id,
                                                userName: profile_user.nome,
                                                userLevel: profile_user.nivel,
                                            }
                                        }
                                        // console.log(dados)
                                        api_bd.put(`/outages/${ev._id}`, dados).then(resp => {
                                            buscarEventos()
                                        }).catch(error => {
                                            // console.log('update error', error)
                                        })
                                    }
                                }

                            }}
                        >
                            {ev.evento_solucionado?.status === true ? (
                                <span className="material-icons left_icon" style={{ margin: 0, fontSize: '2.2em' }}>restart_alt</span>
                            ) : (
                                <span className="material-icons left_icon" style={{ margin: 0, fontSize: '2.2em' }}>check_circle_outline</span>
                            )}
                        </div>
                    }


                    <div style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setComponentToShow({
                                component: 'edit-outage',
                                data: {
                                    ...ev,
                                    profile_user,
                                    buscarEventos: buscar_eventos
                                }
                            })
                        }}
                    >
                        <span className="material-icons left_icon" style={{ margin: 0, fontSize: '2.2em' }}>edit</span>
                    </div>


                    <div style={{ flexDirection: 'row', cursor: 'pointer', marginBottom: ev.interacoes.length > 0 ? -8 : -2 }}
                        onClick={() => {
                            setComponentToShow({
                                component: 'outageDetail',
                                data: ev
                            })
                        }}
                    >
                        {ev.interacoes.length > 0 &&
                            <div
                                className='default_color'
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: 20,
                                    height: 20,
                                    borderRadius: 10,
                                    position: 'absolute',
                                    marginLeft: 12,
                                    marginTop: -5,
                                    fontSize: '1.5em',
                                    color: ev.evento_solucionado?.status === true ? '#3c5ca6' : '#fff',//'#fff', 
                                    backgroundColor: ev.evento_solucionado?.status === true ? '#fff' : '#3c5ca6',//'#fff', 
                                    fontSize: 13
                                }}>{ev.interacoes.length}</div>
                        }
                        <span className="material-icons left_icon" style={{ marginLeft: 0, marginRight: 0, fontSize: '2.2em' }}>menu</span>
                    </div>


                    {ev.status !== 'fechado' &&
                        <>

                            <div style={{ flexDirection: 'row', cursor: 'pointer' }}
                                onClick={() => {
                                    setComponentToShow({
                                        component: 'newInteraction',
                                        data: ev
                                    })
                                }}

                            >
                                {/* <span className="material-icons left_icon" style={{ position: 'absolute', marginLeft: -7, marginTop: -5, fontSize: '1.5em' }}>add</span> */}
                                <span className="material-icons left_icon" style={{ fontSize: '2.5em', marginLeft: 3, marginRight: 0, marginTop: 3 }}>post_add</span>
                            </div>



                            <div style={{ cursor: 'pointer', marginBottom: -2 }}
                                onClick={() => {
                                    setComponentToShow({
                                        component: 'closeTicket',
                                        data: ev
                                    })
                                }}
                            >
                                <span className="material-icons left_icon" style={{ margin: 0, fontSize: '2.2em' }}>fact_check</span>
                            </div>
                        </>
                    }
                </div>


            </div>
            {/* END LINE BUTTONS */}

            {/* CONTENT ITEM */}
            <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', width: '100%', flexDirection: 'column' }}>
                {/* <span style={{ margin: 0, }}>Cliente: {ev.customer_name}</span> */}
                {ev.tipo_evento === 'Estação Indisponível' &&
                    <>
                        <p style={{ margin: 0, marginBottom: 3, }}><b>Estação:</b> {ev.estacao}</p>
                        <p style={{ margin: 0, marginBottom: 3, }}><b>Protocolo:</b> {ev.numero_protocolo}</p>
                    </>
                }
                {ev.tipo_evento === 'Falha Elétrica' &&
                    <>
                        <p style={{ margin: 0, marginBottom: 3, }}><b>Estação:</b> {ev.estacao}</p>
                        {/* <p style={{ margin: 0, marginBottom: 3, }}><b>Equipamento:</b> {ev.equipamento}</p> */}
                        <p style={{ margin: 0, marginBottom: 3, whiteSpace: 'pre-line' }}><b>Responsável:</b> {ev.responsavel_estacao}</p>
                        {ev.autonomia_eletrica && <p style={{ margin: 0, marginBottom: 3, whiteSpace: 'pre-line' }}><b>Autonomia da estação:</b> Aprox. {ev.autonomia_eletrica}hs</p>}
                    </>
                }
                {ev.tipo_evento === 'Link Com Problema' &&
                    <>
                        <p style={{ margin: 0, marginBottom: 3, }}><b>Fornecedor:</b> {ev.fornecedor}</p>
                        <p style={{ margin: 0, marginBottom: 3, }}><b>Protocolo:</b> {ev.numero_protocolo}</p>
                    </>
                }

                {ev.tipo_evento === 'Falha de OLT' &&
                    <>
                        <p style={{ margin: 0, marginBottom: 3, whiteSpace: 'pre-line' }}><b>Estação:</b> {ev.estacao}</p>
                        <p style={{ margin: 0, marginBottom: 3, whiteSpace: 'pre-line' }}><b>SLOT/PON:</b> {ev.slot_pon}</p>
                        <p style={{ margin: 0, marginBottom: 3, whiteSpace: 'pre-line' }}><b>Problema:</b> {ev.qual_o_problema}</p>
                        <p style={{ margin: 0, marginBottom: 3, whiteSpace: 'pre-line' }}><b>Responsável:</b> {ev.responsabilidade}</p>
                    </>
                }

                {ev.trecho_ponto_a ? <p style={{ margin: 0, marginBottom: 3, }}><b>Trecho Ponto A:</b> {ev.trecho_ponto_a}</p> : null}
                {ev.trecho_ponto_b ? <p style={{ margin: 0, marginBottom: 3, }}><b>Trecho Ponto B:</b> {ev.trecho_ponto_b}</p> : null}
                {ev.sub_trecho_ponto_a ? <p style={{ margin: 0, marginBottom: 3, }}><b>Sub Trecho Ponto A:</b> {ev.sub_trecho_ponto_a}</p> : null}
                {ev.sub_trecho_ponto_a ? <p style={{ margin: 0, marginBottom: 3, }}><b>Sub Trecho Ponto B:</b> {ev.sub_trecho_ponto_b}</p> : null}

                <p style={{ margin: 0, marginBottom: 3, }}><b>Horário do incidente:</b> {new Date(ev.horario_inicio).toLocaleDateString()} às {new Date(ev.horario_inicio).toLocaleTimeString()}</p>
                <p style={{ margin: 0, marginBottom: 3, }}><b>Ticket aberto em:</b> {new Date(ev.createdAt).toLocaleDateString()} às {new Date(ev.createdAt).toLocaleTimeString()}</p>
                {/* { ev.evento_solucionado?.status === true && <p style={{ margin: 0, marginBottom: 3, }}><b>Solução do evento:</b> {new Date(ev.evento_solucionado.horario).toLocaleDateString()} às {new Date(ev.evento_solucionado.horario).toLocaleTimeString()}</p> } */}
                <p style={{ margin: 0, marginBottom: 3, }}><b>Última interação:</b> {ev.interacoes.length > 0 ? new Date(ev.interacoes[ev.interacoes.length - 1].data).toLocaleDateString() + ' às ' + new Date(ev.interacoes[ev.interacoes.length - 1].data).toLocaleTimeString() : 'Nenhuma interação realizada neste ticket'}</p>
                {ev.status === 'fechado' && <p style={{ margin: 0, marginBottom: 3, }}><b>Ticket Finalizado em:</b> {new Date(ev.horario_fim).toLocaleDateString()} às {new Date(ev.horario_fim).toLocaleTimeString()}</p>}

            </div>
            {/* END CONTENT ITEM */}
        </div>
    )
}

export default memo(OutageRow);