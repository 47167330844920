import React from 'react';

import styles from './styles.module.css'

const configs = require('../../config.json')

function PopUpListaPosts(props) {

    const add3Dots = (string, limit) => {
        var dots = "...";
        if (string.length > limit) {
            // you can also use substr instead of substring
            string = string.substring(0, limit) + dots;
        }

        return string;
    }

    const notifications = props.notifications || []

    return (
        <div className={styles['notificationsPopupNotifications']}>
            {notifications.map(notify => (
                <div
                    key={`notify${notify._id}`}
                    className={styles['notificationsRow']}
                    style={{
                        backgroundColor: (notify.read.indexOf(props.user_profile.id) > -1) ? '#fff' : '#3c68ce',
                        color: (notify.read.indexOf(props.user_profile.id) > -1) ? '#3c68ce' : '#fff'
                    }}
                    onClick={() => {
                        props.setContentModal(notify)
                        props.setShowModal(true)
                        props.setShowPopUp(false)
                    }}
                >
                    <div>
                        <img style={{ borderRadius: '50%', objectFit: 'cover' }} src={`${configs.api_backend_address}users/getuserimg/${notify.user.username}`} />
                    </div>
                    <div style={{color: (notify.read.indexOf(props.user_profile.id) > -1) ? '#3c68ce' : '#fff'}}>
                        <p><b>Titulo:</b> {notify.title}</p>
                        <p style={{ marginTop: 3 }}><b>Mensagem:</b> {add3Dots(notify.text, 100)}</p>
                        <p style={{ marginTop: 3 }}><b>{String(notify.comments).padStart(2, '0')} Comentário{notify.comments > 1 ? 's' : ''}</b></p>
                        <p style={{ marginTop: 3 }}><b>Última Atualização: </b>{new Date(notify.updatedAt).toLocaleDateString()} às {new Date(notify.updatedAt).toLocaleTimeString()}</p>
                    </div>
                </div>
            ))}

            <div
                className={styles['notificationsRow']}
                style={{ alignItems: 'center' }}
                onClick={() => {
                    props.setShowTextInput(true)
                    props.setShowPopUp(false)
                }}
            ><span className={`material-icons`} style={{ color: '#3760c0' }}>add</span> Nova Mensagem</div>
        </div>
    )
}

export default PopUpListaPosts;