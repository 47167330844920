import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';

import Styles, { primary_color } from '../Styles';

import { api_bd } from '../../services/Api';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import navigationState from '../../atoms/navigate_state.atom';

function AuditoriaGestor(props) {

    const styles = Styles();

    const [navigation_state, set_navigation_state] = useRecoilState(navigationState)



    const [pontuation, setPontuation] = useState(
        props.allFlowNodes.map(step => {
            return {
                step: step.title,
                value: 0
            }
        })
    )

    useEffect(() => {
        if (navigation_state.data?.flowNodes?.validateAudit?.pontuation && navigation_state.data?.flowNodes?.validateAudit?.pontuation.length > 0) {
            setPontuation(navigation_state.data?.flowNodes?.validateAudit?.pontuation)
        }
    }, [])
    
    //setPontuation(tempPontuation)

    return (
        <div style={{ width: '100%', backgroundColor: '#fff', borderRadius: 5, padding: 50 }}>
            <div>
                <div style={{ fontSize: '1.3em', fontWeight: 'bold', marginBottom: 30 }}>Auditoria</div>
                <div style={{ fontSize: '1em', fontWeight: 'bold', marginBottom: 5 }}>Avaliação / Etapa</div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    {pontuation.map((step, index) => (
                        !step.step.match(/Aprovação do planejamento pelo gestor|Auditoria pelo gestor do setor|Atividade concluída/) &&
                        <div key={`${step.step}${index}`} style={{ marginTop: 5, marginBottom: 5 }}>
                            <input type='number' value={step.value} max={10} min={0} step={1} onChange={(ev) => {
                                setPontuation(old => {
                                    let oldData = [...old]
                                    for (let i in oldData) {
                                        if (oldData[i].step === step.step) {
                                            oldData[i].value = parseInt(ev.target.value)
                                        }
                                    }
                                    return oldData
                                })
                            }} /> - {step.step}
                        </div>
                    ))}
                </div>

                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 30 }}>
                    <Button
                        className={styles.btn_submit}
                        variant="contained"
                        size="small"
                        style={{ width: 200, height: 40, backgroundColor: primary_color, color: '#fff', marginRight: 5 }}
                        onClick={async () => {
                            // console.log(pontuation)
                            let response = await api_bd.put(`/tickets/${props.idTicket}`, {
                                situacao: { titulo: 'em_tratativa', descricao: '' },
                                flowNodes: {
                                    ...navigation_state.data.flowNodes,
                                    actualState: props.allFlowNodes.length,
                                    validateAudit: {
                                        state: 'ok',
                                        user: {
                                            id: props.loggeduser.id,
                                            name: props.loggeduser.nome,
                                        },
                                        auditdDate: Date.now(),
                                        pontuation: [...pontuation]
                                    }
                                }
                            })
                            set_navigation_state({
                                page_content: 'ticket_detail',
                                data: { ...response.data[0] }
                            })

                            props.set_situacao_chamado(response.data[0].situacao)

                            props.setShowModal(false)
                        }}>
                        Salvar
                    </Button>
                </div>

            </div>
        </div >
    )

}

export default AuditoriaGestor;