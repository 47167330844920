import React, { useState, useEffect } from 'react';

import './Modal.css'

import * as IoIcons from 'react-icons/io';

const Modal = (props) => {
    const { callbackfn } = props

    const [device_height, set_device_height] = useState(null)
    const [device_width, set_device_width] = useState(null)

    const style_modal = {
        ...props.styleModal
        // display: 'flex',
        // flexDirection: 'column',
        // // position: 'absolute',
        // // top: 0,
        // // left: 0,
        // height: '100vh',
        // maxHeight: '100vh',
        // width: '100vw',
        // overlflowY: 'auto',
    }

    const handleClose = () => {
        props.callbackfn();
    };

    useEffect(() => {
        const dw = document.documentElement.clientWidth
        const dh = document.documentElement.clientHeight
        // set_device_width(dw)
        // set_device_height(dh)

        document.onkeydown = function (evt) {
            evt = evt || window.event;
            var isEscape = false;
            if ("key" in evt) {
                isEscape = (evt.key === "Escape" || evt.key === "Esc");
            } else {
                isEscape = (evt.keyCode === 27);
            }

            if (isEscape) {
                // alert("Escape");
                handleClose()
            }
        }
    }, [])

    return (
        <div
            className='container_modal'
            style={style_modal}
        >
            <div
                className='close_btn_div '
                style={{
                    ...props.btn_close_position,
                    position: 'relative',
                    padding: 0,
                    width: '100%'
                }}>
                <IoIcons.IoIosCloseCircleOutline
                    size={50}
                    onClick={handleClose}
                    style={{
                        color: 'white',
                        // backgroundColor: 'green',
                        // paddingTop: 15,
                        cursor: 'pointer'

                    }}
                // className="text-white"
                />
            </div>

            <div
                style={
                    props.styleContent || {
                        display: 'flex',
                        position: 'relative',
                        width: props._width ? props._width : '100%',
                        justifyContent: 'center',//...props._style
                    }
                }
            >
                {props.children}
            </div>

        </div>
    )
}

export default Modal;