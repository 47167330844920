import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import * as IoIcons from 'react-icons/io';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


import Styles, { primary_color } from '../Styles'

import { api_bd } from '../../services/Api'

import { msToDateTime } from '../../util/fns'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'

function SuportCalendar() {
	const styles = Styles()
	const profile_data = useRecoilValue(profileData)

	const [device_height, set_device_height] = useState(null)
	const [device_width, set_device_width] = useState(null)

	const [dates, set_dates] = useState([])
	// const [selected_date, set_selected_date] = useState(null)
	const [list_users, set_list_users] = useState(null)
	// const [selected_user, set_selected_user] = useState(null)
	const [new_date, set_new_date] = useState({
		idu: '',
		data: '',
		nome: '',
		contato: '',
		feriado: false,
		janela_manutencao: false,
		descricao: ''
	})

	const load_users = async () => {
		const lista = await api_bd.get('/users')
		set_list_users(lista.data.filter(user => user.nivel.match(/Super|Técnico/) && user.ativo === true).sort((a, b) => a.nome.localeCompare(b.nome)))

	}

	const load_dates = async () => {
		const resp = await api_bd.get('/suport_calendar')
		const resp_alphabeltical = resp.data.sort(function (a, b) {
			if (new Date(a.data).getTime() < new Date(b.data).getTime()) return 1;
			if (new Date(b.data).getTime() < new Date(a.data).getTime()) return -1;
			return 0;
		});
		set_dates(resp_alphabeltical)
	}


	const add_new_date = (key, value) => {
		let temp = { ...new_date }
		temp[key] = value
		set_new_date(temp)
	}


	useEffect(() => {
		(async () => {
			await load_users()
			await load_dates()
		})()

		const dw = document.documentElement.clientWidth
		const dh = document.documentElement.clientHeight
		set_device_width(dw)
		set_device_height(dh)
	}, [])
	return (
		<div style={{ display: 'flex', flexDirection: 'column', width: "100%", alignItems: 'center', padding: 15, overflowY: 'auto' }}>

			<Card style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '80%', minHeight: 360, padding: 15 }}>
				<div style={{ width: '60%' }}>
					<TextField
						style={{ width: '100%' }}
						id="date"
						label="Calendário"
						type="date"
						defaultValue=''

						InputLabelProps={{
							shrink: true,
						}}

						onChange={(e) => {
							// set_selected_date(e.target.value)
							add_new_date('data', new Date(`${e.target.value} 00:00:00`))
						}}
					/>

					{list_users && list_users.length > 0 &&
						<FormControl style={{ width: '100%', marginTop: 20 }}>
							<InputLabel htmlFor="colaborador">Colaborador</InputLabel>
							<Select
								value={new_date.idu}
								id="colaborador"
								onChange={e => {
									const usuario = list_users.filter(user => user._id === e.target.value)
									const { _id, nome, contato } = usuario[0]
									let temp = { ...new_date }
									temp['idu'] = _id
									temp['nome'] = nome
									temp['contato'] = contato
									set_new_date(temp)
								}}
							>
								{list_users.map(user => (<MenuItem key={`${user._id}`} value={user._id}>{user.nome}</MenuItem>))}
							</Select>
						</FormControl>
					}

					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%', marginTop: 10 }}>
						<TextField
							className={styles.form_input}
							onChange={e => {
								add_new_date('descricao', e.target.value)
							}}
							label="Descrição"
							value={new_date.descricao}
							id="descricao"
							multiline={true}
						/>
					</div>

					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%', marginTop: 10 }}>
						<Checkbox
							onChange={(e) => {
								//console.log(e.target.checked)
								add_new_date('feriado', e.target.checked ? true : false)
							}}
							style={{ marginLeft: -15 }}
							checked={new_date.feriado}
							color="default"
						/><span style={{ marginLeft: 0 }}>Feriado: </span>
					</div>
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>
						<Checkbox
							onChange={(e) => {
								//console.log(e.target.checked)
								add_new_date('janela_manutencao', e.target.checked ? true : false)
							}}
							style={{ marginLeft: -15 }}
							checked={new_date.janela_manutencao}
							color="default"
						/><span style={{ marginLeft: 0 }}>Janela de Manutenção: </span>
					</div>

					<Button
						style={{ width: '100%', marginTop: 15 }}
						className={styles.btn_submit}
						variant="contained"
						color="primary"
						size="small"
						disabled={(new_date.data !== '' && new_date.nome !== '') ? false : true}
						onClick={async () => {
							const resp = await api_bd.post('/suport_calendar', new_date)
							set_dates(resp.data)
							set_new_date({
								idu: '',
								data: '',
								nome: '',
								contato: '',
								feriado: false,
								janela_manutencao: false,
								descricao: ''
							})
						}}
					>
						Salvar
					</Button>
				</div>
			</Card>

			<Card style={{ width: '80%', padding: 15, marginTop: 15, display: 'flex' }}>
				<div
					style={{ width: '100%', maxHeight: device_height - 410 - 80, height: device_height - 410 - 80, overflowY: 'auto' }}
				>
					<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
						<div style={{ display: 'flex', alignItems: 'center', height: 30 }}><span>CALENDÁRIO:</span></div>
						<div style={{ display: 'flex', alignItems: 'center', height: 30 }}><span className="material-icons" style={{ color: primary_color, fontSize: '1.2em' }}>event_available</span></div><div style={{ display: 'flex', alignItems: 'center', height: 30, fontSize: '1em' }}> - FERIADO</div>
						<div style={{ display: 'flex', alignItems: 'center', height: 30 }}><span className="material-icons" style={{ fontSize: '1.2em' }}>event_available</span></div><div style={{ display: 'flex', alignItems: 'center', height: 30, fontSize: '1em' }}> - DIA NORMAL</div>
					</div>
					<List component="nav" aria-label="main mailbox folders">
						{dates && dates.filter(d => d.janela_manutencao === false).map((el) => {
							const data = msToDateTime(el.data)
							return (
								<ListItem key={`${el._id}_n`} >
									<ListItemIcon>
										{el.janela_manutencao ?
											<span className="material-icons" style={{ color: primary_color }}>build</span>
											:
											<span className="material-icons" style={{ color: el.feriado ? primary_color : '' }}>event_available</span>
										}
									</ListItemIcon>
									<ListItemText primary={`${data.data}/${data.mes}/${data.ano} ${el.descricao === '' ? '' : ' - ' + el.descricao}`} secondary={`${el.nome} - ${el.contato}`} />
									<ListItemIcon>
										{profile_data.nivel.match(/Super|Técnico-Admin/) &&
											<IoIcons.IoIosTrash
												size={26}
												style={{ marginBottom: -3, marginRight: 20, cursor: "pointer" }}
												onClick={async () => {
													try {
														const resp = await api_bd.delete(`/suport_calendar/${el._id}`)
														await load_dates()
													} catch (error) {
														if (error.message.indexOf('code 400') > -1) {
															alert("Ocorreu um erro tente novamente.")
														}
													}
												}}
											/>
										}
									</ListItemIcon>
								</ListItem>
							)
						})}
					</List>
				</div>

				<div style={{ borderLeft: 'gray 1px solid', width: '100%', maxHeight: device_height - 410 - 80, height: device_height - 410 - 80, overflowY: 'auto' }}>
					<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
						<div style={{ display: 'flex', alignItems: 'center', height: 30 }}><span>JANELAS DE MANUTEÇÃO</span></div>
					</div>
					<List component="nav" aria-label="main mailbox folders">
						{dates && dates.filter(d => d.janela_manutencao === true).map((el) => {
							const data = msToDateTime(el.data)
							return (
								<ListItem key={`${el._id}_manut`} >
									<ListItemIcon>
										{el.janela_manutencao ?
											<span className="material-icons">build</span>
											:
											<span className="material-icons" style={{ color: el.feriado ? primary_color : '' }}>event_available</span>
										}
									</ListItemIcon>

									<ListItemText primary={`${data.data}/${data.mes}/${data.ano} ${el.descricao === '' ? '' : ' - ' + el.descricao}`} secondary={`${el.nome} - ${el.contato}`} />

									<ListItemIcon>
										{profile_data.nivel.match(/Super|Técnico-Admin/) &&
											<IoIcons.IoIosTrash
												size={26}
												style={{ marginBottom: -3, marginRight: 20, cursor: "pointer" }}
												onClick={async () => {
													try {
														const resp = await api_bd.delete(`/suport_calendar/${el._id}`)
														await load_dates()
													} catch (error) {
														if (error.message.indexOf('code 400') > -1) {
															alert("Ocorreu um erro tente novamente.")
														}
													}
												}}
											/>
										}
									</ListItemIcon>
								</ListItem>
							)
						})}
					</List>
				</div>
			</Card>

			{/* <iframe src="https://calendar.google.com/calendar/embed?src=pt-br.brazilian%23holiday%40group.v.calendar.google.com&ctz=America%2FFortaleza" style={{ border: 0, width: '100%', height: '95vh' }} frameborder="0" scrolling="no"></iframe> */}
		</div>
	);
}

export default SuportCalendar;