import React, {
    useState,
    useEffect
} from 'react';

import * as IoIcons from 'react-icons/io';

import { Card } from '@material-ui/core';
// import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Styles, { Fab } from '../components/Styles'
import { api_bd } from '../services/Api'
import FormDataUser from '../components/Forms/FormDataUser'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../atoms/user.atom'

const { backend_files_address } = require('../config.json')

function ListUsers(props) {
    const styles = Styles()



    const [data_new_user, set_data_new_user] = useState(null)

    const [data_user, set_data_user] = useState({})
    const [submit_is_disabled, set_submit_is_disabled] = useState(false)

    const [image_profile, set_image_profile] = useState(null);



    const [device_height, set_device_height] = useState(null)
    const [device_width, set_device_width] = useState(null)

    const [show_modal, set_show_modal] = useState(false)
    const [content_modal, set_content_modal] = useState(null)

    const [list_users, set_list_users] = useState(null)
    const [list_users_disabled, set_list_users_disabled] = useState(null)
    const [showDisabledusers, setShowDisabledusers] = useState(false)

    const [user_data, set_user_data] = useRecoilState(userState)
    const profile_data = useRecoilValue(profileData)

    const style_modal = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        flex: 1,
        backgroundColor: '#00000055',
        left: 0,
        top: 0,
        position: 'absolute',
        float: 'left',
        minWidth: '100%',
        maxWidth: '100%',
        height: `${device_height}px`,
        maxHeight: `${device_height}px`,
        justifyContent: 'center',
        alignItems: 'flex-start',
        overflowY: "auto"

    }


    const load_data = async () => {
        const lista = await api_bd.get('/users?state=true')
        set_list_users(lista.data)

        // const data = await getUserData()
        // // console.log(data)
        // set_user_data(data)
    }

    const load_data_inactive = async () => {
        const lista = await api_bd.get('/users?state=false')
        set_list_users_disabled(lista.data)

        // const data = await getUserData()
        // // console.log(data)
        // set_user_data(data)
    }


    useEffect(() => {
        (async () => {
            await load_data()
            await load_data_inactive()
        })()
        if (show_modal) { set_show_modal(false) }
    }, [data_new_user])

    useEffect(() => {
        (async () => {
            await load_data()
            await load_data_inactive()
        })()

        const dw = document.documentElement.clientWidth
        const dh = document.documentElement.clientHeight
        set_device_width(dw)
        set_device_height(dh)

    }, [])

    const handleClose = () => {
        set_show_modal(false);
    };

    const Modal = () => {
        return (
            <div style={style_modal} >
                <div style={{ width: '100%' }}>
                    <div style={{
                        width: '100%',
                        // backgroundColor:'yellow', 
                        maxHeight: 60
                    }}>
                        <IoIcons.IoIosCloseCircleOutline
                            size={60}
                            onClick={handleClose}
                            style={{
                                color: 'white',
                                marginLeft: '75%',
                                paddingTop: 15,
                                cursor: 'pointer'
                            }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            // backgroundColor:'yellow',
                            justifyContent: 'center'
                        }}
                    >
                        {
                            content_modal != null &&
                            (() => {
                                switch (content_modal) {
                                    case 'adduser':
                                        return <FormDataUser width={device_width < device_height ? "80%" : "50%"} set_data_new_user={set_data_new_user} load_users_list={load_data} set_show_form_modal={set_show_modal} />
                                        break;
                                    case 'edituser':
                                        return <FormDataUser width={device_width < device_height ? "80%" : "50%"} fromList={true} edit={data_user} glpi={true} set_data_new_user={set_data_new_user} load_users_list={load_data} set_show_form_modal={set_show_modal} />
                                        break;
                                    default:
                                        return <></>
                                        break;
                                }
                            })()
                        }
                    </div>
                </div>
            </div>)
    }

    return (
        <div className="flex flexRow height_less_header" style={{ width: "100%" }}>
            {show_modal &&
                <Modal />
            }

            <div

                className="flex flexCol"
                style={{
                    padding: 30,
                    width: "100%",
                    overflowY: "auto"
                }}
            >

                {profile_data && profile_data.nivel.match(/Super|Técnico-Admin/i) &&
                    <>
                        <div style={{ maxHeight: 50, paddingLeft: 30, marginTop: 20, marginBottom: 10, width: "100%" }} className='flex flex-row items-center justify-between'>
                            <h3>LISTAGEM DE USUÁRIOS ATIVOS</h3>
                            <div className='flex flex-row items-center'>
                                <span className='mr-2'>Exibir usuários inativos</span>
                                <input
                                    onChange={(e) => {
                                        setShowDisabledusers(e.target.checked)
                                    }}
                                    checked={showDisabledusers}
                                    type="checkbox"
                                />
                            </div>
                        </div>
                        <div className="flex flexWrap" style={{ alignItems: 'flex-start', overflowY: "auto", maxHeight: device_height - 50, paddingBottom: 70 }}>
                            {list_users && list_users.length > 0 && list_users.map((el) => (
                                <Card key={el._id} className={styles.card} style={{ width: 180, height: 230 }}>
                                    <CardContent className="flex flexCol justify-content-sb" style={{ height: "100%" }} >
                                        <img
                                            alt=""
                                            style={{ borderRadius: 5, objectFit: "cover", width: 150, height: 150, cursor: 'pointer' }}
                                            src={el.image_profile.filename == "" ? `${backend_files_address}/user_placeholder.jpg` : `${backend_files_address}/${el.image_profile.filename}`}
                                            onClick={() => {
                                                // console.log('ELEMENTO: ',el)
                                                set_data_user(el)
                                                set_content_modal('edituser')
                                                set_show_modal(true)
                                            }}
                                        />
                                        <div>
                                            <p style={{ fontSize: '0.8em', color: el.ativo === true ? '' : 'grey' }}>
                                                <b>Nome:</b> {el.nome}
                                            </p>
                                            <p style={{ fontSize: '0.8em', color: el.ativo === true ? '' : 'grey' }}>
                                                <b>Login:</b> {el.user_glpi}
                                            </p>
                                        </div>
                                    </CardContent>
                                </Card>
                            ))}

                            {list_users && list_users.length === 0 &&
                                <Card className={styles.card}>
                                    <CardContent className="flex flexCol center-v center-h" style={{ height: "100%" }} >
                                        <h2>Nenhuna Informação Cadastrada</h2>
                                    </CardContent>
                                </Card>
                            }
                        </div>

                        

                        {showDisabledusers &&
                            <>
                                <hr style={{ color: '#c3c3c3' }} />

                                <div style={{ maxHeight: 50, paddingLeft: 30, marginTop: 20, marginBottom: 10, width: "100%" }}>
                                    <h3>LISTAGEM DE USUÁRIOS INATIVOS</h3>
                                </div>
                                <div className="flex flexWrap" style={{ alignItems: 'flex-start', overflowY: "auto", maxHeight: device_height - 50, paddingBottom: 70 }}>
                                    {list_users_disabled && list_users_disabled.length > 0 && list_users_disabled.map((el) => (
                                        <Card key={el._id} className={styles.card} style={{ width: 180, height: 230 }}>
                                            <CardContent className="flex flexCol justify-content-sb" style={{ height: "100%" }} >
                                                <img
                                                    alt=""
                                                    style={{ borderRadius: 5, objectFit: "cover", width: 150, height: 150, cursor: 'pointer', opacity: 0.5 }}
                                                    src={el.image_profile.filename == "" ? `${backend_files_address}/user_placeholder.jpg` : `${backend_files_address}/${el.image_profile.filename}`}
                                                    onClick={() => {
                                                        // console.log('ELEMENTO: ',el)
                                                        set_data_user(el)
                                                        set_content_modal('edituser')
                                                        set_show_modal(true)
                                                    }}
                                                />
                                                <div>
                                                    <p style={{ fontSize: '0.8em', color: el.ativo === true ? '' : 'grey' }}>
                                                        <b>Nome:</b> {el.nome}
                                                    </p>
                                                    <p style={{ fontSize: '0.8em', color: el.ativo === true ? '' : 'grey' }}>
                                                        <b>Login:</b> {el.user_glpi}
                                                    </p>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    ))}


                                    {list_users && list_users.length === 0 &&
                                        <Card className={styles.card}>
                                            <CardContent className="flex flexCol center-v center-h" style={{ height: "100%" }} >
                                                <h2>Nenhuna Informação Cadastrada</h2>
                                            </CardContent>
                                        </Card>
                                    }
                                </div>
                            </>
                        }
                    </>
                }

                {profile_data && profile_data.username && profile_data.nivel === 'Técnico' &&

                    <FormDataUser width={device_width < device_height ? "80%" : "50%"} edit={user_data.sgi_data} set_data_new_user={set_data_new_user} />

                }
            </div>

            {profile_data && profile_data.nivel.match(/Super|Técnico-Admin/i) &&
                <Fab
                    alt="Adicionar Usuário"
                    className="flex center-h center-v"
                    onClick={() => {
                        set_content_modal('adduser')
                        set_show_modal(true)
                    }}
                >
                    <span className="material-icons" style={{ fontSize: 33, color: "white", marginLeft: -3 }}>person_add</span>
                </Fab>
            }
        </div >
    );
}

export default ListUsers;
