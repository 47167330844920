import React, { useState, useEffect } from 'react';

import './Outages.css'

import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { api_bd } from '../../services/Api';

function EditOutage(props) {

    const profile_user = props.profile_user

    const buscarEventos = props.buscarEventos

    const callbackfn = props.callbackfn

    const [dataTicket, setDataTicket] = useState({
        tipo_evento: "Selecione o tipo do evento",
        horario: '',
        trecho_ponto_a: '',
        trecho_ponto_b: '',
        sub_trecho_ponto_a: '',
        sub_trecho_ponto_b: '',
        foi_solucionado: '',
        hora_solucao: '',
        porque_nao_foi_solucionado: '',
        estacao: '',
        qual_o_problema: '',
        feedback_do_suporte: '',
        previsao_para_solucao: '',
        numero_protocolo: '',
        foi_escalonado: '',
        hora_escalonado: '',
        pra_quem_foi_escalonado: '',
        porque_nao_foi_escalonado: '',
        userId: '',
        userName: '',
        userLevel: '',
        data_insercao: '',
        customer_id: '',
        customer_name: ''
    })


    useEffect(()=>{
        setDataTicket(props.ev)
    },[])

    const EstacaoIndisponivel = ({ev}) => {
        // const [cliente, setCliente] = useState('')
        const [horario_inicio, setHorario_inicio] = useState(ev.horario_inicio)
        const [estacao, setEstacao] = useState(ev.estacao)
        const [responsavel_estacao, setResponsavel_Estacao] = useState(ev.responsavel_estacao)
        const [clientesAfetados, setClientesAfetados] = useState(ev.clientes_afetados)
        const [numero_protocolo, setNumero_protocolo] = useState(ev.numero_protocolo)
        // const [ponto_b, setPonto_b] = useState('')
        return (
            <div style={{ width: '100%' }}>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="datetime-local"
                        style={{ width: '100%' }}
                        id="horario_inicio"
                        label="Qual horário do incidente?"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setHorario_inicio(e.target.value)
                            // handle_data("horario_inicio", e.target.value)
                        }}
                        value={horario_inicio}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="clientes_afetados"
                        label="Clientes afetados"
                        onChange={e => {
                            setClientesAfetados(e.target.value)
                            // handle_data("horario_inicio", e.target.value)
                        }}
                        value={clientesAfetados}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="estacao"
                        label="Qual Estação?"
                        onChange={e => {
                            // handle_data("estacao", e.target.value)
                            setEstacao(e.target.value)
                        }}
                        value={estacao}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="text"
                        style={{ width: '100%' }}
                        id="responsavel_estacao"
                        label="Responsável pela estação?"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setResponsavel_Estacao(e.target.value)
                            // handle_data("horario_inicio_inicio", e.target.value)
                        }}
                        value={responsavel_estacao}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="numero_protocolo"
                        label="Qual número do ticket aberto para este evento?"
                        onChange={e => {
                            setNumero_protocolo(e.target.value)
                        }}
                        value={numero_protocolo}
                    />
                </div>

                <button
                    // className={dataTicket.tipo_evento === "Selecione o tipo do evento" ? 'buttom-disabled fixToCenter' : 'buttom fixToCenter'}
                    // disabled={dataTicket.tipo_evento === "Selecione o tipo do evento" ? true : false}
                    className='buttom fixToCenter'
                    style={{ marginTop: 20, padding: 10, width: 100 }}
                    onClick={async () => {
                        const filters = [horario_inicio, estacao]
                        for (let filtro in filters) {
                            // console.log(filters[filtro])
                            if (filters[filtro] === '') {
                                return (alert('Todos os campos devem ser preenchidos'))
                            }
                        }

                        // const { id, name } = JSON.parse(cliente)

                        let data = new Date().getTime()
                        let tempdata = {
                            ...dataTicket,
                            horario_inicio,
                            estacao,
                            responsavel_estacao,
                            clientes_afetados: clientesAfetados,
                            numero_protocolo,
                        }
                        await api_bd.put(`/outages/${ev._id}`, tempdata)
                        await buscarEventos()
                        // console.log(resp.data)
                        callbackfn()
                    }}
                >
                    Salvar
                </button>
            </div>
        )
    }

    const LinkComProblema = ({ev}) => {
        // const [cliente, setCliente] = useState('')
        const [horario_inicio, setHorario_inicio] = useState(ev.horario_inicio)
        const [trecho_ponto_a, setTrecho_Ponto_a] = useState(ev.trecho_ponto_a)
        const [trecho_ponto_b, setTrecho_Ponto_b] = useState(ev.trecho_ponto_b)
        const [sub_trecho_ponto_a, setSubTrecho_Ponto_a] = useState(ev.sub_trecho_ponto_a)
        const [sub_trecho_ponto_b, setSubTrecho_Ponto_b] = useState(ev.sub_trecho_ponto_b)
        const [qual_o_problema, setQual_o_problema] = useState(ev.qual_o_problema)
        const [numero_protocolo, setNumero_protocolo] = useState(ev.numero_protocolo)
        const [fornecedor, setFornecedor] = useState(ev.fornecedor)
        const [clientesAfetados, setClientesAfetados] = useState(ev.clientes_afetados)
        return (
            <div style={{ width: '100%' }}>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="datetime-local"
                        style={{ width: '100%' }}
                        id="horario_inicio"
                        label="Qual horário do incidente?"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setHorario_inicio(e.target.value)
                            // handle_data("horario_inicio", e.target.value)
                        }}
                        value={horario_inicio}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <FormControl style={{ width: '100%', flexDirection: 'column' }}>
                        <InputLabel htmlFor="select-qual_o_problema">Qual o Problema? </InputLabel>
                        <Select
                            defaultValue={qual_o_problema}
                            labelId="select-qual_o_problema"
                            id="qual_o_problema"
                            onChange={e => {
                                setQual_o_problema(e.target.value)
                            }}
                            required={true}
                        >
                            <MenuItem value="ATENUAÇÃO">ATENUAÇÃO</MenuItem>
                            <MenuItem value="BAIXA PERFORMANCE">BAIXA PERFORMANCE</MenuItem>
                            <MenuItem value="INDISPONIBILIDADE">INDISPONIBILIDADE</MenuItem>
                            <MenuItem value="ROMPIMENTO">ROMPIMENTO</MenuItem>
                            <MenuItem value="TAXAÇÃO DE ERROS">TAXAÇÃO DE ERROS</MenuItem>
                        </Select>
                    </FormControl>
                </div>


                <>
                    <div style={{ width: '100%', marginTop: 10 }}>
                        <TextField
                            style={{ width: '100%' }}
                            id="ponto_a"
                            label="Trecho ponto A?"
                            onChange={e => {
                                // handle_data("ponto_a", e.target.value)
                                setTrecho_Ponto_a(e.target.value)
                            }}
                            value={trecho_ponto_a}
                        />
                    </div>

                    <div style={{ width: '100%', marginTop: 10 }}>
                        <TextField
                            style={{ width: '100%' }}
                            id="ponto_b"
                            label="Trecho ponto B?"
                            onChange={e => {
                                // handle_data("ponto_b", e.target.value)
                                setTrecho_Ponto_b(e.target.value)
                            }}
                            value={trecho_ponto_b}
                        />
                    </div>

                    <div style={{ width: '100%', marginTop: 10 }}>
                        <TextField
                            style={{ width: '100%' }}
                            id="ponto_a"
                            label="Sub-trecho ponto A?"
                            onChange={e => {
                                // handle_data("ponto_a", e.target.value)
                                setSubTrecho_Ponto_a(e.target.value)
                            }}
                            value={sub_trecho_ponto_a}
                        />
                    </div>

                    <div style={{ width: '100%', marginTop: 10 }}>
                        <TextField
                            style={{ width: '100%' }}
                            id="ponto_b"
                            label="Sub-trecho ponto B?"
                            onChange={e => {
                                // handle_data("ponto_b", e.target.value)
                                setSubTrecho_Ponto_b(e.target.value)
                            }}
                            value={sub_trecho_ponto_b}
                        />
                    </div>
                </>



                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="numero_protocolo"
                        label="Qual número do ticket aberto para este evento?"
                        onChange={e => {
                            setNumero_protocolo(e.target.value)
                        }}
                        value={numero_protocolo}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="fornecedor"
                        label="Qual o fornecedor do link?"
                        onChange={e => {
                            setFornecedor(e.target.value)
                        }}
                        value={fornecedor}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="clientes_afetados"
                        label="Clientes afetados"
                        onChange={e => {
                            setClientesAfetados(e.target.value)
                            // handle_data("horario_inicio", e.target.value)
                        }}
                        value={clientesAfetados}
                    />
                </div>

                <button
                    // className={dataTicket.tipo_evento === "Selecione o tipo do evento" ? 'buttom-disabled fixToCenter' : 'buttom fixToCenter'}
                    // disabled={dataTicket.tipo_evento === "Selecione o tipo do evento" ? true : false}
                    className='buttom fixToCenter'
                    style={{ marginTop: 20, padding: 10, width: 100 }}
                    onClick={async () => {
                        const filters = [horario_inicio, qual_o_problema, numero_protocolo]
                        for (let filtro in filters) {
                            // console.log(filters[filtro])
                            if (filters[filtro] === '') {
                                return (alert('Todos os campos devem ser preenchidos'))
                            }
                        }

                        // const { id, name } = JSON.parse(cliente)

                        // let data = new Date().getTime()
                        let tempdata = {
                            horario_inicio,
                            qual_o_problema,
                            numero_protocolo,
                            trecho_ponto_a,
                            trecho_ponto_b,
                            sub_trecho_ponto_a,
                            sub_trecho_ponto_b,
                            fornecedor,
                            clientes_afetados: clientesAfetados
                        }
                        await api_bd.put(`/outages/${ev._id}`, tempdata)
                        await buscarEventos()
                        // console.log(resp.data)
                        callbackfn()
                    }}
                >
                    Salvar
                </button>
            </div>
        )
    }

    const FalhaEletrica = ({ev}) => {
        // const [cliente, setCliente] = useState('')
        const [horario_inicio, setHorario_inicio] = useState(ev.horario_inicio)
        const [estacao, setEstacao] = useState(ev.estacao)
        const [responsavel_estacao, setResponsavel_Estacao] = useState(ev.responsavel_estacao)
        const [equipamento, setEquipamento] = useState(ev.equipamento)
        const [clientesAfetados, setClientesAfetados] = useState(ev.clientes_afetados)
        const [autonomia_eletrica, setAutonomia_eletrica] = useState(ev.autonomia_eletrica)
        // const [numero_protocolo, setNumero_protocolo] = useState('')
        return (
            <div style={{ width: '100%' }}>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="datetime-local"
                        style={{ width: '100%' }}
                        id="horario_inicio"
                        label="Qual horário do incidente?"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setHorario_inicio(e.target.value)
                            // handle_data("horario_inicio", e.target.value)
                        }}
                        value={horario_inicio}
                    />
                </div>


                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="clientes_afetados"
                        label="Clientes afetados"
                        onChange={e => {
                            setClientesAfetados(e.target.value)
                            // handle_data("horario_inicio", e.target.value)
                        }}
                        value={clientesAfetados}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="text"
                        style={{ width: '100%' }}
                        id="estacao"
                        label="Qual Estação?"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setEstacao(e.target.value)
                            // handle_data("horario", e.target.value)
                        }}
                        value={estacao}
                    />
                </div>


                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="text"
                        style={{ width: '100%' }}
                        id="responsavel_estacao"
                        label="Responsável pela estação?"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setResponsavel_Estacao(e.target.value)
                        }}
                        value={responsavel_estacao}
                    />
                </div>


                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="text"
                        style={{ width: '100%' }}
                        id="equipamento"
                        label="Qual Equipamento?"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setEquipamento(e.target.value)
                            // handle_data("horario", e.target.value)
                        }}
                        value={equipamento}
                    />
                </div>


                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="number"
                        min="1"
                        style={{ width: '100%' }}
                        id="autonomia"
                        label="Autonomia da estação? (em horas)"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setAutonomia_eletrica(e.target.value)
                            // handle_data("horario", e.target.value)
                        }}
                        value={autonomia_eletrica}
                    />
                </div>

                <button
                    // className={dataTicket.tipo_evento === "Selecione o tipo do evento" ? 'buttom-disabled fixToCenter' : 'buttom fixToCenter'}
                    // disabled={dataTicket.tipo_evento === "Selecione o tipo do evento" ? true : false}
                    className='buttom fixToCenter'
                    style={{ marginTop: 20, padding: 10, width: 100 }}
                    onClick={async () => {
                        const filters = [horario_inicio, estacao, equipamento]
                        for (let filtro in filters) {
                            // console.log(filters[filtro])
                            if (filters[filtro] === '') {
                                return (alert('Todos os campos devem ser preenchidos'))
                            }
                        }

                        // console.log(cliente)
                        // const { id, name } = JSON.parse(cliente)

                        // let data = new Date().getTime()
                        let tempdata = {
                            ...dataTicket,
                            estacao,
                            responsavel_estacao,
                            equipamento,
                            horario_inicio,
                            autonomia_eletrica,
                            clientes_afetados: clientesAfetados
                        }
                        await api_bd.put(`/outages/${ev._id}`, tempdata)
                        await buscarEventos()
                        // console.log(resp.data)
                        callbackfn()
                    }}
                >
                    Salvar
                </button>
            </div>
        )
    }

    const FalhaDeOlt = ({ev}) => {
        // const [cliente, setCliente] = useState('')
        const [horario_inicio, setHorario_inicio] = useState(ev.horario_inicio)
        const [estacao, setEstacao] = useState(ev.estacao)
        const [qual_o_problema, setQual_o_problema] = useState(ev.qual_o_problema)
        const [responsavel, setResponsavel] = useState(ev.responsabilidade)
        const [clientesAfetados, setClientesAfetados] = useState(ev.clientes_afetados)
        const [numero_protocolo, setNumero_protocolo] = useState(ev.numero_protocolo)
        const [slotPon, setSlotPon] = useState(ev.slot_pon)

        // const [ponto_b, setPonto_b] = useState('')

        return (
            <div style={{ width: '100%' }}>
                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="datetime-local"
                        style={{ width: '100%' }}
                        id="horario_inicio"
                        label="Qual horário do incidente?"
                        // defaultValue="2017-05-24T10:30"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setHorario_inicio(e.target.value)
                            // handle_data("horario_inicio", e.target.value)
                        }}
                        value={horario_inicio}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <FormControl style={{ width: '100%', flexDirection: 'column' }}>
                        <InputLabel htmlFor="select-qual_o_problema">Qual o Problema? </InputLabel>
                        <Select
                            defaultValue={qual_o_problema}
                            labelId="select-qual_o_problema"
                            id="qual_o_problema"
                            onChange={e => {
                                setQual_o_problema(e.target.value)
                            }}
                            required={true}
                        >
                            <MenuItem value="ROMPIMENTO SLOT/PON">ROMPIMENTO SLOT/PON</MenuItem>
                            <MenuItem value="OLT INDISPONVÍVEL">OLT INDISPONVÍVEL</MenuItem>
                            <MenuItem value="ROMPIMENTO PARCIAL SLOT/PON">ROMPIMENTO PARCIAL SLOT/PON</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="clientes_afetados"
                        label="Clientes afetados"
                        multiline={true}
                        onChange={e => {
                            setClientesAfetados(e.target.value)
                            // handle_data("horario_inicio", e.target.value)
                        }}
                        value={clientesAfetados}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="estacao"
                        multiline={true}
                        label="Qual Estação?"
                        onChange={e => {
                            setEstacao(e.target.value)
                        }}
                        value={estacao}
                    />
                </div>


                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="slot-pon"
                        multiline={true}
                        label="SLOT / PON"
                        onChange={e => {
                            setSlotPon(e.target.value)
                        }}
                        value={slotPon}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="text"
                        style={{ width: '100%' }}
                        id="responsavel"
                        label="Responsável?"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setResponsavel(e.target.value)
                        }}
                        value={responsavel}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="numero_protocolo"
                        label="Qual número do ticket aberto para este evento?"
                        onChange={e => {
                            setNumero_protocolo(e.target.value)
                        }}
                        value={numero_protocolo}
                    />
                </div>

                <button
                    className='buttom fixToCenter'
                    style={{ marginTop: 20, padding: 10, width: 100 }}
                    onClick={async () => {
                        const filters = [horario_inicio, estacao]
                        for (let filtro in filters) {
                            // console.log(filters[filtro])
                            if (filters[filtro] === '') {
                                return (alert('Todos os campos devem ser preenchidos'))
                            }
                        }

                        let tempdata = {
                            ...dataTicket,
                            horario_inicio,
                            qual_o_problema,
                            estacao,
                            responsabilidade: responsavel,
                            clientes_afetados: clientesAfetados,
                            numero_protocolo,
                            slot_pon: slotPon,
                        }
                        await api_bd.put(`/outages/${ev._id}`, tempdata)
                        await buscarEventos()
                        // console.log(resp.data)
                        props.callbackfn()
                    }}
                >
                    Salvar
                </button>
            </div>
        )
    }


    const TicketOption = ({ option, ev }) => {
        // console.log('Data Ticket TIPO EVENTO: ',dataTicket.tipo_evento)
        switch (option) {
            // case 'Rompimento':
            //     return <Rompimento />
            //     break;
            case 'Estação Indisponível':
                return <EstacaoIndisponivel ev={ev} callbackfn={callbackfn} />
                break;
            case 'Link Com Problema':
                return <LinkComProblema ev={ev} callbackfn={callbackfn} />
                break;
            case 'Falha Elétrica':
                return <FalhaEletrica ev={ev} callbackfn={callbackfn} />
                break;

            case 'Falha de OLT':
                return <FalhaDeOlt ev={ev} callbackfn={callbackfn} />
                break;

            default:
                return <div />
                break;
        }
    }

    return (
        <div style={{ width: '100%' }}>

            <div style={{ fontSize: '1.3em', fontWeight: 'bold', marginBottom: 20 }}>
                Editar Evento
            </div>

            <TicketOption option={props.ev.tipo_evento} ev={props.ev} callbackfn={props.callbackfn} />

            {/* <div style={{ width: '100%', marginTop: 10 }}>
                <TextField
                    type="datetime-local"
                    style={{ width: '100%' }}
                    id="horario_inicio"
                    label="Qual horário do incidente?"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={e => {
                        setHorario_inicio(e.target.value)
                    }}
                    value={horario_inicio}
                />
            </div>

            <div style={{ width: '100%', marginTop: 10 }}>
                <FormControl style={{ width: '100%', flexDirection: 'column' }}>
                    <InputLabel htmlFor="select-qual_o_problema">Qual o Problema? </InputLabel>
                    <Select
                        defaultValue={qual_o_problema}
                        labelId="select-qual_o_problema"
                        id="qual_o_problema"
                        onChange={e => {
                            setQual_o_problema(e.target.value)
                        }}
                        required={true}
                    >
                        <MenuItem value="ATENUAÇÃO">ATENUAÇÃO</MenuItem>
                        <MenuItem value="BAIXA PERFORMANCE">BAIXA PERFORMANCE</MenuItem>
                        <MenuItem value="INDISPONIBILIDADE">INDISPONIBILIDADE</MenuItem>
                        <MenuItem value="ROMPIMENTO">ROMPIMENTO</MenuItem>
                        <MenuItem value="TAXAÇÃO DE ERROS">TAXAÇÃO DE ERROS</MenuItem>
                    </Select>
                </FormControl>
            </div>


            <>
                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="ponto_a"
                        label="Trecho ponto A?"
                        onChange={e => {
                            // handle_data("ponto_a", e.target.value)
                            setTrecho_Ponto_a(e.target.value)
                        }}
                        value={trecho_ponto_a}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="ponto_b"
                        label="Trecho ponto B?"
                        onChange={e => {
                            // handle_data("ponto_b", e.target.value)
                            setTrecho_Ponto_b(e.target.value)
                        }}
                        value={trecho_ponto_b}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="ponto_a"
                        label="Sub-trecho ponto A?"
                        onChange={e => {
                            // handle_data("ponto_a", e.target.value)
                            setSubTrecho_Ponto_a(e.target.value)
                        }}
                        value={sub_trecho_ponto_a}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="ponto_b"
                        label="Sub-trecho ponto B?"
                        onChange={e => {
                            // handle_data("ponto_b", e.target.value)
                            setSubTrecho_Ponto_b(e.target.value)
                        }}
                        value={sub_trecho_ponto_b}
                    />
                </div>
            </>


            <div style={{ width: '100%', marginTop: 10 }}>
                <TextField
                    style={{ width: '100%' }}
                    id="numero_protocolo"
                    label="Qual número do ticket aberto para este evento?"
                    onChange={e => {
                        setNumero_protocolo(e.target.value)
                    }}
                    value={numero_protocolo}
                />
            </div>

            <div style={{ width: '100%', marginTop: 10 }}>
                <TextField
                    style={{ width: '100%' }}
                    id="fornecedor"
                    label="Qual o fornecedor do link?"
                    onChange={e => {
                        setFornecedor(e.target.value)
                    }}
                    value={fornecedor}
                />
            </div>

            <div style={{ width: '100%', marginTop: 10 }}>
                <TextField
                    style={{ width: '100%' }}
                    id="clientes_afetados"
                    label="Clientes afetados"
                    onChange={e => {
                        setClientesAfetados(e.target.value)
                        // handle_data("horario_inicio", e.target.value)
                    }}
                    value={clientesAfetados}
                />
            </div>

            <button
                // className={dataTicket.tipo_evento === "Selecione o tipo do evento" ? 'buttom-disabled fixToCenter' : 'buttom fixToCenter'}
                // disabled={dataTicket.tipo_evento === "Selecione o tipo do evento" ? true : false}
                className='buttom fixToCenter'
                style={{ marginTop: 20, padding: 10, width: 100 }}
                onClick={async () => {
                    const filters = [horario_inicio, qual_o_problema, numero_protocolo]
                    for (let filtro in filters) {
                        // console.log(filters[filtro])
                        if (filters[filtro] === '') {
                            return (alert('Todos os campos devem ser preenchidos'))
                        }
                    }

                    let tempdata = {
                        horario_inicio,
                        qual_o_problema,
                        numero_protocolo,
                        trecho_ponto_a,
                        trecho_ponto_b,
                        sub_trecho_ponto_a,
                        sub_trecho_ponto_b,
                        fornecedor,
                        clientes_afetados: clientesAfetados
                    }

                    // console.log(props.ev._id, tempdata)

                    await api_bd.put(`/outages/${props.ev._id}`, tempdata)
                    await props.buscarEventos()
                    // console.log(resp.data)
                    props.callbackfn()
                }}
            >
                Salvar
            </button> */}
        </div>
    )
}

export default EditOutage;