import React, {
    useState,
    useEffect
} from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Styles from '../Styles'

import { api_bd } from '../../services/Api'


import Autocomplete from '@material-ui/lab/Autocomplete';

import * as IoIcons from 'react-icons/io';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { CheckBox } from '@material-ui/icons';


function FormDataStation(props) {
    const {
        width,
        // set_data_new_station, 
        data_client,
        only_cad
    } = props
    const styles = Styles()

    const [list_stations, set_list_stations] = useState([])
    const [filtered_list, set_filtered_list] = useState([])

    const [data_form, set_data_form] = useState({ user: data_client._id, numero_estacao: 0 })
    const [lista_municipios, set_lista_municipios] = useState([])
    const [localidade_selecionada, set_localidade_selecionada] = useState({})

    const [submit_is_disabled, set_submit_is_disabled] = useState(true)

    const [codigo_estacao, set_codigo_estacao] = useState('')

    // const [numero_estacao, set_numero_estacao] = useState(0)

    const [estacao_duplicada, set_estacao_duplicada] = useState(false)

    const [useNumberToStationCode, setUseNumberToStationCode] = useState(true)

    const carregar_estacoes = async () => {
        const lista = await api_bd.get('/stations')
        set_list_stations(lista.data)
    }

    useEffect(() => {
        (async () => {
            await carregar_estacoes()
        })()


        // if (data_client) { alert(data_client.user_glpi) }

    }, [])

    useEffect(() => {
        (async () => {
            // if (e.target.value.length > 0) {
            //     const regEx = new RegExp(e.target.value, 'i');
            //     const list = list_stations.filter(sta => sta.codigo_estacao.match(regEx))
            //     set_filtered_list(list)
            // } else {
            //     set_filtered_list([])
            // }
            const list = list_stations.filter(sta => sta.user === data_client._id).sort((a, b) => a.codigo_estacao.localeCompare(b.codigo_estacao))
            // const ordered_data = list
            set_filtered_list(list)
        })()
    }, [list_stations])



    useEffect(() => {
        // Object.keys(data_form).length > 0 && console.log(data_form)
        if (Object.keys(data_form).length === 0) {
            set_submit_is_disabled(true)
        } else if (data_form.codigo_localidade && data_form.codigo_localidade.length < 6) {
            set_submit_is_disabled(true)
        } else if (!data_form.nome || data_form.nome.length === 0) {
            set_submit_is_disabled(true)
        } else if (!data_form.sigla || data_form.sigla.length < 2) {
            set_submit_is_disabled(true)
        } else {
            set_submit_is_disabled(false)
        }

        if (Object.keys(data_form).length > 0) {
            let cod_estacao = data_form.codigo_localidade ? `${data_form.codigo_localidade}-` : ''
            cod_estacao += data_form.sigla ? `${data_form.sigla}` : ''
            
            if(useNumberToStationCode){
                cod_estacao += (useNumberToStationCode) ? `-` : ''
                cod_estacao += String(data_form.numero_estacao).padStart(3,'0') //data_form.numero_estacao === 0 ? '000' : data_form.numero_estacao >= 0 && data_form.numero_estacao < 10 ? `00${data_form.numero_estacao}` : data_form.numero_estacao > 9 && data_form.numero_estacao < 100 ? `0${data_form.numero_estacao}` : data_form.numero_estacao
            }

            set_codigo_estacao(cod_estacao)
        }

    }, [data_form, useNumberToStationCode])



    const handle_lista_municipios = async (value) => {
        if (value !== "") {
            const { data } = await api_bd.get(`/locations/${value}`)
            console.log(data)
            set_lista_municipios(data)
        }
    }


    const handle_data_form = (key, value) => {
        let data = { ...data_form }
        data[key] = value
        set_data_form(data)
    }


    const handle_codigo_localidade = (value) => {
        let codigo = ''
        if (value.length > 0 && value.indexOf(`${localidade_selecionada.sigla_estado}-`) > -1) {
            codigo = `${localidade_selecionada.sigla_estado}-` + value.split(`${localidade_selecionada.sigla_estado}-`)[1].toUpperCase()
        } else if (localidade_selecionada.sigla_estado) {
            codigo = `${localidade_selecionada.sigla_estado}-`
        }

        let data = { ...data_form }
        data['codigo_localidade'] = codigo
        set_data_form(data)

    }

    useEffect(() => {
        const list = filtered_list.filter(sta => sta.codigo_estacao === codigo_estacao)

        list.length > 0 ? set_estacao_duplicada(true) : set_estacao_duplicada(false)

    }, [codigo_estacao])

    return (
        <div className={styles.div_root_component} style={{ width }}>
            <Card className={styles.card}>
                <CardContent>
                    <CardHeader
                        title={props.edit ? "Editar Estação" : "Adicionar Estação"}
                        subheader=""
                    />
                    <form className={styles.form_cad_user} noValidate autoComplete="off">
                        <div style={{ width: "100%" }}>
                            <Autocomplete
                                options={lista_municipios}
                                getOptionLabel={(option) => `${option.nome} - ${option.UF.sigla}`}
                                id="localidade"
                                autoComplete
                                includeInputInList
                                renderInput={(params) => <TextField {...params} label="Localidade   *" margin="normal" />}
                                onChange={async (e) => {
                                    const loc = e.target.innerText ? e.target.innerText.split(' - ')[0] : ""
                                    const uf = e.target.innerText ? e.target.innerText.split(' - ')[1] : ""
                                    const data = await lista_municipios.filter((el) => {
                                        return (el.nome === loc && el.UF.sigla === uf)
                                    })
                                    if (data.length > 0) {
                                        set_localidade_selecionada({
                                            municipio: data[0].nome,
                                            nome_estado: data[0].UF.nome,
                                            sigla_estado: data[0].UF.sigla
                                        })

                                        let new_data = { ...data_form }
                                        new_data['codigo_localidade'] = `${data[0].UF.sigla}-`
                                        set_data_form(new_data)
                                        // console.log(data[0])
                                    } else {
                                        set_localidade_selecionada({})
                                    }

                                }}
                                onKeyUp={(e) => {
                                    handle_lista_municipios(e.target.value)
                                }}
                            />
                        </div>

                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    if (e.target.value.length > 7) { return false }
                                    handle_codigo_localidade(e.target.value)
                                }}
                                id="codigo_localidade"
                                label='CNL da localidade'
                                value={data_form.codigo_localidade ? data_form.codigo_localidade : 'Escolha a Localidade'}
                                required={true}
                            />
                        </div>

                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_form("nome", e.target.value.toUpperCase())
                                }}
                                id="name"
                                label="Descrição da Estação"
                                required={true}
                                value={data_form?.nome}

                            />
                        </div>


                        <div style={{ width: "100%", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <TextField
                                className={styles.form_input}
                                id="numero_estacao"
                                label="Número da Estação"
                                type="number"
                                disabled={!useNumberToStationCode}
                                onChange={e => {
                                    const num = parseInt(e.target.value) || 0
                                    if (num < 0 || num > 999) { return false }
                                    handle_data_form("numero_estacao", num)
                                }}
                                value={data_form.numero_estacao}
                                required={true}
                            />

                            <div className='flex flex-row items-center'>
                                <span className='mr-2 w-[130px]'>Usar número no código: </span>
                                <input type='checkbox' checked={useNumberToStationCode} onChange={(e) => {
                                    setUseNumberToStationCode(e.target.checked)
                                    console.log(e.target.checked)
                                }} />
                            </div>
                        </div>


                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    if (e.target.value.length > 5) { return false }
                                    handle_data_form("sigla", e.target.value.toUpperCase())
                                }}
                                id="sigla"
                                label="Sigla da Estação"
                                required={true}
                                value={data_form?.sigla}
                            />
                        </div>

                        <div style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
                            <TextField
                                className={styles.form_input}
                                id="codigo_da_estacao"
                                label="Código da Estaçao"
                                disabled={true}
                                value={codigo_estacao}
                            />
                        </div>

                        <div style={{ width: "100%" }}>
                            <Button
                                className={styles.btn_submit}
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={submit_is_disabled}
                                onClick={async () => {

                                    // const filters = {
                                    //     nome: "Nome",
                                    //     sigla: "Sigla da Estação"
                                    // }

                                    // for (let f in Object.values(filters)) {
                                    //     let filter = Object.keys(filters)[f]
                                    //     if (data_form[filter] === undefined || data_form[filter] == '') {
                                    //         return alert(`Campo obrigatório: ${filters[filter].toUpperCase()}`)
                                    //     }
                                    // }
                                    // console.log({ ...data_form, codigo_estacao, localidade: localidade_selecionada })
                                    try {
                                        await api_bd.post('/stations', { ...data_form, codigo_estacao, localidade: localidade_selecionada })
                                        alert(`Estação cadastrada com sucesso!\nNome: ${data_form.nome}\nCódigo: ${codigo_estacao}`)
                                        // set_data_new_station(resp.data)
                                        await carregar_estacoes()
                                    } catch (error) {
                                        if (error.message.indexOf('code 400') > -1) {
                                            alert("Verifique os dados e tente novamente.")
                                        }
                                    }

                                }}
                            >
                                Salvar
                            </Button>
                            {(data_form.codigo_localidade && data_form.codigo_localidade.length < 6) &&
                                <p style={{ marginBottom: 15, color: 'red', fontSize: '0.8em' }}>No campo <b>CNE da Localidade</b> o código do município que é inserido após o código do estado deve ter no mínimo 3 caracteres'</p>
                            }
                            {(data_form.sigla && data_form.sigla.length < 2) &&
                                <p style={{ color: 'red', fontSize: '0.8em' }}>O campo <b>Sigla da Estação</b> deve ter no mínimo 2 caracteres'</p>
                            }
                            {estacao_duplicada &&
                                <p style={{ color: 'red', fontSize: '0.8em' }}>Já existe uma estação com o codigo <b>{codigo_estacao}</b> cadastrada para este cliente.</p>
                            }
                        </div>
                    </form>
                    {list_stations.length > 0 && !only_cad &&
                        <>
                            <hr style={{ marginTop: 20 }} />
                            <CardHeader
                                title={`${data_client.nome.toUpperCase()}: Lista de Estações`}
                                subheader=""
                            />


                            <List component="nav" aria-label="main mailbox folders">
                                {filtered_list.map((el, index) => (
                                    <ListItem button>
                                        <>
                                            <ListItemIcon key={`${el.codigo_estacao}${index}`}>
                                                <span className="material-icons">business</span>
                                            </ListItemIcon>
                                            <ListItemText primary={el.codigo_estacao} secondary={el.nome} />
                                            <ListItemIcon>
                                                <IoIcons.IoIosTrash
                                                    size={26}
                                                    style={{ marginBottom: -3, marginRight: 20, cursor: "pointer" }}
                                                    onClick={async () => {
                                                        if (window.confirm(`Tem certeza que excluir: ${el.codigo_estacao} / ${el.nome}`)) {
                                                            try {
                                                                const resp = await api_bd.delete(`/stations/${el._id}`)
                                                                // alert(`/stations/${el._id}`)
                                                                await carregar_estacoes()
                                                            } catch (error) {
                                                                if (error.message.indexOf('code 400') > -1) {
                                                                    alert("Ocorreu um erro tente novamente.")
                                                                }
                                                            }
                                                        }
                                                    }}
                                                />
                                            </ListItemIcon>
                                        </>
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    }
                </CardContent>
            </Card>
        </div>
    );
}

export default FormDataStation;

//Schema Cliente
//{"nome":"fulano","cnpj_1":"123","cnpj_2":"321","cnpj_3":"123","end":"Avenida teste","contato":"contato teste","fone_1":"fone1","fone_2":"fone2","fone_3":"fone 3","email_1":"email1","email_2":"email2","password":"senha","link_whats":"wa.me","situacao":"inativo","cliente_desde":"10/01/2020"

