import React, { useState, useRef, useEffect } from 'react'

import DiagramEditor from './draioIntegration'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import { profileData } from '../../atoms/user.atom'
import clientDetails from '../../atoms/client_details.atom'

import styles from './styles.module.css'

import { api_bd_nr } from '../../services/Api'

const { api_backend_address_nr } = require('../../config.json')

function Diagram() {
	const baseImg = `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHdpZHRoPSIxcHgiIGhlaWdodD0iMXB4IiB2aWV3Qm94PSItMC41IC0wLjUgMSAxIiBjb250ZW50PSImbHQ7bXhmaWxlIGV0YWc9JnF1b3Q7bjJmTXJuZ1Brc3dZbnhBSk1yMXEmcXVvdDsgYWdlbnQ9JnF1b3Q7TW96aWxsYS81LjAgKE1hY2ludG9zaDsgSW50ZWwgTWFjIE9TIFggMTBfMTVfNykgQXBwbGVXZWJLaXQvNTM3LjM2IChLSFRNTCwgbGlrZSBHZWNrbykgQ2hyb21lLzExNS4wLjAuMCBTYWZhcmkvNTM3LjM2JnF1b3Q7IG1vZGlmaWVkPSZxdW90OzIwMjMtMDgtMTBUMTg6MjA6MjQuNTM2WiZxdW90OyBob3N0PSZxdW90O2VtYmVkLmRpYWdyYW1zLm5ldCZxdW90OyB2ZXJzaW9uPSZxdW90OzIxLjYuNiZxdW90OyB0eXBlPSZxdW90O2VtYmVkJnF1b3Q7Jmd0OyZsdDtkaWFncmFtIGlkPSZxdW90O3JVdXh2bWFtZE5aMXpyTFhPbF82JnF1b3Q7IG5hbWU9JnF1b3Q7UGFnZS0xJnF1b3Q7Jmd0O2xkRkJENElnRkFEZ1g4UFJEYUcycmsydExyV1doelp2VEFqWmtPZVFwdlhyMDRrWjYxSW40T1B4NEQwUVRlcCtiMWxUSFlFTGpRam1QYUlwSWlTT0tSbUdVUjZUYkFpZFFGckZmZEFDdVhvS2o5anJYWEhSQm9FT1FEdlZoRmlDTWFKMGdURnJvUXZEYnFERFd4c214UmZrSmRQZmVsWGNWYjZLTlY3OElKU3M1cHRqN0hkcU5nZDdhQ3ZHb2ZzZ21pR2FXQUEzemVvK0VYcHMzdHlYVTN4T1Y0UmVJbFBnVXBKdFZrZ1ZUY2wyL3h4NWwyQ0ZjYittSGliTDA0WkY4TUUwZXdFPSZsdDsvZGlhZ3JhbSZndDsmbHQ7L214ZmlsZSZndDsiPjxkZWZzLz48Zy8+PC9zdmc+`
	const diagramRef = useRef(null)
	const [diagram, setDiagram] = useState('')//useState('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHdpZHRoPSIxMjFweCIgaGVpZ2h0PSI2MXB4IiB2aWV3Qm94PSItMC41IC0wLjUgMTIxIDYxIiBjb250ZW50PSImbHQ7bXhmaWxlIGV0YWc9JnF1b3Q7VGdBR2JKbGNJaGw3a1JuRGFxSDQmcXVvdDsgYWdlbnQ9JnF1b3Q7TW96aWxsYS81LjAgKE1hY2ludG9zaDsgSW50ZWwgTWFjIE9TIFggMTBfMTRfNikgQXBwbGVXZWJLaXQvNTM3LjM2IChLSFRNTCwgbGlrZSBHZWNrbykgQ2hyb21lLzgwLjAuMzk4Ny4xMDYgU2FmYXJpLzUzNy4zNiZxdW90OyBtb2RpZmllZD0mcXVvdDsyMDIwLTAyLTE5VDEyOjQ0OjI3LjY1OVomcXVvdDsgaG9zdD0mcXVvdDt0ZXN0LmRyYXcuaW8mcXVvdDsgdmVyc2lvbj0mcXVvdDtARFJBV0lPLVZFUlNJT05AJnF1b3Q7Jmd0OyZsdDtkaWFncmFtIGlkPSZxdW90O3JVdXh2bWFtZE5aMXpyTFhPbF82JnF1b3Q7IG5hbWU9JnF1b3Q7UGFnZS0xJnF1b3Q7Jmd0O2xaUExic0l3RUVXL0prc2t4Nll0V3dvcGZhaWxLcXFRMkpsNGNGdzVHZVFZU1ByMVRZaWRCeXphcmpJK21VZm1YaWRnczdSWUdMNVBYbEdBRGlnUlJjRG1BYVVocGF4NjFLUnN5VjFEcEZIQ3NRNnMxRGM0U0J3OUtBSDVJTkVpYXF2MlF4aGpsa0ZzQjR3Ymc2ZGgyZzcxY09xZVM3Z0NxNWpyYTdwV3dpWU5uZHlRamorQ2tvbWZIQkwzSnVVKzJZRTg0UUpQUGNTaWdNME1vbTJpdEppQnJ0WHp1cnlGNy9NeFpSK2piRU5pU2FmUlJxcFIwK3poUHlYdENnWXkrOWZXbnptWTVmYXJscFFTemJlVnIrZktsZVhHTmczOTBPeXdIZHVuWTFnc1g5YlBiSWU0THFlamJzUDJJM05iZWxVTkhqSUJkVDBKMkwzVVBNOWQzS3BVSDVvNVJ6QVdpZ3M3ZnRrbDdJMWZBS1pnVFZuVnVTN01lK0p1NWNRZFQ1M0RvVTlKZXU3ZU9zYmRwWkp0NTA2NEtuQWIrMk5QU284NjE4L3B2WitIUlQ4PSZsdDsvZGlhZ3JhbSZndDsmbHQ7L214ZmlsZSZndDsiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiByZ2IoMjU1LCAyNTUsIDI1NSk7Ij48ZGVmcy8+PGc+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEyMCIgaGVpZ2h0PSI2MCIgZmlsbD0iI2ZmZmZmZiIgc3Ryb2tlPSIjMDAwMDAwIiBwb2ludGVyLWV2ZW50cz0iYWxsIi8+PGcgZmlsbD0iIzAwMDAwMCIgZm9udC1mYW1pbHk9IkhlbHZldGljYSIgdGV4dC1hbmNob3I9Im1pZGRsZSIgZm9udC1zaXplPSIxMnB4Ij48dGV4dCB4PSI1OS41IiB5PSIzNC41Ij5TdGFydDwvdGV4dD48L2c+PC9nPjwvc3ZnPg==")

	const profile_user = useRecoilValue(profileData)
	const data_client = useRecoilValue(clientDetails)

	//Image data
	// const [src_image, set_src_image] = useState(false)
	// const [device_height, set_device_height] = useState(null)
	const [image_height, set_image_height] = useState(null)
	const [image_width, set_image_width] = useState(null)
	const [show_loading, set_show_loading] = useState(true)

	const [errorMessage, setErrorMessage] = useState('')


	const diagramEditor = new DiagramEditor

	const saveData = (_data, _client) => {
		if (errorMessage) {
			setErrorMessage('')
		}

		return new Promise((resolve, reject) => {
			api_bd_nr.post('/diagrams', {
				client: _client,
				dados: _data
			})
				.then(res => resolve(res.data))
				.catch(e => reject(e))
		})
	}

	const loadData = (_client, _setDiagram) => {
		api_bd_nr.get(`/diagrams?client=${_client}`).then(res => {
			if (res.data.message && res.data.message === 'error') {
				// console.log('setBase', baseImg)
				_setDiagram(baseImg)
				set_show_loading(false)
				setErrorMessage("Nenhum diagrama encontrado.")
			} else {
				_setDiagram(res.data.diagram)
			}
		})
	}

	const startEdit = () => {
		// console.log('start edit called')
		diagramEditor.editElement(diagramRef.current)
	}

	diagramEditor.setElementData = function (elem, data) {
		var name = elem.nodeName.toLowerCase();

		// console.log(data)

		saveData(data, data_client._id).then(res => console.log)

		if (name == 'svg') {
			// console.log('setElement SVG::', data)
			elem.outerHTML = atob(data.substring(data.indexOf(',') + 1));
		}
		else {
			// console.log('setElement IMG::', data)
			elem.setAttribute((name == 'img') ? 'src' : 'data', data);
		}

		return elem;
	};

	const zoomIn = () => {
		set_image_width(old => {
			return old + (old * 0.20)
		})
		set_image_height(old => {
			return old + (old * 0.20)
		})
	}

	const zoomOut = () => {
		set_image_width(old => {
			return old - (old * 0.20)
		})
		set_image_height(old => {
			return old - (old * 0.20)
		})
	}

	useEffect(() => {
		if (Object.keys(data_client).length > 0) {
			// console.log(data_client)
			loadData(data_client._id, setDiagram)
		}
	}, [data_client])

	useEffect(() => {
		const dw = document.documentElement.clientWidth - 50
		const dh = document.documentElement.clientHeight - 50
		// set_device_width(dw)
		// set_device_height(dh)

		set_image_height(dh - 110)
		set_image_width(dw)

		document.onkeydown = (evt) => {
			switch (evt.keyCode) {
				case 187:
					//zoon in
					zoomIn()
					break;
				case 189:
					//zoon out
					zoomOut()
					break;

				default:
					break;
			}
		}

	}, [])


	return (
		<div className={`${styles.content} flex h-full w-full p-5`}>

			{show_loading &&
				<div className="flex" style={{ position: 'absolute', justifyContent: 'center', alignItems: 'center', width: image_width, maxWidth: image_width, height: image_height, maxHeight: image_height }}>
					<div className="loader" style={{ width: 50, height: 50, marginRight: 10 }}></div>
					<h2>Carregando mapa, por favor aguarde...</h2>
				</div>
			}

			{show_loading === false &&
				<>
					{ profile_user.nivel.match(/técnico|super/i) &&
						<div
							className='flex flex-row items-center bg-intec-500 cursor-pointer decoration-inherit hover:bg-intec-600 rounded text-white bold px-5 py-3 absolute top-30 left-10'
							onClick={() => {
								startEdit()
							}}
						>
							<span className="material-icons mr-2 ml-0 pl-0">edit</span>	EDITAR
						</div>
					}

					<div onContextMenu={() => false} onDragStart={() => false} onSelect={() => false}
						style={{
							position: 'absolute',
							top: 80,
							right: 40
						}}
					>
						<button
							className="material-icons"
							style={{
								backgroundColor: 'Transparent',
								backgroundRpeat: 'no-repeat',
								color: '#3c5ca6',
								border: 'none',
								overflow: 'hidden',
								outline: 'none',
								fontSize: 35,
								cursor: 'pointer'
							}}
							onClick={() => {
								zoomIn()
							}}
						>zoom_in</button>

						<button
							className="material-icons"
							style={{
								marginLeft: 10,
								backgroundColor: 'Transparent',
								backgroundRpeat: 'no-repeat',
								color: '#3c5ca6',
								border: 'none',
								overflow: 'hidden',
								outline: 'none',
								fontSize: 35,
								cursor: 'pointer'
							}}
							onClick={() => {
								zoomOut()
							}}
						>zoom_out</button>
					</div>
				</>
			}

			{errorMessage &&
				<div className="flex" style={{ marginTop: 100, position: 'absolute', fontSize: '1.5em', justifyContent: 'center', alignItems: 'center', width: image_width, maxWidth: image_width, height: image_height - 200, maxHeight: image_height - 200 }}>
					{errorMessage}
				</div>
			}

			<div className='m-3'>
				<img
					alt="Esquema de rede v2"
					ref={diagramRef}
					src={diagram}
					onLoad={() => {
						set_show_loading(false)
					}}
					className='ease-in-out duration-500'
					style={{
						width: image_width,
						maxWidth: image_width,
						height: image_height,
						maxHeight: image_height,
						visibility: show_loading ? 'hidden' : 'visible'
					}}
				/>
			</div>

		</div>
	);
}

export default Diagram
