import React, { useState, useEffect } from 'react';
import { api_bd_nr } from '../../services/Api';

const verifyAuthorization = (userId) => {
    return new Promise((resolve, reject) => {
        api_bd_nr.get(`/authtreatment?customer_id=${userId}`)
            .then(resp => {
                if (resp.data.length > 0 && resp.data[0].authorizationState === true) {
                    resolve(true)
                }
            }, {
                timeout: 3000
            })
            .catch(error => {
                reject(false)
            })
    })
}

function TimeContract({ userId, setAuthPlay }) {
    const [report, setReport] = useState({
        nome: '',
        horasContrato: 0,
        horasContratoMs: 0,
        tempoTotal: 0,
        tempoTotalF: '00:00',
        analistas: {},
        month: '',
        year: 0
    })
    const [classColor, setClassColor] = useState('#fff')


    const loadData = (setData) => {
        api_bd_nr.get(`/getcustomertime?id=${userId}&fromuserid=true`).then(async (resp) => {
            if (resp.data?.nome) {
                const contratoMS = (resp.data.horasContrato * 3600000)
                const percentUtilization = resp.data.horasContrato === 0 ? 0 : (resp.data.tempoTotal / contratoMS) * 100

                setData(old => {
                    return (resp.data)
                })

                if (percentUtilization <= 60) {
                    setClassColor('#00b00f') // verde
                    setAuthPlay(true)

                } else if (percentUtilization > 60 && percentUtilization <= 100) {
                    setClassColor('orange') // laranja
                    setAuthPlay(true)

                } else if (percentUtilization > 100) {
                    setClassColor('#ff0000') // vermelho

                    let isAuthorized = await verifyAuthorization(userId)
                    isAuthorized === true ? setAuthPlay(true) : setAuthPlay(false)
                    // verificar a liberação do play apos o ercebimento do SMS
                }


            }
        }).catch(error => {
            console.log(error)
        })
    }


    useEffect(() => {
        if (userId) {
            loadData(setReport)
        }
    }, [])

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }} >
            <span style={{ margin: 0 }}>CONSUMO CONTRATO: </span>
            {classColor === '#00b00f' && <span style={{ marginLeft: 5, marginRight: 5 }}>✅</span>}
            {classColor === 'orange' && <span style={{ marginLeft: 5, marginRight: 5 }}>⚠️</span>}
            {classColor === '#ff0000' && <span style={{ marginLeft: 5, marginRight: 5 }}>🟥</span>}
            <span style={{ margin: 0, color: classColor, fontWeight: 'bold' }}>{report.tempoTotal === 0 ? '00:00' : report.tempoTotalF} / {String(report.horasContrato).padStart(2, '0') + ':00'}</span>
        </div>
    )


}

export default TimeContract;