import React, { useState, useEffect, memo } from 'react';

import './MonitoramentoNoturno.css';

import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import EventDetail from './EventDetail';
import FormRompimento from './FormRompimento';
import FormEstacaoIndisponivel from './FormEstacaoIndisponivel';
import FormLinkComProblema from './FormLinkComProblema';
import FormClienteComProblema from './FormClienteComProblema';

import { api_bd } from '../../services/Api'

import { useRecoilState, useRecoilValue } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'
import customerData from '../../atoms/customers.atom'
import nightMonitoringComponentToShowState from '../../atoms/night_monitoring _state.atom'

import Modal from '../Modal';
// import NewTicket from './NewTicket';
import NewInteraction from './NewInteraction';
// import TicketList from './TicketList';

import { msToTime } from '../../util/fns';

// const { backend_files_address } = require('../../config.json')

function TicketRow({ ev, buscar_eventos }) {
    const [componentToShow, setComponentToShow] = useRecoilState(nightMonitoringComponentToShowState)
    const [evDetail, setEvDetail] = useState(null)
    const profile_user = useRecoilValue(profileData)

    const buscarEventos = buscar_eventos

    const startTreatment = async (_args) => {
        const { id, user } = _args
        const resp = await api_bd.put(`/night_monitoring/starttreatment/${id}`, {
            id_usuario: user.id,
            nivel: user.nivel,
            nome: user.nome,
            inicio: Date.now()
        })
        buscarEventos()
        // console.log(resp.status, resp.data)
    }

    const stopTreatment = async (_args) => {
        const { id, user } = _args
        const resp = await api_bd.put(`/night_monitoring/stoptreatment/${id}`, {
            id_usuario: user.id,
            nivel: user.nivel,
            nome: user.nome,
            fim: Date.now()
        })
        buscarEventos()
        // console.log(resp.status, resp.data)
    }

    const verifyIsRunning = (ev) => {
        return ev.tratativas.find(tr => (
            (tr.id_usuario === profile_user.id) && (tr.inicio !== null && tr.fim === null)
        )) ? true : false
    }

    const isRunning = verifyIsRunning(ev)

    const et = ev.tratativas.filter(tr => tr.fim !== null).reduce(function (accumulator, curValue) {
        return accumulator + curValue.elapsedTime
    }, 0)

    let elapsedTime = 0
    if (isRunning === false) {
        elapsedTime = msToTime(et)
    } else {
        let ini = ev.tratativas.filter(tr => tr.fim === null)[0].inicio
        let timeIni = new Date(ini).getTime()
        let timeEnd = new Date().getTime()
        elapsedTime = msToTime((et + (timeEnd - timeIni)))
    }

    const minutesToAlert = 60
    let blinkClass = ''
    
    if(ev.interacoes.length > 0){
        blinkClass = (ev.status === 'aberto' && (((new Date().getTime() - new Date(ev.interacoes[ev.interacoes.length - 1].data).getTime() > (minutesToAlert * 60 * 1000)))) ? ' blink' : '')
    }else{
        blinkClass = (ev.status === 'aberto' && (((new Date().getTime() - new Date(ev.createdAt).getTime() > (minutesToAlert * 60 * 1000)))) ? ' blink' : '')
    }
    
    // console.log('ELAPSED TIME MS:: ', et)
    // const elapsedTime = isRunning ? msToTime(et)

    return (
        <div className={`card ${blinkClass}`} style={{ width: '99%', marginLeft: 10, marginRight: 10, paddingTop: 10, paddingBottom: 15, marginRight: 3, marginLeft: 3, minHeight: 150 }}>
            {/* LINE BUTTONS */}
            <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', width: '100%', borderBottom: '1px solid  #3c5ca6', marginBottom: 10 }}>

                <div style={{ display: 'flex', flexDirection: 'row', width: '70%', alignItems: 'center', gap: 10, alignSelf: 'center', fontSize: '1.3em' }}>
                    Nº: {(ev.cod_id >= 0 && ev.cod_id < 10) ? '000' : (ev.cod_id >= 10 && ev.cod_id < 100) ? '00' : (ev.cod_id >= 100 && ev.cod_id < 1000) ? '0' : ''}{ev.cod_id} - {ev.tipo_evento}
                </div>

                {/* Action Buttons */}
                <div style={{ display: 'flex', flexDirection: 'row', width: '30%', alignItems: 'center', gap: 12, justifyContent: 'flex-end' }}>
                    <div style={{ position: 'relative', cursor: 'pointer' }}>
                        {isRunning !== true &&
                            <span className="material-icons left_icon" style={{ margin: 0, fontSize: '2.2em' }}
                                onClick={() => {
                                    startTreatment({
                                        id: ev._id,
                                        user: profile_user
                                    })
                                }}
                            >play_circle</span>
                        }
                        {isRunning === true &&
                            <>
                                <div className='load' style={{ position: 'absolute', width: 26, height: 26, zIndex: -1 }}></div>
                                <span className="material-icons left_icon" style={{ margin: 0, fontSize: '2.2em' }}
                                    onClick={() => {
                                        stopTreatment({
                                            id: ev._id,
                                            user: profile_user
                                        })
                                    }}
                                >stop_circle</span>
                            </>
                        }
                    </div>

                    {/* <div style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setComponentToShow({
                                component: 'eventDetail',
                                data: ev
                            })
                        }}
                    >
                        <span className="material-icons left_icon" style={{ margin: 0, fontSize: '2.2em' }}>visibility</span>
                    </div> */}

                    <div style={{ flexDirection: 'row', cursor: 'pointer' }}
                        onClick={() => {
                            setComponentToShow({
                                component: 'newInteraction',
                                data: ev
                            })
                        }}

                    >
                        <span className="material-icons left_icon" style={{ position: 'absolute', marginLeft: -7, marginTop: -5, fontSize: '1.5em' }}>add</span>
                        <span className="material-icons left_icon" style={{ fontSize: '2em', marginLeft: 3, marginRight: 0, marginTop: 3 }}>edit_square</span>
                    </div>

                    <div style={{ flexDirection: 'row', cursor: 'pointer', marginBottom: ev.interacoes.length > 0 ? -8 : -2 }}
                    onClick={() => {
                        setComponentToShow({
                            component: 'eventDetail',
                            data: ev
                        })
                    }}
                    >
                        {ev.interacoes.length > 0 &&
                            <div className='default_color' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 20, height: 20, borderRadius: 10, position: 'absolute', marginLeft: 12, marginTop: -5, fontSize: '1.5em', color: '#fff', fontSize: 13 }}>{ev.interacoes.length}</div>
                        }
                        <span className="material-icons left_icon" style={{ marginLeft: 0, marginRight: 0, fontSize: '2.2em' }}>menu</span>
                    </div>

                    <div style={{ cursor: 'pointer', marginBottom: -2 }}
                        onClick={() => {
                            setComponentToShow({
                                component: 'closeTicket',
                                data: ev
                            })
                        }}
                    >
                        <span className="material-icons left_icon" style={{ margin: 0, fontSize: '2.2em' }}>task</span>
                    </div>
                </div>

            </div>
            {/* END LINE BUTTONS */}

            {/* CONTENT ITEM */}
            <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', width: '100%', flexDirection: 'column' }}>
                <span style={{ margin: 0, }}>Cliente: {ev.customer_name}</span>
                <span style={{ margin: 0, }}>Horário do incidente: {ev.horario}</span>
                <span style={{ margin: 0, }}>Última interação: {ev.interacoes.length > 0 ? ( new Date().toLocaleDateString() === new Date(ev.interacoes[ev.interacoes.length - 1].data).toLocaleDateString() ? new Date(ev.interacoes[ev.interacoes.length - 1].data).toLocaleTimeString() : new Date(ev.interacoes[ev.interacoes.length - 1].data).toLocaleDateString()+ ' às ' +new Date(ev.interacoes[ev.interacoes.length - 1].data).toLocaleTimeString()) : 'Nenhuma interação realizada neste ticket'}</span>
                <span style={{ margin: 0, }}>Tempo de tratativa: {elapsedTime.horas}:{elapsedTime.minutos}:{elapsedTime.segundos}</span>
                <span style={{ margin: 0, }}>Ticket aberto em: {new Date(ev.createdAt).toLocaleDateString()} às {new Date(ev.createdAt).toLocaleTimeString()}</span>
            </div>
            {/* END CONTENT ITEM */}


            {/* {(evDetail !== null) &&
                <Modal callbackfn={() => {
                    setEvDetail(null)
                }}>
                    <EventDetail ev={evDetail} />
                </Modal>
            }

            {showFormNewInteraction &&
                <Modal callbackfn={() => {
                    setShowFormNewInteraction(false)
                }}>
                    <div className='card' style={{ padding: 30, width: '80%', maxWidth: '80%', height: '85vh', backgroundColor: '#fff', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                        <NewInteraction ev={ev} callbackfn={() => {
                            setShowFormNewInteraction(false)
                        }} />
                    </div>
                </Modal>
            } */}
        </div>
    )
}

export default memo(TicketRow);