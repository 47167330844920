import React, { useState, useEffect, useRef } from 'react';

import styles from './styles.module.css'

import { api_bd } from '../../services/Api'

import { useRecoilValue } from 'recoil';

import { realtimeState } from '../../atoms/realtimeState.atom';

const configs = require('../../config.json')

// import { Container } from './styles';


function ViewPost(props) {

    const [postData, sePostData] = useState({})

    const [btnSendCommentDisabled, setBtnSendCommentDisabled] = useState(false)

    const realtimeData = useRecoilValue(realtimeState)

    const listCommentsRef = useRef(null)

    const scrollToBottom = (id) => {
        const element = document.getElementById(id)
        if (element) {
            // const height = element.scrollHeight
            // setTimeout(()=>{
            //     element.scrollTop = height
            // },1000)
            element.scrollTo({
                top: element.scrollHeight,
                behavior: 'smooth'
            });
        }
    }

    const setPostRead = (_idNode, _idUser) => {
        api_bd.put(`/outages/teamnotes/note/setread/${_idNode}/${_idUser}`)
    }

    const loadPost = (_idNode) => {
        api_bd.get(`/outages/teamnotes/note/${_idNode}`).then(resp => {
            if(resp.data.read){
                if(resp.data.read.indexOf(props.user_profile.id) === -1){
                    setPostRead(resp.data._id, props.user_profile.id)
                }
            }

            if (postData.comments) {
                if (resp.data.comments && resp.data.comments.length !== postData.comments.length) {
                    sePostData(resp.data)
                    scrollToBottom("commentlist")
                }
            } else {
                sePostData(resp.data)
                scrollToBottom("commentlist")
            }

        })
    }

    useEffect(()=>{
        if(Object.keys(realtimeData).length > 0){
            if(realtimeData.topic === 'teamnotes_update' && realtimeData.data.match(/new_comment|comment_deleted/i)){
                // console.log("View Posts: ",realtimeData)
                loadPost(props.idNote)
            }
        }
    },[realtimeData])

    useEffect(() => {
        loadPost(props.idNote)
        // let interval = setInterval(() => {
        //     loadPost(props.idNote)
        // }, 5000)

        return () => {
            // clearInterval(interval)
        }
    }, [])

    return (
        <div className={styles.notificationsContentModal}>
            {Object.keys(postData).length > 0 &&
                <>

                    <div className={styles.notificationsContentModalOriginalMessage} >
                        <div>
                            <img style={{ objectFit: 'cover' }} src={`${configs.api_backend_address}users/getuserimg/${postData.user.username}`} />
                        </div>
                        <div>
                            <div>
                                <p><b style={{ fontSize: '1.1em' }}>{postData.title}</b></p>
                                <p style={{ marginTop: 10 }}><b>Mensagem:</b><br /> {postData.text}</p>
                            </div>
                            <p className={styles.notificationUserNameInMsg}><b>Usuário:</b> {postData.user.nome}</p>
                        </div>

                        {( (postData.user.username === props.user_profile.username) || (props.user_profile.nivel.match(/super/i)) ) &&
                            <span
                                style={{
                                    float: 'right',
                                    cursor: 'pointer'
                                }}
                                className={`material-icons`}
                                onClick={() => {
                                    // delete('/teamnotes/dellcomment/:idnote/:idcomment'
                                    let confirmation = window.confirm('Deseja realmente excluir esta postagem?')
                                    if (confirmation) {
                                        api_bd.delete(`/outages/teamnotes/dellnote/${postData._id}`, {})
                                            .then(resp => {
                                                props.setShowModal(false)
                                            })
                                            .catch(e => {
                                                alert('Ocorreu um erro ao tentar excluir, tente novamente mais tarde.')
                                            })
                                    }
                                }}
                            >
                                delete
                            </span>
                        }
                    </div>

                    <div id="commentlist" style={{ overflowY: 'auto' }}>

                        {postData.comments.map(comment => (
                            <div key={`${comment.date}`} className={`${styles.notificationsContentModalComments} ${(comment.user.username === props.user_profile.username) ? styles.CommentsFromMe : ''}`} >

                                <div>
                                    <img src={`${configs.api_backend_address}users/getuserimg/${comment.user.username}`} />
                                </div>
                                <div>
                                    {/* <p><b>Titulo:</b> {postData.title}</p> */}
                                    {/* <p style={{ whiteSpace: 'pre-wrap' }}><b>Mensagem:</b> <br />{comment.text}</p> */}
                                    <p style={{ whiteSpace: 'pre-wrap' }}>{comment.text}</p>
                                    <p className={styles.notificationUserNameInMsg}><b>Usuário:</b> {comment.user.nome}</p>
                                </div>
                                {comment.user.username === props.user_profile.username &&
                                    <span
                                        style={{
                                            float: 'left',
                                            cursor: 'pointer'
                                        }}
                                        className={`material-icons`}
                                        onClick={() => {
                                            api_bd.delete(`/outages/teamnotes/dellcomment/${postData._id}/${comment._id}`, {})
                                                .then(resp => {
                                                    loadPost(props.idNote)
                                                    // props.setContentModal(old => {
                                                    //     const filteredComments = old.comments.filter(el => el._id !== comment._id)
                                                    //     return ({ ...old, comments: filteredComments })
                                                    // })
                                                })
                                                .catch(e => {
                                                    alert('Ocorreuum erro ao tentar excluir, tente novamente mais tarde.')
                                                })
                                        }}
                                    >
                                        delete
                                    </span>
                                }
                            </div>

                        ))}
                    </div>


                    <div className={styles.notificationsTextbox}>
                        <textarea
                            placeholder='Digite um comentário.'
                            // onChange={(e) => {
                            //     setTextComment(e.target.value)
                            // }}
                            id="textcomment"
                        ></textarea>
                        <div
                            style={{
                                backgroundColor: btnSendCommentDisabled ? '#c3c3c3' : '#3c68ce'
                            }}
                            onClick={() => {
                                const textComment = document.getElementById('textcomment').value

                                if (textComment === '') {
                                    return alert("O campo comentário deve ser preenchido.")
                                }

                                if (btnSendCommentDisabled === false) {
                                    setBtnSendCommentDisabled(true)


                                    const comment = {
                                        text: textComment,
                                        user: {
                                            nome: props.user_profile.nome,
                                            username: props.user_profile.username,
                                            nivel: props.user_profile.nivel
                                        },
                                        date: new Date()
                                    }

                                    //addcomment
                                    api_bd.put(`/outages/teamnotes/addcomment/${postData._id}`, comment).then(resp => {
                                        // props.setContentModal(old => {
                                        //     let comments = [...old.comments]
                                        //     comments.push(comment)
                                        //     return ({ ...old, comments })
                                        // })
                                        document.getElementById('textcomment').value = ''
                                        loadPost(props.idNote)
                                        setBtnSendCommentDisabled(false)
                                    }).catch(e => {
                                        alert('Ocorreu um erro ao enviar os dados, tente novamente mais tarde.')
                                        setBtnSendCommentDisabled(false)
                                    })
                                }
                            }}
                        >
                            <span className={`material-icons`}>{btnSendCommentDisabled ? 'hourglass_empty' : 'keyboard_arrow_right'}</span>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default ViewPost;