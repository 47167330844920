import React, { useState, useEffect } from 'react';

import './Outages.css';

import { api_bd } from '../../services/Api'

import { backend_ticket_files } from '../../config.json'

function OutageDetail(props) {
    const [eventos, setEventos] = useState([])
    const [loadMessage, setLoadMessage] = useState('Carregando.......')

    const style_p = { marginTop: 5, marginBottom: 5 }
    const style_span = { marginLeft: 0, marginTop: 5, marginBottom: 5, width: '50%' }
    const style_b = {}

    const style_intera_span = { marginLeft: 0, marginTop: 5, marginBottom: 5 }
    const style_intera_b = {}

    const labels = {
        tipo_interacao: "Tipo de Interação",
        acionou_plantonista: "Acionou o plantonista",
        hora_acionamento: "Hora do acionamento",
        quem_foi_acionado: "Quem foi acionado",
        foi_escalonado: "Foi escalonado",
        hora_escalonado: "Hora do escalonamento",
        horario_inicio: "Horário",
        hora_contato: "Horário do contato",
        quem_foi_contatado: "Pessoa que foi contatada",
        observacao: "Observação",
        pra_quem_foi_escalonado: "Para quem foi escalonado",
        porque_nao_foi_escalonado: "Porque não foi escalonado",
        previsao_para_solucao: "Existe previsão para solução",
        foi_solucionado: "Foi solucionado",
        hora_solucao: "Hora que foi solucionado",
        porque_nao_foi_solucionado: "Porque não foi solucionado",
        feedback_do_suporte: "Feedback do suporte",
        userName: "Analista",
        data: "Data do registro",
        escalonar_diurno: "Escalonado para o suporte diurno",
        id_ticket_escalonamento_diurno: "ID do ticket no SGI",
        qual_foi_solucao: "Solução",
        userId: "",
        userLevel: "",
        _id: "",
    }

    const ShowInteraction = ({ data }) => {
        const render = []

        let counter = 1
        for (let el in data) {
            if (!el.match(/userId|userLevel|_id/i) && data[el] !== '') {
                if (el === 'data') {
                    render.push(<div style={{
                        display: 'flex',
                        width: '49%',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        borderLeft: counter % 2 == 0 ? '1px solid #c3c3c3' : '',
                        paddingLeft: 8
                    }}><span style={style_intera_span}>{labels[el]}:&nbsp; <b style={style_intera_b}>{new Date(data[el]).toLocaleDateString()} às {new Date(data[el]).toLocaleTimeString()}</b></span></div>)
                } else if (el === 'escalonar_diurno') {
                    render.push(<div style={{
                        display: 'flex',
                        width: '49%',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        borderLeft: counter % 2 == 0 ? '1px solid #c3c3c3' : '',
                        paddingLeft: 8
                    }}><span style={style_intera_span}>{labels[el]}:&nbsp; <b style={style_intera_b}>{data[el] === true ? 'SIM' : 'NÃO'}</b></span></div>)
                } else {
                    render.push(<div style={{
                        display: 'flex',
                        width: '49%',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        borderLeft: counter % 2 == 0 ? '1px solid #c3c3c3' : '',
                        paddingLeft: 8
                    }}><span style={style_intera_span}>{labels[el]}:&nbsp; <b style={style_intera_b}>{data[el]}</b></span></div>)
                }
                counter++
            }
        }

        return (
            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: 10, marginBottom: 10, borderBottom: '1px solid #c3c3c3', paddingBottom: 10 }}>
                {render}
            </div>
        )
    }


    const RowInteracation = ({ ev }) => {

        return (
            <div className='outage-details'>
                <div style={{ width: '100%' }}>
                    <div style={{ fontSize: '2em', marginBottom: 10, fontWeight: 'bold' }}>Detalhes do evento</div>

                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span>ID do outage: <b>{(ev.cod_id >= 0 && ev.cod_id < 10) ? '000' : (ev.cod_id >= 10 && ev.cod_id < 100) ? '00' : (ev.cod_id >= 100 && ev.cod_id < 1000) ? '0' : ''}{ev.cod_id}</b></span>
                    </div>
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span>Tipo de Incidente: <b>{ev.tipo_evento}</b></span>
                        <span>Horário do Incidente: <b>{ev.horario_inicio}</b></span>
                    </div>

                    {ev.qual_o_problema === 'ROMPIMENTO' &&
                        <>
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                                {ev.trecho_ponto_a ? <span>Trecho Ponto A: <b>{ev.trecho_ponto_a}</b></span> : null}
                                {ev.trecho_ponto_b ? <span>Trecho Ponto B: <b>{ev.trecho_ponto_b}</b></span> : null}
                            </div>
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                                {ev.sub_trecho_ponto_a ? <span>Sub Trecho Ponto A: <b>{ev.sub_trecho_ponto_a}</b></span> : null}
                                {ev.sub_trecho_ponto_a ? <span>Sub Trecho Ponto B: <b>{ev.sub_trecho_ponto_b}</b></span> : null}
                            </div>
                        </>
                    }

                    {ev.tipo_evento === 'Falha de OLT' &&
                        <>
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <span>Estação: <b> {ev.estacao}</b></span>
                                <span>SLOT / PON: <b>  {ev.slot_pon}</b></span>
                            </div>

                            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <span>Problema: <b>  {ev.qual_o_problema}</b></span>
                                <span>Responsável: <b> {ev.responsabilidade}</b></span>
                            </div>
                        </>
                    }

                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {ev.fornecedor !== '' &&
                            <>
                                <span>Fornecedor: <b>{ev.fornecedor}</b></span>
                                <span>Protocolo: <b>{ev.numero_protocolo}</b></span>
                            </>
                        }
                    </div>

                    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
                        {/* <span>Foi solucionado? <b>{ev.foi_solucionado}</b></span> */}
                        {ev.foi_solucionado === 'SIM' &&
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <span>Horário que foi solucionado: <b>{ev.hora_solucao}</b></span>
                                <span style={{ ...style_span, width: '100%' }}>Feedback do suporte: <b>{ev.feedback_do_suporte}</b></span>
                            </div>
                        }

                        {ev.foi_solucionado === 'NÃO' &&
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <span>Por que não foi solucionado: <b>{ev.porque_nao_foi_solucionado}</b></span>
                                <span>Previsão Para Solucionar: <b>{ev.previsao_para_solucao}</b></span>
                            </div>
                        }
                    </div>

                    <p>Analista: <b>{ev.userName} ({new Date(ev.data_insercao).toLocaleDateString()} às {new Date(ev.data_insercao).toLocaleTimeString()})</b></p>

                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <div style={{ display: 'flex', width: '100%', fontSize: '1.2em', fontWeight: 'bold', marginTop: 20, marginBottom: 10 }}>Interações</div>
                        <div style={{ width: '100%', maxHeight: '63vh', overflowY: 'auto' }}>


                            {ev.interacoes.length > 0 && ev.interacoes.map((interacao, key) => (
                                <div className='outage-details-interactions'>
                                    <p><b>Interação:</b></p>
                                    <pre>{interacao.txt_interacao}</pre>
                                    <p><b>Horario da interação:</b> {new Date(interacao.data).toLocaleDateString()} às {new Date(interacao.data).toLocaleTimeString()}</p>
                                    <p><b>Analista:</b> {interacao.userName}</p>
                                    <p><b>Incluído na RFO:</b> {interacao.add_rfo === true ? 'Sim' : 'Não'}</p>

                                    <div style={{ marginTop: 20, width: '100%', maxWidth: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', flexDirection: 'row', gap: 10 }}>
                                        {interacao.anexos?.length > 0 &&
                                            interacao.anexos.map((anexo => (
                                                <div key={`${anexo.filename}`}>
                                                    {
                                                        anexo.filename.match(/\.jpeg|\.jpg|\.png|\.gif|\.pjpeg|\.svg/i)
                                                            ?
                                                            (
                                                                <img
                                                                    style={{ width: 150, height: 150, objectFit: 'contain', cursor: 'pointer' }}
                                                                    src={`${backend_ticket_files}/${anexo.filename}`}
                                                                    onClick={() => {
                                                                        const imghtml = `<img style="width: 100%; height: 100%; object-fit: contain" src="${backend_ticket_files}/${anexo.filename}"/>`
                                                                        const nWindow = window.open('', '', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,left=100,top=100,width=420,height=420')
                                                                        nWindow.document.write(imghtml)

                                                                    }}
                                                                />
                                                            )
                                                            :
                                                            anexo.filename.match(/\.pdf/i)
                                                                ?
                                                                (<div
                                                                    style={{ display: 'flex', flexDirection: 'row' }}
                                                                    onClick={() => {
                                                                        const imghtml = `
                                                                <style>
                                                                    *{
                                                                        padding: 0px;
                                                                        margin: 0px;
                                                                    }
                                                                </style>
                                                                <iframe style="width: 100%;height: 100%" src="${backend_ticket_files}/${anexo.filename}"</iframe>`
                                                                        const nWindow = window.open('', '', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,left=100,top=100,width=720,height=820')
                                                                        nWindow.document.write(imghtml)

                                                                    }}
                                                                ><span style={{ fontSize: '2.5em' }} className="material-icons">picture_as_pdf</span> {anexo.filename.split('-')[1]}</div>)
                                                                :
                                                                anexo.filename.match(/\.mp4|\.ogg|\.3gp|\.mpeg/i)
                                                                    ?
                                                                    (
                                                                        <video width="320" height="240" controls>
                                                                            <source src={`${backend_ticket_files}/${anexo.filename}`} />
                                                                        </video>
                                                                    )
                                                                    :
                                                                    anexo.filename.match(/\.aac|\.m4a|\.mp3/i)
                                                                        ?
                                                                        (
                                                                            <audio controls style={{ width: 320 }}>
                                                                                <source src={`${backend_ticket_files}/${anexo.filename}`} />
                                                                            </audio>
                                                                        )
                                                                        :
                                                                        anexo.filename.match(/\.xls|\.xlsx|\.csv|\.doc|\.docx|\.txt/i)
                                                                            ?
                                                                            (<div
                                                                                style={{ display: 'flex', flexDirection: 'row' }}
                                                                                onClick={() => {
                                                                                    const imghtml = `
                                                                <style>
                                                                    *{
                                                                        padding: 0px;
                                                                        margin: 0px;
                                                                    }
                                                                </style>
                                                                <iframe style="width: 100%;height: 100%" src="${backend_ticket_files}/${anexo.filename}"</iframe>`
                                                                                    const nWindow = window.open('', '', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,left=100,top=100,width=720,height=820')
                                                                                    nWindow.document.write(imghtml)

                                                                                }}
                                                                            ><span style={{ fontSize: '2.5em' }} className="material-icons">description</span> {anexo.filename.split('-')[1]}</div>)
                                                                            :
                                                                            (<></>)
                                                    }
                                                </div>
                                            )))
                                        }
                                    </div>
                                    <hr />
                                </div>
                            ))}

                            {ev.interacoes.length === 0 && <h2>Nenhuma interação foi adicionada a este ticket</h2>}
                        </div>
                    </div>
                    {/* <p style={{ marginTop: 30 }}>Analista: <b>{ev.userName}</b> ({new Date(ev.data_insercao).toLocaleDateString()} às {new Date(ev.data_insercao).toLocaleTimeString()})</p> */}
                </div>
            </div>
        )
    }


    const EvRompimento = ({ ev }) => {

        return (
            <div className='outage-details'>
                <div style={{ width: '100%' }}>
                    <div style={{ fontSize: '2em', marginBottom: 10, fontWeight: 'bold' }}>Detalhes do evento</div>
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={style_span}>ID do outage: <b style={style_b}>{(ev.cod_id >= 0 && ev.cod_id < 10) ? '000' : (ev.cod_id >= 10 && ev.cod_id < 100) ? '00' : (ev.cod_id >= 100 && ev.cod_id < 1000) ? '0' : ''}{ev.cod_id}</b></span>
                    </div>

                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={style_span}>Tipo de Incidente: <b style={style_b}>{ev.tipo_evento}</b></span>
                        <span style={style_span}>Horário do Incidente: <b style={style_b}>{ev.horario_inicio}</b></span>
                    </div>

                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={style_span}>Ponto A: <b style={style_b}>{ev.ponto_a}</b></span>
                        <span style={style_span}>Ponto B: <b style={style_b}>{ev.ponto_b}</b></span>
                    </div>

                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <div style={{ display: 'flex', width: '100%', fontSize: '1.2em', fontWeight: 'bold', marginTop: 20, marginBottom: 10 }}>Interações</div>
                        {ev.interacoes.length > 0 && ev.interacoes.map((interacao, key) => (
                            <ShowInteraction data={interacao} />
                        ))}
                        {ev.interacoes.length === 0 && <h2>Nenhuma interação foi adicionada a este ticket</h2>}
                    </div>
                    {/* <p style={{ marginTop: 30 }}>Analista: <b>{ev.userName}</b> ({new Date(ev.data_insercao).toLocaleDateString()} às {new Date(ev.data_insercao).toLocaleTimeString()})</p> */}
                </div>
            </div>
        )
    }


    const EvEstacaoIndisponivel = ({ ev }) => {
        return (
            <div className='outage-details'>
                <div style={{ width: '100%' }}>
                    <div style={{ fontSize: '2em', marginBottom: 10, fontWeight: 'bold' }}>Detalhes do evento</div>
                    {/* <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={style_span}>Cliente: <b style={style_b}>{ev.customer_name}</b></span>
                    </div> */}
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={style_span}>ID do outage: <b style={style_b}>{(ev.cod_id >= 0 && ev.cod_id < 10) ? '000' : (ev.cod_id >= 10 && ev.cod_id < 100) ? '00' : (ev.cod_id >= 100 && ev.cod_id < 1000) ? '0' : ''}{ev.cod_id}</b></span>
                    </div>
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={style_span}>Tipo de Incidente: <b style={style_b}>{ev.tipo_evento}</b></span>
                        <span style={style_span}>Horário do Incidente: <b style={style_b}>{ev.horario_inicio}</b></span>
                    </div>
                    {/* <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={style_span}>Ponto A: <b style={style_b}>{ev.ponto_a}</b></span>
                        <span style={style_span}>Ponto B: <b style={style_b}>{ev.ponto_b}</b></span>
                    </div> */}
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <div style={{ display: 'flex', width: '100%', fontSize: '1.2em', fontWeight: 'bold', marginTop: 20, marginBottom: 10 }}>Interações</div>
                        {ev.interacoes.length > 0 && ev.interacoes.map((interacao, key) => (
                            <ShowInteraction data={interacao} />
                        ))}
                        {ev.interacoes.length === 0 && <h2>Nenhuma interação foi adicionada a este ticket</h2>}
                    </div>
                    {/* <p style={{ marginTop: 30 }}>Analista: <b>{ev.userName}</b> ({new Date(ev.data_insercao).toLocaleDateString()} às {new Date(ev.data_insercao).toLocaleTimeString()})</p> */}
                </div>
            </div>
        )
    }


    const EvLinkComProblema = ({ ev }) => {
        return (
            <div className='outage-details'>
                {/* <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={style_span}>Cliente: <b>{ev.customer_name}</b></span>
                    </div> */}
                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span style={style_span}>Interação: <b>{ev.txt_interacao}</b></span>
                    <span style={style_span}>Horário do Incidente: <b>{ev.horario_inicio}</b></span>
                </div>

                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span style={style_span}>Problema: <b>{ev.qual_o_problema}</b></span>
                    <span style={style_span}>Número do ticket com o suporte: <b>{ev.numero_protocolo}</b></span>
                </div>

                {ev.qual_o_problema === 'ROMPIMENTO' &&
                    <>
                        <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <span style={style_span}>Ponto A: <b>{ev.ponto_a}</b></span>
                            <span style={style_span}>Ponto B: <b>{ev.ponto_b}</b></span>
                        </div>
                    </>
                }

                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span style={style_span}>Fornecedor do link: <b>{ev.fornecedor}</b></span>
                </div>

                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
                    {/* <span style={style_span}>Foi solucionado? <b>{ev.foi_solucionado}</b></span> */}
                    {ev.foi_solucionado === 'SIM' &&
                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <span style={style_span}>Horário que foi solucionado: <b>{ev.hora_solucao}</b></span>
                            <span style={{ ...style_span, width: '100%' }}>Feedback do suporte: <b>{ev.feedback_do_suporte}</b></span>
                        </div>
                    }

                    {ev.foi_solucionado === 'NÃO' &&
                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <span style={style_span}>Por que não foi solucionado: <b>{ev.porque_nao_foi_solucionado}</b></span>
                            <span style={style_span}>Previsão Para Solucionar: <b>{ev.previsao_para_solucao}</b></span>
                        </div>
                    }
                </div>

                <p style={{ marginTop: 30 }}>Analista: <b>{ev.userName}</b> ({new Date(ev.data_insercao).toLocaleDateString()} às {new Date(ev.data_insercao).toLocaleTimeString()})</p>
            </div>

        )
    }


    const EvFalhaDeOlt = ({ ev }) => {
        return (
            <div className='outage-details'>
                {/* <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={style_span}>Cliente: <b>{ev.customer_name}</b></span>
                    </div> */}
                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span style={style_span}>Interação: <b>{ev.txt_interacao}</b></span>
                    <span style={style_span}>Horário do Incidente: <b>{ev.horario_inicio}</b></span>
                </div>

                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span style={style_span}>Problema: <b>{ev.qual_o_problema}</b></span>
                    <span style={style_span}>Número do ticket com o suporte: <b>{ev.numero_protocolo}</b></span>
                </div>


                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span style={style_span}>Estação: <b> {ev.estacao}</b></span>
                    <span style={style_span}>Responsável: <b> {ev.responsabilidade}</b></span>
                </div>

                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span style={style_span}>SLOT / PON: <b>  {ev.slot_pon}</b></span>
                </div>


                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span style={style_span}>Fornecedor do link: <b>{ev.fornecedor}</b></span>
                </div>

                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
                    {/* <span style={style_span}>Foi solucionado? <b>{ev.foi_solucionado}</b></span> */}
                    {ev.foi_solucionado === 'SIM' &&
                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <span style={style_span}>Horário que foi solucionado: <b>{ev.hora_solucao}</b></span>
                            <span style={{ ...style_span, width: '100%' }}>Feedback do suporte: <b>{ev.feedback_do_suporte}</b></span>
                        </div>
                    }

                    {ev.foi_solucionado === 'NÃO' &&
                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <span style={style_span}>Por que não foi solucionado: <b>{ev.porque_nao_foi_solucionado}</b></span>
                            <span style={style_span}>Previsão Para Solucionar: <b>{ev.previsao_para_solucao}</b></span>
                        </div>
                    }
                </div>

                <p style={{ marginTop: 30 }}>Analista: <b>{ev.userName}</b> ({new Date(ev.data_insercao).toLocaleDateString()} às {new Date(ev.data_insercao).toLocaleTimeString()})</p>
            </div>

        )
    }



    const EvClienteComProblema = ({ ev }) => {
        return (
            <div>
                <div style={{ width: '100%' }}>
                    {/* <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={style_span}>Cliente: <b>{ev.customer_name}</b></span>
                    </div> */}
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={style_span}>Tipo de Incidente: <b>{ev.tipo_evento}</b></span>
                        <span style={style_span}>Horário do Incidente: <b>{ev.horario}</b></span>
                    </div>
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={style_span}>Problema: <b>{ev.qual_o_problema}</b></span>
                        <span style={style_span}>Número do ticket com o suporte: <b>{ev.numero_do_ticket_com_o_supoerte}</b></span>
                    </div>
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <span style={style_span}>Foi escalonado?: <b>{ev.foi_escalonado}</b></span>
                        {ev.foi_escalonado === 'SIM' &&
                            <span style={style_span}>Para quem foi escalonado: <b>{ev.pra_quem_foi_escalonado}</b></span>
                        }

                        {ev.foi_escalonado === 'NÃO' &&
                            <span style={style_span}>Porque não foi escalonado: <b>{ev.porque_nao_foi_escalonado}</b></span>

                        }
                    </div>
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <span style={style_span}>Foi solucionado? <b>{ev.foi_solucionado}</b></span>
                        {ev.foi_solucionado === 'SIM' &&
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <span style={style_span}>Horário que foi solucionado: <b>{ev.hora_solucao}</b></span>
                                <span style={{ ...style_span, width: '100%' }}>Feedback do suporte: <b>{ev.feedback_do_suporte}</b></span>
                            </div>
                        }

                        {ev.foi_solucionado === 'NÃO' &&
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <span style={style_span}>Por que não foi solucionado: <b>{ev.porque_nao_foi_solucionado}</b></span>
                                <span style={style_span}>Previsão Para Solucionar: <b>{ev.previsao_para_solucao}</b></span>
                            </div>
                        }
                    </div>

                    <p style={{ marginTop: 30 }}>Analista: <b>{ev.userName}</b> ({new Date(ev.data_insercao).toLocaleDateString()} às {new Date(ev.data_insercao).toLocaleTimeString()})</p>
                </div>
            </div>
        )
    }



    const ItemToShow = ({ ev }) => {
        switch (ev.tipo_evento) {
            case 'Rompimento':
                return (<EvRompimento ev={ev} />)
                break;

            case 'Estação Indisponível':
                return (<EvEstacaoIndisponivel ev={ev} />)
                break;

            case 'Link Com Problema':
                return (<EvLinkComProblema ev={ev} />)
                break;

            case 'Cliente com Problema':
                return (<EvClienteComProblema ev={ev} />)
                break;

            case 'Falha de OLT':
                return (<EvFalhaDeOlt ev={ev} />)
                break;

            default:
                break;
        }
    }

    return (
        // <ItemToShow ev={props.ev} />
        <RowInteracation ev={props.ev} />
    );
}

export default OutageDetail;