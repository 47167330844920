import React, { useState, useEffect, useRef } from 'react';

import './Rfo.css'

import { api_bd } from '../../services/Api';

function Rfo(props) {

    const [descricao, setDescricao] = useState(props.ev.descricao || '')
    const [client, setClient] = useState(props.ev.customer_name || '')
    const [tempoEv, setTempoEv] = useState(props.ev.ev_time || '')
    const [dateRfo, setDateRfo] = useState(props.ev.date_rfo || '')
    const [ticket, setTicket] = useState(props.ev.rfo_numero_protocolo || '')
    const [supervisor, setSupervisor] = useState(props.ev.rfo_supervisor || '')
    const [analyst, setAnalyst] = useState(props.ev.rfo_analyst || '')

    const previousValue = useRef({
        client,
        tempoEv,
        dateRfo,
        ticket,
        supervisor,
        descricao
    })


    function printElem(elem) {
        const css = `<style>
        
        * {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            font-family: "Roboto", sans-serif;
            font-size: 12px;
            color: #3c5ca6;
        }

        .container-rfo {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;
            max-height: 100%;
            overflow-y: auto;
            flex: 1;
        }
        
        .header-rfo {
            width: 100%;
        }
        
        .header-rfo img {
            width: 100%;
            object-fit: contain;
        }
        
        .content-rfo {
            margin: auto;
            display: flex;
            flex-direction: column;
            width: 90%;
            flex-grow: 1;
        }
        
        .content-rfo-title {
            align-self: center;
            margin-top: 30px;
            margin-bottom: 30px;
            font-size: 1.5em;
            font-weight: bold;
        }
        
        .content-rfo-title2 {
            margin-top: 30px;
            font-size: 1.1em;
            font-weight: bold;
        }
        
        .content-rfo-txt {
            font-size: 1em;
        }

        .content-rfo-information{
            width: 100%;
        }

        .content-rfo-information table tr td{
            font-size: 0.9em;
        }

        .content-rfo-information table{
            margin: auto;
        }
        
        .content-rfo table {
            width: 100%;
            border-spacing: 0px;
            border-collapse: separate;
            margin-bottom: 40px;
        }
        
        .content-rfo tr td:nth-child(1) {
            font-weight: bold;
            padding-left: 2px;
            width: 25%;
        }
        
        .content-rfo tr td:nth-child(2) {
            text-align: center;
        }
        
        .content-rfo td {
            font-size: 1.3em;
            padding: 0px;
            border: 1px solid #000;
        }
        
        .content-rfo ul {
            margin-left: 12px;
        }
        
        .content-rfo li {
            margin-top: 3px;
            margin-bottom: 12px;
        }
        
        .resposability_div {
            width: 100%;
            margin-top: 90px;
            margin-bottom: 15px;
            display: flex;
            flex-direction: row;
        }
        
        .resposability_div div {
            width: 50%;
            display: flex;
            align-items: center;
            flex-direction: column;
        }
        
        .resposability_div div p:nth-child(1) {
            font-size: 1.2em;
            font-weight: bold;
        }
        
        .resposability_div div p:nth-child(2) {
            font-style: italic;
        }

        .onlyprint{
            display: none;
        }
        
        .footer-rfo {
            width: 100%;
        }
        
        .footer-rfo img {
            width: 100%;
            object-fit: contain;
        }

        .rfo-btnprint{
            display: none;
        }

        .rfo-display-linebreak{
            white-space: pre-line;
        }

        @media print {
            @page {
                size: A4;
                margin: 11mm 17mm 17mm 17mm;
            }

            .header-rfo {
                top: 0;
                width: 100%;
            }

            .footer-rfo {
                align-self: flex-end;
                width: 100%;
            }

            li{
                page-break-before: auto;
            }

            p{
                page-break-before: auto;
            }
        }
        
        </style>`
        var mywindow = window.open('', 'PRINT', 'height=600,width=800');

        mywindow.document.write('<html><head><title>' + document.title + '</title>');
        mywindow.document.write(css);
        mywindow.document.write('</head><body>');
        mywindow.document.write(document.getElementById(elem).innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        setTimeout(() => {
            mywindow.print();
            mywindow.close();
        }, 500)
        return true;
    }

    function printDiabled() {
        // console.log("onbeforeprint function");
    }

    useEffect(() => {
        window.onbeforeprint = (event) => {
            event.preventDefault();
            printDiabled();
        };
    }, [])

    useEffect(() => {
        if (previousValue.current.descricao !== descricao) {
            // console.log(props.ev._id, { descricao: descricao })
            api_bd.put(`/outages/${props.ev._id}`, { descricao: descricao })
            previousValue.current = { ...previousValue, descricao }
        }
    }, [descricao])

    useEffect(() => {
        if (previousValue.current.client !== client) {
            // console.log(props.ev._id, { customer_name: client })
            api_bd.put(`/outages/${props.ev._id}`, { customer_name: client })
            previousValue.current = { ...previousValue, client }
        }
    }, [client])

    useEffect(() => {
        if (previousValue.current.tempoEv !== tempoEv) {
            // console.log(props.ev._id, { ev_time: tempoEv })
            api_bd.put(`/outages/${props.ev._id}`, { ev_time: tempoEv })
            previousValue.current = { ...previousValue, tempoEv }
        }
    }, [tempoEv])

    useEffect(() => {
        if (previousValue.current.dateRfo !== dateRfo) {
            // console.log(props.ev._id, { dateRfo: dateRfo })
            api_bd.put(`/outages/${props.ev._id}`, { date_rfo: dateRfo })
            previousValue.current = { ...previousValue, dateRfo }
        }
    }, [dateRfo])

    useEffect(() => {
        if (previousValue.current.ticket !== ticket) {
            // console.log(props.ev._id, { rfo_numero_protocolo: ticket })
            api_bd.put(`/outages/${props.ev._id}`, { rfo_numero_protocolo: ticket })
            previousValue.current = { ...previousValue, ticket }
        }
    }, [ticket])

    useEffect(() => {
        if (previousValue.current.supervisor !== supervisor) {
            // console.log(props.ev._id, { rfo_supervisor: supervisor })
            api_bd.put(`/outages/${props.ev._id}`, { rfo_supervisor: supervisor })
            previousValue.current = { ...previousValue, supervisor }
        }
    }, [supervisor])


    return (
        <div
            className='container-rfo'
            id='container_rfo'
        >

            <div className='rfo-btnprint'
                onClick={() => {
                    printElem('container_rfo')
                }}
            >
                IMPRIMIR<span className="material-icons">print</span>
            </div>

            <div className='onlyprint'>
                <h1>Para imprimir use o botão.</h1>
            </div>
            <div className='header-rfo'>
                <img src="/rfo/header.png" />
            </div>
            <div className='content-rfo'>
                <p className='content-rfo-title'>RFO (Reason For Outage) Report</p>
                <div className='content-rfo-information'>
                    <table>
                        <tr>
                            <td>CLIENTE</td>
                            <td
                                contentEditable={true}
                                onBlur={(e) => setClient(e.target.textContent)}
                            >{client || 'Nome do cliente'}</td>
                        </tr>
                        <tr>
                            <td>TEMPO DE EVENTO</td>
                            <td
                                contentEditable={true}
                                onBlur={(e) => setTempoEv(e.target.textContent)}
                            >{tempoEv || 'Tempo de evento'}</td>
                        </tr>
                        <tr>
                            <td>RFO DATA</td>
                            <td
                                contentEditable={true}
                                onBlur={(e) => setDateRfo(e.target.textContent)}
                            >{dateRfo || 'Data da RFO'}</td>
                        </tr>
                        <tr>
                            <td>TICKET</td>
                            <td
                                contentEditable={true}
                                onBlur={(e) => setTicket(e.target.textContent)}
                            >{ticket || 'Número do Protocolo'}</td>
                        </tr>
                    </table>
                    <p className='content-rfo-title2'>DESCRIÇÃO DO PROBLEMA</p>
                    <p className='content-rfo-txt'
                        contentEditable={true}
                        onBlur={(e) => setDescricao(e.target.textContent)}
                    >{descricao || 'Descrição da RFO'}</p>

                    <p className='content-rfo-title2'>TIMELINE</p>
                    <ul>
                        {props.ev.interacoes.filter(el => el.add_rfo === true).map(el => (
                            <li><b>{new Date(el.data).toLocaleDateString()} {new Date(el.data).toLocaleTimeString()} [BRT]: </b><p className='rfo-display-linebreak'>{el.txt_interacao}</p></li>
                        ))}
                    </ul>

                    <p className='content-rfo-title2'>RFO:</p>
                    <p className='content-rfo-txt'>CAUSA: {props.ev.causa}</p>
                    <p className='content-rfo-txt'>SOLUÇÃO: {props.ev.solucao}</p>
                    <p className='content-rfo-txt'>HORÁRIO DA SOLUÇÃO: {new Date(props.ev.horario_fim).toLocaleDateString()} às {new Date(props.ev.horario_fim).toLocaleTimeString()}</p>

                    <p className='content-rfo-title2'>OBS:</p>
                    <p className='content-rfo-txt'>{props.ev.observacao}</p>

                    <div className='resposability_div'>
                        <div>
                            <p>{props.ev.profile_user.nome}</p>
                            <p>Network Operations Center</p>
                        </div>
                        <div>
                            <p
                                contentEditable={true}
                                onBlur={(e) => {
                                    setSupervisor(e.target.textContent)
                                }}
                            >{supervisor || 'Insira o nome do supervisor'}</p>
                            <p>Transmissions Operations Center Superviser</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-rfo'>
                <img src="/rfo/footer.png" />
            </div>
        </div>
    );
}

export default Rfo;