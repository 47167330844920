import React, { useEffect, useState } from 'react';
import { Warehouse } from 'lucide-react';
import { api_bd_nr } from '../../services/Api';

import { useRecoilValue } from 'recoil'
import { profileData } from '../../atoms/user.atom'
import SwipeToConfirm from './SwipeToConfirm';


function DeskAutomation() {
    const profile_user = useRecoilValue(profileData)
    const [controllerGarageState, setControllerGarageState] = useState({}) 

    const [wareHouseBtn, setWareHouseBtn] = useState({
        label: "Acionar portão",
        disabled: false
    })

    const [autoClose, setAutoClose] = useState(false)

    const handleError = (error) => {

        let message = '"Ocorreu um erro aguarde e tente novamente"'
        if (error?.response?.data?.error) {
            if (error.response.status === 405) {
                message = 'NÃO AUTORIZADO!'
            } else if (error.response.status === 408) {
                message = 'Sem resposta do servidor, tente novamente em alguns segundos.'
            }

        }


        setTimeout(() => {
            setWareHouseBtn(old => {
                return { ...old, label: "Acionar Portão", disabled: false }
            })
        }, 4000)

        setWareHouseBtn(old => {
            return { ...old, label: message, disabled: true }
        })
    }

    const handleOK = () => {
        setTimeout(() => {
            setWareHouseBtn(old => {
                return { ...old, label: controllerGarageState?.status === 'online' ? "Acionar Portão" : "Controlador do portão offline", disabled: false }
            })
        }, 2000)

        setWareHouseBtn(old => {
            return { ...old, label: "OK!", disabled: true }
        })
    }

    useEffect(()=>{
        api_bd_nr.get('/icontrollerstates').then(resp => {
            const garageState = resp.data.find(el => el.name === "IGARAGE1") || {}
            setControllerGarageState(garageState)
        })
    },[])

    return (
        <div className='flex flex-col w-4/4'>
            <div className='flex flex-row items-center gap-1'>
                <span className='bg-white font-bold'>MENU DE AUTOMAÇÃO DO ESCRITÓRIO</span>
            </div>
            <div className={`flex flex-col items-start gap-1 mt-5 p-3 ${wareHouseBtn.disabled === true ? 'text-zinc-400' : ''}`}>
                <div className='flex flex-row w-full flex-wrap items-center px-10 mb-5 justify-between'>
                    <div className='flex flex-row items-center justify-start min-w-[200px]'>
                        {wareHouseBtn.disabled === false &&
                            <Warehouse size={20} className={`${controllerGarageState?.status === 'online' ? 'text-intec-500' : 'text-red-500' }`} />
                        }
                        {wareHouseBtn.disabled === true &&
                            <div className="loader2 border-4 border-intec-500 border-t-white w-6 h-6 ml-3 mr-0 rounded-full"></div>
                        }
                        <span className={`bg-white ml-3 text-xl ${controllerGarageState?.status === 'online' ? 'text-intec-500' : 'text-red-500' }`}>{controllerGarageState?.status === 'online' ? wareHouseBtn.label : 'Controlador do portão offline.'}</span>
                    </div>

                    <div className='flex flex-row items-center justify-start md:justify-end mt-5 md:mt-0  min-w-[200px]'>
                        <input
                            className='scale-110'
                            type='checkbox'
                            value={autoClose}
                            onChange={(e) => {
                                setAutoClose(e.target.checked)
                            }}
                        />
                        <span className='text-xl'>Fechamento Rápido (Auto.)</span>
                    </div>
                </div>
                <SwipeToConfirm
                    disabled={wareHouseBtn.disabled}
                    onOk={() => {
                        if (wareHouseBtn.disabled === true) {
                            return
                        }

                        setWareHouseBtn(old => {
                            return { ...old, label: "Acionando, aguarde...", disabled: true }
                        })

                        api_bd_nr.post(`/igarage`, {
                            idUser: profile_user.id,
                            autoClose
                        }).then(resp => {
                    handleOK(resp.data)

                }).catch(error => {
                    handleError(error)
                })

                    }}
                />
            </div>
        </div>
    );
}

export default DeskAutomation;