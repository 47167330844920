import React, { useState } from 'react';

import LineData from './LineData';
import styles from './styles.module.css'

function DataUser({ saveDataToServer, user, data, handleData }) {

    const [showData, setShowData] = useState(false)

    let rdata = [...data]
    rdata.reverse()
    return (
        <div
            style={{
                borderBottom: '1px solid #c3c3c3',
                position: 'relative',
                marginBottom: 20
            }}
        >
            <div
                style={{
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    height: 30,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 20
                }}
                onClick={() => {
                    setShowData(!showData)
                }}
            >
                <span style={{ margin: 0, padding: 0 }}>{user}</span>
                <span className={`material-icons`} style={{ color: '#3760c0' }}>{showData ? 'expand_less' : 'expand_more'}</span>
            </div>
            <div
                className={styles.transitionHeight}
                style={{
                    height: showData ? '60vh' : 0,
                    marginLeft: 20,
                    marginRight: 20,
                    overflowY: 'auto'
                }}
            >
                {rdata.map((el, index) => ( el.fim !== null &&
                    <LineData handleData={handleData} dados={el} key={`edit_${index}`} />
                ))}
            </div>
        </div>
    )
}

export default DataUser;