import React, { useState, useEffect } from 'react';

import styles from './styles.module.css';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { profileData } from '../../atoms/user.atom'
import { realtimeState } from '../../atoms/realtimeState.atom';

import { api_bd } from '../../services/Api'

import Modal from '../Modal'

import PopUpListaPosts from './PopUpListaPosts';

import NewPost from './NewPost';

import ViewPost from './ViewPost';

const configs = require('../../config.json')

function HomePosts(props) {

    const user_profile = useRecoilValue(profileData)

    const [new_notify, setNewNotify] = useState(false)

    const [showPopUp, setShowPopUp] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showTextInput, setShowTextInput] = useState(false)
    const [contentModal, setContentModal] = useState({})

    const [listPosts, setListPosts] = useState([])

    const realtimeData = useRecoilValue(realtimeState)

    // const [textComment, setTextComment] = useState('')
    const [btnSendCommentDisabled, setBtnSendCommentDisabled] = useState(false)


    const loadPosts = () => {
        api_bd.get('/homeposts/list/100').then(resp => {
            if (resp.data.length > 0) {
                let counter = 0
                resp.data.forEach(element => {
                    if (element.read.indexOf(user_profile.id) === -1) {
                        counter++
                    }
                });
                if (counter === 0) {
                    setNewNotify(false)
                } else {
                    setNewNotify(true)
                }

                const orderedlist = resp.data.sort(function (a, b) {
                    // return new Date(b.updatedAt) - new Date(a.updatedAt);
                    return new Date(b.date) - new Date(a.date);
                });
                setListPosts(orderedlist)
            } else {
                setListPosts([])
            }
        })
    }

    useEffect(() => {
        // console.log(realtimeData)
        if (Object.keys(realtimeData).length > 0) {
            if (realtimeData.topic === 'homeposts_update') {
                loadPosts()
            }
        }
    }, [realtimeData])

    useEffect(() => {
        loadPosts()
        // let interval = setInterval(() => {
        //     loadPosts()
        // }, 15000)

        return () => {
            // clearInterval(interval)
        }
    }, [])

    return (
        <div style={{
            width: '100%',
            margin: 10,
            overflowY: 'auto',
            height: '92vh'
        }}>

            <PopUpListaPosts
                user_profile={user_profile}
                notifications={listPosts}
                setShowPopUp={setShowPopUp}
                setShowModal={setShowModal}
                setContentModal={setContentModal}
                setShowTextInput={setShowTextInput}
                setNewNotify={setNewNotify}
            />


            {listPosts && listPosts.length === 0 &&
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <p style={{fontSize: '1.7em'}}>Nenhuma postagem.</p>
                    <p style={{fontSize: '1.7em'}}>Clique no botão "<b style={{fontSize: '1em'}}>Nova Postagem</b>" abaixo e seja o primeiro a postar!</p>
                </div>
            }


            {showModal &&
                <Modal callbackfn={() => {
                    setShowModal(false)
                }}>
                    <ViewPost idNote={contentModal._id} setShowModal={setShowModal} user_profile={user_profile} />
                </Modal>
            }

            {showTextInput === true &&
                <Modal callbackfn={() => {
                    setShowTextInput(false)
                }}>
                    <NewPost user_profile={user_profile} setShowTextInput={setShowTextInput} />
                </Modal>
            }

            {/* <span
                className={`material-icons ${styles.notificationsIcon}`}
                style={{color: new_notify ? '#f00' : null }}
                onClick={() => {
                    setShowPopUp(!showPopUp)
                }}
            >
                {new_notify ? 'notifications_active' : 'notifications'}
            </span> */}


            <div
                className={styles['notificationsBtnNewPost']}
                style={{ alignItems: 'center' }}
                onClick={() => {
                    setShowTextInput(true)
                    setShowPopUp(false)
                }}
            ><span className={`material-icons`} style={{ color: '#3760c0' }}>add</span> Nova Postagem</div>
        </div>
    );
}

export default HomePosts

{/* <span className="material-icons left_icon default_color" style={{ margin: 0, color: '#fff', fontSize: '2.3em' }}>add</span> */ }