import React from 'react';
import {Route, Redirect} from 'react-router-dom'

// import { Container } from './styles';

import Login from '../pages/Login';

import { useRecoilValue } from 'recoil'
import { profileData } from '../atoms/user.atom'

function RoutePrivate({component: Component, ...rest}) {
	const profile_data = useRecoilValue(profileData)
  // if(Object.keys(profile_data).length === 0){
	// 	if(window.location.pathname !=='/'){
	// 		window.location.href='/'
	// 	}
	// 	return <Login />
	// }



  return (
    <Route {...rest}
    render={()=> Object.keys(profile_data).length === 0 
      ? <Redirect to='/login' />
      : <Component {...rest} />
    }
    />
  )
}

export default RoutePrivate;