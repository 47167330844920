import React, {
    useState,
    //  useEffect 
} from 'react';

// import { makeStyles } from '@material-ui/core/styles';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Styles from '../Styles'

import { api_bd } from '../../services/Api'

import { useRecoilState, useRecoilValue } from 'recoil'
import clientDetailsData from '../../atoms/client_details.atom'

function FormDataSenhas(props) {
    const { data_client, width, data_client_update } = props
    const styles = Styles();

    const [update_client_data, set_update_client_data] = useRecoilState(clientDetailsData)

    const [data_senha, set_data_senha] = useState({})
    const [submit_is_disabled, set_submit_is_disabled] = useState(false)


    const handle_data_senha = (key, value) => {
        let data = data_senha
        data[key] = value
        set_data_senha(data)
    }


    return (
        <div className={styles.div_root_component} style={{ width }}>
            <Card className={styles.card}>
                <CardContent>
                    <CardHeader
                        title="Adicionar Senha"
                        subheader=""
                    />
                    <form className={styles.form_cad_user} noValidate autoComplete="off">
                        <div style={{width: "100%"}}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_senha("descricao", e.target.value)
                                }}
                                id="descricao"
                                label="Descrição"
                                required={true}
                            />
                        </div>
                        <div style={{width: "100%"}}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_senha("usuario", e.target.value)
                                }}
                                id="usuario"
                                label="Usuário"
                                required={true}
                            />
                        </div>
                        <div style={{width: "100%"}}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_senha("senha", e.target.value)
                                }}
                                id="senha"
                                label="Senha"
                                required={true}
                            />
                        </div>
                        <div style={{width: "100%"}}>
                            <Button
                                className={styles.btn_submit}
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={submit_is_disabled}
                                onClick={async () => {
                                    
                                    const filters = {
                                        descricao: "Descrição", 
                                        senha: "Senha",
                                        usuario: "Usuário"
                                    }
    
                                    for(let f in Object.values(filters)){
                                        let filter = Object.keys(filters)[f]
                                        if(data_senha[filter] === undefined || data_senha[filter] === ''){
                                            return alert(`Campo obrigatório: ${filters[filter].toUpperCase()}`)
                                        }
                                    }

                                    try {
                                        const resp = await api_bd.put(`/customers/addpasstolist/${data_client._id}`, data_senha)
                                        props.callbackfn()
                                        set_update_client_data(resp.data)
                                        // alert(`Dados inseridos com sucesso!\nCliente: ${data_client.nome}`)
                                    } catch (error) {
                                        if (error.message.indexOf('code 400') > -1) {
                                            alert("Verifique os dados e tente novamente.")
                                        }
                                    }

                                    // console.log('Dados IP Publico: ', JSON.stringify(data_public_ip))
                                }}
                            >
                                Salvar
                        </Button>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
}

export default FormDataSenhas;

//Schema IP PUBLICO
//{"descricao":"descricao","ip":"192.168.10.1","bloco":"192.160.10.0/24"}