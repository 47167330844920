import React from 'react';

// import { Container } from './styles';
import AnalystReport from '../AnalystReport';
import CustomerReport from '../CustomerReport';
import CustomerReportCGR from '../CustomerReportCGR';

function ShowContent(props) {
    switch (props.content) {
        case 'analystreport':
            return (<AnalystReport interval={props.interval} />)
            break;

        case 'customerreport':
            return (<CustomerReport interval={props.interval} />)
            break;

        case 'customerreportcgr':
            return (<CustomerReportCGR interval={props.interval} />)
            break;

        default:
            return(<></>)
            break;
    }
}

export default ShowContent;