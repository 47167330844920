import React, { useState, useEffect, memo } from 'react';

import './MonitoramentoNoturno.css';

import TicketRow from './TicketRow';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'
import nightMonitoringComponentToShowState, { nightMonitoringListTicketState } from '../../atoms/night_monitoring _state.atom';


import { api_bd } from '../../services/Api'


function TicketList(props) {
    const [eventList, setEventList] = useRecoilState(nightMonitoringListTicketState)
    const profile_user = useRecoilValue(profileData)
    const componentToShow = useRecoilValue(nightMonitoringComponentToShowState)

    const buscarEventos = async () => {
        let tmpEv = await api_bd.get(`/night_monitoring/status/aberto/${profile_user.id}`)
        if (tmpEv.data.length === 0) {
            // setLoadMessage('Nenhum evento encontrado para os filtros selecionados')
        } else {
            if (componentToShow === null) {
                setEventList(tmpEv.data)
            }
        }
    }

    useEffect(() => {
        buscarEventos()
        let interval = setInterval(() => {
            buscarEventos()
        }, 30000)
        return () => {
            clearInterval(interval)
        }
    }, [])



    return (
        <div className='card' style={{ width: '100%' }}>
            <span style={{ fontSize: '1.8em', fontWeight: 'bold', paddingTop: 13, paddingBottom: 30 }}>TICKETS</span>
            {/* Content */}
            <div style={{ width: '100%', paddingBottom: 13, flexDirection: 'column' }}>
                {eventList.length > 0 && eventList.map(ev => (
                    <TicketRow ev={ev} key={`${ev._id}`} buscar_eventos={buscarEventos} />
                ))}

                {eventList.length === 0 &&
                    <div style={{fontSize: '1.5em', display: 'flex', width: '100%', justifyContent: 'center'}}>Nenhum ticket aberto</div>
                }
            </div>
        </div>
    )
}

export default TicketList;