import React, {
    useState,
    //  useEffect 
} from 'react';

// import { makeStyles } from '@material-ui/core/styles';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Styles from '../Styles'

import { api_bd } from '../../services/Api'

import { useRecoilState, useRecoilValue } from 'recoil'
import clientDetailsData from '../../atoms/client_details.atom'

function FormDataDefaults(props) {
    const { data_client, width, data_client_update } = props
    const styles = Styles();

    const [data_defaults, set_data_defaults] = useState({...data_client.defaults})
    const [submit_is_disabled, set_submit_is_disabled] = useState(false)

    const [update_client_data, set_update_client_data] = useRecoilState(clientDetailsData)


    const handle_data_defaults = (key, value) => {
        let data = {...data_defaults}
        data[key] = value
        set_data_defaults(data)
    }


    return (
        <div className={styles.div_root_component} style={{ width }}>
            <Card className={styles.card}>
                <CardContent>
                    <CardHeader
                        title="Defaults"
                        subheader=""
                    />
                    <form className={styles.form_cad_user} noValidate autoComplete="off">
                        <div style={{width: "100%"}}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_defaults("winbox", e.target.value)
                                }}
                                type="number"
                                id="winbox"
                                label="Porta WINBOX Ex: 8291"
                                value={data_defaults.winbox}
                            />
                        </div>
                        <div style={{width: "100%"}}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_defaults("ssh", e.target.value)
                                }}
                                type="number"
                                id="ssh"
                                label="Porta SSH Ex: 22"
                                value={data_defaults.ssh}
                            />
                        </div>
                        <div style={{width: "100%"}}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_defaults("telnet", e.target.value)
                                }}
                                type="number"
                                id="telnet"
                                label="Porta TELNET Ex: 23"
                                value={data_defaults.telnet}
                            />
                        </div>
                        <div style={{width: "100%"}}>
                            <Button
                                className={styles.btn_submit}
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={submit_is_disabled}
                                onClick={async () => {

                                    try {
                                        const resp = await api_bd.put(`/customers/addorupdefaults/${data_client._id}`, data_defaults)
                                        // data_client_update(resp.data)
                                        // alert(`Dados inseridos com sucesso!\nCliente: ${data_client.nome}`)
                                        props.callbackfn()
                                        set_update_client_data(resp.data)
                                    } catch (error) {
                                        if (error.message.indexOf('code 400') > -1) {
                                            alert("Verifique os dados e tente novamente.")
                                        }
                                    }

                                    // console.log('Dados IP Publico: ', JSON.stringify(data_public_ip))
                                }}
                            >
                                Salvar
                        </Button>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
}

export default FormDataDefaults;

//Schema IP PUBLICO
//{"descricao":"descricao","ip":"192.168.10.1","bloco":"192.160.10.0/24"}