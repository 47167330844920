import React, { useState, useEffect } from 'react';
// import {
// 	Chart as ChartJS,
// 	CategoryScale,
// 	LinearScale,
// 	BarElement,
// 	Title,
// 	Tooltip,
// 	Legend
// } from 'chart.js';
import { Bar } from 'react-chartjs-2';

function CustomerBarChart(props) {

	const msToTime = (ms) => {
		var hours = ms / (1000 * 60 * 60);
		var absoluteHours = Math.floor(hours);
		var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

		//Get remainder from hours and convert to minutes
		var minutes = (hours - absoluteHours) * 60;
		var absoluteMinutes = Math.floor(minutes);
		var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

		//Get remainder from minutes and convert to seconds
		var seconds = (minutes - absoluteMinutes) * 60;
		var absoluteSeconds = Math.floor(seconds);
		var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

		return h + ':' + m + ':' + s;
	}

	return (
		<div className='flex flex-col w-full rounded border border-intec-200 p-3' style={{ width: '100%', maxWidth: '100%', display: 'flex', flexDirection: 'column' }}>
			<span style={{ marginTop: 20, marginBottom: 10, fontSize: '1.3em', fontWeight: 'bold' }}>TOP 10 Clientes (horas)</span>
			<div style={{
				display: 'flex',
				width: '100%',
				height: 400
			}}>
				<Bar
					options={{
						indexAxis: 'y',
						elements: {
							bar: {
								borderWidth: 2,
							},
						},
						responsive: true,
						maintainAspectRatio: false,
						// maintainAspectRatio : true,
						plugins: {
							datalabels: {
								display: true,
								color: '#fff',
								formatter: (valor) => valor > 0 ? msToTime(valor) : '',//Math.round,
								anchor: "end",
								offset: -80,
								align: "end",
								font: { size: 15, weight: 'bold' }
							},
							tooltip: {
								callbacks: {
									title: function (tooltipItem) {
										// console.log(tooltipItem)
										return tooltipItem[0].label +' - '+ tooltipItem[0].dataset.label
									},
									label: function (tooltipItem) {
										return ' ' + msToTime(tooltipItem.raw) + 'hs'
									}
								},
								titleFont: {
									size: 12
								},
								bodyFont: {
									size: 12
								}
							},
							legend: {
								display: false,
								// position: 'top',
							},
							title: {
								display: false,
								text: 'Tempo por Cliente',
							}
						},
						scales: {
							x: {
								ticks: {
									// Include a dollar sign in the ticks
									callback: function (value, index, ticks) {
										return msToTime(value)
									},
									font: {
										size: 12
									}
								}
							},
							y: {
								ticks: {
									beginAtZero: true,
									font: {
										size: 12
									}
								}
							}
						},

					}}
					data={props.data}
				/>
			</div>
		</div>
	)
}

export default CustomerBarChart;