import React, { useState, useEffect, useRef } from 'react';

import './MonitoramentoNoturno.css';

import { api_bd } from '../../services/Api'

import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'
import customerData from '../../atoms/customers.atom'
import { nightMonitoringListTicketState } from '../../atoms/night_monitoring _state.atom';

function CloseTicket(props) {
    const refComp1 = useRef()
    const refComp2 = useRef()
    const [actualComp, setActualComp] = useState(1)
    const [buttonEnabled, setButtonEnabled] = useState(false)

    const profile_user = useRecoilValue(profileData)
    const clientList = useRecoilValue(customerData)

    const setEventList = useSetRecoilState(nightMonitoringListTicketState)
    const buscarEventos = async () => {
        let tmpEv = await api_bd.get(`/night_monitoring/status/aberto/${profile_user.id}`)
        setEventList(tmpEv.data)
    }

    const [dataSolucaoTicket, setDataSolucaoTicket] = useState({
        foi_solucionado: "",
        hora_solucao: "",
        qual_foi_solucao: "",
        porque_nao_foi_solucionado: "",
        feedback_do_suporte: "",
        escalonar_diurno: false
    })


    // useEffect(() => {
    //     console.log(props.ev)
    // }, [])

    /* *************************************************************** 
    Ao escalonar no customer_id do NOVO Ticket DIURNO

    buscar o "user_glpi" na tabela de customers usando o "customer_id" do ticket noturno
    
    */



    const style_p = { marginTop: 5, marginBottom: 5 }
    const style_span = { marginLeft: 0, marginTop: 5, marginBottom: 5, width: '50%', fontSize: '1.3em' }
    const style_b = { fontSize: '1em' }

    const style_intera_span = { marginLeft: 0, marginTop: 5, marginBottom: 5, width: '50%', fontSize: '1em' }
    const style_intera_b = { fontSize: '1em' }

    const labels = {
        tipo_interacao: "Tipo de Interação",
        acionou_plantonista: "Acionou o plantonista?",
        hora_acionamento: "Hora do acionamento?",
        quem_foi_acionado: "Quem foi acionado?",
        foi_escalonado: "Foi escalonado?",
        hora_escalonado: "Hora do escalonamento?",
        pra_quem_foi_escalonado: "Para quem foi escalonado?",
        porque_nao_foi_escalonado: "Porque não foi escalonado?",
        previsao_para_solucao: "Existe previsão para solução?",
        foi_solucionado: "Foi solucionado?",
        hora_solucao: "Hora que foi solucionado?",
        porque_nao_foi_solucionado: "Porque não foi solucionado?",
        feedback_do_suporte: "Feedback do suporte?",
        userName: "Analista",
        data: "Data do registro",
        escalonar_diurno: "Escalonado para o suporte diurno",
        userId: "",
        userLevel: "",
        _id: "",

    }

    const ShowInteraction = ({ data }) => {
        const render = []

        for (let el in data) {
            if (!el.match(/userId|userLevel|_id/i) && data[el] !== '') {
                if (el === 'data') {
                    render.push(<div style={{ display: 'flex', width: '50%' }}><span style={style_intera_span}>{labels[el]}:&nbsp; <b style={style_intera_b}>{new Date(data[el]).toLocaleDateString()} às {new Date(data[el]).toLocaleTimeString()}</b></span></div>)
                } else {
                    render.push(<div style={{ display: 'flex', width: '50%' }}><span style={style_intera_span}>{labels[el]}:&nbsp; <b style={style_intera_b}>{data[el]}</b></span></div>)
                }
            }
        }

        return (
            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', flexWrap: 'wrap', marginTop: 10, marginBottom: 10, borderBottom: '1px solid #c3c3c3', paddingBottom: 10 }}>
                {render}
            </div>
        )
    }

    useEffect(() => {
        (dataSolucaoTicket.foi_solucionado !== 'Selecione uma opção' && ((dataSolucaoTicket.hora_solucao !== '' && dataSolucaoTicket.qual_foi_solucao !== '') || dataSolucaoTicket.porque_nao_foi_solucionado !== '')) ? setButtonEnabled(true) : setButtonEnabled(false)
    }, [dataSolucaoTicket])

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%', padding: 30, overflow: 'hidden' }}>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', height: 60, width: '100%', marginBottom: 40 }}>
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 60, height: 60, maxWidth: 60, maxHeight: 60 }}>
                    <div style={{ position: 'relative', display: 'flex', flexDirection: 'row' }}>
                        <span className="material-icons left_icon" style={{ position: 'absolute', marginLeft: -7, marginTop: -17, fontSize: '3.5em' }}>done</span>
                        <span className="material-icons left_icon" style={{ fontSize: '4em', marginLeft: 13, marginRight: 0, marginTop: 3 }}>edit_square</span>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginLeft: 20 }}>
                    <span style={{ margin: 0, fontSize: '2.5em' }}>Finalizar Ticket</span>
                </div>
            </div>
            <div ref={refComp1}
                className='show_in'
                style={{
                    position: 'absolute',
                    width: '90%'
                }}
            >
                <FormControl style={{ width: '100%' }}>
                    <InputLabel htmlFor="select-event-type">O ticket foi solucionado</InputLabel>
                    <Select
                        defaultValue="Selecione uma opcão"
                        labelId="select-interaction-type"
                        id="situacao"
                        style={{ width: '100%' }}
                        onChange={e => {
                            setDataSolucaoTicket({
                                foi_solucionado: e.target.value !== 'Selecione uma opcão' ? e.target.value : '',
                                hora_solucao: "",
                                qual_foi_solucao: "",
                                porque_nao_foi_solucionado: "",
                                feedback_do_suporte: "",
                                escalonar_diurno: false
                            })
                        }}
                        required={true}
                    >
                        <MenuItem value="Selecione uma opcão">Selecione uma opcão</MenuItem>
                        <MenuItem value="SIM">SIM</MenuItem>
                        <MenuItem value="NÃO">NÃO</MenuItem>
                    </Select>
                </FormControl>

                {dataSolucaoTicket.foi_solucionado === 'SIM' &&
                    <>
                        <div style={{ width: '100%', marginTop: 10 }}>
                            <TextField
                                type="time"
                                style={{ width: '100%' }}
                                id="hora_solucao"
                                label="Que horas foi solucionado?"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={e => {
                                    // handle_data("hora_solucao", e.target.value)
                                    setDataSolucaoTicket(old => {
                                        return ({ ...old, hora_solucao: e.target.value })
                                    })
                                }}
                                value={dataSolucaoTicket.hora_solucao}
                            />
                        </div>

                        <div style={{ width: '100%', marginTop: 10 }}>
                            <TextField
                                style={{ width: '100%' }}
                                id="qual_foi_solucao"
                                label="Qual foi a solução para o ticket"
                                onChange={e => {
                                    setDataSolucaoTicket(old => {
                                        return ({ ...old, qual_foi_solucao: e.target.value })
                                    })
                                }}
                                value={dataSolucaoTicket.qual_foi_solucao}
                            />
                        </div>
                    </>
                }


                {dataSolucaoTicket.foi_solucionado === 'NÃO' &&
                    <>
                        <div style={{ width: '100%', marginTop: 10 }}>
                            <TextField
                                style={{ width: '100%' }}
                                id="porque_nao_foi_solucionado"
                                label="Porque não foi solucionado?"
                                onChange={e => {
                                    setDataSolucaoTicket(old => {
                                        return ({ ...old, porque_nao_foi_solucionado: e.target.value })
                                    })
                                }}
                                value={dataSolucaoTicket.porque_nao_foi_solucionado}
                            />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', width: '100%', marginTop: 30, marginBottom: 30 }}>
                            <input type="checkbox"
                                style={{ marginLeft: 0, transform: 'scale(1.5)' }}
                                checked={dataSolucaoTicket.escalonar_diurno}
                                onChange={e => {
                                    setDataSolucaoTicket(old => {
                                        return ({ ...old, escalonar_diurno: e.target.checked })
                                    })
                                }}
                            />
                            <span style={{ fontSize: '1.1em' }}>Escalonar para o suporte diurno</span>
                        </div>
                    </>
                }

                <div
                    className='buttom fixToCenter'
                    style={{ marginTop: 20, padding: 10, width: 100, backgroundColor: buttonEnabled ? '#3c5ca6' : '#c3c3c3' }}
                    onClick={async () => {
                        if (buttonEnabled) {
                            // console.log('ID: ', props.ev._id, 'Dados:', dataSolucaoTicket)
                            setButtonEnabled(false)
                            await api_bd.put(`/night_monitoring/addinteract/${props.ev._id}`, {
                                ...dataSolucaoTicket,
                                tipo_interacao: 'Solução',
                                userId: profile_user.id,
                                userName: profile_user.nome,
                                userLevel: profile_user.nivel
                            })
                            await buscarEventos()
                            props.callbackfn()
                        }
                    }}
                >
                    Salvar
                </div>

            </div>


        </div >
    );
}

export default CloseTicket;