import React, {
    useState,
    useEffect
} from 'react';

import Card from '@material-ui/core/Card';
// import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';


import { api_bd, api_bd_nr } from '../../services/Api'

const { tipos_situacao, cor_tipos_situacao } = require('../../config.json')


function ContadoresDE({ set_filtro_situacao, load_tickets }) {

    const [qtd_list_chamados, set_qtd_list_chamados] = useState([])
    const [ticketsCounter, setTicketsCounter] = useState({})

    const styleCardsContent = {
        marginTop: -8,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 100,
        maxHeight: 100
    }

    const style_image_loading = {
        width: 100,
        marginLeft: 0
    }


    const load_cards_counter_data = async () => {
        api_bd_nr.get('deticketcounter').then(resp => {
            setTicketsCounter(resp.data)
        })

        // set_qtd_list_chamados(resp_counter.data.length === 0 ? [1] : resp_counter.data)
        //console.log( 'TMP LIST: ', tmp_list,'\n\n') 
    }

    useEffect(() => {
        (async () => {
            await load_cards_counter_data()
        })()

        const interval = setInterval(async () => {
            await load_cards_counter_data()
        }, 15000);

        return () => {
            clearInterval(interval);
        };

    }, [])


    return (
        <div className="container_cards_contadores">
            <Card className="card_contador_chamados"
                style={{
                    // backgroundColor: cor_tipos_situacao['novo'],
                    borderTop: `8px solid ${cor_tipos_situacao['novo']}`,
                    borderBottom: `8px solid ${cor_tipos_situacao['novo']}`,
                    borderRadius: '40px 0px 30px 0px'

                }}
                onClick={() => {
                    localStorage.setItem('filtro_situacao', 'novos_e_atribuidos')
                    set_filtro_situacao('novos_e_atribuidos')
                    load_tickets('novos_e_atribuidos', true)
                }}>
                <CardContent style={styleCardsContent}>
                    <div style={{ width: '100%', textAlign: 'center', fontSize: '3em' }}>{Object.keys(ticketsCounter).length === 0 ? <img style={style_image_loading} src="https://sgi.intecsolutions.com.br:4000/uploads/loading.webp" /> : ticketsCounter.novos_atribuidos}</div>
                    <div style={{ width: '100%', textAlign: 'center', fontSize: '1.3em' }}>Novos e atribuidos</div>
                </CardContent>
            </Card>


            <Card className="card_contador_chamados"
                style={{
                    // backgroundColor: cor_tipos_situacao['em_tratativa'],
                    borderTop: `8px solid ${cor_tipos_situacao['em_tratativa']}`,
                    borderBottom: `8px solid ${cor_tipos_situacao['em_tratativa']}`,
                    borderRadius: '40px 0px 30px 0px'
                }}
                onClick={() => {
                    localStorage.setItem('filtro_situacao', 'em_tratativa')
                    set_filtro_situacao('em_tratativa')
                    load_tickets('em_tratativa', true)
                }}>
                <CardContent style={styleCardsContent}>
                    <div style={{ width: '100%', textAlign: 'center', fontSize: '3em' }}>{Object.keys(ticketsCounter).length === 0 ? <img style={style_image_loading} src="https://sgi.intecsolutions.com.br:4000/uploads/loading.webp" /> : ticketsCounter.em_tratativa}</div>
                    <div style={{ width: '100%', textAlign: 'center', fontSize: '1.3em' }}>Em tratativa</div>
                </CardContent>
            </Card>


            <Card className="card_contador_chamados"
                style={{
                    // backgroundColor: cor_tipos_situacao['em_execucao'],
                    borderTop: `8px solid ${cor_tipos_situacao['em_execucao']}`,
                    borderBottom: `8px solid ${cor_tipos_situacao['em_execucao']}`,
                    borderRadius: '40px 0px 30px 0px'
                }}
                onClick={() => {
                    localStorage.setItem('filtro_situacao', 'em_execucao')
                    set_filtro_situacao('em_execucao')
                    load_tickets('em_execucao', true)
                }}>
                <CardContent style={styleCardsContent}>
                    <div style={{ width: '100%', textAlign: 'center', fontSize: '3em' }}>{Object.keys(ticketsCounter).length === 0 ? <img style={style_image_loading} src="https://sgi.intecsolutions.com.br:4000/uploads/loading.webp" /> : ticketsCounter.em_execucao}</div>
                    <div style={{ width: '100%', textAlign: 'center', fontSize: '1.3em' }}>Em Execução</div>
                </CardContent>
            </Card>


            <Card className="card_contador_chamados"
                style={{
                    // backgroundColor: cor_tipos_situacao['pendente_cliente'],
                    borderTop: `8px solid ${cor_tipos_situacao['pendente_cliente']}`,
                    borderBottom: `8px solid ${cor_tipos_situacao['pendente_cliente']}`,
                    borderRadius: '40px 0px 30px 0px'
                }}
                onClick={() => {
                    localStorage.setItem('filtro_situacao', 'pendente')
                    set_filtro_situacao('pendente')
                    load_tickets('pendente', true)
                }}>
                <CardContent style={styleCardsContent}>
                    <div style={{ width: '100%', textAlign: 'center', fontSize: '3em' }}>{Object.keys(ticketsCounter).length === 0 ? <img style={style_image_loading} src="https://sgi.intecsolutions.com.br:4000/uploads/loading.webp" /> : ticketsCounter.pendentes}</div>
                    <div style={{ width: '100%', textAlign: 'center', fontSize: '1.3em' }}>Pendentes</div>
                </CardContent>
            </Card>


            <Card className="card_contador_chamados"
                style={{
                    // backgroundColor: cor_tipos_situacao['janela_agendada'],
                    borderTop: `8px solid ${cor_tipos_situacao['janela_agendada']}`,
                    borderBottom: `8px solid ${cor_tipos_situacao['janela_agendada']}`,
                    borderRadius: '40px 0px 30px 0px'
                }}
                onClick={() => {
                    localStorage.setItem('filtro_situacao', 'janela_agendada')
                    set_filtro_situacao('janela_agendada')
                    load_tickets('janela_agendada', true)
                }}>
                <CardContent style={styleCardsContent}>
                    <div style={{ width: '100%', textAlign: 'center', fontSize: '3em' }}>{Object.keys(ticketsCounter).length === 0 ? <img style={style_image_loading} src="https://sgi.intecsolutions.com.br:4000/uploads/loading.webp" /> : ticketsCounter.janela_agendada}</div>
                    <div style={{ width: '100%', textAlign: 'center', fontSize: '1.3em' }}>Janela Agendada</div>
                </CardContent>
            </Card>

        </div>
    );
}

export default ContadoresDE;