import React, { useState, useEffect } from 'react';

import './Outages.css';

import OutageDetail from './OutageDetail';

import { api_bd } from '../../services/Api'

import OutageReport from './OutageReport';

import {
	useRecoilState,
	useRecoilValue,
	useSetRecoilState
} from 'recoil'

import outagesComponentToShowState, { outagesListTicketState } from '../../atoms/outages_state.atom';
import { profileData } from '../../atoms/user.atom';

import Modal from '../Modal';
// import NewOutage from './NewOutage';
import NewInteraction from './NewInteraction';
import OutageList from './OutageList';
import CloseTicket from './CloseTicket';

function Outages(props) {
	const [showFormNewEvent, setShowFormNewEvent] = useState(false)

	const [componentToShow, setComponentToShow] = useRecoilState(outagesComponentToShowState)

	const setEventList = useSetRecoilState(outagesListTicketState)

	const [contentOption, setContentOption] = useState('list')

	const loggedUser = useRecoilValue(profileData)

	const ContentComponent = () => {
		switch (componentToShow.component) {
			case 'newInteraction':
				return (
					<NewInteraction ev={componentToShow.data} callbackfn={async () => {
						setComponentToShow(null)
					}} />
				)
				break;
			case 'outageDetail':
				return (
					<OutageDetail ev={componentToShow.data} callbackfn={async () => {
						setComponentToShow(null)
					}} />
				)
				break;

			case 'closeTicket':
				return (
					<CloseTicket ev={componentToShow.data} callbackfn={async () => {
						setComponentToShow(null)
					}} />
				)
				break;

			default:
				break;
		}
	}

	const buscarEventos = async () => {
		let tmpEv = await api_bd.get(`/outages/status/aberto`)
		if (tmpEv.data.length === 0) {
			setEventList([])
		} else {
			if (componentToShow === null) {
				setEventList(tmpEv.data)
			}
		}
	}

	const ShowContent = ({ option }) => {
		switch (option) {
			case 'list':
				return (<OutageList />)
				break;
			case 'report':
				return (<OutageReport />)
				break;

			default:
				break;
		}
	}

	useEffect(() => {
		buscarEventos()
	}, [componentToShow])

	return (
		<>
			{/* <div style={{backgroundColor: '#3c5ca6', padding: 10}}>
                <img src="../logo-intec.svg" style={{height: 50, marginLeft: 20}} />
            </div> */}

			<div className='container'>
				<div className='card' style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingTop: 10, paddingBottom: 10 }}>
					<span style={{ fontSize: '1.8em', fontWeight: 'bold' }}>OUTAGES</span>
					{loggedUser && loggedUser.nivel.match(/Outage-level-1|Super|Técnico-Admin/i) &&
						<div>
							<span className='font-bold'>Selecione a opção de visualização do conteúdo: </span>
							<select
								onChange={(e) => {
									setContentOption(e.target.value)
								}}
							>
								<option value='list'>Lista de evento</option>
								<option value='report'>Relatórios</option>
							</select>
						</div>
					}
				</div>

				{/* Content */}
				{/* <OutageList /> */}
				<ShowContent option={contentOption} />

				{showFormNewEvent &&
					<Modal callbackfn={() => {
						setShowFormNewEvent(false)
					}}>
						<div className='card' style={{ padding: 30, width: '80%', maxWidth: '80%', height: '85vh', backgroundColor: '#fff', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
							{/* <NewOutage callbackfn={() => {
								setShowFormNewEvent(false)
							}} /> */}
						</div>
					</Modal>
				}


				{componentToShow !== null &&
					<Modal callbackfn={() => {
						setComponentToShow(null)
					}}>
						<div className='card' style={{ padding: 30, width: '80%', maxWidth: '80%', height: '85vh', backgroundColor: '#fff', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
							<ContentComponent />
						</div>
					</Modal>
				}
			</div>
		</>
	);
}

export default Outages;