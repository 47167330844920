import React 
// {
//     useState,
//     useEffect
// } 
from 'react';

// import {
//     Link,
//     useHistory
// } from "react-router-dom";

import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';

// import TextField from '@material-ui/core/TextField';
// import Grid from '@material-ui/core/Grid';

// import * as IoIcons from 'react-icons/io';
// import EditIcon from '@material-ui/icons/Edit';
// import WhatsAppIcon from '@material-ui/icons/WhatsApp';
// import GDriveIcon from '../assets/drive_logo.svg';

import Styles, { colors_access_types } from '../components/Styles'

// import { 
//     api_bd, 
//     // getUserData 
// } from '../services/Api'

// import FormEditClient from './Forms/FormEditClient'
// import FormDataTransit from './Forms/FormDataTransit'
// import FormDataPublicIp from './Forms/FormDataPublicIp'
// import FormDataAccess from './Forms/FormDataAccess'
// import FormEditAccess from './Forms/FormEditAccess'
// import FormDataSenhas from './Forms/FormDataSenhas'
// import FormDataObservacoes from './Forms/FormDataObservacoes'
// import FormDataDefaults from './Forms/FormDataDefaults'
// import FormDataStation from './Forms/FormDataStation'


import { useRecoilValue } from 'recoil'
// import userState, { profileData } from '../atoms/user.atom'
import clientDetailsData from '../atoms/client_details.atom'
import { profileData } from '../atoms/user.atom'
import HidePass, { copyToClipBoard } from '../util/fns'


// const config = require('../config.json')

function ListAccessByType(props) {
    const styles = Styles();
    const { _filter, _situacao, _classes, _style, set_content_modal, set_access_to_edit, set_show_modal, dados } = props

    const data_access = [...dados]
    const data_client = useRecoilValue(clientDetailsData)
    const user_sgi = useRecoilValue(profileData)

    return (
        <div className={_classes ? `${_classes}` : 'flex flexRow flexWrap justify-content-start'} style={_style ? _style : {}}>
            {
                data_access && data_access.length > 0 && _filter.map(filtro => (
                    data_access.map((ac) => (ac.tipo == filtro && ac.situacao == _situacao &&
                        <Card
                            key={`${ac._id}${ac.tipo}`}
                            className={styles.card}
                            style={{ width: 200, maxWidth: 200, height: 275, maxHeight: 275 }}
                        >
                            <CardContent style={{ padding: 0 }}>
                                <div
                                    className="flex justify-content-sb"
                                    style={{
                                        width: "100%",
                                        backgroundColor: ac.situacao == 'Ativo' ? `${colors_access_types[ac.tipo]}` : `${colors_access_types[ac.tipo]}33`,
                                        color: 'white',
                                        padding: 10,
                                        cursor: user_sgi.nivel.match(/Super/i) || user_sgi.nivel.match(/Técnico/i) ? "pointer" : ""
                                    }}
                                    onClick={() => {
                                        if (user_sgi.nivel.match(/Super/i) || user_sgi.nivel.match(/Técnico/i)) {
                                            set_content_modal('edit_access')
                                            set_access_to_edit(ac)
                                            set_show_modal(true)
                                        }
                                    }}>
                                    <div className="flex">
                                        {ac.descricao}
                                    </div>
                                </div>

                                <div style={{
                                    width: "100%",
                                    padding: 10,
                                    color: ac.situacao == 'Ativo' ? '#3c5ca6' : '#3c5ca655'
                                }}>
                                    {/* <div style={{ fontSize: 14, marginBottom: 10 }}><b>{ac.tipo}</b></div> */}
                                    {/* {(ac.tipo == 'VPN' || ac.tipo == 'Conta' || ac.tipo == 'Servidor' || ac.tipo == 'Ferramenta' || ac.tipo == 'Ativo de Rede') && ac.localidade && <div style={{ fontSize: 12, marginBottom: 5, wordWrap: 'break-word' }}><b>Localidade:</b> {ac.localidade}</div>} */}
                                    {(ac.tipo == 'Ativo de Rede' || ac.tipo == 'Servidor') && ac.estacao && <div style={{ fontSize: 12, marginBottom: 5, wordWrap: 'break-word' }}><b>Estação:</b> {ac.estacao}</div>}
                                    {(ac.tipo == 'VPN') && ac.referencia_estacao && <div style={{ fontSize: 12, marginBottom: 5, wordWrap: 'break-word' }}><b>Referência da Estação:</b> {ac.referencia_estacao}</div>}
                                    {(ac.tipo == 'VPN') && ac.tipo_vpn && <div style={{ fontSize: 12, marginBottom: 5, wordWrap: 'break-word' }}><b>Tipo da VPN:</b> {ac.tipo_vpn}</div>}
                                    {(ac.tipo == 'VPN') && ac.chave_ipsec && <HidePass _style={{ fontSize: 12, marginBottom: 5, wordWrap: 'break-word', cursor: 'pointer' }} _txt="Chave IPSec: " _pass={ac.chave_ipsec} />}

                                    {(ac.tipo == 'Ferramenta' || ac.tipo == 'Conta') && ac.servico && <div style={{ fontSize: 12, marginBottom: 5, wordWrap: 'break-word' }}><b>Serviço:</b> {ac.servico}</div>}
                                    {/* {ac.situacao && <div style={{ fontSize: 12, marginBottom: 5, wordWrap: 'break-word' }}><b>Situação:</b> {ac.situacao}</div>} */}
                                    {ac.equipamento && <div style={{ fontSize: 12, marginBottom: 5, wordWrap: 'break-word' }}><b>Equipamento:</b> {ac.equipamento}</div>}
                                    {ac.fabricante && <div style={{ fontSize: 12, marginBottom: 5, wordWrap: 'break-word' }}><b>Fabricante:</b> {ac.fabricante}</div>}
                                    {ac.modelo && <div style={{ fontSize: 12, marginBottom: 5, wordWrap: 'break-word' }}><b>Modelo:</b> {ac.modelo}</div>}
                                    {(ac.tipo == 'Servidor' || ac.tipo == 'Ativo de Rede') && ac.tipo_acesso && <div style={{ fontSize: 12, marginBottom: 5, wordWrap: 'break-word' }}><b>Tipo de Acesso: </b> {ac.tipo_acesso.toUpperCase()}</div>}
                                    {(ac.tipo == 'VPN' || ac.tipo == 'Ferramenta' || ac.tipo == 'Conta' || ac.tipo == 'Servidor' || ac.tipo == 'Ativo de Rede') && ac.ip_dominio_link &&
                                        <div style={{ fontSize: 12, marginBottom: 5, wordWrap: 'break-word', cursor: (ac.ip_dominio_link && ac.ip_dominio_link.match(/http|https/i)) ? 'pointer' : '' }}
                                            onClick={() => {
                                                if (ac.ip_dominio_link && ac.tipo_acesso.toLowerCase() === 'ssh') {
                                                    copyToClipBoard(`ssh ${ac.usuario_intec || ac.usuario_cliente}@${ac.ip_dominio_link} -p ${ac.porta}`)
                                                }
                                                if (ac.ip_dominio_link && ac.ip_dominio_link.match(/http|https/i)) {
                                                    window.open(ac.ip_dominio_link)
                                                }
                                            }}>
                                            <b>{(ac.tipo == 'VPN' || ac.tipo == 'Servidor') ? 'IP:' : ac.tipo == 'Conta' ? 'URL' : 'IP/Domínio:'}</b> {ac.ip_dominio_link}
                                        </div>
                                    }
                                    {(ac.tipo == 'Ferramenta' || ac.tipo == 'Servidor' || ac.tipo == 'Ativo de Rede') && (ac.porta != 0 && ac.porta != null && ac.porta != undefined) && <div style={{ fontSize: 12, marginBottom: 5, wordWrap: 'break-word' }}><b>{ac.tipo == 'Servidor' ? 'Porta SSH:' : 'Porta:'}</b> {ac.porta}</div>}


                                    {(user_sgi.nivel.match(/Super/i) || user_sgi.nivel.match(/Técnico/i)) &&
                                        <>
                                            {(ac.tipo == 'VPN' || ac.tipo == 'Ferramenta' || ac.tipo == 'Conta' || ac.tipo == 'Servidor' || ac.tipo == 'Ativo de Rede') && (ac.usuario_intec || data_client.user_default_intec) && <div style={{ fontSize: 12, marginBottom: 5, wordWrap: 'break-word' }}><b>Usuário Intec:</b> {(ac.usuario_intec == '' || ac.usuario_intec == undefined) ? data_client.user_default_intec : ac.usuario_intec}</div>}
                                            {(ac.tipo == 'VPN' || ac.tipo == 'Ferramenta' || ac.tipo == 'Conta' || ac.tipo == 'Servidor' || ac.tipo == 'Ativo de Rede') && (ac.senha_intec || data_client.senha_default_intec) && <HidePass _style={{ fontSize: 12, marginBottom: 5, wordWrap: 'break-word', cursor: 'pointer' }} _txt="Senha Intec: " _pass={(ac.senha_intec == '' || ac.senha_intec == undefined) ? data_client.senha_default_intec : ac.senha_intec} />}
                                            {(ac.tipo == 'Servidor' || ac.tipo == 'Ativo de Rede') && ac.senha_root && <HidePass _style={{ fontSize: 12, marginBottom: 5, wordWrap: 'break-word', cursor: 'pointer' }} _txt="Senha ROOT: " _pass={ac.senha_root} />}
                                        </>
                                    }

                                    {user_sgi.nivel.match(/Cliente/i) &&
                                        <>
                                            {(ac.tipo == 'VPN' || ac.tipo == 'Ferramenta' || ac.tipo == 'Conta' || ac.tipo == 'Ativo de Rede' || ac.tipo == 'Servidor') && (ac.usuario_cliente || data_client.user_default_cliente) && <div style={{ fontSize: 12, marginBottom: 5, wordWrap: 'break-word' }}><b>Usuário:</b> {(ac.usuario_cliente == '' || ac.usuario_cliente == undefined) ? data_client.user_default_cliente : ac.usuario_cliente}</div>}
                                            {(ac.tipo == 'VPN' || ac.tipo == 'Ferramenta' || ac.tipo == 'Conta' || ac.tipo == 'Ativo de Rede' || ac.tipo == 'Servidor') && (ac.senha_cliente || data_client.senha_default_cliente) && <HidePass _style={{ fontSize: 12, marginBottom: 5, wordWrap: 'break-word', cursor: 'pointer' }} _txt="Senha: " _pass={(ac.senha_cliente == '' || ac.senha_cliente == undefined) ? data_client.senha_default_cliente : ac.senha_cliente} />}
                                        </>
                                    }

                                    {(ac.tipo == 'VPN' || ac.tipo == 'Conta' || ac.tipo == 'Servidor' || ac.tipo == 'Ferramenta' || ac.tipo == 'Ativo de Rede') && ac.observacoes && <div style={{ fontSize: 12, marginBottom: 5, wordWrap: 'break-word' }}><b>Observações:</b> {ac.observacoes}</div>}
                                </div>
                            </CardContent>
                        </Card>
                    ))
                ))}
        </div>
    )
}


export default ListAccessByType;