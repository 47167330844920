import React from 'react';

import styles from './styles.module.css'

import ViewPost from './ViewPost';

import RowPosts from './RowPosts';

const configs = require('../../config.json')

function PopUpListaPosts(props) {
    const notifications = props.notifications || []
    return (
        <div className={styles['notificationsContainer']}>
            {notifications.map(notify => (
                <RowPosts key={`notify${notify._id}`} notify={notify} user_profile={props.user_profile} />
            ))}
        </div>
    )
}

export default PopUpListaPosts;