import React, { useEffect, useState, useRef } from 'react';

import './Outages.css'

import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { api_bd } from '../../services/Api'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'
import customerData from '../../atoms/customers.atom'
import { outagesListTicketState, outagesListTickeClosedtState } from '../../atoms/outages_state.atom';

function NewOutage(props) {
    const refComp1 = useRef()
    const refComp2 = useRef()

    const profile_user = useRecoilValue(profileData)
    const clientList = useRecoilValue(customerData)
    const [listaClientes, setListaClientes] = useState([])
    const [eventFormIcon, setEventFormIcon] = useState('checklist')
    const [actualComp, setActualComp] = useState(1)
    const [dataTicket, setDataTicket] = useState({
        tipo_evento: "Selecione o tipo do evento",
        horario: '',
        trecho_ponto_a: '',
        trecho_ponto_b: '',
        sub_trecho_ponto_a: '',
        sub_trecho_ponto_b: '',
        foi_solucionado: '',
        hora_solucao: '',
        porque_nao_foi_solucionado: '',
        estacao: '',
        qual_o_problema: '',
        feedback_do_suporte: '',
        previsao_para_solucao: '',
        numero_protocolo: '',
        foi_escalonado: '',
        hora_escalonado: '',
        pra_quem_foi_escalonado: '',
        porque_nao_foi_escalonado: '',
        userId: '',
        userName: '',
        userLevel: '',
        data_insercao: { type: Date, default: '' },
        customer_id: '',
        customer_name: ''
    })

    const setEventList = useSetRecoilState(outagesListTicketState)
    const setEventListClosed = useSetRecoilState(outagesListTickeClosedtState)

    const buscarEventos = async () => {
        let tmpEv = await api_bd.get(`/outages/status/aberto`)
        setEventList(tmpEv.data)
    }

    const buscarEventosFechados = async () => {
        let tmpEv = await api_bd.get(`/outages/status/fechado`)
        setEventListClosed(tmpEv.data)
    }

    const handle_data = (key, value) => {
        let data = { ...dataTicket }
        data[key] = value
        setDataTicket(data)
    }

    const handle_select_client_data = (value) => {
        let data = { ...dataTicket }
        const { id, name } = JSON.parse(value)
        data.customer_id = id
        data.customer_name = name
        setDataTicket(data)
    }

    useEffect(() => {
        // console.log('ActualComp', actualComp)
        switch (actualComp) {
            case 1:
                refComp2.current.classList.remove('show_in')
                refComp2.current.classList.add('hidden_out')

                refComp1.current.classList.add('show_in')
                refComp1.current.classList.remove('hidden_out')
                break;
            case 2:
                refComp1.current.classList.remove('show_in')
                refComp1.current.classList.add('hidden_out')

                refComp2.current.classList.add('show_in')
                refComp2.current.classList.remove('hidden_out')
                break;

            default:
                break;
        }
    }, [actualComp])


    useEffect(() => {
        // ICONES FORMULARIOS
        //https://fonts.google.com/icons
        switch (dataTicket.tipo_evento) {
            case 'Selecione o tipo do evento':
                setEventFormIcon('checklist')
                break;
            case 'Rompimento':
                setEventFormIcon('link_off')
                break;
            case 'Estação Indisponível':
                setEventFormIcon('wifi_tethering_error')
                break;
            case 'Link Com Problema':
                setEventFormIcon('mobiledata_off')
                break;
            case 'Falha Elétrica':
                setEventFormIcon('bolt')
                break;
            case 'Falha de OLT':
                setEventFormIcon('lan')
                break;

            default:
                break;
        }
    }, [dataTicket.tipo_evento])


    useEffect(() => {
        setListaClientes(clientList.map(el => (<MenuItem value={`{"id": "${el._id}", "name": "${el.nome}"}`} style={{ color: '#000' }}>{el.nome}</MenuItem>)))
    }, [])


    // const Rompimento = () => {
    //     // const [cliente, setCliente] = useState('')
    //     const [horario_inicio, setHorario_inicio] = useState('')
    //     const [ponto_a, setPonto_a] = useState('')
    //     const [ponto_b, setPonto_b] = useState('')
    //     const [clientesAfetados, setClientesAfetados] = useState('')
    //     return (
    //         <div style={{ width: '100%' }}>

    //             {/* <div style={{ width: '100%', marginTop: 10 }}>
    //                 <FormControl style={{ width: '100%' }}>
    //                     <InputLabel htmlFor="select-customer">CLIENTE</InputLabel>
    //                     <Select
    //                         autoFocus={true}
    //                         defaultValue={"Selecione o cliente"}
    //                         value={cliente}
    //                         labelId="select-customer"
    //                         id="situacao"
    //                         onChange={e => {
    //                             setCliente(e.target.value)
    //                             // handle_select_client_data(e.target.value)
    //                         }}
    //                         required={true}
    //                     >
    //                         {listaClientes}
    //                     </Select>
    //                 </FormControl>
    //             </div> */}

    //             <div style={{ width: '100%', marginTop: 10 }}>
    //                 <TextField
    //                     type="time"
    //                     style={{ width: '100%' }}
    //                     id="horario"
    //                     label="Qual horário do incidente?"
    //                     InputLabelProps={{
    //                         shrink: true,
    //                     }}
    //                     onChange={e => {
    //                         setHorario_inicio(e.target.value)
    //                         // handle_data("horario", e.target.value)
    //                     }}
    //                     value={horario_inicio}
    //                 />
    //             </div>

    //             <div style={{ width: '100%', marginTop: 10 }}>
    //                 <TextField
    //                     style={{ width: '100%' }}
    //                     id="ponto_a"
    //                     label="Qual Ponto A?"
    //                     onChange={e => {
    //                         // handle_data("ponto_a", e.target.value)
    //                         setPonto_a(e.target.value)
    //                     }}
    //                     value={ponto_a}
    //                 />
    //             </div>

    //             <div style={{ width: '100%', marginTop: 10 }}>
    //                 <TextField
    //                     style={{ width: '100%' }}
    //                     id="ponto_b"
    //                     label="Qual Ponto B?"
    //                     onChange={e => {
    //                         // handle_data("ponto_b", e.target.value)
    //                         setPonto_b(e.target.value)
    //                     }}
    //                     value={ponto_b}
    //                 />
    //             </div>

    //             <div style={{ width: '100%', marginTop: 10 }}>
    //                 <TextField
    //                     style={{ width: '100%' }}
    //                     id="clientes_afetados"
    //                     label="Clientes afetados"
    //                     onChange={e => {
    //                         setClientesAfetados(e.target.value)
    //                         // handle_data("horario_inicio", e.target.value)
    //                     }}
    //                     value={clientesAfetados}
    //                 />
    //             </div>

    //             <button
    //                 // className={dataTicket.tipo_evento === "Selecione o tipo do evento" ? 'buttom-disabled fixToCenter' : 'buttom fixToCenter'}
    //                 // disabled={dataTicket.tipo_evento === "Selecione o tipo do evento" ? true : false}
    //                 className='buttom fixToCenter'
    //                 style={{ marginTop: 20, padding: 10, width: 100 }}
    //                 onClick={async () => {
    //                     const filters = [horario_inicio, ponto_a, ponto_b]
    //                     for (let filtro in filters) {
    //                         // console.log(filters[filtro])
    //                         if (filters[filtro] === '') {
    //                             return (alert('Todos os campos devem ser preenchidos'))
    //                         }
    //                     }

    //                     // const { id, name } = JSON.parse(cliente)

    //                     let data = new Date().getTime()
    //                     let tempdata = {
    //                         ...dataTicket,
    //                         horario_inicio,
    //                         ponto_a,
    //                         ponto_b,
    //                         clientes_afetados: clientesAfetados,
    //                         // customer_id: id,
    //                         // customer_name: name,
    //                         userId: profile_user.id,
    //                         userName: profile_user.nome,
    //                         userLevel: profile_user.nivel,
    //                         data_insercao: data
    //                     }
    //                     await api_bd.post('/outages', tempdata)
    //                     await buscarEventos()
    //                     await buscarEventosFechados()
    //                     // console.log(resp.data)
    //                     props.callbackfn()

    //                 }}
    //             >
    //                 Salvar
    //             </button>
    //         </div>
    //     )
    // }

    const EstacaoIndisponivel = () => {
        // const [cliente, setCliente] = useState('')
        const date = new Date()
        const [horario_inicio, setHorario_inicio] = useState(`${date.getFullYear()}-${String(date.getMonth()+1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`)
        const [estacao, setEstacao] = useState('')
        const [responsavel_estacao, setResponsavel_Estacao] = useState('')
        const [clientesAfetados, setClientesAfetados] = useState('')
        const [numero_protocolo, setNumero_protocolo] = useState('')
        // const [ponto_b, setPonto_b] = useState('')

        return (
            <div style={{ width: '100%' }}>
                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="datetime-local"
                        style={{ width: '100%' }}
                        id="horario_inicio"
                        label="Qual horário do incidente?"
                        // defaultValue="2017-05-24T10:30"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setHorario_inicio(e.target.value)
                            // handle_data("horario_inicio", e.target.value)
                        }}
                        value={horario_inicio}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="clientes_afetados"
                        label="Clientes afetados"
                        multiline={true}
                        onChange={e => {
                            setClientesAfetados(e.target.value)
                            // handle_data("horario_inicio", e.target.value)
                        }}
                        value={clientesAfetados}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="estacao"
                        label="Qual Estação?"
                        onChange={e => {
                            // handle_data("estacao", e.target.value)
                            setEstacao(e.target.value)
                        }}
                        value={estacao}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="text"
                        style={{ width: '100%' }}
                        id="responsavel_estacao"
                        label="Responsável pela estação?"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setResponsavel_Estacao(e.target.value)
                            // handle_data("horario_inicio_inicio", e.target.value)
                        }}
                        value={responsavel_estacao}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="numero_protocolo"
                        label="Qual número do ticket aberto para este evento?"
                        onChange={e => {
                            setNumero_protocolo(e.target.value)
                        }}
                        value={numero_protocolo}
                    />
                </div>

                <button
                    className='buttom fixToCenter'
                    style={{ marginTop: 20, padding: 10, width: 100 }}
                    onClick={async () => {
                        const filters = [horario_inicio, estacao]
                        for (let filtro in filters) {
                            // console.log(filters[filtro])
                            if (filters[filtro] === '') {
                                return (alert('Todos os campos devem ser preenchidos'))
                            }
                        }

                        let data = new Date().getTime()
                        let tempdata = {
                            ...dataTicket,
                            horario_inicio,
                            estacao,
                            responsavel_estacao,
                            clientes_afetados: clientesAfetados,
                            numero_protocolo,

                            // customer_id: id,
                            // customer_name: name,
                            userId: profile_user.id,
                            userName: profile_user.nome,
                            userLevel: profile_user.nivel,
                            data_insercao: data
                        }
                        await api_bd.post('/outages', tempdata)
                        await buscarEventos()
                        // console.log(resp.data)
                        props.callbackfn()
                    }}
                >
                    Salvar
                </button>
            </div>
        )
    }

    const LinkComProblema = () => {
        // const [cliente, setCliente] = useState('')
        const date = new Date()
        const [horario_inicio, setHorario_inicio] = useState(`${date.getFullYear()}-${String(date.getMonth()+1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`)
        const [trecho_ponto_a, setTrecho_Ponto_a] = useState('')
        const [trecho_ponto_b, setTrecho_Ponto_b] = useState('')
        const [sub_trecho_ponto_a, setSubTrecho_Ponto_a] = useState('')
        const [sub_trecho_ponto_b, setSubTrecho_Ponto_b] = useState('')
        const [qual_o_problema, setQual_o_problema] = useState('ATENUAÇÃO')
        const [numero_protocolo, setNumero_protocolo] = useState('')
        const [fornecedor, setFornecedor] = useState('')
        const [clientesAfetados, setClientesAfetados] = useState('')
        return (
            <div style={{ width: '100%' }}>
                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="datetime-local"
                        style={{ width: '100%' }}
                        id="horario_inicio"
                        label="Qual horário do incidente?"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setHorario_inicio(e.target.value)
                            // handle_data("horario_inicio", e.target.value)
                        }}
                        value={horario_inicio}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <FormControl style={{ width: '100%', flexDirection: 'column' }}>
                        <InputLabel htmlFor="select-qual_o_problema">Qual o Problema? </InputLabel>
                        <Select
                            defaultValue={qual_o_problema}
                            labelId="select-qual_o_problema"
                            id="qual_o_problema"
                            onChange={e => {
                                setQual_o_problema(e.target.value)
                            }}
                            required={true}
                        >
                            <MenuItem value="ATENUAÇÃO">ATENUAÇÃO</MenuItem>
                            <MenuItem value="BAIXA PERFORMANCE">BAIXA PERFORMANCE</MenuItem>
                            <MenuItem value="INDISPONIBILIDADE">INDISPONIBILIDADE</MenuItem>
                            <MenuItem value="ROMPIMENTO">ROMPIMENTO</MenuItem>
                            <MenuItem value="TAXAÇÃO DE ERROS">TAXAÇÃO DE ERROS</MenuItem>
                        </Select>
                    </FormControl>
                </div>


                <>
                    <div style={{ width: '100%', marginTop: 10 }}>
                        <TextField
                            style={{ width: '100%' }}
                            id="ponto_a"
                            label="Trecho ponto A?"
                            onChange={e => {
                                // handle_data("ponto_a", e.target.value)
                                setTrecho_Ponto_a(e.target.value)
                            }}
                            value={trecho_ponto_a}
                        />
                    </div>

                    <div style={{ width: '100%', marginTop: 10 }}>
                        <TextField
                            style={{ width: '100%' }}
                            id="ponto_b"
                            label="Trecho ponto B?"
                            onChange={e => {
                                // handle_data("ponto_b", e.target.value)
                                setTrecho_Ponto_b(e.target.value)
                            }}
                            value={trecho_ponto_b}
                        />
                    </div>

                    <div style={{ width: '100%', marginTop: 10 }}>
                        <TextField
                            style={{ width: '100%' }}
                            id="ponto_a"
                            label="Sub-trecho ponto A?"
                            onChange={e => {
                                // handle_data("ponto_a", e.target.value)
                                setSubTrecho_Ponto_a(e.target.value)
                            }}
                            value={sub_trecho_ponto_a}
                        />
                    </div>

                    <div style={{ width: '100%', marginTop: 10 }}>
                        <TextField
                            style={{ width: '100%' }}
                            id="ponto_b"
                            label="Sub-trecho ponto B?"
                            onChange={e => {
                                // handle_data("ponto_b", e.target.value)
                                setSubTrecho_Ponto_b(e.target.value)
                            }}
                            value={sub_trecho_ponto_b}
                        />
                    </div>
                </>



                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="numero_protocolo"
                        label="Qual número do ticket aberto para este evento?"
                        onChange={e => {
                            setNumero_protocolo(e.target.value)
                        }}
                        value={numero_protocolo}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="fornecedor"
                        label="Qual o fornecedor do link?"
                        onChange={e => {
                            setFornecedor(e.target.value)
                        }}
                        value={fornecedor}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="clientes_afetados"
                        label="Clientes afetados"
                        onChange={e => {
                            setClientesAfetados(e.target.value)
                            // handle_data("horario_inicio", e.target.value)
                        }}
                        value={clientesAfetados}
                    />
                </div>

                <button
                    className='buttom fixToCenter'
                    style={{ marginTop: 20, padding: 10, width: 100 }}
                    onClick={async () => {
                        const filters = [horario_inicio, qual_o_problema, numero_protocolo]
                        for (let filtro in filters) {
                            // console.log(filters[filtro])
                            if (filters[filtro] === '') {
                                return (alert('Todos os campos devem ser preenchidos'))
                            }
                        }

                        let data = new Date().getTime()
                        let tempdata = {
                            ...dataTicket,
                            horario_inicio,
                            qual_o_problema,
                            numero_protocolo,
                            trecho_ponto_a,
                            trecho_ponto_b,
                            sub_trecho_ponto_a,
                            sub_trecho_ponto_b,
                            fornecedor,
                            clientes_afetados: clientesAfetados,

                            userId: profile_user.id,
                            userName: profile_user.nome,
                            userLevel: profile_user.nivel,
                            data_insercao: data
                        }
                        await api_bd.post('/outages', tempdata)
                        await buscarEventos()
                        // console.log(resp.data)
                        props.callbackfn()
                    }}
                >
                    Salvar
                </button>
            </div>
        )
    }

    const FalhaEletrica = () => {
        // const [cliente, setCliente] = useState('')
        const date = new Date()
        const [horario_inicio, setHorario_inicio] = useState(`${date.getFullYear()}-${String(date.getMonth()+1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`)
        const [estacao, setEstacao] = useState('')
        const [responsavel_estacao, setResponsavel_Estacao] = useState('')
        const [equipamento, setEquipamento] = useState('')
        const [clientesAfetados, setClientesAfetados] = useState('')
        const [autonomia_eletrica, setAutonomia_eletrica] = useState(0)
        // const [numero_protocolo, setNumero_protocolo] = useState('')
        return (
            <div style={{ width: '100%' }}>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="datetime-local"
                        style={{ width: '100%' }}
                        id="horario_inicio"
                        label="Qual horário do incidente?"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setHorario_inicio(e.target.value)
                            // handle_data("horario_inicio", e.target.value)
                        }}
                        value={horario_inicio}
                    />
                </div>


                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="clientes_afetados"
                        label="Clientes afetados"
                        onChange={e => {
                            setClientesAfetados(e.target.value)
                            // handle_data("horario_inicio", e.target.value)
                        }}
                        value={clientesAfetados}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="text"
                        style={{ width: '100%' }}
                        id="estacao"
                        label="Qual Estação?"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setEstacao(e.target.value)
                            // handle_data("horario", e.target.value)
                        }}
                        value={estacao}
                    />
                </div>


                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="text"
                        style={{ width: '100%' }}
                        id="responsavel_estacao"
                        label="Responsável pela estação?"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setResponsavel_Estacao(e.target.value)
                        }}
                        value={responsavel_estacao}
                    />
                </div>


                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="text"
                        style={{ width: '100%' }}
                        id="equipamento"
                        label="Qual Equipamento?"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setEquipamento(e.target.value)
                            // handle_data("horario", e.target.value)
                        }}
                        value={equipamento}
                    />
                </div>


                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="number"
                        min="1"
                        style={{ width: '100%' }}
                        id="autonomia"
                        label="Autonomia da estação? (em horas)"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setAutonomia_eletrica(e.target.value)
                            // handle_data("horario", e.target.value)
                        }}
                        value={autonomia_eletrica}
                    />
                </div>


                <button
                    className='buttom fixToCenter'
                    style={{ marginTop: 20, padding: 10, width: 100 }}
                    onClick={async () => {
                        const filters = [horario_inicio, estacao, equipamento]
                        for (let filtro in filters) {
                            // console.log(filters[filtro])
                            if (filters[filtro] === '') {
                                return (alert('Todos os campos devem ser preenchidos'))
                            }
                        }


                        let data = new Date().getTime()
                        let tempdata = {
                            ...dataTicket,
                            estacao,
                            responsavel_estacao,
                            equipamento,
                            horario_inicio,
                            autonomia_eletrica,
                            clientes_afetados: clientesAfetados,

                            userId: profile_user.id,
                            userName: profile_user.nome,
                            userLevel: profile_user.nivel,
                            data_insercao: data
                        }
                        await api_bd.post('/outages', tempdata)
                        await buscarEventos()
                        // console.log(resp.data)
                        props.callbackfn()
                    }}
                >
                    Salvar
                </button>
            </div>
        )
    }

    const FalhaDeOlt = () => {
        // const [cliente, setCliente] = useState('')
        const date = new Date()
        const [horario_inicio, setHorario_inicio] = useState(`${date.getFullYear()}-${String(date.getMonth()+1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`)
        const [estacao, setEstacao] = useState('')
        const [qual_o_problema, setQual_o_problema] = useState('ROMPIMENTO SLOT/PON')
        const [responsavel, setResponsavel] = useState('')
        const [clientesAfetados, setClientesAfetados] = useState('')
        const [numero_protocolo, setNumero_protocolo] = useState('')
        const [slotPon, setSlotPon] = useState('')

        // const [ponto_b, setPonto_b] = useState('')

        return (
            <div style={{ width: '100%' }}>
                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="datetime-local"
                        style={{ width: '100%' }}
                        id="horario_inicio"
                        label="Qual horário do incidente?"
                        // defaultValue="2017-05-24T10:30"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setHorario_inicio(e.target.value)
                            // handle_data("horario_inicio", e.target.value)
                        }}
                        value={horario_inicio}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <FormControl style={{ width: '100%', flexDirection: 'column' }}>
                        <InputLabel htmlFor="select-qual_o_problema">Qual o Problema? </InputLabel>
                        <Select
                            defaultValue={qual_o_problema}
                            labelId="select-qual_o_problema"
                            id="qual_o_problema"
                            onChange={e => {
                                setQual_o_problema(e.target.value)
                            }}
                            required={true}
                        >
                            <MenuItem value="ROMPIMENTO SLOT/PON">ROMPIMENTO SLOT/PON</MenuItem>
                            <MenuItem value="OLT INDISPONVÍVEL">OLT INDISPONVÍVEL</MenuItem>
                            <MenuItem value="ROMPIMENTO PARCIAL SLOT/PON">ROMPIMENTO PARCIAL SLOT/PON</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="clientes_afetados"
                        label="Clientes afetados"
                        multiline={true}
                        onChange={e => {
                            setClientesAfetados(e.target.value)
                            // handle_data("horario_inicio", e.target.value)
                        }}
                        value={clientesAfetados}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="estacao"
                        multiline={true}
                        label="Qual Estação?"
                        onChange={e => {
                            setEstacao(e.target.value)
                        }}
                        value={estacao}
                    />
                </div>


                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="slot-pon"
                        multiline={true}
                        label="SLOT / PON"
                        onChange={e => {
                            setSlotPon(e.target.value)
                        }}
                        value={slotPon}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        type="text"
                        style={{ width: '100%' }}
                        id="responsavel"
                        label="Responsável?"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => {
                            setResponsavel(e.target.value)
                        }}
                        value={responsavel}
                    />
                </div>

                <div style={{ width: '100%', marginTop: 10 }}>
                    <TextField
                        style={{ width: '100%' }}
                        id="numero_protocolo"
                        label="Qual número do ticket aberto para este evento?"
                        onChange={e => {
                            setNumero_protocolo(e.target.value)
                        }}
                        value={numero_protocolo}
                    />
                </div>

                <button
                    className='buttom fixToCenter'
                    style={{ marginTop: 20, padding: 10, width: 100 }}
                    onClick={async () => {
                        const filters = [horario_inicio, estacao]
                        for (let filtro in filters) {
                            // console.log(filters[filtro])
                            if (filters[filtro] === '') {
                                return (alert('Todos os campos devem ser preenchidos'))
                            }
                        }

                        let data = new Date().getTime()
                        let tempdata = {
                            ...dataTicket,
                            horario_inicio,
                            qual_o_problema,
                            estacao,
                            responsabilidade: responsavel,
                            clientes_afetados: clientesAfetados,
                            numero_protocolo,
                            slot_pon: slotPon,

                            // customer_id: id,
                            // customer_name: name,
                            userId: profile_user.id,
                            userName: profile_user.nome,
                            userLevel: profile_user.nivel,
                            data_insercao: data
                        }
                        await api_bd.post('/outages', tempdata)
                        await buscarEventos()
                        // console.log(resp.data)
                        props.callbackfn()
                    }}
                >
                    Salvar
                </button>
            </div>
        )
    }


    const TicketOption = ({ option }) => {
        // console.log('Data Ticket TIPO EVENTO: ',dataTicket.tipo_evento)
        switch (option) {
            // case 'Rompimento':
            //     return <Rompimento />
            //     break;
            case 'Estação Indisponível':
                return <EstacaoIndisponivel />
                break;
            case 'Link Com Problema':
                return <LinkComProblema />
                break;
            case 'Falha Elétrica':
                return <FalhaEletrica />
                break;
            case 'Falha de OLT':
                return <FalhaDeOlt />
                break;

            default:
                return <div />
                break;
        }
    }

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            padding: 15,
            overflow: 'hidden',
        }}>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', height: 60, maxHeight: 60, width: '100%', marginBottom: 30, overflow: 'hidden' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 60, height: 60, maxWidth: 60, maxHeight: 60 }}>
                    <span className="material-icons left_icon" style={{ margin: 0, fontSize: '4em' }}>{eventFormIcon}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginLeft: 20 }}>
                    <span style={{ margin: 0, fontSize: '2.5em' }}>{dataTicket.tipo_evento}</span>
                </div>
            </div>

            <div ref={refComp1}
                className='hidden_out'
                style={{
                    position: 'absolute',
                    width: '90%'
                }}
            >
                <FormControl style={{ width: '100%' }}>
                    <InputLabel htmlFor="select-event-type">TIPO DE EVENTO</InputLabel>
                    <Select
                        defaultValue="Selecione o tipo do evento"
                        labelId="select-event-type"
                        id="situacao"
                        style={{ width: '100%' }}
                        onChange={e => {
                            handle_data('tipo_evento', e.target.value)
                            // handle_data_client("situacao", e.target.value)
                        }}
                        required={true}
                    >
                        <MenuItem value="Selecione o tipo do evento">Selecione o tipo do evento</MenuItem>
                        {/* <MenuItem value="Rompimento">Rompimento</MenuItem> */}
                        <MenuItem value="Estação Indisponível">Estação Indisponível</MenuItem>
                        <MenuItem value="Link Com Problema">Link Com Problema</MenuItem>
                        <MenuItem value="Falha Elétrica">Falha Elétrica</MenuItem>
                        <MenuItem value="Falha de OLT">Falha de OLT</MenuItem>
                    </Select>
                </FormControl>

                <button
                    className={dataTicket.tipo_evento === "Selecione o tipo do evento" ? 'buttom-disabled fixToCenter' : 'buttom fixToCenter'}
                    disabled={dataTicket.tipo_evento === "Selecione o tipo do evento" ? true : false}
                    style={{ marginTop: 20, padding: 10, width: 100, border: 'none' }}
                    onClick={() => {
                        // console.log(dataTicket)
                        if (dataTicket.tipo_evento === "Selecione o tipo do evento") {
                            return (alert('Selecione um tipo de evento para continuar'))
                        } else {
                            setActualComp(old => {
                                return old === 2 ? 1 : old + 1
                            })
                        }
                    }}
                >
                    Avançar
                </button>

            </div>

            <div ref={refComp2}
                className='hidden_out'
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'absolute',
                    width: '94%',
                    maxHeight: 'calc(100% - 105px)',
                    overflowY: 'auto'
                }}
            >
                <TicketOption option={dataTicket.tipo_evento} />
            </div>
        </div>
    );
}

export default NewOutage;