import React from 'react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import navigationState from '../atoms/navigate_state.atom'

// import { Container } from './styles';

function MonitoramentoOpts() {

    const [navigation, set_navigation] = useRecoilState(navigationState)

    /**
     * 
     * set_navigation({ page_content: 'monitoramento' })
        if (window.location.pathname !== '/') {
            window.location.href = '/'
        }
     */

    return (
        <div className='container'>
            <div className='card flexRow center-h' style={{ boxSizing: 'border-box', padding: 30 }}>
                <h2 style={{fontSize: '1.4em', fontWeigth: 'bolder'}}>SELECIONE UMA OPÇÃO ABAIXO</h2>
            </div>
            <div className='card flexRow center-h' style={{ boxSizing: 'border-box', padding: 30 }}>
                <div
                    className='card center-h hover-opacity hover-transform'
                    style={{ width: '30%', backgroundColor: 'var(--primary-color)', color: '#fff' }}
                    onClick={() => {
                        set_navigation({ page_content: 'monitoramento_noturno' })
                        if (window.location.pathname !== '/') {
                            window.location.href = '/'
                        }
                    }}
                >
                    <span style={{ fontSize: '1.4em', fontWeight: 'bold', marginLeft: 0 }}>MONITORAMENTO NOTURNO</span>
                </div>
                <div
                    className='card center-h hover-opacity hover-transform'
                    style={{ width: '30%', backgroundColor: 'var(--primary-color)', color: '#fff' }}
                    onClick={() => {
                        set_navigation({ page_content: 'outages_adm' })
                        if (window.location.pathname !== '/') {
                            window.location.href = '/'
                        }
                    }}
                >
                    <span style={{ fontSize: '1.4em', fontWeight: 'bold', marginLeft: 0 }}>OUTAGES</span>
                </div>
            </div>
        </div>
    );
}

export default MonitoramentoOpts;